import PadDetailView from 'routes/apps/pad/Candidates'

import { SortOrders } from 'enums/SortOrders'

import { IDTreatments360ClinicalTrialsModel } from 'config/common/baseModel/idtreatments360/IDTreatment360ClinicalTrials'
import { IDTreatments360CandidateModel } from 'config/common/baseModel/idtreatments360/IDTreatments360Candidates'
import {
  IDTreatments360RegulatoryModel,
  IDTreatments360DealsData,
} from 'config/common/baseModel/idtreatments360/IDTreatments360Deals'
import { IDTreatments360MediaModel } from 'config/common/baseModel/idtreatments360/IDTreatments360Media'
import { IDTreatments360PressReleasesModel } from 'config/common/baseModel/idtreatments360/IDTreatments360PressReleases'

import { groupByFnDefault } from 'utils/grouping'
import { compose, renameColumn, whiteListColumn } from 'utils/overrideModel'

export const PadCandidateRankingModel = compose<
  typeof IDTreatments360CandidateModel
>(
  whiteListColumn([
    'linkAfTrUniqueTreatmentNameToBnf',
    'latestPhasePad',
    'linkCompanies',
    'researchInstitutions',
    'treatmentNameAlias',
    'linkInfectiousDisease',
    'luPathogen',
    'luPathogenType',
    'luPathogenFamily',
    'treatmentTiming',
    'lookupAfTrRouteOfAdministrationFromBnf',
    'lookupAfTrTypeFromBnf',
    'targetTreatments',
    'antiviralMechanismClass',
    'stageOfIntervention',
    'patientSettingFromClinicalTrialsRollup',
    'viralStrainTargeted',
  ]),
  renameColumn('treatmentTiming', 'Category'),
  renameColumn(
    'lookupAfTrRouteOfAdministrationFromBnf',
    'Routes of Administration'
  )
)({
  ...IDTreatments360CandidateModel,
  name: 'Candidates',
  defaultSortKey: 'latestPhasePad',
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: PadDetailView,
  quickFilter: 'luPathogenFamily',
})

export const PadClinicalTrialModel = compose<
  typeof IDTreatments360ClinicalTrialsModel
>(
  whiteListColumn([
    'govId',
    'title',
    'linkInfectiousDisease',
    'luPathogen',
    'luPathogenFamily',
    'acronym',
    'afUniqueTreatmentName',
    'afTrType',
    'luAntiviralClass',
    'linkFundingBodyOrg',
    'phasesClean',
    'status',
    'afStartDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'participants',
    'studyTypeClean',
    'ages',
    'patientSetting',
    'routesOfAdministrationClinicalTrial',
    'primaryOutcomeMeasures',
    'linkLocations',
    'url',
  ]),
  renameColumn('afUniqueTreatmentName', 'Candidate'),
  renameColumn('afTrType', 'Candidate Type'),
  renameColumn('linkFundingBodyOrg', 'Sponsors'),
  renameColumn('phasesClean', 'Phase')
)({
  ...IDTreatments360ClinicalTrialsModel,
  name: 'Clinical Trial',
  quickFilter: 'luPathogenFamily',
})

export const PadPressReleasesModel = whiteListColumn<
  typeof IDTreatments360PressReleasesModel
>([
  'title',
  'sourceUrl',
  'datePublished',
  'vaccine',
  'company',
  'afTags',
  'niaidPathogenFromLinkInfectiousDisease',
  'linkInfectiousDisease',
])({ ...IDTreatments360PressReleasesModel })

export const PadMediaModel = whiteListColumn<typeof IDTreatments360MediaModel>([
  'title',
  'description',
  'vaccine',
  'luCompany',
  'datePublished',
  'linkInfectiousDisease',
  'niaidPathogenFromLinkInfectiousDisease',
  'source',
  'afTopic',
  'afTags',
  'link',
])({ ...IDTreatments360MediaModel })

const whiteListRegulatoryColumns: IDTreatments360DealsData[] = [
  'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
  'afDecisionDate',
  'countryOrganisationGrantingDecision',
  'organisationsRegulators',
  'approvalType',
  'indication',
  'patientSetting',
  'ttApprovalAgeWb',
  'linkInfectiousDisease',
  'approvalLabel',
  'pressReleases',
  'media',
  'supportingDocumentsUrlApprovalsFda',
  'clinicalTrials',
]

export const PadRegulatoryCountryModel = whiteListColumn<
  typeof IDTreatments360RegulatoryModel
>(whiteListRegulatoryColumns)({
  ...IDTreatments360RegulatoryModel,
  grouping: {
    groupBy: 'countryOrganisationGrantingDecision',
    parentColumns: ['countryOrganisationGrantingDecision'],
    groupByFn: groupByFnDefault,
  },
  defaultSortObject: [
    { id: 'countryOrganisationGrantingDecision', sortOrder: SortOrders.ASC },
  ],
  endpoint: 'approval',
})

export const PadRegulatoryTreatmentModel = whiteListColumn<
  typeof IDTreatments360RegulatoryModel
>(whiteListRegulatoryColumns)({
  ...IDTreatments360RegulatoryModel,
  grouping: {
    groupBy: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
    parentColumns: ['linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'],
    groupByFn: groupByFnDefault,
  },
  defaultSortKey: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
  defaultSortOrder: SortOrders.ASC,
  endpoint: 'approval',
})
