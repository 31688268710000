import { Box } from '@chakra-ui/react'
import { forwardRef } from 'react'

import EntityTag, { TagComponentProp } from 'modules/EntityTags'

import { Tag } from 'components'

import type {
  FileObject,
  LinkObject,
  RelationObject,
  ResponseData,
} from 'api/types'

type PropTypes = {
  value: ResponseData
  columnWidth: number
  isRelational?: boolean
  colours?: Record<string, string>
  wrap?: boolean
}

export default function Tags({
  columnWidth,
  value,
  colours,
  wrap,
  ...rest
}: PropTypes) {
  if (!value) return null

  const val: Array<string | RelationObject | number | FileObject | LinkObject> =
    (
      !Array.isArray(value)
        ? typeof value === 'string'
          ? value.split(',')
          : [value]
        : value
    )?.sort()

  if (!val) {
    return null
  }

  return (
    <Box display='flex' flexWrap='nowrap' pt={1} width='100%' {...rest}>
      {val.map((v, i) => {
        return (
          <Box key={i}>
            {/* This is so that the hoverable box is just the tag itself */}
            <Box display='inline-flex'>
              <EntityTag
                value={v}
                component={TagComponents}
                colours={colours}
                wrap={wrap}
                columnWidth={columnWidth}
              />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const TagComponents = forwardRef<any, TagComponentProp>(
  ({ value, isRelational, colours, wrap }, ref) => {
    return (
      <Tag
        ref={ref}
        color={colours && colours[value] ? colours[value] : 'gray.200'}
        whiteSpace={wrap ? 'normal' : 'nowrap'}
        mr={1}
        mb={1}
        fontSize={'12px'}
        isRelational={isRelational}
      >
        {value}
      </Tag>
    )
  }
)
