import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createElement, useState } from 'react'

import { FullPageLoading } from 'components'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'
import Filter from 'components/Table/Toolbar/FilterButton'

import { flattenPaginatedData } from 'api/helper'
import { IResponseBase, Paginated } from 'api/types'
import { useCollectionDataRaw } from 'api/useTableData/useCollectionDataNew'

import { IChart } from 'interfaces/charts.interface'
import { VisualisationPageComponentProps } from 'interfaces/navigationPage.interface'

import { getCustomSortOrderFromModel } from 'utils/model'
import useIsMobile from 'utils/useIsMobile'

import { useTablePageData } from '../TablePageProvider'
import { TableViewSelector } from '../Views/TableViewSelector'
import Dial from './Dial'

export default ({
  charts,
  views,
  selectedView,
  setView,
}: VisualisationPageComponentProps) => {
  const [isMobile] = useIsMobile()
  const { app, model, viewData, page } = useTablePageData()

  const {
    customCharts: CustomChart,
    showStandardCharts = true,
    hideAnalyticsFilter = false,
  } = model || {}

  const [advancedFilters] = useState<SingleAdvancedFilter<any, any>[]>([])

  const collectionDataQuery = useCollectionDataRaw(
    app.slug,
    model.endpoint,
    { view: viewData.airtableName, airtableBase: viewData.airtableBase },
    {
      // We don't use these states
      sortBy: [],
      pageIndex: 1,
      perPage: -1,
      extraState: {},
      globalFilter: { columns: [], searchString: '' },
      // But we use the advanced filters
      advancedFilters,
      schema: [],
    },
    {
      paginate: false,
      customSortOrder: getCustomSortOrderFromModel(model),
      grouped: false,
      key: page.key,
    },
    {
      customAPIFunction: model.customAPIFunction,
    },
    {
      keepPreviousData: true,
    }
  ) as UseInfiniteQueryResult<Paginated<IResponseBase[]>, AxiosError<any>>

  const { isLoading } = collectionDataQuery

  const flattenedPaginatedData = flattenPaginatedData(
    collectionDataQuery.data?.pages
  )

  const data = flattenedPaginatedData?.results as any

  const chartData = model.customChartData ? model.customChartData(data) : data
  if (!chartData) {
    return <FullPageLoading />
  }
  return (
    <Box flex={1} py={2}>
      <Flex>
        {!hideAnalyticsFilter && (
          <Box bg='white' rounded='md' display='inline-block' mb={3}>
            <Filter model={model} data={chartData ?? []} />
          </Box>
        )}
        {setView && views && selectedView && (
          <TableViewSelector
            views={views}
            selectedView={selectedView}
            handleSetView={setView}
          />
        )}
      </Flex>
      {CustomChart && (
        <Box mb='1rem'>
          <CustomChart data={chartData ?? []} />
        </Box>
      )}
      {showStandardCharts && (
        <SimpleGrid columns={isMobile ? 1 : 2} spacing={4}>
          {charts.map((chart: IChart, i) => {
            const filteredData = chart?.customDataFilter
              ? chart?.customDataFilter(chartData ?? [])
              : (chartData ?? [])
            if (!!chart.customComponent) {
              return createElement(chart.customComponent, {
                model,
                isLoading,
                data: filteredData,
                chart,
              })
            }

            return (
              <Box display='flex' key={i}>
                <Dial
                  model={model}
                  isLoading={isLoading}
                  data={filteredData}
                  chart={chart}
                />
              </Box>
            )
          })}
        </SimpleGrid>
      )}
    </Box>
  )
}
