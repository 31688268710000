export default {
  ABW: 'Aruba',
  AFG: 'Afghanistan',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ALA: 'Åland Islands',
  ALB: 'Albania',
  AND: 'Andorra',
  ARE: 'United Arab Emirates',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ASM: 'American Samoa',
  ATA: 'Antarctica',
  ATF: 'French Southern Territories',
  ATG: 'Antigua and Barbuda',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BDI: 'Burundi',
  BEL: 'Belgium',
  BEN: 'Benin',
  BES: 'Bonaire, Sint Eustatius and Saba',
  BFA: 'Burkina Faso',
  BGD: 'Bangladesh',
  BGR: 'Bulgaria',
  BHR: 'Bahrain',
  BHS: 'Bahamas',
  BIH: 'Bosnia and Herzegovina',
  BLM: 'Saint Barthélemy',
  BLR: 'Belarus',
  BLZ: 'Belize',
  BMU: 'Bermuda',
  BOL: 'Bolivia (Plurinational State of)',
  BRA: 'Brazil',
  BRB: 'Barbados',
  BRN: 'Brunei Darussalam',
  BTN: 'Bhutan',
  BVT: 'Bouvet Island',
  BWA: 'Botswana',
  CAF: 'Central African Republic',
  CAN: 'Canada',
  CCK: 'Cocos (Keeling) Islands',
  CHE: 'Switzerland',
  CHL: 'Chile',
  CHN: 'China',
  CIV: "Côte d'Ivoire",
  CMR: 'Cameroon',
  COD_1: 'Congo, Democratic Republic of the',
  COD: 'Congo, The Democratic Republic of the',
  COG: 'Congo',
  COK: 'Cook Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  CPV: 'Cabo Verde',
  CRI: 'Costa Rica',
  CUB: 'Cuba',
  CUW: 'Curaçao',
  CXR: 'Christmas Island',
  CYM: 'Cayman Islands',
  CYP: 'Cyprus',
  CZE: 'Czechia',
  DEU: 'Germany',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DNK: 'Denmark',
  DOM: 'Dominican Republic',
  DZA: 'Algeria',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  ERI: 'Eritrea',
  ESH: 'Western Sahara',
  ESP: 'Spain',
  EST: 'Estonia',
  ETH: 'Ethiopia',
  EUR: 'European Union (EU)',
  FIN: 'Finland',
  FJI: 'Fiji',
  FLK: 'Falkland Islands (Malvinas)',
  FRA: 'France',
  FRO: 'Faroe Islands',
  FSM: 'Micronesia (Federated States of)',
  GAB: 'Gabon',
  GBR: 'United Kingdom',
  GEO: 'Georgia',
  GGY: 'Guernsey',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GIN: 'Guinea',
  GLP: 'Guadeloupe',
  GMB: 'Gambia',
  GNB: 'Guinea-Bissau',
  GNQ: 'Equatorial Guinea',
  GRC: 'Greece',
  GRD: 'Grenada',
  GRL: 'Greenland',
  GTM: 'Guatemala',
  GUF: 'French Guiana',
  GUM: 'Guam',
  GUY: 'Guyana',
  HKG: 'Hong Kong',
  HMD: 'Heard Island and McDonald Islands',
  HND: 'Honduras',
  HRV: 'Croatia',
  HTI: 'Haiti',
  HUN: 'Hungary',
  IDN: 'Indonesia',
  IMN: 'Isle of Man',
  IND: 'India',
  IOT: 'British Indian Ocean Territory',
  IRL: 'Ireland',
  IRN: 'Iran (Islamic Republic of)',
  IRQ: 'Iraq',
  ISL: 'Iceland',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JEY: 'Jersey',
  JOR: 'Jordan',
  JPN: 'Japan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KGZ: 'Kyrgyzstan',
  KHM: 'Cambodia',
  KIR: 'Kiribati',
  KNA: 'Saint Kitts and Nevis',
  KOR: 'Korea, Republic of',
  KWT: 'Kuwait',
  LAO: "Lao People's Democratic Republic",
  LBN: 'Lebanon',
  LBR: 'Liberia',
  LBY: 'Libya',
  LCA: 'Saint Lucia',
  LIE: 'Liechtenstein',
  LKA: 'Sri Lanka',
  LSO: 'Lesotho',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  LVA: 'Latvia',
  MAC: 'Macao',
  MAF: 'Saint Martin (French part)',
  MAR: 'Morocco',
  MCO: 'Monaco',
  MDA: 'Moldova, Republic of',
  MDG: 'Madagascar',
  MDV: 'Maldives',
  MEX: 'Mexico',
  MHL: 'Marshall Islands',
  MKD: 'North Macedonia',
  MLI: 'Mali',
  MLT: 'Malta',
  MMR: 'Myanmar',
  MNE: 'Montenegro',
  MNG: 'Mongolia',
  MNP: 'Northern Mariana Islands',
  MOZ: 'Mozambique',
  MRT: 'Mauritania',
  MSR: 'Montserrat',
  MTQ: 'Martinique',
  MUS: 'Mauritius',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MYT: 'Mayotte',
  NAM: 'Namibia',
  NCL: 'New Caledonia',
  NER: 'Niger',
  NFK: 'Norfolk Island',
  NGA: 'Nigeria',
  NIC: 'Nicaragua',
  NIU: 'Niue',
  NLD: 'Netherlands',
  NOR: 'Norway',
  NPL: 'Nepal',
  NRU: 'Nauru',
  NZL: 'New Zealand',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PAN: 'Panama',
  PCN: 'Pitcairn',
  PER: 'Peru',
  PHL: 'Philippines',
  PLW: 'Palau',
  PNG: 'Papua New Guinea',
  POL: 'Poland',
  PRI: 'Puerto Rico',
  PRK: "Korea (Democratic People's Republic of)",
  PRT: 'Portugal',
  PRY: 'Paraguay',
  PSE: 'Palestine, State of',
  PYF: 'French Polynesia',
  QAT: 'Qatar',
  REU: 'Réunion',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  SAU: 'Saudi Arabia',
  SDN: 'Sudan',
  SEN: 'Senegal',
  SGP: 'Singapore',
  SGS: 'South Georgia and the South Sandwich Islands',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha',
  SJM: 'Svalbard and Jan Mayen',
  SLB: 'Solomon Islands',
  SLE: 'Sierra Leone',
  SLV: 'El Salvador',
  SMR: 'San Marino',
  SOM: 'Somalia',
  SPM: 'Saint Pierre and Miquelon',
  SRB: 'Serbia',
  SSD: 'South Sudan',
  STP: 'Sao Tome and Principe',
  SUR: 'Suriname',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SWE: 'Sweden',
  SWZ: 'Eswatini',
  SXM: 'Sint Maarten (Dutch part)',
  SYC: 'Seychelles',
  SYR: 'Syrian Arab Republic',
  TCA: 'Turks and Caicos Islands',
  TCD: 'Chad',
  TGO: 'Togo',
  THA: 'Thailand',
  TJK: 'Tajikistan',
  TKL: 'Tokelau',
  TKM: 'Turkmenistan',
  TLS: 'Timor-Leste',
  TON: 'Tonga',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TUV: 'Tuvalu',
  TWN: 'Taiwan, Province of China',
  TZA: 'Tanzania, United Republic of',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  UMI: 'United States Minor Outlying Islands',
  URY: 'Uruguay',
  USA: 'United States of America',
  UZB: 'Uzbekistan',
  VAT: 'Holy See',
  VCT: 'Saint Vincent and the Grenadines',
  VEN: 'Venezuela (Bolivarian Republic of)',
  VGB: 'Virgin Islands (British)',
  VIR: 'Virgin Islands (U.S.)',
  VNM: 'Viet Nam',
  VUT: 'Vanuatu',
  WLF: 'Wallis and Futuna',
  WSM: 'Samoa',
  YEM: 'Yemen',
  ZAF: 'South Africa',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
}

export const countryColors = {
  ABW: '#FF6B6B',
  AFG: '#4ECDC4',
  AGO: '#45B7D1',
  AIA: '#96CEB4',
  ALA: '#FFEEAD',
  ALB: '#D4A5A5',
  AND: '#9B59B6',
  ARE: '#3498DB',
  ARG: '#E74C3C',
  ARM: '#2ECC71',
  ASM: '#F1C40F',
  ATA: '#95A5A6',
  ATF: '#D35400',
  ATG: '#8E44AD',
  AUS: '#2980B9',
  AUT: '#27AE60',
  AZE: '#F39C12',
  BDI: '#C0392B',
  BEL: '#16A085',
  BEN: '#7F8C8D',
  BES: '#FF4757',
  BFA: '#546E7A',
  BGD: '#2196F3',
  BGR: '#4CAF50',
  BHR: '#FF9800',
  BHS: '#9C27B0',
  BIH: '#795548',
  BLM: '#607D8B',
  BLR: '#E91E63',
  BLZ: '#009688',
  BMU: '#673AB7',
  BOL: '#FFC107',
  BRA: '#00BCD4',
  BRB: '#8BC34A',
  BRN: '#CDDC39',
  BTN: '#FF5722',
  BVT: '#795548',
  BWA: '#9E9E9E',
  CAF: '#3F51B5',
  CAN: '#F44336',
  CCK: '#4CAF50',
  CHE: '#FF9800',
  CHL: '#03A9F4',
  CHN: '#E91E63',
  CIV: '#FFC107',
  CMR: '#009688',
  COD: '#673AB7',
  COG: '#FF5722',
  COK: '#795548',
  COL: '#9E9E9E',
  COM: '#3F51B5',
  CPV: '#2196F3',
  CRI: '#4CAF50',
  CUB: '#FF9800',
  CUW: '#9C27B0',
  CXR: '#795548',
  CYM: '#607D8B',
  CYP: '#FF4081',
  CZE: '#7C4DFF',
  DEU: '#536DFE',
  DJI: '#448AFF',
  DMA: '#40C4FF',
  DNK: '#18FFFF',
  DOM: '#64FFDA',
  DZA: '#69F0AE',
  ECU: '#B2FF59',
  EGY: '#EEFF41',
  ERI: '#FFD740',
  ESH: '#FFAB40',
  ESP: '#FF6E40',
  EST: '#8D6E63',
  ETH: '#78909C',
  FIN: '#FF8A80',
  FJI: '#FF80AB',
  FLK: '#EA80FC',
  FRA: '#B388FF',
  FRO: '#8C9EFF',
  FSM: '#82B1FF',
  GAB: '#80D8FF',
  GBR: '#84FFFF',
  GEO: '#A7FFEB',
  GGY: '#B9F6CA',
  GHA: '#CCFF90',
  GIB: '#F4FF81',
  GIN: '#FFE57F',
  GLP: '#FFD180',
  GMB: '#FF9E80',
  GNB: '#FF9E80',
  GNQ: '#FCE4EC',
  GRC: '#F3E5F5',
  GRD: '#EDE7F6',
  GRL: '#E8EAF6',
  GTM: '#E3F2FD',
  GUF: '#E1F5FE',
  GUM: '#E0F7FA',
  GUY: '#E0F2F1',
  HKG: '#E8F5E9',
  HMD: '#F1F8E9',
  HND: '#F9FBE7',
  HRV: '#FFFDE7',
  HTI: '#FFF8E1',
  HUN: '#FFF3E0',
  IDN: '#FBE9E7',
  IMN: '#EFEBE9',
  IND: '#FAFAFA',
  IOT: '#ECEFF1',
  IRL: '#263238',
  IRN: '#1B5E20',
  IRQ: '#B71C1C',
  ISL: '#880E4F',
  ISR: '#4A148C',
  ITA: '#311B92',
  JAM: '#1A237E',
  JEY: '#0D47A1',
  JOR: '#01579B',
  JPN: '#006064',
  KAZ: '#004D40',
  KEN: '#1B5E20',
  KGZ: '#33691E',
  KHM: '#827717',
  KIR: '#F57F17',
  KNA: '#FF6F00',
  KOR: '#E65100',
  KWT: '#BF360C',
  LAO: '#3E2723',
  LBN: '#212121',
  LBR: '#DD2C00',
  LBY: '#FF3D00',
  LCA: '#FF6D00',
  LIE: '#FF9100',
  LKA: '#FFC400',
  LSO: '#FFEA00',
  LTU: '#C6FF00',
  LUX: '#76FF03',
  LVA: '#00E676',
  MAC: '#00E5FF',
  MAF: '#2979FF',
  MAR: '#304FFE',
  MCO: '#6200EA',
  MDA: '#AA00FF',
  MDG: '#C51162',
  MDV: '#D50000',
  MEX: '#FF1744',
  MHL: '#FF4081',
  MKD: '#7C4DFF',
  MLI: '#536DFE',
  MLT: '#448AFF',
  MMR: '#40C4FF',
  MNE: '#18FFFF',
  MNG: '#64FFDA',
  MNP: '#69F0AE',
  MOZ: '#B2FF59',
  MRT: '#EEFF41',
  MSR: '#FFD740',
  MTQ: '#FFAB40',
  MUS: '#FF6E40',
  MWI: '#A1887F',
  MYS: '#90A4AE',
  MYT: '#EF9A9A',
  NAM: '#F48FB1',
  NCL: '#CE93D8',
  NER: '#B39DDB',
  NFK: '#9FA8DA',
  NGA: '#90CAF9',
  NIC: '#81D4FA',
  NIU: '#80DEEA',
  NLD: '#80CBC4',
  NOR: '#A5D6A7',
  NPL: '#C5E1A5',
  NRU: '#E6EE9C',
  NZL: '#FFF59D',
  OMN: '#FFE082',
  PAK: '#FFCC80',
  PAN: '#FFAB91',
  PCN: '#BCAAA4',
  PER: '#B0BEC5',
  PHL: '#FF8A65',
  PLW: '#FFB74D',
  PNG: '#FFF176',
  POL: '#81C784',
  PRI: '#4DD0E1',
  PRK: '#9575CD',
  PRT: '#7986CB',
  PRY: '#64B5F6',
  PSE: '#4FC3F7',
  PYF: '#4DB6AC',
  QAT: '#81C784',
  REU: '#AED581',
  ROU: '#DCE775',
  RUS: '#FFF176',
  RWA: '#FFB74D',
  SAU: '#FF8A65',
  SDN: '#A1887F',
  SEN: '#E0E0E0',
  SGP: '#90A4AE',
  SGS: '#F8BBD0',
  SHN: '#E1BEE7',
  SJM: '#D1C4E9',
  SLB: '#C5CAE9',
  SLE: '#BBDEFB',
  SLV: '#B3E5FC',
  SMR: '#B2EBF2',
  SOM: '#B2DFDB',
  SPM: '#C8E6C9',
  SRB: '#DCEDC8',
  SSD: '#F0F4C3',
  STP: '#FFF9C4',
  SUR: '#FFECB3',
  SVK: '#FFE0B2',
  SVN: '#FFCCBC',
  SWE: '#D7CCC8',
  SWZ: '#CFD8DC',
  SXM: '#FF9E80',
  SYC: '#FF8A80',
  SYR: '#FF80AB',
  TCA: '#EA80FC',
  TCD: '#B388FF',
  TGO: '#8C9EFF',
  THA: '#82B1FF',
  TJK: '#80D8FF',
  TKL: '#84FFFF',
  TKM: '#A7FFEB',
  TLS: '#B9F6CA',
  TON: '#CCFF90',
  TTO: '#F4FF81',
  TUN: '#FFE57F',
  TUR: '#FFD180',
  TUV: '#FF9E80',
  TWN: '#FF8A65',
  TZA: '#A1887F',
  UGA: '#90A4AE',
  UKR: '#9575CD',
  UMI: '#7986CB',
  URY: '#64B5F6',
  USA: '#4FC3F7',
  UZB: '#4DB6AC',
  VAT: '#81C784',
  VCT: '#AED581',
  VEN: '#DCE775',
  VGB: '#FFF176',
  VIR: '#FFB74D',
  VNM: '#FF8A65',
  VUT: '#A1887F',
  WLF: '#90A4AE',
  WSM: '#B39DDB',
  YEM: '#9FA8DA',
  ZAF: '#90CAF9',
  ZMB: '#81D4FA',
  ZWE: '#80DEEA',
}
