import { Box, Text } from '@chakra-ui/react'
import { apps } from 'config/apps'
import { BioriskPages } from 'config/apps/Biorisk/Custom/Biorisk/BioriskPages'
import { createElement } from 'react'

const MpoxApprovalsTable = () => {
  const approvalsPage = BioriskPages['BioriskApprovals']
  return (
    <Box>
      <Text fontWeight={'semibold'} fontSize={'xl'}>
        Mpox Latest Approvals
      </Text>
      {createElement(approvalsPage.component, {
        app: apps['biorisk'],
        views: approvalsPage.views,
        model: {
          ...approvalsPage.model,
          tableHeight: '500px',
          onlyAllowExport: true,
        },
        page: approvalsPage,
      })}
    </Box>
  )
}

export default MpoxApprovalsTable
