import ReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getNewsPressReleasesDefaultWidget } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'

import { IDA360Slugs } from './IDA360Menu'
import { IDA360Pages } from './IDA360Pages'

const MainDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: [''],
  component: () => {
    return (
      <>
        <Dashboard
          widgetTabs={[
            getNewsPressReleasesDefaultWidget({
              view: IDA360Pages['NewsPressReleases'].views[0],
            }),
          ]}
        />
      </>
    )
  },
}

const Reports: ISingleCustomPage<IDA360Slugs> = {
  path: ['reports'],
  component: () => <ReportPage showCategory={false} showAppFilter />,
}

const ResearchBriefs: ISingleCustomPage<IDA360Slugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const ApprovalsMap: ISingleCustomPage<IDA360Slugs> = {
  path: ['regulatory', 'map'],
  component: () => <TableauEmbed path='/IDA360Approvals/IDA360Approvals' />,
}
const PriceDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['supply-price', 'price-dashboard'],
  component: () => (
    <TableauEmbed path='/IDA360Supplydata/VaccinePriceDashboardV2-Fixed' />
  ),
}
const SupplyDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['supply-price', 'supply-dashboard'],
  component: () => (
    <TableauEmbed path='/IDA360Supplydata/VaccineSupplyDashboardV1' />
  ),
}
const RevenueDashboard: ISingleCustomPage<IDA360Slugs> = {
  path: ['revenue', 'revenue-dashboard'],
  component: () => <TableauEmbed path='/RevenueInformation-new/Revenue' />,
}

export const IDA360CustomPages: Record<
  string,
  ISingleCustomPage<IDA360Slugs>
> = {
  MainDashboard,
  Reports,
  ApprovalsMap,
  PriceDashboard,
  SupplyDashboard,
  RevenueDashboard,
  ResearchBriefs,
}
