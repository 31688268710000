import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { flattenPaginatedData } from 'api/helper'
import { ICleanResponse } from 'api/types'
import useCollectionData from 'api/useCollectionData'
import { InlineLoading } from 'components'
import { Select, SelectOption } from 'components/Select'

import { IDTreatment360NewsAndPressReleasesModel } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'
import { IBaseView } from 'interfaces/navigationPage.interface'
import { useEffect, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { formatSort } from 'routes/dashboard/WidgetComponents'
import { Article } from 'routes/dashboard/WidgetComponents/WidgetArticle'
import { useAppRoute } from 'routes/utils'

const itemsPerPage = 5

const LatestMedia = () => {
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, SelectOption<string>>
  >({
    areas: { label: 'All', value: 'All' },
    tags: { label: 'All', value: 'All' },
    candidates: { label: 'All', value: 'All' },
    organisations: { label: 'All', value: 'All' },
  })
  const [articles, setArticles] = useState<ICleanResponse<any>[] | undefined>(
    []
  )
  const [currentPage, setCurrentPage] = useState<number>(1)

  const app = useAppRoute()

  const keyNews = 'keyNewsAlert'

  const view = {
    airtableName: 'outbreak_mpox',
    airtableBase: 'lzdb',
    name: 'Default',
  } as IBaseView<any, any>

  const model = IDTreatment360NewsAndPressReleasesModel
  const sort =
    model.defaultSortObject && model.defaultSortObject.length > 0
      ? formatSort(
          model.defaultSortObject[0].id,
          model.defaultSortObject[0]?.sortOrder
        )
      : model.defaultSortKey
        ? formatSort(model.defaultSortKey, model.defaultSortOrder)
        : ''

  const {
    data: firstPart,
    isFetched: isFetchedFirstPart,
    isError: isErrorFirstPart,
  } = useCollectionData(app, model.endpoint, {
    limit: 200,
    offset: 0,
    sort,
    view: view.airtableName,
    airtableBase: view.airtableBase,
  })
  const {
    data: secondPart,
    isFetched: isFetchedSecondPart,
    isError: isErrorSecondPart,
  } = useCollectionData(app, model.endpoint, {
    limit: 100000,
    offset: 200,
    sort,
    view: view.airtableName,
    airtableBase: view.airtableBase,
  })

  const isFetched = isFetchedFirstPart && isFetchedSecondPart
  const isError = isErrorFirstPart || isErrorSecondPart

  useEffect(() => {
    if (isFetchedFirstPart || isFetchedSecondPart) {
      const firstPartArticles =
        flattenPaginatedData(firstPart?.pages)?.results || []

      const secondPartArticles =
        flattenPaginatedData(secondPart?.pages)?.results || []

      setArticles([...firstPartArticles, ...secondPartArticles])
    }
  }, [firstPart, secondPart, isFetchedFirstPart, isFetchedSecondPart])

  const filteredArticles = useMemo(() => {
    return (
      articles?.filter((article) => {
        return Object.entries(selectedFilters).every(([key, filter]) => {
          if (filter.value === 'All') return true

          const articleValues = article[key]
          // Check if the filter's value exists in the article's array of values
          return (
            Array.isArray(articleValues) && articleValues.includes(filter.value)
          )
        })
      }) ?? []
    )
  }, [articles, selectedFilters])

  const uniqueAreas = useMemo(() => {
    const areas = filteredArticles?.flatMap((article) => article.areas) ?? []
    return [...new Set(areas)]
      .filter((area): area is string => typeof area === 'string')
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
  }, [filteredArticles])

  const uniqueCandidates = useMemo(() => {
    const candidates =
      filteredArticles?.flatMap((article) => article.candidates) ?? []
    return [...new Set(candidates)]
      .filter((candidate): candidate is string => typeof candidate === 'string')
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
  }, [filteredArticles])

  const uniqueOrganisations = useMemo(() => {
    const organisations =
      filteredArticles?.flatMap((article) => article.organisations) ?? []
    return [...new Set(organisations)]
      .filter(
        (organisation): organisation is string =>
          typeof organisation === 'string'
      )
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
  }, [filteredArticles])

  const filterConfigs = [
    {
      key: 'areas',
      label: 'Location',
      options: uniqueAreas,
    },
    {
      key: 'candidates',
      label: 'Candidate',
      options: uniqueCandidates,
    },
    {
      key: 'organisations',
      label: 'Organisation',
      options: uniqueOrganisations,
    },
  ]

  const totalPages = Math.ceil(filteredArticles.length / itemsPerPage)

  const paginatedArticles = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage
    return filteredArticles.slice(startIndex, startIndex + itemsPerPage)
  }, [filteredArticles, currentPage])

  if (isError) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        Sorry, something went wrong loading the analysis and news
      </Flex>
    )
  }
  if (!articles?.length && isFetched) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        No alerts at the moment
      </Flex>
    )
  }
  if (!articles?.length) {
    return (
      <Flex w='100%' alignItems='center' justifyContent='center'>
        <InlineLoading />
      </Flex>
    )
  }

  const updateFilters = (key: string, value: SelectOption<string>) => {
    setSelectedFilters({ ...selectedFilters, [key]: value })
  }

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  return (
    <Box
      w='full'
      border={'1px solid'}
      borderColor='gray3'
      borderRadius='8px'
      px={5}
      pt={4}
    >
      <Flex
        mb={4}
        flexDir={'row'}
        alignItems={'center'}
        gap={4}
        justifyContent={'space-between'}
      >
        <Text minW='max-content' fontWeight={'semibold'} fontSize={'lg'}>
          Latest Media
        </Text>
        <Grid templateColumns={'repeat(4, 1fr)'} gap={2} mt={0}>
          {filterConfigs.map((config) => (
            <Flex flexDir={'row'} key={config.key} alignItems='center'>
              <Text fontWeight={'semibold'} fontSize={'sm'} minW='fit-content'>
                {config.label}:{' '}
              </Text>
              <Box width={'full'} ml={1} w='100px'>
                <Select
                  value={selectedFilters[config.key]}
                  isMulti={false}
                  onChange={(x: any) => updateFilters(config.key, x)}
                  options={[
                    { label: 'All', value: 'All' },
                    ...config.options.map((option) => ({
                      label: option,
                      value: option,
                    })),
                  ]}
                  borderColor='gray.400'
                  backgroundColor='transparent'
                  fontSize={'10px'}
                  height='26px'
                />
              </Box>
            </Flex>
          ))}
        </Grid>
      </Flex>
      <>
        <Box h={'60vh'} w='full' overflow={'hidden'} mt={'5px'} mb='1rem'>
          <Virtuoso
            style={{ height: '53vh' }}
            totalCount={paginatedArticles.length}
            itemContent={(index: number) => {
              const row = paginatedArticles[index]
              return (
                <Article
                  id={row.id}
                  modelEndpoint={model.endpoint}
                  airtableBase={view.airtableBase}
                  key={row.id}
                  url={row.link as string}
                  date={row.publishedAt as string}
                  title={row?.titleDisplay as string}
                  isKeyNews={!!row[keyNews]}
                  trackMediaClick={() => {}}
                />
              )
            }}
          />
          <HStack spacing={2} justify='center' mt={5}>
            <Button
              size={'xs'}
              border={'1px'}
              borderColor={'gray.200'}
              h={8}
              variant={'solid'}
              borderRadius={'sm'}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              let pageNum = currentPage - 2 + i
              if (currentPage <= 3) pageNum = i + 1
              if (currentPage >= totalPages - 2) pageNum = totalPages - 4 + i

              if (pageNum > 0 && pageNum <= totalPages) {
                return (
                  <Button
                    key={pageNum}
                    size='sm'
                    borderColor={'gray.200'}
                    h={8}
                    variant={currentPage === pageNum ? 'solid' : 'outline'}
                    onClick={() => setCurrentPage(pageNum)}
                  >
                    {pageNum}
                  </Button>
                )
              }
              return null
            })}

            <Button
              size={'xs'}
              border={'1px'}
              borderColor={'gray.200'}
              h={8}
              variant={'solid'}
              borderRadius={'sm'}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        </Box>
      </>
    </Box>
  )
}

export default LatestMedia
