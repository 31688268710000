import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { useMemo } from 'react'
import { formatDate } from 'utils/formatDate'
import { getCountryName } from '../..'
import { DataT } from '../utils/types'
import { Row } from './helpers'

dayjs.extend(minMax)

function getProperties(hoverInfo: any) {
  if (!hoverInfo) return null
  const { properties } = hoverInfo.feature || {}
  const allCases = JSON.parse(properties.allCases || '[]') as DataT[]
  return allCases
}

export const MapPopover = ({
  hoverInfo,
  onClick,
}: {
  hoverInfo: any
  onClick: (country: string) => void
}) => {
  const cases = useMemo(() => getProperties(hoverInfo), [hoverInfo])

  if (hoverInfo.isClade) {
    const { properties } = hoverInfo.feature || {}
    if (!properties?.area) return null
    return (
      <Box
        shadow={'md'}
        pos='absolute'
        left={hoverInfo.x}
        top={hoverInfo.y}
        padding='1rem'
        bg='white'
        zIndex={9}
        fontSize={'sm'}
        margin='-0.25rem -0.25rem'
        borderRadius={'xl'}
        onClick={() => onClick(properties?.areaAlpha3)}
      >
        <Row title={'Latest Clade'} value={properties?.clade} />
        <Row title={'Date'} value={properties?.date ?? null} />
        <Row title={'Location'} value={properties?.name} />
        <Row title={'Latest Strain'} value={properties?.strain ?? null} />
      </Box>
    )
  }
  if (!cases) return null

  const startDate = dayjs
    .min(cases.map((c) => dayjs(c.dateStart)))
    .format('YYYY-MM-DD')
  const endDate = dayjs
    .max(cases.map((c) => dayjs(c.dateEnd)))
    .format('YYYY-MM-DD')

  const selectedCase = cases[0]

  const sumCases = cases.reduce((acc, item) => (acc += +item.value), 0)

  const datesFormatted = `${formatDate(startDate)} - ${formatDate(endDate)}`

  return (
    <Box
      shadow={'md'}
      pos='absolute'
      left={hoverInfo.x}
      top={hoverInfo.y}
      padding='1rem'
      bg='white'
      zIndex={9}
      fontSize={'sm'}
      margin='-0.25rem -0.25rem'
      borderRadius={'xl'}
      onClick={() => onClick(cases[0]?.areaAlpha3)}
    >
      <Row title={'Country'} value={getCountryName(selectedCase?.areaAlpha3)} />
      <Row title={'Location'} value={selectedCase?.area} />
      <Row title={'Cases'} value={+sumCases} />
      <Row title={'Dates'} value={datesFormatted ?? null} />
    </Box>
  )
}
