import { Flex } from '@chakra-ui/react'

import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import EpiTopCountries from './Cards/EpiTopCountries'
import EpiCases from './Cards/EpiCases'
import { DemoDataPlaceholder } from '../LeftSection/Cards/EpiDemographicsChart'

const RightSection = ({
  filters,
  dataType,
  selectCountry,
}: {
  filters: FiltersT
  dataType: string
  selectCountry: (country: string) => void
}) => {
  return (
    <Flex flexDir={'column'} gap='0.5rem' w='350px' maxW='350px' minW='350px'>
      <Flex h='100%'>
        <EpiTopCountries filters={filters} selectCountry={selectCountry} />
      </Flex>
      <Flex h='100%'>
        <EpiCases filters={filters} dataType={dataType} />
      </Flex>
      <Flex h='100%'>
        <DemoDataPlaceholder />
        {/* TODO: subnational removal */}
        {/* <EpiDemographicsChart
          filters={filters}
          dataType={dataType}
          selectCountry={selectCountry}
        /> */}
      </Flex>
    </Flex>
  )
}

export default RightSection
