import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { recursiveCamelCase } from 'api/helper'
import { FullPageError, LoadingAnimation } from 'components'

import SummaryDataWrapper from './SummarySection'
import ZoomableLineChart from './ZoomableLineChart'
import FilterSection, { CountryOptions } from './Filters'
import { formatData, getGraphsData } from './helpers'
import { useState } from 'react'
import useAxios from 'api/useAxios'
import moment from 'moment'
import { Information } from '@carbon/icons-react'
import WarningPopover from 'components/PageHeader/WarningPopover'
import UpsellBanner from '../common/UpsellBanner'

const mainDefaultFilters = {
  efficacy: 0.76,
  doseStrategy: 'single',
  targetGroup: ['high_risk'],
  vaccineStart: moment().format('YYYY-MM-DD'),
  vaccineDuration: 12,
}

export const defaultFilters = {
  COD: {
    country: 'COD',
    nDoses: 120000,
    ...mainDefaultFilters,
  },
  UGA: {
    country: 'UGA',
    nDoses: 60000,
    ...mainDefaultFilters,
  },
  BDI: {
    country: 'BDI',
    nDoses: 15000,
    ...mainDefaultFilters,
  },
}

const dataLimitationsMessage = `The Mpox model is a compartmental model with 3 sub-populations designed to reflect the disease progression and reported data of MPox within the central African countries that are currently facing an outbreak. 
\n 
This scenario model forecasts infections and deaths under given vaccine campaign conditions, specifically within the Democratic Republic of the Congo, Burundi and Uganda. There are three key sub-populations within the model. 
\n
The under 18 years old group, the “high-risk” group (which contain healthcare workers, sex workers, the MSM community and the immunocompromised population), and the not-at-risk adults, referred to as the “general population”. As per the reported data, it is assumed that the general population are at low risk and there will be negligible infections.
\n
The default settings of the scenario tool consider single doses of vaccines, at 76% effectiveness. This choice was made based on the current vaccine protocols seen in Central Africa, where there is an estimated efficacy of 76% effectiveness as reported by the WHO 13/09/2024 (https://www.who.int/news/item/13-09-2024-who-prequalifies-the-first-vaccine-against-mpox). 
\n
This data provides a recommended efficacy for double doses of 82%. Please note that the chosen vaccine efficacy input into this tool must reflect the number of doses given and will not change automatically depending on the dosage strategy, to allow for a greater range of vaccine types and efficacies. 
`

export type FiltersT = (typeof defaultFilters)[keyof typeof defaultFilters]

const getCountryName = (data: any) => data?.baseline?.dataDict?.areaName ?? ''

const DataVizWrapper = ({ data }: { data: any }) => {
  const { graphData, keys } = getGraphsData(data)
  const countryName = getCountryName(data)

  const chartData = graphData.map((_data) =>
    formatData(_data, data.chosen, data.baseline)
  )

  return (
    <>
      <SummaryDataWrapper data={data} />
      <Flex flexDir={'row'} gap='1rem' w='100%'>
        {chartData.map((chart, index) => (
          <ZoomableLineChart
            chartData={chart}
            keys={keys}
            key={`${index}-${countryName}`}
          />
        ))}
      </Flex>
    </>
  )
}

const ModellingDashboard = () => {
  const axios = useAxios()
  const [filters, setFilters] = useState(defaultFilters.COD)
  const { data, error, isLoading, isFetched } = useQuery(
    ['mpox-modelling', filters.country, filters],
    async () => {
      const countryCode = filters.country
      const response = await axios.post('/mpox/forecast', {
        iso_code: countryCode,
        vaccs_efficacy: filters.efficacy,
        dose_strategy: filters.doseStrategy,
        target_group: filters.targetGroup,
        number_of_doses: filters.nDoses,
        vaccine_start: filters.vaccineStart,
        vaccine_duration: filters.vaccineDuration,
      })

      const data = response.data.predictions
      return recursiveCamelCase(data)
    }
  )

  if (error) {
    return <FullPageError />
  }

  const countryName = CountryOptions.find(
    (option) => option.value === filters.country
  )

  return (
    <Box>
      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        mt='8px'
        pb='2rem'
      >
        <Flex mb={2} alignItems={'center'}>
          <Box mt={2} mr={-2}>
            <Information />
          </Box>
          <WarningPopover
            split
            title='Methodology and Limitations'
            message={dataLimitationsMessage}
          />
        </Flex>
        <Heading fontSize={'2xl'}>{countryName?.label} - forecasting</Heading>
      </Flex>
      <FilterSection filters={filters} setFilters={setFilters} />
      {!isLoading && isFetched ? (
        <DataVizWrapper data={data} />
      ) : (
        <Flex
          flexDir={'column'}
          justifyContent='center'
          alignItems={'center'}
          h='50vh'
        >
          <LoadingAnimation height='fit-content' />
          <Text fontSize={'xl'} fontWeight='semibold'>
            Simulating
          </Text>
        </Flex>
      )}
      <UpsellBanner />
    </Box>
  )
}

export default ModellingDashboard
