import Case from 'case'
import { camelCase, isArray, isObject } from 'lodash'

import { IResponseBase, Paginated } from './types'

/** Snake cases a variable with the addition that it adds an underscore after a number
 * e.g. snake7Case => snake_7_case
 */
export const snakeCase = (variable: string) => {
  var snake = Case.snake(variable)
  return snake.replace(/([A-Z,a-z])(\d+)/g, '$1_$2')
}

/** Expects an object or an array and recursively changes all the keys, values including nested arrays to camelCase. However it preserves the case of final values */
export const recursiveCamelCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map((value) => recursiveCamelCase(value))
  } else if (isObject(obj)) {
    const newObject: { [key: string]: any } = {}
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = recursiveCamelCase(value)
    })
    return newObject
  }
  return obj
}
/** Expects an object or an array and recursively changes all the keys, values including nested arrays to camelCase. However it preserves the case of final values */
export const simpleRecursiveCamelCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map((value) => recursiveCamelCase(value))
  } else if (isObject(obj)) {
    const newObject: { [key: string]: any } = {}
    Object.entries(obj).forEach(([key, value]) => {
      newObject[key] = recursiveCamelCase(value)
    })
    return newObject
  }
  return obj
}

export const queryBuilder = (options: any) => {
  let params = new URLSearchParams()

  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      const value = options[key]
      if (value) {
        params.append(key, value)
      }
    }
  }

  return params.toString()
}

export function flattenPaginatedData<TResponse extends IResponseBase>(
  data?: Paginated<TResponse[]>[]
): Paginated<TResponse[]> | undefined {
  return {
    count: !!data && data?.length > 0 ? data[0].count : 0,
    globalLoaded: !!data && data?.length > 0 ? data[0].globalLoaded : 0,
    globalTotal: !!data && data?.length > 0 ? data[0].globalTotal : 0,
    results: (data?.map((x) => x.results).flat() as TResponse[]) ?? [],
  }
}
