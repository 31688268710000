export const GraphColors = [
  '#6dc7bb',
  '#FF6F61', // Coral
  '#6B5B95', // Deep Purple
  '#B565A7', // Medium Purple
  '#92A8D1', // Cool Blue
  '#55B4B0', // Soft Turquoise
  '#88B04B', // Moss Green
  '#F7CAC9', // Soft Pink
  '#955251', // Mauve Red
  '#009B77', // Teal Green
  '#DD4124', // Rust Red
  '#45B8AC', // Aqua
  '#EFC050', // Golden Yellow
  '#5B5EA6', // Indigo Blue
  '#9B2335', // Berry Red
  '#DFCFBE', // Sand Beige
  '#E15D44', // Brick Orange
  '#7FCDCD', // Light Cyan
  '#BC243C', // Deep Rose
  '#98B4D4', // Periwinkle
  '#C3447A', // Pink Magenta
  '#F4A460', // Sandy Brown
  '#4682B4', // Steel Blue
  '#D2691E', // Chocolate Brown
  '#FF4500', // Bright Orange
  '#8A2BE2', // Blue Violet
  '#DC143C', // Crimson Red
  '#00CED1', // Dark Turquoise
  '#20B2AA', // Light Sea Green
  '#FFD700', // Gold
  '#32CD32', // Lime Green
  '#8B4513', // Saddle Brown
  '#FF1493', // Deep Pink
  '#00FA9A', // Medium Spring Green
  '#9370DB', // Medium Purple
  '#3CB371', // Medium Sea Green
  '#DAA520', // Goldenrod
  '#C71585', // Medium Violet Red
  '#468499', // Cool Gray Blue
]
