import { Box, Flex, Text } from '@chakra-ui/react'
import { flattenPaginatedData } from 'api/helper'
import useCollectionData from 'api/useCollectionData'
import { InlineLoading } from 'components'

import { IBaseView } from 'interfaces/navigationPage.interface'
import { Virtuoso } from 'react-virtuoso'
import { Article } from 'routes/dashboard/WidgetComponents/WidgetArticle'
import { useAppRoute } from 'routes/utils'
import { Card } from '../../Epidemiology'
import { useEffect } from 'react'

const view = {
  airtableName: 'outbreak_epidemiology_mpox',
  airtableBase: 'lzdb',
  name: 'Default',
} as IBaseView<any, any>

const SimpleCard = ({ h, children }: any) => {
  return (
    <Card minW='100%' h='100%'>
      <Text fontWeight='semibold' mb={2}>
        Mpox latest media
      </Text>
      <Box h={'100%'} w='full' overflow={'hidden'} mt={'10px'}>
        <Flex justifyContent='center' alignItems='center' h='80%'>
          {children}
        </Flex>
      </Box>
    </Card>
  )
}

const LatestMedia = ({ selectedArea }: { selectedArea?: string }) => {
  const app = useAppRoute()

  const keyNews = 'keyNewsAlert'

  const {
    data,
    isFetched,
    isFetching,
    isError,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useCollectionData(app, 'rss-article', {
    limit: 50,
    sort: '-published_at',
    view: view.airtableName,
    airtableBase: view.airtableBase,
  })

  useEffect(() => {
    if (canFetchMore && !isFetching) {
      fetchMore()
    }
  }, [canFetchMore, isFetching, fetchMore])

  const articles = flattenPaginatedData(data?.pages)?.results

  if (isError || (!articles?.length && isFetched) || !articles?.length) {
    const message = isError
      ? 'Sorry, something went wrong loading the media'
      : !articles?.length && isFetched
        ? 'No media at the moment'
        : null

    return (
      <SimpleCard>
        {message ? (
          <Text
            textAlign={'center'}
            color={isError ? 'error' : 'inherit'}
            fontWeight={'semibold'}
          >
            {message}
          </Text>
        ) : (
          <Flex w='100%' alignItems='center' justifyContent='center'>
            <InlineLoading />
          </Flex>
        )}
      </SimpleCard>
    )
  }

  const filteredArticles =
    selectedArea === 'global'
      ? articles
      : articles.filter((article) => {
          if (Array.isArray(article.areasAlpha3)) {
            return article.areasAlpha3.includes(selectedArea || '')
          }
          return article.areasAlpha3 === selectedArea
        })

  return (
    <Card minW='100%' h='100%'>
      <Text fontWeight='semibold' mb={2}>
        Mpox latest media
      </Text>
      {filteredArticles.length === 0 && (
        <Flex justifyContent='center' alignItems='center' h='80%'>
          <Text>No media articles match selected filters.</Text>
        </Flex>
      )}
      <Box
        h={'100%'}
        pb='2rem'
        w='full'
        overflow={'hidden'}
        mt={'5px'}
        ml='-1rem'
        mb='-1rem'
      >
        <Virtuoso
          totalCount={filteredArticles.length}
          endReached={() => {
            canFetchMore && fetchMore()
          }}
          itemContent={(index: number) => {
            const row = filteredArticles[index]
            return (
              <Article
                id={row.id}
                modelEndpoint={'rss-article'}
                airtableBase={view.airtableBase}
                key={row.id}
                url={row.link as string}
                date={row.publishedAt as string}
                title={row?.titleDisplay as string}
                isKeyNews={!!row[keyNews]}
                trackMediaClick={() => {}}
              />
            )
          }}
        />
      </Box>
    </Card>
  )
}

export default LatestMedia
