import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
  defaultDashboardConfig,
} from 'interfaces/navigationPage.interface'

import { BioriskSlugs } from './BioriskMenu'
import {
  BioriskCandidateModel,
  BioriskApprovalsModel,
  BioriskMpoxCandidateModel,
} from './BioriskModels'
import BarChart from 'modules/Tables/Dashboard/AllDashboards/BarChart'
import { IDACandidatesDetailView } from 'routes/apps/ida360/Candidates'

type PandemicNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  BioriskSlugs,
  TModel
>

const BioriskMpoxPipeline: PandemicNavigationPage<
  typeof BioriskCandidateModel
> = {
  key: 'MpoxPipeline',
  model: {
    ...BioriskCandidateModel,
    hideToolbar: true,
    tableFitHeight: true,
    quickFilter: 'candidateName',
    name: 'Mpox Candidates Competitive Landscape',
    newNote: {
      text: 'Click on Candidates for additional information on trials, approvals, news & press releases, revenue and supply.',
      newUntil: new Date(2025, 1, 1),
    },
  },
  component: GenericTable,
  path: null,
  views: [
    {
      name: 'Default',
      airtableName: 'outbreak_pipeline_mpox',
      airtableBase: 'lzdb',
    },
  ],
  autoGenerateDashboard: {
    ...defaultDashboardConfig,
    hideFilters: true,
    isEmbed: true,
    demoEnabled: false,
    render: BarChart,
    cardsRight: true,
    hideViewSelector: true,
    verticalAxisLabel: 'Number of Candidates',
    defaultStackByFilterValue: {
      label: 'Technology Type',
      value: 'technologyType',
    },
    defaultHorizontalFilterValue: {
      label: 'Phase',
      value: 'latestPhaseManual',
    },
    sortingOrders: {
      latestPhaseManual: [
        'Preclinical',
        'Phase 0',
        'Phase I',
        'Phase I/II',
        'Phase II',
        'Phase II/III',
        'Phase III',
        'Approved',
        'Phase IV',
        'Paused',
        'Not Applicable',
        'Unknown',
      ],
    },
  },
}

const BioriskApprovals: PandemicNavigationPage<typeof BioriskApprovalsModel> = {
  key: 'Approvals',
  path: null,
  model: {
    ...BioriskApprovalsModel,
    excludeGeneratedCharts: true,
    hideViewSelector: true,
    quickFilter: 'candidateName',
    hideToolbar: true,
    fullHeader: true,
    hideTableHeader: true,
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'outbreak_approval_mpox',
      airtableBase: 'lzdb',
    },
  ],
}

const BioriskMpoxPipelineTable: PandemicNavigationPage<
  typeof BioriskMpoxCandidateModel
> = {
  key: 'MpoxPipelineTable',
  model: {
    ...BioriskMpoxCandidateModel,
    excludeGeneratedCharts: true,
    hideViewSelector: true,
    detailViewType: 'Generic',
    renderDetailView: IDACandidatesDetailView,
    hideToolbar: true,
    fullHeader: true,
    hideTableHeader: true,
  },
  component: GenericTable,
  path: null,
  views: [
    {
      name: 'Default',
      airtableName: 'outbreak_pipeline_mpox',
      airtableBase: 'lzdb',
    },
  ],
}
export const BioriskPages: INavigationPages<BioriskSlugs> = {
  BioriskMpoxPipeline,
  BioriskApprovals,
  BioriskMpoxPipelineTable,
}
