import { useQuery } from '@tanstack/react-query'
import { Card, FiltersT } from '../..'
import TopCountriesGraph from '../../../common/TopCountries'
import useAxios from 'api/useAxios'
import { SummaryDataT } from '../../Map/utils/types'
import { getQueryData } from 'modules/BioriskMpox/Response/LeftSection/Cards/Procurement'
import { simpleRecursiveCamelCase } from 'api/helper'
import { Flex, Text } from '@chakra-ui/react'
import { LoadingAnimation } from 'components'
import { useState } from 'react'

function getEndpoint(filters: FiltersT, dataType: string) {
  if (filters.dataType === 'infections') {
    if (dataType === 'cases' || dataType === 'cases_per100K') {
      return 'infections'
    }
    if (
      dataType === 'suspected_cases' ||
      dataType === 'suspected_cases_per100K'
    ) {
      return 'suspected-infections'
    }
  }

  return filters.dataType
}

const EpiTopCountries = ({
  filters,
  selectCountry,
}: {
  filters: FiltersT
  selectCountry: (country: string) => void
}) => {
  const [dropDownType, setDropdownType] = useState('cases')

  const axios = useAxios()
  const dataType = getEndpoint(filters, dropDownType)
  const { data, isLoading, error } = useQuery<SummaryDataT>(
    [
      'top-affected-countries',
      filters.dateRange,
      // TODO: subnational removal
      // filters.country,
      filters.dataType,
      dataType,
    ],
    async () => {
      const endpoint = `/lzdb/science-macro/time-series-${dataType}-national-cumulative-summary/?`
      const summaryData = await axios.get(getQueryData(filters, endpoint))
      return simpleRecursiveCamelCase(summaryData?.data ?? {})
    }
  )

  if (!data || isLoading || error) {
    return (
      <Card>
        <Text fontWeight='semibold'>
          Top Affected {filters.country === 'global' ? 'Countries' : 'Regions'}
        </Text>
        <Flex justifyContent={'center'} alignItems='center' h='100%'>
          {error ? (
            <Text color='error'>Something went wrong loading the data</Text>
          ) : (
            <LoadingAnimation />
          )}
        </Flex>
      </Card>
    )
  }
  return (
    <TopCountriesGraph
      selectCountry={selectCountry}
      filters={filters}
      data={data}
      dataType={dropDownType}
      setDataType={setDropdownType}
    />
  )
}

export default EpiTopCountries
