import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { Paginated } from 'api/types'
import { FetchCollectionQueries } from 'api/useCollectionData'

import { apps, appsListUnion } from 'config/apps'

import useAxios from '../../useAxios'

export type AlertType =
  | 'IN'
  | 'HR'
  | 'MR'
  | 'LR'
  | 'UR'
  | 'NR'
  | 'AI'
  | 'ZR'
  | 'WA'

export type AlertIcon = 'PL' | 'CO' | 'DE'

export interface AlertsType {
  title: string
  sub_header: string
  description: string
  type: AlertType
  icon: AlertIcon
  date_published: Date
  diseases: string[]
  areas: string[]
  areas_alpha_3: string[]
  disease_id: number
}

export function useCountryAlerts(
  app: appsListUnion,
  queries: FetchCollectionQueries,
  extraProps?: UseInfiniteQueryOptions<Paginated<AlertsType[]>, AxiosError>,
  extraQueries?: Record<string, unknown>
): UseInfiniteQueryResult<Paginated<AlertsType[]>, AxiosError> {
  const axios = useAxios()

  const query = useInfiniteQuery<Paginated<AlertsType[]>, AxiosError>(
    ['alerts', app, extraQueries],
    async ({ pageParam: offset }) => {
      const response = await axios.get(
        `/${apps[app].endpoint}/analyst-alerts/`,
        {
          params: { ...queries, ...extraQueries, offset },
        }
      )
      return response.data
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalRowsFetched = allPages.reduce(
          (total, val) => total + val?.results?.length,
          0
        )
        const totalOverall = lastPage.count
        if (totalRowsFetched === totalOverall) {
          return false
        }
        const offset = totalRowsFetched
        return offset
      },
      ...extraProps,
    }
  )

  return query
}
