import { Box, SlideFade } from '@chakra-ui/react'
import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import theme from 'theme'

import EntityPreview from 'modules/EntityPreview'

import { useAppRoute } from 'routes/utils'

import { FileObject, LinkObject, RelationObject } from 'api/types'

import { IModel } from 'interfaces/model.interface'

import { apps } from 'config/apps'

import { isRelational } from 'utils/relational'
import resolvePath from 'utils/resolvePath'

export type TagComponentProp = {
  value: string
  isRelational?: boolean
  colours?: Record<string, string>
  wrap?: boolean
}

type PropTypes = {
  value: string | number | RelationObject | FileObject | LinkObject
  component: React.FC<React.PropsWithChildren<TagComponentProp>>
  colours?: Record<string, string>
  wrap?: boolean
  columnWidth: number
}

export default function EntityTag({
  value,
  component: TagComponent,
  colours,
  wrap = false,
  columnWidth,
}: PropTypes) {
  const [isHovered, setIsHovered] = useState(false)
  const app = useAppRoute()

  if (!isRelational(value)) {
    return (
      <TagComponent value={value as string} colours={colours} wrap={wrap} />
    )
  }

  const relationalData = value as RelationObject

  const page = Object.values(apps[app].pages).find(
    (x) => x.model.endpoint === relationalData.endpoint
  )

  let modelInBaseModel

  if (!page) {
    modelInBaseModel = apps[app]?.baseModels?.find(
      (x) => x.endpoint === relationalData.endpoint
    )

    if (!modelInBaseModel) {
      return (
        <TagComponent
          value={relationalData.displayText}
          colours={colours}
          wrap={wrap}
        />
      )
    }
  }

  const model: IModel<any, any> = page
    ? page.model
    : (modelInBaseModel as IModel<any, any>)

  // NOTE: We'll probably need to pass the view here eventually. See: Global search
  const entityLink = page
    ? resolvePath([app, page.path, relationalData.id])
    : null

  // Delay opening the preview window by some time
  const debouncedHover = _.debounce(() => setIsHovered(true), 200)

  return (
    <Box
      onMouseEnter={() => debouncedHover()}
      onMouseLeave={() => {
        debouncedHover.cancel()
        setIsHovered(false)
      }}
    >
      <Popover
        isOpen={isHovered}
        positions={['right', 'left', 'bottom', 'top']}
        content={({ position, childRect, popoverRect }) => (
          <SlideFade in={true} onClick={(e) => e.stopPropagation()}>
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor='lightgrey'
              arrowSize={10}
              arrowStyle={{
                opacity: 0.7,
              }}
            >
              <Box
                bgColor='white'
                width='400px'
                overflow='auto'
                height='container.md'
                boxShadow={theme.boxShadow.xl}
              >
                <EntityPreview
                  model={model}
                  entityData={{
                    id: relationalData.id,
                    endpoint: relationalData.endpoint,
                    displayText: relationalData.displayText,
                    base: relationalData.base,
                  }}
                />
              </Box>
            </ArrowContainer>
          </SlideFade>
        )}
      >
        <Link to={entityLink ?? '#'} onClick={(e) => e.stopPropagation()}>
          <TagComponent
            value={relationalData.displayText}
            isRelational={true}
            colours={colours}
            wrap={wrap}
          />
        </Link>
      </Popover>
    </Box>
  )
}
