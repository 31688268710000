import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react'
import { AlertsType, useCountryAlerts } from 'api/cms/alerts/useCountryAlerts'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'
import { flattenPaginatedData } from 'api/helper'
import { IResponseBase, Paginated } from 'api/types'
import { InlineLoading, Select } from 'components'
import { SelectOption } from 'components/Select'
import _ from 'lodash'

import Alert from 'modules/Pandemic/Alerts/Alert'
import { useEffect, useMemo, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { useAppRoute } from 'routes/utils'
import { getCountryName } from '../Epidemiology'

const itemsPerPage = 5

const SimpleCard = ({ h, children }: any) => {
  return (
    <Box
      h='100%'
      w={'full'}
      border={'1px solid'}
      borderColor='gray3'
      borderRadius='8px'
      overflow={'visible'}
      pl={5}
      pr={1}
      py={4}
    >
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text fontWeight={'semibold'} fontSize={'md'}>
          Latest Alerts
        </Text>
      </Flex>
      <Box
        h={h || 'calc(76vh - 130px)'}
        w='full'
        overflow={'hidden'}
        mt={'10px'}
      >
        <Flex justifyContent='center' alignItems='center' h='80%'>
          {children}
        </Flex>
      </Box>
    </Box>
  )
}

function filterAlerts(
  alerts: AlertsType[],
  selectedArea: SelectOption<string>,
  dateRange?: { start: string; end: string }
) {
  const byArea = alerts.filter((alert) => {
    return selectedArea?.value === 'All'
      ? true
      : selectedArea
        ? alert.areas_alpha_3.includes(selectedArea.value)
        : true
  })
  return byArea
}

const LatestAlerts = ({
  fullPage = true,
  width,
  countryCode,
  pagination = true,
  h,
  dateRange,
}: {
  fullPage?: boolean
  width?: string
  countryCode?: any
  pagination?: boolean
  h?: string
  dateRange?: {
    start: string
    end: string
  }
}) => {
  const selectedApp = useAppRoute()

  const [alerts, setAlerts] = useState<AlertsType[]>([])
  const [selectedArea, setSelectedArea] = useState<SelectOption<string>>({
    value: 'All',
    label: 'All',
  })
  const [currentPage, setCurrentPage] = useState<number>(1)

  const {
    data,
    isFetched,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useCountryAlerts(
    selectedApp,
    {
      limit: 100,
      offset: 0,
    },
    undefined,
    { disease_id: '3292' }
  )

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  useEffect(() => {
    if (isFetched) {
      const allAlerts =
        (flattenPaginatedData(
          data?.pages as unknown as Paginated<IResponseBase[]>[]
        )?.results as unknown as AlertsType[]) || []
      setAlerts(allAlerts)
    }
  }, [data, isFetched])

  useEffect(() => {
    if (countryCode) {
      const selectedCountry = (countryCodeToNameMapping as any)[countryCode]
      if (selectedCountry) {
        setSelectedArea({ value: countryCode, label: selectedCountry })
      } else {
        setSelectedArea({ value: 'All', label: 'All' })
      }
    }
  }, [countryCode])

  const uniqueAreas = useMemo(
    () =>
      [...new Set(alerts.flatMap((alert) => alert.areas_alpha_3))].sort(
        (a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })
      ),
    [alerts]
  )

  if (isError || (!alerts.length && isFetched) || !alerts.length) {
    const message = isError
      ? 'Sorry, something went wrong loading the alerts'
      : !alerts.length && isFetched
        ? 'No alerts at the moment'
        : null

    return (
      <SimpleCard h={h}>
        {message ? (
          <Text
            textAlign={'center'}
            color={isError ? 'error' : 'inherit'}
            fontWeight={'semibold'}
          >
            {message}
          </Text>
        ) : (
          <Flex w='100%' alignItems='center' justifyContent='center'>
            <InlineLoading />
          </Flex>
        )}
      </SimpleCard>
    )
  }

  const filteredAlerts = filterAlerts(alerts, selectedArea, dateRange)

  const totalPages = Math.ceil(filteredAlerts.length / itemsPerPage)

  const paginatedAlerts = pagination
    ? (() => {
        const startIndex = (currentPage - 1) * itemsPerPage
        return filteredAlerts.slice(startIndex, startIndex + itemsPerPage)
      })()
    : filteredAlerts

  const groupedAlerts = _.groupBy(
    paginatedAlerts,
    (alert) => alert.date_published
  )

  const sortedDates = Object.keys(groupedAlerts).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime()
  )

  const displayDates = fullPage ? sortedDates : [sortedDates[0]]
  const total = Object.keys(displayDates).length

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage)
    }
  }

  return (
    <Box
      h='100%'
      w={width || 'full'}
      border={'1px solid'}
      borderColor='gray3'
      borderRadius='8px'
      overflow={'visible'}
      pl={5}
      pr={1}
      py={4}
    >
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text fontWeight={'semibold'} fontSize={'md'}>
          Latest Alerts
        </Text>
        <Flex alignItems={'center'} mr={4}>
          <Text fontWeight={'semibold'} fontSize={'xs'}>
            Location:{' '}
          </Text>
          <Box width={'100px'} ml={1}>
            <Select
              value={selectedArea}
              isMulti={false}
              isSearchable={true}
              onChange={setSelectedArea}
              options={[
                { label: 'All', value: 'All' },
                ...uniqueAreas.map((area) => ({
                  label: getCountryName(area),
                  value: area,
                })),
              ]}
              borderColor='gray.400'
              backgroundColor='transparent'
              fontSize={'10px'}
              height='26px'
            />
          </Box>
        </Flex>
      </Flex>
      <Box
        h={h || 'calc(76vh - 130px)'}
        w='full'
        overflow={'hidden'}
        mt={'10px'}
      >
        {filteredAlerts.length === 0 && isFetched && (
          <Flex justifyContent='center' alignItems='center' h='80%'>
            <Text> No alerts match the selected filters</Text>
          </Flex>
        )}
        {fullPage ? (
          <Virtuoso
            style={{ height: h || 'calc(70vh - 130px)' }}
            totalCount={total}
            itemContent={(index: number) => {
              return (
                <Alert
                  index={index}
                  displayDates={displayDates}
                  groupedAlerts={groupedAlerts}
                  fullPage={false}
                />
              )
            }}
          />
        ) : (
          <Box overflowY='auto' h='100%' mt={8}>
            <Alert
              index={0}
              displayDates={displayDates}
              groupedAlerts={groupedAlerts}
            />
            <Box mb='3rem' />
          </Box>
        )}
        {pagination && (
          <HStack mt={4} spacing={1} justify='center'>
            <Button
              size={'xs'}
              border={'1px'}
              borderColor={'gray.200'}
              h={8}
              variant={'solid'}
              borderRadius={'sm'}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
              let pageNum = currentPage - 2 + i
              if (currentPage <= 3) pageNum = i + 1
              if (currentPage >= totalPages - 2) pageNum = totalPages - 4 + i

              if (pageNum > 0 && pageNum <= totalPages) {
                return (
                  <Button
                    key={pageNum}
                    size='xs'
                    borderColor={'gray.200'}
                    h={6}
                    variant={currentPage === pageNum ? 'solid' : 'outline'}
                    onClick={() => setCurrentPage(pageNum)}
                  >
                    {pageNum}
                  </Button>
                )
              }
              return null
            })}

            <Button
              size={'xs'}
              border={'1px'}
              borderColor={'gray.200'}
              h={8}
              variant={'solid'}
              borderRadius={'sm'}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  )
}

export default LatestAlerts
