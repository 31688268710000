import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

type ProviderType = {
  overlayShown: boolean
  setOverlayShown: React.Dispatch<React.SetStateAction<boolean>>
  globalSearchString: string
  setGlobalSearchString: React.Dispatch<string>
  reset: () => void
  searchParams: URLSearchParams
}

export const GlobalSearchContext = React.createContext<ProviderType>({
  overlayShown: false,
  setOverlayShown: () => {},
  globalSearchString: '',
  setGlobalSearchString: () => {},
  reset: () => {},
  searchParams: new URLSearchParams(),
})

export const useGlobalSearch = (): ProviderType => {
  return useContext(GlobalSearchContext)
}

export const GlobalSearchConsumer = GlobalSearchContext.Consumer

export const GlobalSearchProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [globalSearchString, setGlobalSearchStringRaw] = useState(
    searchParams.get('search') ?? ''
  )
  const [overlayShown, setOverlayShown] = useState(false)

  const setGlobalSearchString = useCallback(
    (newString: string) => {
      setGlobalSearchStringRaw((prevString) => {
        if (prevString === '') {
          setOverlayShown(true)
        }
        return newString
      })
      const newSearchParams = new URLSearchParams(searchParams) // Copy existing params
      newSearchParams.set('search', newString) // Add or update the parameter
      setSearchParams(newSearchParams) // Update the search params in the URL
    },
    [setGlobalSearchStringRaw, setSearchParams, searchParams]
  )

  const reset = useCallback(() => {
    setOverlayShown(false)
    setGlobalSearchStringRaw('')
    searchParams.delete('search')
    setSearchParams(searchParams)
  }, [setGlobalSearchStringRaw, searchParams, setSearchParams])

  useEffect(() => {
    if (searchParams.get('search')) {
      setOverlayShown(true)
    }
  }, [searchParams])

  return (
    <GlobalSearchContext.Provider
      value={{
        overlayShown,
        setOverlayShown,
        globalSearchString,
        setGlobalSearchString,
        reset,
        searchParams,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  )
}
