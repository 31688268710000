import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import { ObesitySlugs } from './ObesityMenu'
import {
  ObesityCandidateRankingModel,
  ObesityMediaModel,
  ObesityPressReleasesModel,
  ObesityPublicationsModel,
  ObesitySocialMediaModel,
  ObesityTrialsModel,
} from './ObesityModels'

import ObesityCandidatesDetailView from 'routes/apps/obesity/CandidateRanking'

type ObesityNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  ObesitySlugs,
  TModel
>

const Candidates: ObesityNavigationPage<typeof ObesityCandidateRankingModel> = {
  key: 'Candidates',
  path: ['candidates', 'table'],
  component: GenericTable,
  model: {
    ...ObesityCandidateRankingModel,
    renderDetailView: ObesityCandidatesDetailView,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity Candidates - Platform View',
      airtableBase: 'cardio360',
    },
    {
      name: 'Pharmacological interventions',
      airtableName:
        'Obesity Candidates - Platform View - Pharmacological interventions',
      airtableBase: 'cardio360',
    },
  ],
}

const Publications: ObesityNavigationPage<typeof ObesityPublicationsModel> = {
  key: 'Publications',
  path: ['publications'],
  component: GenericTable,
  model: {
    ...ObesityPublicationsModel,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity Publications - Platform View',
      airtableBase: 'cardio360',
    },
  ],
}
const ClinicalTrials: ObesityNavigationPage<typeof ObesityTrialsModel> = {
  key: 'ClinicalTrials',
  path: ['trials', 'all-data'],
  component: GenericTable,
  model: {
    ...ObesityTrialsModel,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity trials - Platform View',
      airtableBase: 'cardio360',
    },
    {
      name: 'Industry sponsored',
      airtableName: 'Obesity trials - Platform View - Industry Sponsored',
      airtableBase: 'cardio360',
    },
    {
      name: 'Non-industry sponsored',
      airtableName: 'Obesity trials - Platform View - Non-Industry Sponsored',
      airtableBase: 'cardio360',
    },
  ],
}
const Media: ObesityNavigationPage<typeof ObesityMediaModel> = {
  key: 'Media',
  path: ['media'],
  component: GenericTable,
  model: {
    ...ObesityMediaModel,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity Media - Platform View',
      airtableBase: 'cardio360',
    },
  ],
}
const PressReleases: ObesityNavigationPage<typeof ObesityPressReleasesModel> = {
  key: 'Press Releases',
  path: ['press-releases'],
  component: GenericTable,
  model: {
    ...ObesityPressReleasesModel,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity Press Releases - Platform View',
      airtableBase: 'cardio360',
    },
  ],
}

const SocialMediaListening: ObesityNavigationPage<
  typeof ObesitySocialMediaModel
> = {
  key: 'Social Media',
  path: ['social-media', 'social-media-listening'],
  component: GenericTable,
  model: {
    ...ObesitySocialMediaModel,
  },
  views: [
    {
      name: 'Default',
      airtableName: 'Obesity_Tweets_Platform',
      airtableBase: 'cardio360',
    },
  ],
}

export const ObesityPages: INavigationPages<ObesitySlugs> = {
  Candidates,
  Publications,
  ClinicalTrials,
  Media,
  PressReleases,
  SocialMediaListening,
}
