import { useAxiosRequestConfig } from 'api/useAxios'
import axios from 'axios'

import { assistantsId } from 'routes/common/ai/common/constants'
import {
  ChatT,
  AnswerT,
  ChatHistoryT,
  RoleT,
} from 'routes/common/ai/common/types'

import { useEffect, useRef, useState } from 'react'
import useTracking from 'tracking/useTracking'

async function postMessage(
  message: ChatT,
  threadId: string,
  _assistantId: string,
  baseURL: string,
  authHeader: any,
  cb: (value: any) => void,
  streamCB: (value: any) => void,
  reportId?: number
) {
  const res = await fetch(`${baseURL}/llm-thread/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader,
    },
    body: JSON.stringify({
      messages: message.content,
      threadId,
      _assistantId,
      ...(reportId ? { report_id: reportId } : {}),
    }),
    mode: 'cors',
  })

  if (!res.body) return
  const reader = res.body.getReader()
  const decoder = new TextDecoder()
  const loopRunner = true
  let content = ''
  let sources = []

  while (loopRunner) {
    const { value, done } = await reader.read()
    if (done) {
      break
    }
    const decodedChunk = decoder.decode(value, { stream: true })
    if (decodedChunk.includes('file_citation')) {
      try {
        sources = JSON.parse(decodedChunk)['sources']
      } catch (err) {
        console.error('error parsing sources', err)
      }
    } else {
      content += decodedChunk
      streamCB(decodedChunk)
    }
  }
  cb({
    id: 1,
    role: 'assistant',
    content: content,
    sources: sources,
  })
}
async function getThread(baseURL: string, authHeader: any) {
  try {
    const res = await axios.get(`${baseURL}/llm-thread/`, {
      headers: { ...authHeader },
    })

    if (!res.data) return
    return res.data.thread_id
  } catch (err) {
    console.error('getThread err', err)
  }
}
async function askTsQuestion(
  baseURL: string,
  authHeader: any,
  message: ChatT,
  cb: (value: any) => void
) {
  const res = await axios.post(
    `${baseURL}/ask-ts-question/`,
    {
      question: message.content,
    },
    { headers: { ...authHeader } }
  )
  if (!res.data) return
  const response = res.data[0][0][1][1][1]
  cb({
    id: 1,
    role: 'assistant',
    content: response,
    sources: [],
  })
  return res.data
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function useAskAI() {
  const [threadId, setThread] = useState<string | null>()
  const { baseURL, ...authHeader } = useAxiosRequestConfig()

  const ask = async (
    messages: ChatHistoryT,
    assistantId: string,
    cb: (value: any) => void,
    streamCB: (value: any) => void,
    reportId?: number
  ) => {
    let currentThreadId = threadId

    if (assistantId === assistantsId.ts_infections_biorisk) {
      const response = await askTsQuestion(
        baseURL,
        authHeader.headers,
        messages[messages.length - 1],
        cb
      )
      return response
    }
    if (assistantId === assistantsId.router) {
      await timeout(2000)
      return cb({
        id: 1,
        role: 'assistant',
        content: 'text',
        sources: [],
      })
    }

    if (!currentThreadId) {
      currentThreadId = await getThread(baseURL, authHeader.headers)
      setThread(currentThreadId)
    }

    if (currentThreadId) {
      // TODO: This is to fake the router, should be removed once the tables are working correctly.
      const response = await postMessage(
        messages[messages.length - 1],
        currentThreadId,
        assistantId,
        baseURL,
        authHeader.headers,
        cb,
        streamCB,
        reportId
      )
      return response
    }
  }
  const clearThread = () => {
    setThread(null)
  }
  return { ask, clearThread }
}

const TableMapping = {
  'Table 2': {
    assistant: assistantsId.table_press_releases,
    name: 'Press Releases',
    url: '/biorisk/news/press-releases',
  },
  'Table 3': {
    assistant: assistantsId.table_media,
    name: 'Media',
    url: '/biorisk/news/media',
  },
  'Table 4': {
    assistant: assistantsId.table_disease_information,
    name: 'Disease Information',
    url: '/biorisk/disease-information',
  },
} as { [key: string]: { name: string; url: string; assistant: string } }

const routerMapping = {
  Text: assistantsId.reports,
  'Table 2': TableMapping['Table 2'].assistant,
  'Table 3': TableMapping['Table 3'].assistant,
  'Table 4': TableMapping['Table 4'].assistant,
} as { [key: string]: string }

const DbAssistants = Object.values(TableMapping).map((table) => table.assistant)

const DEFAULT_ASSISTANT = assistantsId.router

const useAI = (
  reportId?: number,
  defaultAssistant?: string,
  reportTitle?: string
) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [error, setIsError] = useState(false)
  const [tracking] = useTracking()

  const [query, setQuery] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')
  const [assistantId, setAssistantID] = useState(DEFAULT_ASSISTANT)

  const [chatHistory, setChatHistory] = useState<ChatHistoryT>([])
  const [streamingAnswer, setStreamingAnswer] = useState<string>('')

  const { ask: askAI, clearThread } = useAskAI()

  const divRef = useRef(null)

  const scrollToBottom = () => {
    if (divRef.current) {
      ;(divRef.current as any).scrollTop = (divRef.current as any).scrollHeight
    }
  }

  const search = async (forceQuery?: string, _id?: string) => {
    const searchQuery = query || forceQuery
    const _assistantID = _id || defaultAssistant || assistantId
    if (!searchQuery) return
    setAnswer('')
    reportId && reportTitle
      ? tracking.aiReportQuery({ report: reportTitle, reportId, query })
      : tracking.aiQuery({ query })
    setQuery('')
    const role = 'user' as RoleT
    const messages = [
      ...chatHistory?.map((chat) => ({
        id: chat.id,
        role: chat.role,
        content: chat.content,
      })),
      { id: chatHistory.length + 1, role, content: searchQuery },
    ]
    if (!_id) {
      setChatHistory((history) => [
        ...history,
        { id: history.length + 1, role: 'user', content: searchQuery },
      ])
    }
    setAnswer(' ')
    setTimeout(() => scrollToBottom(), 10)

    function cb(answer: AnswerT) {
      const newAnswer = { ...answer }
      const isRouter = assistantsId['router'] === _assistantID
      if (isRouter) {
        clearThread()
        const response = answer.content
        const route = TableMapping[response] || ''
        const routerAnswer = {
          ...newAnswer,
          role: 'assistant-internal' as RoleT,
          content: route.name
            ? `Searching on ${route.name} Table`
            : 'Searching on Reports',
        }
        const routedAssistant = routerMapping[response] || routerMapping.Text
        setChatHistory((history) => [
          ...history,
          { ...routerAnswer, id: history[history.length - 1]?.id + 1 },
        ])
        setAssistantID(routedAssistant)
        search(searchQuery, routedAssistant)
      } else {
        const isDbSearch = DbAssistants.includes(_assistantID)
        if (!isDbSearch && !newAnswer.sources.length) {
          newAnswer.content =
            'Sorry, I can not answer that question, try asking something else.'
        }
        setAnswer('')
        setStreamingAnswer('')
        setChatHistory((history) => [
          ...history,
          { ...newAnswer, id: history[history.length - 1]?.id + 1 },
        ])
        setTimeout(() => scrollToBottom(), 10)
      }
    }
    function streamCB(streamText: AnswerT) {
      const isRouter = assistantsId['router'] === _assistantID
      if (isRouter) return null
      setAnswer('')
      setStreamingAnswer((text) => text + streamText)
    }
    try {
      await askAI(messages, _assistantID, cb, streamCB, reportId)
    } catch (err) {
      setIsError(true)
      console.error('askAI error', err)
    }
  }

  useEffect(() => {
    setQuery('')
    setAnswer('')
  }, [])

  function handleAnotherQuestion() {
    setAssistantID(DEFAULT_ASSISTANT)
    setQuery('')
    setAnswer('')
    setChatHistory([])
    clearThread()
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])
  return {
    chatHistory,
    error,
    divRef,
    streamingAnswer,
    search,
    answer,
    handleAnotherQuestion,
    setQuery,
    query,
    assistantId,
  }
}

export default useAI
