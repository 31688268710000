import { CircleLayer, FillLayer } from 'react-map-gl/maplibre'

export const EmptyLayer = { type: 'FeatureCollection', features: [] }

export type LayerT = 'confirmed' | 'suspected' | 'notSpecified' | 'clade' | ''

export const colors = {
  notSpecified: '#AE1212',
  confirmed: '#149880',
  suspected: '#FFBF84',
  clade: '#800080',
} as { [key in LayerT]: string }

export const layersMappingToName = {
  notSpecified: 'Cases Not Specified',
  confirmed: 'Cases Confirmed',
  suspected: 'Cases Suspected',
  clade: 'Presence of Clade I in 2024/25',
} as { [key: string]: string }

const getCircleLayout = (color: string, highestN: number) => ({
  'circle-radius': [
    'interpolate',
    ['linear'], // Use base-2 exponential interpolation
    ['get', 'value'],
    1,
    5, // value of 0 -> radius 5
    highestN,
    20, // value of 500 -> radius 20
  ] as any,
  'circle-opacity': 0.5,
  'circle-stroke-width': 2,
  'circle-color': color,
  'circle-stroke-color': color,
})

const CircleSuspected = (highestN: number): CircleLayer => ({
  id: 'data-suspected',
  type: 'circle',
  source: 'point',
  paint: getCircleLayout(colors.suspected, highestN),
})
const CircleNotSpecified = (highestN: number): CircleLayer => ({
  id: 'data-notSpecified',
  type: 'circle',
  source: 'point',
  paint: getCircleLayout(colors.notSpecified, highestN),
})

const CircleConfirmed = (highestN: number): CircleLayer => ({
  id: 'data-confirmed',
  type: 'circle',
  source: 'point',
  paint: getCircleLayout(colors.confirmed, highestN),
})
const CountryBorders: FillLayer = {
  id: 'data-countries',
  type: 'fill',
  source: 'point',
  paint: {
    'fill-color': ['get', 'color'], // Use the color property of the clade
    'fill-opacity': 0.5,
  },
}

export default {
  CircleSuspected,
  CircleConfirmed,
  CountryBorders,
  CircleNotSpecified,
}
