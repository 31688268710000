export default {
  baseStyle: {
    cursor: 'pointer',
    width: 'auto',
    rounded: '2px',
    borderColor: 'blue.500',
    color: 'blue.800',
    _focus: {
      boxShadow: 'rgba(0, 71, 186, 0.5) 0px 0px 0px 2px',
    },
  },
  defaultProps: {
    variant: 'secondary',
    size: 'base',
  },
  sizes: {
    xs: {
      height: '30px',
      px: 3,
      fontSize: 'xs',
    },
    sm: {
      height: '35px',
      px: 4,
      fontSize: 'sm',
    },
    base: {
      height: '45px',
      px: 6,
      fontSize: 'sm',
    },
  },
  variants: {
    secondary: {
      bgColor: 'white',
      color: 'blue.800',
      borderWidth: 1,
      _hover: {
        bgColor: 'blue.600',
        color: 'white',
      },
    },
    black: {
      bgColor: 'legacy-primary.400',
      color: 'white',
      _hover: {
        bg: 'legacy-primary.900',
      },
    },
    primary: {
      bgColor: 'blue.500',
      color: 'white',
      _hover: {
        bg: 'blue.600',
      },
    },
    gray: {
      bgColor: 'gray.100',
      color: 'gray.800',
      borderColor: 'gray.100',
      borderWidth: 1,
      _hover: {
        bg: 'gray.200',
        borderColor: 'gray.200',
      },
    },
    dropdown: {
      bgColor: 'white',
      height: '32px',
      borderColor: 'blue.500',
      borderWidth: 1,
      rounded: 'sm',
      color: 'inherit',
      px: 2,
      py: 1,
      _hover: {
        background: 'blue.500',
        color: 'white',
        transition: 'all 0.2s',
      },
    },
    ghost: {
      bgColor: 'transparent',
      color: 'inherit',
      rounded: '4px',
      px: '16px',
      py: '8px',
      fontSize: '13px',
      fontWeight: '600',
      height: '34px',
      _hover: {
        textShadow: '0 0 0.1px black',
        transition: 'all 0.1s',
      },
      _focus: {
        textShadow: '0 0 0.1px black',
        transition: 'all 0.1s',
        boxShadow: 'none',
      },
    },
    clean: {
      bgColor: 'transparent',
      height: '32px',
      color: 'inherit',
      fontWeight: 400,
      p: 0,
      _hover: {
        textShadow: '0 0 0.1px black',
        transition: 'all 0.1s',
      },
      _focus: {
        textShadow: '0 0 0.1px black',
        transition: 'all 0.1s',
        boxShadow: 'none',
      },
    },

    success: {
      bgColor: 'success',
      height: '32px',
      color: 'white',
      fontWeight: 400,
      p: 0,
      _hover: {
        bgColor: 'success.600',
        transition: 'all 0.1s',
      },
      _focus: {
        transition: 'all 0.1s',
        boxShadow: 'none',
      },
    },
    error: {
      bgColor: 'error',
      height: '32px',
      color: 'white',
      fontWeight: 400,
      p: 0,
      _hover: {
        bgColor: 'error.600',
        transition: 'all 0.1s',
      },
      _focus: {
        transition: 'all 0.1s',
        boxShadow: 'none',
      },
    },
    yellow: {
      height: '34px',
      bg: 'secondary',
      color: 'primary',
      rounded: '4px',
      px: '16px',
      py: '8px',
      fontSize: '13px',
      fontWeight: '600',
    },
    outline: {
      bgColor: 'transparent',
      borderColor: 'primary',
      border: '1px solid',
      fontWeight: '600',
      fontSize: '13px',
      rounded: '4px',
      height: '34px',
      px: '16px',
      py: '8px',
    },
  },
}
