import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import { Box, Button, Flex, Text, Tooltip as Tooltip2 } from '@chakra-ui/react'
import useAxios from 'api/useAxios'
import { recursiveCamelCase } from 'api/helper'
import { useQuery } from '@tanstack/react-query'
import {
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { LoadingAnimation, Select } from 'components'
import { getQueryData } from './Procurement'
import { useState } from 'react'
import CardContainer from 'components/Dashboards/IDATrialsMapDashboard/Cards/CardContainer'
import { Download, Information } from '@carbon/icons-react'
import { exportPriceData } from '../helpers/exportData'

const OPTIONS = [
  { label: 'Recipient Region', value: 'recipientRegion' },
  { label: 'Recipients', value: 'recipients' },
  { label: 'Procurement Mechanism', value: 'procurementMechanism' },
  { label: 'Candidate', value: 'candidates' },
  { label: 'Year', value: 'yearDelivered' },
]

function formatData(data: any, stackBy: string) {
  if (!data) return [] as string[]

  const chartData = data.reduce((acc: any, item: any) => {
    if (!item) return acc

    const stackValue = item[stackBy]
    const price = +item.pricePerUnitUsd

    if (Array.isArray(stackValue)) {
      return [
        ...acc,
        ...stackValue.map((value) => ({
          region: value,
          price,
          ...item,
        })),
      ]
    }
    return [
      ...acc,
      {
        region: stackValue,
        price,
        ...item,
      },
    ].sort((a, b) => {
      if (stackBy === 'yearDelivered') {
        return a.yearDelivered - b.yearDelivered
      }
      return 0
    })
  }, [])

  return { chartData, barKeys: [] }
}

const CustomTooltip = ({ active, payload }: any) => {
  const dataPoints = [
    { label: 'Price Per Unit (USD)', value: 'price' },
    { label: 'Candidate', value: 'candidates' },
    { label: 'Year', value: 'yearDelivered' },
    { label: 'Procurement Mechanism', value: 'procurementMechanism' },
    { label: 'Recipient Region', value: 'recipientRegion' },
    { label: 'Recipients', value: 'recipients' },
    { label: 'Suppliers', value: 'suppliers' },
    { label: 'Category', value: 'categoryA' },
    { label: 'Price Methodology', value: 'pricePerUnitMethodologyType' },
    { label: 'Market', value: 'market' },
  ]
  if (active && payload && payload.length) {
    const item = payload[0].payload
    return (
      <Flex
        className='custom-tooltip'
        flexDirection='column'
        bg='white'
        p='8px'
        border='2px solid'
        borderColor='gray.100'
        fontSize='sm'
        borderRadius='8px'
      >
        {dataPoints.map(({ label, value }) => (
          <Flex alignItems='center' key={value}>
            <Text fontSize={'xs'} color='gray.700'>
              {label}:
            </Text>
            <Text
              fontSize={'xs'}
              color='gray.900'
              ml='0.5rem'
              fontWeight='bold'
            >
              {item[value]}
            </Text>
          </Flex>
        ))}
        <Flex alignItems='top' key={'sources'}>
          <Text fontSize={'xs'} color='gray.700'>
            Sources:
          </Text>
          <Flex flexDir={'column'}>
            {item.sources.map((source: { title: string; link: string }) => {
              return (
                <a href={source.link} target='_blank' rel='noreferrer'>
                  <Text
                    fontSize={'xs'}
                    color='gray.900'
                    ml='0.5rem'
                    fontWeight='bold'
                  >
                    {source.title}
                  </Text>
                </a>
              )
            })}
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return null
}

const PricingChart = ({
  chartData,
  error,
  isLoading,
}: {
  chartData: any
  error: any
  isLoading: boolean
}) => {
  if (error?.message) {
    return (
      <Flex justifyContent={'center'} alignItems='center' h='100%'>
        <Text color='error'>Something went wrong loading the data</Text>
      </Flex>
    )
  }

  if (chartData?.length === 0 || isLoading) {
    return (
      <Flex justifyContent={'center'} alignItems='center' h='100%'>
        <LoadingAnimation />
      </Flex>
    )
  }

  return (
    <ResponsiveContainer width='100%' height={'100%'}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 10,
        }}
        onClick={(e) => console.log(e)}
      >
        <XAxis
          type='category'
          dataKey='region'
          angle={-10}
          allowDuplicatedCategory={false}
          interval={0}
          fontSize={'10px'}
        />
        <YAxis type='number' dataKey='price'>
          <Label
            angle={-90}
            value='Price Per Unit (USD)'
            position='insideLeft'
            style={{ textAnchor: 'middle' }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Scatter data={chartData.chartData} fill='#006DDB' />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

const defaultProperty = { label: 'Candidates', value: 'candidates' }

const VaccinesPricing = ({ filters }: { filters: FiltersT }) => {
  const [expanded, setExpanded] = useState(false)
  const axios = useAxios()
  const [selectedOption, setOption] = useState(defaultProperty)

  const { data, isLoading, error } = useQuery(
    ['vaccines-pricing'],
    async () => {
      const endpoint = '/lzdb/science-macro/deal/?view=outbreak_pricing_mpox&'
      const vaccinesPrices = await axios.get(getQueryData(filters, endpoint))
      return recursiveCamelCase(vaccinesPrices.data)
    }
  )

  function downloadHandler() {
    exportPriceData(data, 'vaccine-pricing')
  }

  const chartData = formatData(data, selectedOption.value)

  return (
    <CardContainer
      minWidth='350px'
      expanded={expanded}
      setExpanded={setExpanded}
      width='100%'
    >
      <Flex alignItems={'center'} justifyContent={'space-around'} pr='1rem'>
        <Text fontWeight={'semibold'} mr='1rem'>
          Mpox Candidates Pricing
        </Text>
        <Box>
          <Select
            value={selectedOption}
            isSearchable={false}
            isMulti={false}
            onChange={setOption}
            options={OPTIONS}
            backgroundColor='transparent'
            fontSize={'13px'}
          />
        </Box>
        <Button
          onClick={downloadHandler}
          variant={'ghost'}
          w={'24px'}
          h={'24px'}
          p={0}
        >
          <Download size={16} />
        </Button>
        <Flex>
          <Tooltip2
            label={
              'Prices paid for mpox candidates, collected from secondary sources covering public and private markets.'
            }
          >
            <Information color='gray5' />
          </Tooltip2>
        </Flex>
      </Flex>
      <PricingChart chartData={chartData} error={error} isLoading={isLoading} />
    </CardContainer>
  )
}

export default VaccinesPricing
