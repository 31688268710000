import { Card, Flex, Text } from '@chakra-ui/react'
import { LoadingAnimation } from 'components'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import { compactNumberWithPrefix } from 'utils/formatNumber'
import { DataT } from '..'

const CardWrapper = ({
  title,
  value,
}: {
  title: string
  value: string | number
}) => {
  return (
    <Card
      display={'flex'}
      p={2}
      justifyContent='center'
      alignItems={'center'}
      color='black'
      border='1px solid'
      borderColor='gray3'
      shadow={'none'}
    >
      <Text fontWeight='normal' fontSize={'sm'} color={'black'}>
        {title}
      </Text>
      <Text fontWeight={'semibold'}>{value}</Text>
    </Card>
  )
}

const SummaryCards = ({
  data,
  filters,
}: {
  data: DataT
  filters: FiltersT
}) => {
  if (!data) return <LoadingAnimation />

  function filterData(data: any[]) {
    if (!data) return null
    return data.filter((item: any) => {
      return (
        filters.country === 'global' ||
        item.recipients.includes(filters.country)
      )
    })
  }
  const uniqueCandidates = [
    ...new Set(
      filterData(data?.procurement.data)?.flatMap(
        (item: any) => item.candidates
      )
    ),
  ]
  const uniqueCountries = [
    ...new Set(
      filterData(data?.supply.data)?.flatMap((item: any) => item.recipients)
    ),
  ]
  const donationsPledged = filterData(data?.donations.data)?.reduce(
    (acc: number, item: any) => (acc += item.quantity),
    0
  )

  return (
    <Flex flexDir={'column'} gap='0.5rem'>
      <CardWrapper
        title='Candidates procured'
        value={uniqueCandidates.length}
      />
      <CardWrapper
        title='Donations pledged'
        value={compactNumberWithPrefix(donationsPledged)}
      />
      <CardWrapper
        title='Countries procuring candidates'
        value={uniqueCountries.length}
      />
    </Flex>
  )
}

export default SummaryCards
