import GenericTable from 'modules/Tables'
import BarChart from 'modules/Tables/Dashboard/AllDashboards/BarChart'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
  defaultDashboardConfig,
} from 'interfaces/navigationPage.interface'

import { SortOrders } from 'enums/SortOrders'

import { MetaVaccineEfficacyModel } from 'config/common/baseModel/MetaVaccineEfficacy'
import { VaccinesUnderReviewModel } from 'config/common/baseModel/VaccinesUnderReview'

import {
  CombinationVaccineCandidates,
  VaccinesApprovalsDealsModel,
  VaccinesCandidatePapersCorrelatesModel,
  VaccinesCandidatePapersModel,
  VaccinesCandidatesModel,
  VaccinesMediaModel,
  VaccinesPhaseIIIOrIVCandidates,
  VaccinesPhaseIOrIICandidates,
  VaccinesPreclinicalCandidates,
  VaccinesPressReleasesModel,
  VaccinesTrialsModel,
} from '../Vaccines/VaccinesModels'
import { VaccinesSlugs } from './VaccinesMenu'
import { VaccineCampaignsModel } from '../../Commercial/Vaccines/Covid19CommercialVaccineModels'
import barChartMessages from 'modules/Tables/Dashboard/AllDashboards/BarChart/message'

type VaccinesNavigationPage<TModel extends IModel<any>> = ISingleNavigationPage<
  VaccinesSlugs,
  TModel
>

const Candidates: VaccinesNavigationPage<typeof VaccinesCandidatesModel> = {
  key: 'Candidates',
  path: ['candidates', 'dashboard'],
  component: GenericTable,
  model: {
    ...VaccinesCandidatesModel,
    newNote: {
      text: barChartMessages,
      newUntil: new Date(2024, 8, 25),
    },
  },
  views: [
    {
      name: 'All',
      airtableName: 'api_all',
    },
    {
      name: 'Preclinical',
      airtableName: 'api_preclinical',
      overrideModel: VaccinesPreclinicalCandidates,
    },
    {
      name: 'Phase I or II',
      airtableName: 'api_phase_i_or_ii',
      overrideModel: VaccinesPhaseIOrIICandidates,
    },
    {
      name: 'Phase III or IV',
      airtableName: 'api_phase_iii_or_iv',
      overrideModel: VaccinesPhaseIIIOrIVCandidates,
    },
    {
      name: 'Approved',
      airtableName: 'api_approved',
      overrideModel: (model) => ({
        ...model,
        name: 'Approved',
      }),
    },
    {
      name: 'Combination Vaccine',
      airtableName: 'api_combination',
      overrideModel: CombinationVaccineCandidates,
    },
  ],
  autoGenerateDashboard: {
    ...defaultDashboardConfig,
    render: BarChart,
    filterBlacklistOptions: [
      'actionMechanism',
      'researchInstitutions',
      'activeDevelopment',
      'approvalCountry',
    ],
    verticalAxisLabel: 'Number of Candidates',
    defaultHorizontalFilterValue: {
      label: 'Phase',
      value: 'stage',
    },
    defaultStackByFilterValue: {
      label: 'Technology Type',
      value: 'type',
    },
    sortingOrders: {
      stage: [
        'Preclinical',
        'Phase 0',
        'Phase I',
        'Phase I/II',
        'Phase II',
        'Phase II/III',
        'Phase III',
        'Approved',
        'Phase IV',
        'Discontinued',
        'Paused',
        'Inactive',
        'Not Applicable',
        'Unknown',
      ],
    },
  },
}

const CandidatePapers: VaccinesNavigationPage<
  typeof VaccinesCandidatePapersModel
> = {
  key: 'Publications',
  path: null,
  component: GenericTable,
  model: { ...VaccinesCandidatePapersModel, name: 'All' },
  views: [
    {
      name: 'All',
      airtableName: 'api_candidate_papers',
    },
    {
      name: 'Correlates of Protection',
      airtableName: 'api_correlates',
      overrideModel: {
        ...VaccinesCandidatePapersCorrelatesModel,
        name: 'Correlates of Protection',
      },
    },
  ],
}

const PhaseIVaccineTrials: VaccinesNavigationPage<typeof VaccinesTrialsModel> =
  {
    key: 'PhaseVaccineTrials',
    model: { ...VaccinesTrialsModel, name: 'Clinical Trials' },
    path: ['trials', 'table'],
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'api_phase_all',
        overrideModel: (model) => ({
          ...model,
          defaultSortKey: 'afDateAdded',
          defaultSortOrder: SortOrders.DESC,
        }),
      },
    ],
  }

const ResultsRealWorld: VaccinesNavigationPage<
  typeof MetaVaccineEfficacyModel
> = {
  key: 'ResultsRealWorld',
  model: {
    ...MetaVaccineEfficacyModel,
    name: 'Real World',
    defaultSortKey: 'publicationDate',
    defaultSortOrder: SortOrders.DESC,
  },
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_real_world',
    },
  ],
}

const ResultsPhase3: VaccinesNavigationPage<typeof MetaVaccineEfficacyModel> = {
  key: 'ResultsPhase3',
  model: {
    ...MetaVaccineEfficacyModel,
    name: 'Phase III',
    defaultSortKey: 'publicationDate',
    defaultSortOrder: SortOrders.DESC,
  },
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_phase_iii',
    },
  ],
}

const VaccineApprovals: VaccinesNavigationPage<
  typeof VaccinesApprovalsDealsModel
> = {
  key: 'VaccinesApproval',
  path: ['regulatory', 'table'],
  model: { ...VaccinesApprovalsDealsModel, hideTableHeader: true },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'API-vaccine-approvals',
    },
  ],
}

const VaccinesUnderReview: VaccinesNavigationPage<
  typeof VaccinesUnderReviewModel
> = {
  key: 'Vaccines Under Review',
  path: ['regulatory', 'vaccines-under-review'],
  model: VaccinesUnderReviewModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api',
    },
  ],
}

const PressReleases: VaccinesNavigationPage<typeof VaccinesPressReleasesModel> =
  {
    key: 'PressReleases',
    model: VaccinesPressReleasesModel,
    path: null,
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'api',
      },
    ],
  }
const Media: VaccinesNavigationPage<typeof VaccinesMediaModel> = {
  key: 'Media',
  model: VaccinesMediaModel,
  path: null,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api',
    },
  ],
}

const VaccineCampaigns: VaccinesNavigationPage<typeof VaccineCampaignsModel> = {
  key: 'VaccineCampaigns',
  path: null,
  model: {
    ...VaccineCampaignsModel,
    name: 'Vaccine Campaigns',
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'COVID Vaccine Campaigns',
      airtableBase: 'lzdb',
    },
  ],
}

export const VaccineCampaignsPages: INavigationPages<VaccinesSlugs> = {
  VaccineCampaigns,
}

export const VaccinesPages: INavigationPages<VaccinesSlugs> = {
  Candidates,
  CandidatePapers,
  PhaseIVaccineTrials,
  ResultsRealWorld,
  ResultsPhase3,
  VaccineApprovals,
  PressReleases,
  VaccinesUnderReview,
  VaccineCampaigns,
  Media,
}

export const VaccinesResults: INavigationPages<VaccinesSlugs> = {
  ResultsRealWorld,
  ResultsPhase3,
}
