import { Box } from '@chakra-ui/react'

import Details from 'modules/Summary'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'

type PropTypes = {
  rowData: IResponseBase
  model: IModel<any>
}

export const DetailDrawerSummary = ({ rowData, model }: PropTypes) => {
  return (
    <Box>
      <Details data={rowData} model={model} />
    </Box>
  )
}
