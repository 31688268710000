import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'
import { useAuth } from 'react-oidc-context'

import { useClientType } from 'contexts/ClientType'

const getHeader = (loggedIn: boolean, jwt: string) => ({
  headers: {
    ...(loggedIn
      ? { Authorization: `Bearer ${jwt}`, Accept: 'application/json' }
      : {}),
  },
})

const errorFunction = function (error: AxiosError<any>, isLoggedIn: boolean) {
  if (error?.response?.status === 403) {
    if (error.response.data.detail === 'EULA Not Agreed To') {
      window.location.replace('/terms-and-conditions')
      return
    }
    isLoggedIn && window.location.replace('/')
  } else if (error?.response?.status === 401) {
    isLoggedIn && window.location.replace('/logout')
  } else {
    Sentry.captureException(error)
    return Promise.reject(error)
  }
}

export function getBaseUrl(isGov: boolean) {
  const baseURL = isGov
    ? process.env.REACT_APP_API_BASE_URL_GOV
    : process.env.REACT_APP_API_BASE_URL
  return baseURL as string
}

export default () => {
  const auth = useAuth()

  const { isGov } = useClientType()
  const baseURL = getBaseUrl(isGov)

  const loggedIn = auth.isAuthenticated ?? false
  const jwt = auth.user?.access_token ?? ''

  const authHeader = getHeader(loggedIn, jwt)

  const instance = axios.create({
    baseURL,
    ...authHeader,
  })

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    (error) => {
      return errorFunction(error, loggedIn)
    }
  )
  return instance
}

/** Use this to create a new axios instance */
export function useAxiosRequestConfig() {
  const auth = useAuth()

  const loggedIn = auth.isAuthenticated ?? false
  const jwt = auth.user?.access_token ?? ''

  const authHeader = getHeader(loggedIn, jwt)
  const { isGov } = useClientType()
  const baseURL = getBaseUrl(isGov)

  return {
    baseURL,
    ...authHeader,
  }
}
