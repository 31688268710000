import _ from 'lodash'

export function formatData(
  data: any,
  groupBy: string,
  stackBy: string,
  nCountries: number,
  selectedCountry: string
) {
  let mechanismList = [] as string[]
  if (!data) return { chartData: [] as string[], uniqueKeys: [] }
  const countries = _.groupBy(data, (item) => item?.[groupBy].join('-'))

  const chartData = Object.keys(countries)
    .reduce((acc: any, item: string) => {
      if (!item || (item !== selectedCountry && selectedCountry !== 'global')) {
        return acc
      }
      const deals = countries[item].reduce((acc, deal) => {
        const mechanism = deal[stackBy]
        mechanismList.push(mechanism)
        return {
          ...acc,
          [mechanism]: (acc[mechanism] || 0) + deal.quantity / 1_000_000,
        }
      }, {})

      return [
        ...acc,
        {
          country: item,
          ...deals,
        },
      ]
    }, [])
    .sort((a: any, b: any) => {
      const totalA = Object.keys(a)
        .filter((key) => key !== 'country')
        .reduce((sum, key) => sum + a[key], 0)
      const totalB = Object.keys(b)
        .filter((key) => key !== 'country')
        .reduce((sum, key) => sum + b[key], 0)

      return totalB - totalA // Descending order
    })
    .slice(0, nCountries)

  const uniqueKeys = (
    [
      ...new Set(
        chartData.flatMap((row: any) =>
          Object.keys(row).filter((key) => key !== 'country')
        )
      ),
    ] as string[]
  ).sort((a, b) => chartData[0][b] - chartData[0][a])

  return { chartData, uniqueKeys }
}
