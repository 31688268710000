import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type RSVSlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'pipeline'
  | 'progress-tracker'
  | 'results'
  | 'trials'
  | 'recent-trials-map'
  | 'all-data'
  | 'landscape'
  | 'vaccines'
  | 'non-vaccines'
  | 'candidate-papers'
  | 'reports'
  | 'timeline'
  | 'site-distribution'
  | 'regulatory'
  | 'map'
  | 'combination-reports'
  | 'research-briefs'
  | 'dashboard'
  | 'conferences'
  | 'debriefs'
  | 'deep-dives'

const RSVMenu: Array<INavigationMenu<RSVSlugs>[]> = [
  [
    {
      title: 'Latest Information',
      slug: '',
      description:
        'The most recent news reports, press releases, publications, tweets and reports',
      createdAt: new Date(2021, 9, 8),
    },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description:
        'See all information on RSV vaccine and treatment candidates',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Dashboard',
          slug: 'dashboard',
          createdAt: new Date(2024, 8, 6),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2022, 5, 23),
        },
      ],
    },
    {
      title: 'Combination Reports',
      slug: 'combination-reports',
      createdAt: new Date(2023, 8, 2),
    },
    {
      title: 'Conference Coverage',
      slug: 'conferences',
      description: '',
      createdAt: new Date(2024, 10, 13),
      children: [
        {
          title: 'Daily Debriefs',
          slug: 'debriefs',
          createdAt: new Date(2024, 10, 13),
        },
        {
          title: 'Conference Deep Dives',
          slug: 'deep-dives',
          createdAt: new Date(2024, 10, 13),
        },
      ],
    },

    {
      title: 'Results',
      slug: 'results',
      description:
        'Trial results and information for leading RSV vaccine candidates.',
      createdAt: new Date(2021, 10, 3),
      children: [
        {
          title: 'Vaccines',
          slug: 'vaccines',
          createdAt: new Date(2021, 10, 3),
        },
        {
          title: 'Non-Vaccines',
          slug: 'non-vaccines',
          createdAt: new Date(2022, 1, 23),
        },
      ],
    },
    {
      title: 'Trials',
      slug: 'trials',
      description:
        'List of clinical trials involving RSV vaccines and treatments',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'All Data',
          slug: 'all-data',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Recent Trials Map',
          slug: 'recent-trials-map',
          createdAt: new Date(2022, 2, 29),
        },
      ],
    },
    {
      title: 'Regulatory',
      slug: 'regulatory',
      description: '',
      createdAt: new Date(2021, 9, 8),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2021, 9, 8),
        },
        {
          title: 'Map',
          slug: 'map',
          createdAt: new Date(2023, 9, 8),
        },
      ],
    },
    {
      title: 'Reports',
      slug: 'reports',
      description: 'In-depth insights on topics relevant to RSV',
      createdAt: new Date(2021, 9, 12),
      children: [
        {
          title: 'Reports',
          slug: '',
          createdAt: new Date(2021, 9, 12),
        },
        {
          title: 'Research Briefs',
          slug: 'research-briefs',
          createdAt: new Date(2022, 7, 24),
        },
      ],
    },
  ],
]

export { RSVMenu }
