import { Box, TabPanels, TabPanel, TabPanelProps } from '@chakra-ui/react'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListCandidate,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { IDA360CandidatesModel } from 'config/apps/IDA360/Custom/IDA360/IDA360Models'
import { ClinicalTrialsData } from 'config/common/baseModel'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<ClinicalTrialsData, any>> = {
  handleClose: () => void
  trial: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<ClinicalTrialsData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, trial, model, title, viewData } = props

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={trial && trial[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs tabs={['Summary', 'Candidates']}>
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={trial} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                model={IDA360CandidatesModel}
                ids={trial.candidateIds}
                itemComponent={DetailDrawerListCandidate}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
