import { Select } from 'components'

export const DataTypeSelector = ({
  value,
  onChange,
  options,
}: {
  value: string
  onChange: (e: { value: string; label: string }) => void
  options: { value: string; label: string }[]
}) => {
  const selectedOption = options.find((item) => item.value === value)
  return (
    <Select
      value={selectedOption}
      isMulti={false}
      isSearchable={false}
      options={options}
      color='#000'
      onChange={onChange}
      fontSize={'10px'}
      height='26px'
    />
  )
}
