import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { FullPageError } from 'components'
import WarningPopover from '../common/WarningPopover'
import { StreamingWrapper, ThinkingWrapper } from '../common/ThinkingWrapper'
import Header from '../common/Header'
import ChatSection from '../common/ChatSection'
import BottomSection from '../common/BottomSection'
import EmptyScreen from '../common/EmptyScreen'
import useAI from 'api/ai/useAI'

const AiSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    chatHistory,
    error,
    divRef,
    streamingAnswer,
    search,
    answer,
    handleAnotherQuestion,
    setQuery,
    query,
    assistantId,
  } = useAI()

  if (error) return <FullPageError />

  const isEmptyScreen = chatHistory.length === 0 && !streamingAnswer

  return (
    <>
      <WarningPopover onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Flex
        flexDir={'column'}
        border={'none'}
        borderColor={'gray3'}
        borderRadius={'6px'}
        mb={0}
        mt={'4px'}
      >
        <Header
          title={"Biorisk's Reports AI-Search"}
          description='(Reports + Alerts)'
        />
      </Flex>
      <Flex flexDir={'column'} w='50vw' margin={'auto'} h='100%'>
        <Flex overflow='auto' h='68vh' flexDir={'column'}>
          <Box
            mb='2rem'
            margin={'auto'}
            mt='1rem'
            w='50vw'
            ref={divRef}
            h='100%'
          >
            {chatHistory.length === 0 && !streamingAnswer && (
              <EmptyScreen
                description='The question will be answered using Reports and Alerts.'
                exampleQuestions={[
                  'Where are mpox clade Ib cases being detected.',
                  'Is there risk of dengue in the UK?',
                  'Why is mpox clade I dangerous?',
                  'what countries are at the highest risk of disease spillover',
                ]}
                handleSuggestion={(query) => search(query)}
              />
            )}
            {chatHistory.map((message, index) => {
              return (
                <ChatSection
                  message={message}
                  key={index}
                  chatHistory={chatHistory}
                />
              )
            })}
            <StreamingWrapper answer={streamingAnswer} />
            <ThinkingWrapper answer={answer} assistantId={assistantId} />
            {chatHistory.length >= 3 && (
              <Flex justifyContent={'center'} mb='2rem' mt='2rem'>
                <Button variant={'yellow'} onClick={handleAnotherQuestion}>
                  Ask a different question
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
        <BottomSection
          setQuery={setQuery}
          search={search}
          query={query}
          handleOpenWarningMessage={onOpen}
          inputStyle={{ width: '50vw' }}
        />
      </Flex>
    </>
  )
}

export default AiSearch
