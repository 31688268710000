import { Checkmark, Close, ShareKnowledge } from '@carbon/icons-react'
import { Button, Checkbox, Flex, Text } from '@chakra-ui/react'
import { Input, Select } from 'components'
import { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'

type ShareStateT = 'success' | 'error' | 'loading' | undefined

function validateEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  return emailRegex.test(email)
}

const ShareEmailPopover = ({
  handleClick,
  nPages,
}: {
  handleClick: (email: string, pages?: number[]) => Promise<boolean>
  nPages: number
}) => {
  const [shareSelectedPages, setSharedSelectedPages] = useState(false)
  const [selectedPages, setSelectedPages] = useState<number[]>([])
  const [email, setEmail] = useState('')
  const [sharedState, setSharedState] = useState<ShareStateT>()
  const [isOpen, setIsPopoverOpen] = useState(false)

  const onClick = async () => {
    setSharedState('loading')
    const _sharedState = await handleClick(email, selectedPages)
    setSharedState(_sharedState ? 'success' : 'error')
  }

  function onClose() {
    setEmail('')
    setIsPopoverOpen(false)
    setSharedState(undefined)
  }

  const ShareReportAction = () => {
    const validEmail = validateEmail(email)

    if (sharedState === 'error') {
      return (
        <Button onClick={onClick} variant={'error'}>
          <Checkmark size={16} color='white' />{' '}
          <Text ml='6px'>Something went wrong</Text>
        </Button>
      )
    }
    if (sharedState === 'success') {
      return (
        <Button onClick={onClick} variant={'success'}>
          <Checkmark size={16} color='white' />{' '}
          <Text ml='6px'>Shared successful</Text>
        </Button>
      )
    }
    return (
      <Button
        variant={'yellow'}
        onClick={onClick}
        disabled={!validEmail}
        opacity={validEmail ? 1 : 0.5}
      >
        {sharedState === 'loading' ? (
          <>
            <span className='loader' /> Sharing
          </>
        ) : (
          'Share report'
        )}
      </Button>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      containerStyle={{ zIndex: '9999' }}
      positions={['right', 'left', 'bottom', 'top']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='lightgrey'
          arrowSize={10}
          arrowStyle={{
            opacity: 0.7,
          }}
        >
          <Flex
            bgColor='white'
            maxW={'550px'}
            marginTop='24px'
            borderRadius={15}
            p='24px'
            fontSize={'sm'}
            boxShadow='lg'
            border={'1px solid'}
            borderColor='gray.200'
            color='black'
            flexDir={'column'}
            pos='relative'
          >
            <Button
              variant={'outline'}
              pos='absolute'
              right='1rem'
              top='1rem'
              border='1px solid'
              borderColor={'gray3'}
              px='6px'
              h='24px'
              onClick={onClose}
            >
              <Close size={14} color='#0B0B0B' />
            </Button>
            <Text fontSize={'xl'} mb='1rem'>
              Report sharing.
            </Text>
            <Text mb='1rem' fontSize={'md'}>
              Write the email of the person you want to share the report with.
            </Text>
            <Text color='gray.800' mb='0.5rem'>
              This person will receive an email with a link to access the
              report.
            </Text>
            <Text color='gray.800' mb='1rem'>
              The link is valid for up to 30 days.
            </Text>
            <Flex mb='1rem' alignItems={'center'} gap='8px'>
              <Checkbox
                onChange={() => setSharedSelectedPages((x) => !x)}
                isChecked={shareSelectedPages}
              />
              <Text mr='8px'>Share specific pages</Text>
              <Select
                onChange={(selectedOptions: any) => {
                  const selectedPages = selectedOptions.map(
                    (option: any) => option.value
                  )
                  setSelectedPages(selectedPages)
                }}
                placeholder='Select the pages to share'
                isMulti
                options={new Array(nPages).fill('').map((_, index) => ({
                  value: index,
                  label: index + 1,
                }))}
                backgroundColor='#fff'
                color='#000'
                fontSize={'10px'}
                height='26px'
                isDisabled={!shareSelectedPages}
              />
            </Flex>
            <Input
              placeholder='user@company.com'
              mb='1rem'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type='email'
            />
            <ShareReportAction />
          </Flex>
        </ArrowContainer>
      )}
    >
      <Button
        onClick={() => setIsPopoverOpen((x) => !x)}
        variant='yellow'
        gap='8px'
      >
        <ShareKnowledge size={14} />
        <Text>Share Report</Text>
      </Button>
    </Popover>
  )
}

export default ShareEmailPopover
