import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { IResponseBase } from 'api/types'
import { useCallback, useEffect, useState } from 'react'
import {
  OccurenceByKeyOutput,
  checkIfSelected,
  countOccurrences,
  getResultsValue,
  useGetFilterType,
} from '../utils'
import CardContainer from './CardContainer'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { FilterTypeKeys } from 'components/Table/AdvancedSearch/filters'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

const KeyPieCharts = ({
  data,
  loading,
  property,
  title,
  multipleSelection = false,
  filterType,
}: {
  data: IResponseBase[]
  loading: boolean
  property: string
  title: string
  multipleSelection?: boolean
  filterType?: FilterTypeKeys
}) => {
  const [results, setResults] = useState<OccurenceByKeyOutput[]>([])
  const [valueResults, setValueResults] = useState<OccurenceByKeyOutput[]>([])
  const [expanded, setExpanded] = useState<boolean>(false)

  const { tableFilters, addToFilters } = useTableChartFilters()

  const filterTypes = useGetFilterType(property)

  const handleClick = useCallback(
    (property: string, value: string) => {
      const tempFilters: SingleAdvancedFilter<any, any>[] = [...tableFilters]
      const existingIndex = tempFilters.findIndex((f) => f.column === property)
      const newFilterObj = {
        label: value,
        value,
      }
      if (existingIndex !== -1) {
        const existingFilter = tempFilters[existingIndex]
        const valueIndex = existingFilter.filterValue.findIndex(
          (v: any) => v.value === value
        )

        if (valueIndex !== -1) {
          existingFilter.filterValue.splice(valueIndex, 1)
          if (existingFilter.filterValue.length === 0) {
            tempFilters.splice(existingIndex, 1)
          }
        } else if (multipleSelection) {
          existingFilter.filterValue.push(newFilterObj)
        } else {
          existingFilter.filterValue = [newFilterObj]
        }
      } else {
        tempFilters.push({
          column: property,
          filterValue: [newFilterObj],
          type: filterType ?? filterTypes[0].key ?? 'includesAny',
        })
      }
      addToFilters(tempFilters)
    },
    [addToFilters, filterTypes, filterType, multipleSelection, tableFilters]
  )

  useEffect(() => {
    setResults(countOccurrences(data, property, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, loading])

  useEffect(() => {
    setValueResults(countOccurrences(data, property, true))
  }, [property, data])

  return (
    <CardContainer
      className='keyPieCharts'
      loading={loading}
      expanded={expanded}
      setExpanded={setExpanded}
      height='100%'
    >
      <Box maxH='170px'>
        <Flex
          alignItems={'center'}
          h='max-content'
          justifyContent={'space-between'}
        >
          <Text fontWeight='bold' fontSize={'sm'} color={'black'} mb={2}>
            {title} Overview
          </Text>
          {loading && (
            <Spinner
              position={'absolute'}
              zIndex={20}
              top={1}
              right={1}
              ml={2}
              size={'sm'}
            />
          )}
        </Flex>
        <Box
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          w={expanded ? '100%' : '240px'}
          maxH={expanded ? '400px' : '80%'}
          overflowY={'auto'}
        >
          <Grid
            templateColumns={expanded ? 'repeat(6, 1fr)' : 'repeat(2, 1fr)'}
          >
            {results.map((result, index) => {
              const isSelected = checkIfSelected(
                tableFilters,
                property,
                result.label
              )
              const { percentage } =
                getResultsValue(valueResults, result.label as string) ?? {}
              return (
                <Flex
                  backgroundColor={isSelected ? 'gray.100' : 'white'}
                  onClick={() => handleClick(property, result.label as string)}
                  _hover={{
                    backgroundColor: 'gray.100',
                    color: 'black',
                  }}
                  border={isSelected ? '1px' : 'none'}
                  borderColor={'gray.200'}
                  key={index}
                  cursor={'pointer'}
                  rounded={'lg'}
                  color={isSelected ? 'black' : 'gray.500'}
                  alignItems={'center'}
                  gap={1}
                >
                  <CircularProgress
                    size={expanded ? '84px' : '40px'}
                    value={percentage ?? 0}
                    color='green.400'
                  >
                    <CircularProgressLabel>
                      {percentage ?? 0}%
                    </CircularProgressLabel>
                  </CircularProgress>
                  <Text fontSize={expanded ? 'sm' : 'xs'}>{result.label}</Text>
                </Flex>
              )
            })}
          </Grid>
        </Box>
      </Box>
    </CardContainer>
  )
}

export default KeyPieCharts
