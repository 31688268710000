import { Document } from '@carbon/icons-react'
import { Skeleton, Box, Link } from '@chakra-ui/react'
import LinesEllipsis from 'react-lines-ellipsis'
import _ from 'underscore'

import { useAppRoute } from 'routes/utils'

import { flattenPaginatedData, snakeCase } from 'api/helper'
import useCollectionData from 'api/useCollectionData'

import { IModel } from 'interfaces/model.interface'
import { IBaseView } from 'interfaces/navigationPage.interface'
import { WidgetComponentPropsTypes } from 'interfaces/widget.interface'

import { SortOrders } from 'enums/SortOrders'

import { randomIntFromInterval } from 'utils/random'

export const formatSort = (sortKey: string, sortOrder?: SortOrders) => {
  return `${sortOrder === SortOrders.DESC ? '-' : ''}${snakeCase(sortKey)}`
}

export type WidgetProps = {
  view: IBaseView<any, any>
  model?: IModel<any>
  title?: string
}

export const useWidgetData = ({
  maxItems = 10,
  model,
  view,
}: WidgetComponentPropsTypes) => {
  const endpoint = model.endpoint
  const sort =
    model.defaultSortObject && model.defaultSortObject.length > 0
      ? formatSort(
          model.defaultSortObject[0].id,
          model.defaultSortObject[0]?.sortOrder
        )
      : model.defaultSortKey
        ? formatSort(model.defaultSortKey, model.defaultSortOrder)
        : ''

  const airtableName = view.airtableName
  const airtableBase = view.airtableBase

  const app = useAppRoute()

  const {
    isLoading,
    isFetchingNextPage,
    data: paginatedData,
    hasNextPage: canFetchMore,
    fetchNextPage: fetchMore,
  } = useCollectionData(app, endpoint, {
    limit: maxItems,
    sort,
    view: airtableName,
    airtableBase,
  })

  const data = flattenPaginatedData(paginatedData?.pages)?.results
  return {
    isLoading,
    data,
    maxItems,
    sort,
    view: airtableName,
    canFetchMore,
    fetchMore,
    isFetchingNextPage,
  }
}

type CustomProp<TData> = {
  readMoreLink?: TData
}

export default function WidgetComponentBase<TData>({
  model,
  view,
  readMoreLink,
}: WidgetComponentPropsTypes & CustomProp<TData>) {
  const { isLoading, data, maxItems } = useWidgetData({ model, view })

  if (isLoading || !data) {
    return <LoadingComponent maxItems={maxItems} />
  }

  return (
    <>
      {data.map((row, i) => (
        <Box
          key={i}
          display='flex'
          alignItems='center'
          px={4}
          py={3}
          width='100%'
          fontWeight={500}
          fontSize='sm'
          lineHeight='1.4'
          title={row[model.displayKey ?? ''] as string}
          borderBottom='1px solid'
          borderColor='gray.100'
        >
          <Box mr={2}>
            <Document size={16} />
          </Box>
          <Link href={row[readMoreLink] as string} target='_blank'>
            <LinesEllipsis
              text={row[model.displayKey ?? ''] as string}
              maxLine={2}
              trimRight
              ellipsis='...'
            />
          </Link>
        </Box>
      ))}
    </>
  )
}

const LoadingComponent = ({ maxItems = 10 }: { maxItems?: number }) => {
  return (
    <>
      {_.times(maxItems, (i) => {
        return (
          <Box
            key={i}
            py={3}
            width='100%'
            px={4}
            borderBottom='1px solid'
            borderColor='gray.100'
            className='skeleton-wrapper'
          >
            <Skeleton height={20} width={randomIntFromInterval(80, 95) + '%'} />
          </Box>
        )
      })}
    </>
  )
}
