import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { LoadingAnimation, Select } from 'components'

import MapHeatmap from 'components/Dashboards/MapHeatmap'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import { useContext } from 'react'
import { DataContext, DataTypes, DataTypesT } from '..'
import { Download } from '@carbon/icons-react'
import { exportMapData } from '../LeftSection/helpers/exportData'

const selectOptions = [
  { label: 'Procurement', value: 'procurement' },
  { label: 'Current Supply', value: 'supply' },
  { label: 'Donation Pledges', value: 'donations' },
]

const MapSection = ({
  dataType,
  handleSelect,
  filters,
}: {
  dataType: DataTypesT
  handleSelect: (value: { value: DataTypesT; label: DataTypesT }) => void
  filters: FiltersT
}) => {
  const data = useContext(DataContext)

  if (!data?.[dataType]?.data) {
    return (
      <Box
        position={'relative'}
        height={'full'}
        width={'full'}
        borderRadius={'sm'}
        border='1px'
        borderColor={'gray.200'}
      >
        <LoadingAnimation />
      </Box>
    )
  }

  const formattedData = data?.[dataType]?.data.filter(
    (item: any) =>
      filters?.country === 'global' ||
      item.recipients.includes(filters?.country)
  )
  const selectedOption = selectOptions.find((item) => item.value === dataType)

  return (
    <Box
      pointerEvents={'auto'}
      className='mapContainer'
      width='100%'
      bg='white'
      height={'full'}
      pos='relative'
    >
      <MapHeatmap
        data={formattedData}
        summaryTitle={DataTypes[dataType].title}
        popoverTitle={DataTypes[dataType].popoverTitle}
        countFunction={(item: any) => item?.quantity}
        itemToCount={DataTypes[dataType].valueKey}
        filterKey='area'
        onClickCB={data.handleCountrySelection}
        colorScale={[
          '#ffff87', // Bright Yellow
          '#fae86e', // Bright Orange
          '#FF0000', // Bright Red
        ]}
      />
      <Flex pos='absolute' left={2} top={2}>
        <Button
          onClick={() => {
            exportMapData(
              data?.[dataType]?.data,
              selectedOption?.value ?? 'map-export'
            )
          }}
          border={'1px'}
          borderColor={'gray.200'}
          px={1}
          variant={'ghost'}
          alignItems={'center'}
          bg='white'
          w={7}
          h={7}
          p={0}
          ml={1}
          mt='2px'
        >
          <Download size={16} />
        </Button>
      </Flex>
      <Flex
        pos='absolute'
        left={'50%'}
        bottom={'74px'}
        transform={'translate(-50%,0)'}
        bg='white'
        flexDir='row'
        p='8px'
        border='1px solid'
        borderColor={'gray.200'}
        borderRadius={'8px'}
        boxShadow={'xl'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={3}
        zIndex={100}
      >
        <Text fontSize={'14px'}>Showing on the map</Text>
        <Select
          value={selectedOption}
          options={selectOptions}
          onChange={handleSelect}
          isMulti={false}
          isSearchable={true}
          backgroundColor='#fff'
          color='#000'
          fontSize={'14px'}
          height='36px'
        />
      </Flex>
    </Box>
  )
}

export default MapSection
