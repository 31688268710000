import { Box, TabPanels, TabPanel, TabPanelProps } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { DealsData } from 'config/common/baseModel'
import { IDTreatments360CandidateModel } from 'config/common/baseModel/idtreatments360/IDTreatments360Candidates'
import { IDTreatments360MediaModel } from 'config/common/baseModel/idtreatments360/IDTreatments360Media'
import { IDTreatments360PressReleasesModel } from 'config/common/baseModel/idtreatments360/IDTreatments360PressReleases'

import { DetailDrawerListCandidate } from './DetailDrawerCandidates'
import { DetailDrawerMedia } from './Media'
import { DetailDrawerListPressRelease } from './NewsPressRelease'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<DealsData, any>> = {
  handleClose: () => void
  deal: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<DealsData, any>>(props: PropTypes<D>) {
  const { handleClose, deal, model, title, viewData } = props
  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={deal && deal[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={['Summary', 'Candidate', 'Media', 'News & Press Releases']}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={deal} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                model={IDTreatments360CandidateModel}
                sortOrder={(x) => moment(x.completedAt)}
                ids={deal.linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList}
                itemComponent={DetailDrawerListCandidate}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                model={IDTreatments360MediaModel}
                sortOrder={(x) => moment(x.approvalDate)}
                ids={deal.media}
                itemComponent={DetailDrawerMedia}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={3}
                viewData={viewData}
                model={IDTreatments360PressReleasesModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={deal.pressReleases}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
