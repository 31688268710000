import React, { useEffect, useState } from 'react'
import { useContext } from 'react'

import { useAppParams } from 'routes/utils'

import { SelectOption } from 'components/Select'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { apps } from 'config/apps'

type ProviderType = {
  tableFilters: SingleAdvancedFilter<any, any>[]
  addToFilters: (filter: SingleAdvancedFilter<any, any>[]) => void
  removeFromFilters: (filter: SingleAdvancedFilter<any, any>) => void
  stackBy: SelectOption<string>
  setStackBy: React.Dispatch<React.SetStateAction<SelectOption<string>>>
  transformBy: SelectOption<string>
  setTransformBy: React.Dispatch<React.SetStateAction<SelectOption<string>>>
}

const defaultSelectOption = { label: '', value: '' }

export const TableChartFiltersContext = React.createContext<ProviderType>({
  addToFilters: () => {},
  removeFromFilters: () => {},
  tableFilters: [],
  stackBy: defaultSelectOption,
  setStackBy: () => {},
  transformBy: defaultSelectOption,
  setTransformBy: () => {},
})

export const useTableChartFilters = (): ProviderType => {
  return useContext(TableChartFiltersContext)
}

export const TableChartFiltersConsumer = TableChartFiltersContext.Consumer

export const TableChartFiltersProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { app, pageSlug, subPageSlug } = useAppParams()
  const selectedPage = apps[app]
    ? Object.values(apps[app].pages).find((page) => {
        if (subPageSlug === '') {
          return JSON.stringify(page.path) === JSON.stringify([pageSlug])
        } else {
          return (
            JSON.stringify(page.path) ===
            JSON.stringify([pageSlug, subPageSlug])
          )
        }
      })
    : null

  const { defaultHorizontalFilterValue, defaultStackByFilterValue } =
    selectedPage?.autoGenerateDashboard || {}

  const [tableAdvancedFilters, setTableAdvancedFilters] = useState<
    SingleAdvancedFilter<any, any>[]
  >([])
  const [stackBy, setStackBy] = useState<SelectOption<string>>(
    defaultStackByFilterValue ?? { label: '', value: '' }
  )
  const [transformBy, setTransformBy] = useState<SelectOption<string>>(
    defaultHorizontalFilterValue ?? { label: '', value: '' }
  )

  const removeFromFilters = (filter: SingleAdvancedFilter<any, any>) => {
    const [table] = filterOut(filter.column)
    setTableAdvancedFilters(table)
  }

  const addToFilters = (filter: SingleAdvancedFilter<any, any>[]) => {
    setTableAdvancedFilters(filter)
  }

  const filterOut = (columnToRemove: string) => {
    return [tableAdvancedFilters.filter((f) => f.column !== columnToRemove)]
  }

  useEffect(() => {
    setTableAdvancedFilters([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])

  useEffect(() => {
    if (defaultHorizontalFilterValue && defaultStackByFilterValue) {
      setStackBy(defaultStackByFilterValue)
      setTransformBy(defaultHorizontalFilterValue)
    }
  }, [defaultHorizontalFilterValue, defaultStackByFilterValue])

  return (
    <TableChartFiltersContext.Provider
      value={{
        addToFilters,
        removeFromFilters,
        tableFilters: tableAdvancedFilters,
        setTransformBy,
        transformBy,
        setStackBy,
        stackBy,
      }}
    >
      {children}
    </TableChartFiltersContext.Provider>
  )
}
