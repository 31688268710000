import { Box, TabPanels, TabPanel, TabPanelProps } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListCountry,
  DetailDrawerListDeal,
  DetailDrawerListMedia,
  DetailDrawerListPaper,
  DetailDrawerListPressRelease,
  DetailDrawerListTrial,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase, ResponseData } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import {
  CountriesDetailViewModal,
  VaccinesTrialsModel,
} from 'config/apps/Covid19/Science360/Vaccines/VaccinesModels'
import {
  PressReleasesModel,
  MediaModel,
  DealsModel,
  CandidateRankingData,
  PresentationsModel,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'

import { isRelational, relationGetIds } from 'utils/relational'

import { DetailDrawerListApprovals } from './DetailDrawerListApprovals'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<CandidateRankingData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<CandidateRankingData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props
  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={[
          'Summary',
          'Papers',
          'Press Releases',
          'Trials',
          'Media',
          'Deals',
          'Countries',
          'Approvals',
        ]}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={candidate} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                type='Papers'
                sortOrder={(x) => moment(x.publicationDate)}
                model={PresentationsModel}
                ids={candidate.science}
                itemComponent={DetailDrawerListPaper}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                type='PressReleases'
                model={PressReleasesModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={candidate.pressReleases}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={3}
                viewData={viewData}
                model={VaccinesTrialsModel}
                sortOrder={(x) => moment(x.completedAt)}
                ids={candidate.trials}
                itemComponent={DetailDrawerListTrial}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={4}
                viewData={viewData}
                type='Media'
                model={MediaModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={candidate.media}
                itemComponent={DetailDrawerListMedia}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={5}
                viewData={viewData}
                model={DealsModel}
                ids={candidate.deals}
                itemComponent={DetailDrawerListDeal}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={6}
                viewData={viewData}
                model={CountriesDetailViewModal}
                ids={
                  [
                    candidate.afEmergencyApproval,
                    candidate.afFullApproval,
                    candidate.afSuspendedUse,
                  ]
                    .map((responseData) =>
                      isRelational(responseData)
                        ? relationGetIds(responseData)
                        : (responseData as string[])
                    )
                    .flat() as ResponseData
                }
                itemComponent={DetailDrawerListCountry}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={7}
                viewData={viewData}
                model={ApprovalsModel}
                ids={candidate.approvals}
                itemComponent={DetailDrawerListApprovals}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
