import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type BioriskSlugs = '' | 'global-disease-resurgence'

const BioriskMenu: Array<INavigationMenu<BioriskSlugs>[]> = [
  [
    {
      title: 'Mpox',
      slug: '',
      createdAt: new Date(2024, 10, 28),
      infoText: {
        title: 'New',
        content:
          'New Mpox Dashboard Covering Forecasting, Competitive Intelligence and Epidemiology',
      },
    },

    {
      title: 'Global Disease Resurgence',
      slug: 'global-disease-resurgence',
      createdAt: new Date(2024, 5, 11),
    },
  ],
]

export { BioriskMenu }
