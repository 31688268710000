import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { DemoDataT, FiltersT, getCountryName } from '../../Epidemiology'
import { CategoricalChartState } from 'recharts/types/chart/types'
import CardContainer from 'components/Dashboards/IDATrialsMapDashboard/Cards/CardContainer'
import { GraphColors } from '../GraphColors'
import { Download } from '@carbon/icons-react'
import { DataTypeSelector } from '../DataTypeSelector'
import { exportData, getFormattedData } from './helpers'

export type DemoTypeT = 'gender' | 'airfinityAgeText'

const DataSelectorOptions = [
  { value: 'gender', label: 'Gender' },
  { value: 'airfinityAgeText', label: 'Age' },
]

const DemographicChart = ({
  data,
  filters,
  selectCountry,
}: {
  data: DemoDataT | undefined
  filters: FiltersT
  selectCountry?: (country: string) => void
}) => {
  const [expanded, setExpanded] = useState(false)
  const [demoType, setDemoType] = useState<DemoTypeT>('gender')

  function handleDataTypeChange(e: { label: string; value: string }) {
    setDemoType(e.value as DemoTypeT)
  }

  if (!data) return null

  const { formattedData, uniqueSubtypes } = getFormattedData(data, demoType)

  const graphData = formattedData.slice(0, filters.nCountries) // Top 5 countries

  function handleClick(e: CategoricalChartState) {
    if (e?.activeLabel) {
      selectCountry && selectCountry(e.activeLabel)
    }
  }

  const CustomTooltip = ({ active, payload, label, uniqueSubtypes }: any) => {
    if (active && payload && payload.length) {
      const sortedItems = payload.sort((a: any, b: any) => b.value - a.value)
      return (
        <Box
          p={2}
          borderWidth='1px'
          borderRadius='md'
          bg='white'
          shadow='md'
          fontSize={'sm'}
          w='fit-content'
        >
          <Text mb='8px' fontWeight={'bold'}>
            {getCountryName(label)} ({label})
          </Text>
          <Flex flexWrap={'wrap'} gap='8px'>
            {sortedItems.map((item: any, index: number) => {
              if (!item.value) return null

              return (
                <Flex key={index} alignItems='center' minWidth={'120px'}>
                  <Box bg={item.fill} w='12px' h='12px' />
                  <Flex ml='8px' alignItems='center'>
                    <Text
                      fontSize={'sm'}
                      textTransform={'capitalize'}
                      whiteSpace={'nowrap'}
                    >
                      {item.dataKey}
                    </Text>
                    :
                    <Text ml='8px' fontWeight={'semibold'}>
                      {item.value}
                    </Text>
                  </Flex>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      )
    }

    return null
  }

  const noData = !uniqueSubtypes.some((item) => !!item)

  return (
    <CardContainer
      minWidth='350px'
      expanded={expanded}
      setExpanded={setExpanded}
      width='100%'
    >
      <Flex
        w='90%'
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontWeight={'semibold'} fontSize='sm'>
          Mpox trends by
        </Text>
        <Flex alignItems={'center'} gap={2}>
          <DataTypeSelector
            value={demoType}
            onChange={handleDataTypeChange}
            options={DataSelectorOptions}
          />

          <Button
            onClick={() =>
              exportData(uniqueSubtypes, formattedData, demoType, filters)
            }
            variant={'ghost'}
            w={'24px'}
            h={'24px'}
            p={0}
          >
            <Download size={16} />
          </Button>
        </Flex>
      </Flex>
      <Box w={'100%'} h={'100%'} mt='1rem'>
        {noData ? (
          <Flex justifyContent={'center'} alignItems='center' h='100%'>
            <Text>No data for this filters</Text>
          </Flex>
        ) : (
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              data={graphData}
              onClick={handleClick}
              margin={{
                top: 5,
                right: 10,
                left: expanded ? 10 : -20,
                bottom: -10,
              }}
            >
              <XAxis
                dataKey='country'
                fontSize={expanded ? '12px' : '8px'}
                interval={0}
              />
              <YAxis fontSize={expanded ? '12px' : '8px'} />
              <Tooltip
                content={<CustomTooltip uniqueSubtypes={uniqueSubtypes} />}
              />
              {uniqueSubtypes.map((key, index) => (
                <Bar dataKey={key} key={key} fill={GraphColors[index]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </CardContainer>
  )
}

export default DemographicChart
