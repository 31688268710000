import { Box, Flex, Text } from '@chakra-ui/react'
import { flattenPaginatedData } from 'api/helper'
import useCollectionData from 'api/useCollectionData'
import { InlineLoading } from 'components'

import { IDTreatment360NewsAndPressReleasesModel } from 'config/common/baseModel/ida360/IDTreatment360NewsAndPressReleases'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { IBaseView } from 'interfaces/navigationPage.interface'
import { Virtuoso } from 'react-virtuoso'
import { formatSort } from 'routes/dashboard/WidgetComponents'
import { Article } from 'routes/dashboard/WidgetComponents/WidgetArticle'
import { useAppRoute } from 'routes/utils'

import { useEffect } from 'react'

const Card = ({ children }: { children: any }) => {
  return (
    <Box w='350px'>
      <Flex mb={6} flexDir={'column'} justifyContent={'space-between'}>
        <Text
          fontSize='18px'
          fontWeight='semibold'
          whiteSpace={'nowrap'}
          mt='5px'
          mb='2px'
        >
          Mpox latest media
        </Text>
      </Flex>
      <Box
        border='1px solid'
        borderColor='gray3'
        w={'100%'}
        py='1rem'
        px='0.5rem'
        bg='white'
        rounded='md'
        overflow={'hidden'}
        pos='relative'
        h='calc(70vh)'
      >
        {children}
      </Box>
    </Box>
  )
}

const LatestMediaPipeline = () => {
  const app = useAppRoute()
  const { tableFilters } = useTableChartFilters()

  const keyNews = 'keyNewsAlert'

  const view = {
    airtableName: 'outbreak_pipeline_mpox',
    airtableBase: 'lzdb',
    name: 'Default',
  } as IBaseView<any, any>

  const model = IDTreatment360NewsAndPressReleasesModel
  const sort =
    model.defaultSortObject && model.defaultSortObject.length > 0
      ? formatSort(
          model.defaultSortObject[0].id,
          model.defaultSortObject[0]?.sortOrder
        )
      : model.defaultSortKey
        ? formatSort(model.defaultSortKey, model.defaultSortOrder)
        : ''

  const { data, isFetched, isError, fetchNextPage, hasNextPage } =
    useCollectionData(app, model.endpoint, {
      limit: 200,
      sort,
      view: view.airtableName,
      airtableBase: view.airtableBase,
    })

  const articles = flattenPaginatedData(data?.pages)?.results

  const candidateFilter = tableFilters.find(
    (filter) => filter.column === 'candidateName'
  )

  const filteredArticles = candidateFilter
    ? articles?.filter((article) => {
        const res = candidateFilter?.filterValue?.some(
          (candidate: { value: string }) =>
            (article?.candidates as string)?.includes(candidate?.value)
        )
        return res
      })
    : articles

  useEffect(() => {
    fetchNextPage()
  }, [hasNextPage, fetchNextPage])

  if (isError) {
    return (
      <Card>
        <Flex w='100%' alignItems='center' justifyContent='center'>
          Sorry, something went wrong loading the latest media
        </Flex>
      </Card>
    )
  }
  if (!filteredArticles?.length && isFetched) {
    return (
      <Card>
        <Flex justifyContent={'center'} alignItems={'center'} h='full'>
          <Text> No alerts fort this Candidate</Text>
        </Flex>
      </Card>
    )
  }
  if (!filteredArticles?.length) {
    return (
      <Card>
        <Flex justifyContent={'center'} alignItems={'center'} h='full'>
          <InlineLoading />
        </Flex>
      </Card>
    )
  }

  return (
    <Card>
      <Box w='full' overflow={'hidden'} mt={'3px'} h='full'>
        <Virtuoso
          totalCount={filteredArticles.length}
          itemContent={(index: number) => {
            const row = filteredArticles[index]
            return (
              <Article
                id={row.id}
                modelEndpoint={'rss-article'}
                airtableBase={view.airtableBase}
                key={row.id}
                url={row.link as string}
                date={row.publishedAt as string}
                title={row?.titleDisplay as string}
                isKeyNews={!!row[keyNews]}
                trackMediaClick={() => {}}
              />
            )
          }}
        />
      </Box>
    </Card>
  )
}

export default LatestMediaPipeline
