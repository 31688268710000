import { Grid } from '@chakra-ui/react'
import TabbedDisplay from 'modules/TabbedDisplay'
import EpidemiologyDashboard from './Epidemiology'
import LatestAnalysisNews from './LatestAnalysisNews'
import ModellingDashboard from './Modelling'
import MpoxPipeline from './Pipeline'
import Response from './Response'

const BioriskMpoxTabs = () => {
  return (
    <TabbedDisplay
      minHeight='39px'
      tabListWrapper={(props) => (
        <Grid
          templateColumns='repeat(5, 1fr)'
          gap={2}
          width='100%'
          {...props}
        />
      )}
      tabs={[
        {
          heading: 'Latest Analysis & News',
          body: <LatestAnalysisNews />,
        },
        {
          heading: 'Epidemiology',
          body: <EpidemiologyDashboard />,
        },
        {
          heading: 'Response',
          body: <Response />,
        },
        {
          heading: 'Competitive Landscape',
          body: <MpoxPipeline />,
        },
        {
          heading: 'Forecasting',
          body: <ModellingDashboard />,
        },
      ]}
    />
  )
}

export default BioriskMpoxTabs
