import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip as ChakraTooltip,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { compactNumberWithPrefix } from 'utils/formatNumber'
import { FiltersT } from '../../Epidemiology'
import {
  downloadCSV,
  generateColorFromString,
  getTopCountries,
} from '../../Epidemiology/helpers'
import { SummaryDataT } from '../../Epidemiology/Map/utils/types'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'
import CardContainer from 'components/Dashboards/IDATrialsMapDashboard/Cards/CardContainer'
import { Download, Information } from '@carbon/icons-react'
import _ from 'lodash'
import { GraphColors } from '../GraphColors'
import { DataTypeSelector } from '../DataTypeSelector'

export type TopCountriesChartDataT = {
  label: string
  country: string
  countryName: string
  curr: number
  prev: number
  suspected: number
}[]

const DeathOptions = [
  { value: 'cases', label: 'Confirmed Cases' },
  { value: 'cases_per100K', label: 'Confirmed Per 100K' },
]
const InfectionsOptions = [
  ...DeathOptions,
  { value: 'suspected_cases', label: 'Suspected Cases' },
  { value: 'suspected_cases_per100K', label: 'Suspected Per 100K' },
]

export const truncateText = (text: string) => {
  if (text.length > 20) return `${text.substring(0, 13)}`
  return text
}

function findCountry(countries: TopCountriesChartDataT, label: string) {
  const defaultCountry = {
    label: '',
    country: '',
    countryName: '',
    curr: 0,
    prev: 0,
    suspected: 0,
  }
  if (!countries) return defaultCountry

  return countries.find((item) => item.countryName === label) || defaultCountry
}

function exportData(data: any, dataType: string, filters: FiltersT) {
  const headers = [
    'Country',
    'Country Code',
    'Date Start',
    'Date End',
    'Type',
    InfectionsOptions.find((item) => item.value === dataType)?.label,
  ].join(',')
  const rows: string[] = []

  Object.keys(data).forEach((_country) => {
    const row = data[_country]
    const match = _country.match(/^(.+?)\s\((\w+)\)$/)
    let areaAlpha3 = _country,
      country = _country

    if (match) {
      country = match[1]
      areaAlpha3 = match[2]
    }
    rows.push(
      [
        country?.replaceAll(',', ''),
        areaAlpha3,
        filters.dateRange.start,
        filters.dateRange.end,
        dataType === 'cases'
          ? row.finalValueCurrentPeriod
          : row.finalValuePer100KCurrentPeriod,
        filters.dataType,
      ].join(',')
    )
  })

  const sortedRows = rows.sort((a, b) => {
    const valueA = +a.split(',')[4]
    const valueB = +b.split(',')[4]
    return valueB - valueA
  })

  const fileName = `TopCountries_${filters.country}_${dataType}_${filters.dateRange.start}_${filters.dateRange.end}.csv`
  downloadCSV(headers, sortedRows, fileName)
}

const TopCountriesGraph = ({
  data,
  selectCountry,
  filters,
  dataType,
  setDataType,
}: {
  data: SummaryDataT | undefined
  selectCountry?: (country: string) => void
  filters: FiltersT
  dataType: string
  setDataType: (dataType: string) => void
}) => {
  const [expanded, setExpanded] = useState(false)

  const [topCountries, setTopCountries] = useState<TopCountriesChartDataT>()
  const nameToCountryCodeMapping = _.invert(countryCodeToNameMapping)

  const selectorOptions =
    filters.dataType === 'infections' ? InfectionsOptions : DeathOptions

  useEffect(() => {
    if (data) {
      const topCountriesData = getTopCountries(data, dataType, filters.country)
      topCountriesData && setTopCountries(topCountriesData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataType])
  // // TODO: subnational removal
  // }, [data, filters.country, dataType])

  if (!topCountries) return null

  // TODO: subnational removal (remove filtering)

  const selectedCountry =
    filters.country !== 'global'
      ? topCountries.filter((country) => country.country === filters.country)
      : topCountries

  const chartData = selectedCountry.slice(0, filters.nCountries)

  const noData = !chartData.some((item) => !!item.country)

  function handleClick(e: CategoricalChartState) {
    if (e?.activeLabel) {
      const countryCode =
        nameToCountryCodeMapping[
          e.activeLabel as keyof typeof countryCodeToNameMapping
        ]
      selectCountry && selectCountry(countryCode)
    }
  }

  function handleDataTypeChange(e: { label: string; value: string }) {
    setDataType(e.value)
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const country = findCountry(topCountries, label)
      return (
        <Box
          p={2}
          borderWidth='1px'
          borderRadius='md'
          bg='white'
          shadow='md'
          fontSize={'sm'}
        >
          <Flex gap='4px'>
            <Text fontWeight={'semibold'}>{country.countryName}</Text>(
            <Text textTransform='uppercase'>{country.country}</Text>)
          </Flex>
          <Flex gap='8px'>
            <Text color='gray.700'>Current Period: </Text>
            <Text fontWeight={'semibold'}>{country.curr.toFixed(1)}</Text>
          </Flex>
          {!!country.suspected && (
            <Flex gap='8px'>
              <Text color='gray.700'>Current Period Suspected: </Text>
              <Text fontWeight={'semibold'}>
                {country.suspected.toFixed(1)}
              </Text>
            </Flex>
          )}
          <Flex color='gray.700' gap='8px'>
            <Text>Previous Period: </Text>
            <Text fontWeight={'semibold'}>{country.prev.toFixed(1)}</Text>
          </Flex>
        </Box>
      )
    }

    return null
  }

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props
    const country = findCountry(topCountries, value)
    let increment = ((country.curr - country.prev) / country.prev) * 100
    const formattedIncrement = compactNumberWithPrefix(increment, 2)
    const increase = increment > 0
    return (
      <g>
        <text
          fontSize={'10px'}
          x={1.7 * x + width}
          y={y + height / 2}
          fill={increment > 0 ? 'green' : 'red'}
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {increase ? '↑' : '↓'}
          {formattedIncrement}%
        </text>
      </g>
    )
  }

  return (
    <CardContainer
      minWidth='350px'
      expanded={expanded}
      setExpanded={setExpanded}
      width='100%'
    >
      <Flex
        w='90%'
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <ChakraTooltip label='Current period refers to the selected time period in the filter. All calculations are based on the same absolute time period before the one selected. Ie if a 30 day time period was selected in February, the calculations would be based off a 30 day time period in January.'>
          <Information />
        </ChakraTooltip>
        <Text fontWeight={'semibold'} whiteSpace={'nowrap'} fontSize='sm'>
          Top Affected {filters.country === 'global' ? 'Countries' : 'Regions'}
        </Text>
        <Flex alignItems={'center'} gap={2}>
          <DataTypeSelector
            value={dataType}
            onChange={handleDataTypeChange}
            options={selectorOptions}
          />
          <Button
            onClick={() => exportData(data, dataType, filters)}
            variant={'ghost'}
            w={'24px'}
            h={'24px'}
            p={0}
          >
            <Download size={16} />
          </Button>
        </Flex>
      </Flex>
      <Box w={'100%'} h={'100%'} pb='1rem'>
        {noData ? (
          <Flex justifyContent={'center'} alignItems='center' h='100%'>
            <Text>No data for this filters</Text>
          </Flex>
        ) : (
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              data={chartData}
              layout='vertical'
              margin={{
                top: 20,
                right: 50,
                left: expanded ? 10 : 0,
                bottom: -30,
              }}
              onClick={handleClick}
            >
              <Tooltip content={<CustomTooltip />} />
              <XAxis type='number' fontSize={expanded ? '12px' : '8px'} />
              <YAxis
                dataKey='countryName'
                type='category'
                width={40}
                fontSize={expanded ? '12px' : '8px'}
                ticks={chartData.map((item) => item.countryName)}
                interval={0}
                tickFormatter={truncateText} // Apply custom formatter
              />
              <Bar dataKey='curr' stackId='a' fill={GraphColors[4]}>
                {chartData.map((entry) => (
                  <Cell
                    key={`cell-${entry.country}`}
                    fill={generateColorFromString(entry.countryName)}
                  />
                ))}
                <LabelList
                  dataKey='countryName'
                  position='right'
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey='suspected' stackId='a' fill={GraphColors[1]} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </CardContainer>
  )
}

export default TopCountriesGraph
