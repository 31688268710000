export enum AccessGroups {
  Vaccines = 'product_covid_vaccines',
  Treatments = 'product_covid_treatments',
  Diagnostics = 'product_covid_diagnostics',
  Pandemic = 'product_covid_macro',
  PandemicDemo = 'product_client_demo_mpox',
  PAD = 'product_pad',
  CardioLipid = 'product_cardio_lipid',
  APIForecast = 'product_covid_api_forecast',
  APIEpidId = 'product_api_infectious_epid',
  APIOutbreak = 'product_api_outbreak',
  APIReport = 'product_api_report',
  CovidCommercialVaccines = 'product_covid_supply_and_production',
  CardioConference = 'product_esc2021',
  Influenza = 'product_influenza',
  InfluenzaCommercial = 'product_influenza_market_and_production',
  InfluenzaEpidemiology = 'product_influenza_disease360',
  RSV = 'product_rsv',
  RSVEpidemiology = 'product_epidemiology_rsv',
  RSVCommercial = 'product_rsv_market_and_production',
  CovidEpidData = 'product_covid_epid_data',
  Moderna = 'product_covid_booster_market_forecast',
  CovidVaccineMarketForecast = 'product_covid_vaccine_market',
  Epidemiology = 'product_covid_epidemiology',
  CovidCommercialTherapeutics = 'product_covid_market_and_production_therapeutics',
  IEGPolicySurveillance = 'product_real_world_evidence',
  ResearchPowerIndex = 'product_research_power_index',
  CardioAntithrombotics = 'product_cardio_antithrombotics',
  InternalTooling = 'internal_tooling',
  RNATherapeutics = 'product_rna_therapeutics',
  PNH = 'product_pnh',
  IDA360Vaccines = 'product_ida_vaccines',
  IDA360Treatments = 'product_ida_treatments',
  IDA360ChikV = 'product_ida_chikv',
  IDA360Amr = 'product_ida_amr',
  IDA360Malaria = 'product_ida_malaria',
  IDA360Dengue = 'product_ida_dengue',
  Obesity360 = 'product_obesity_360',
  APISiga = 'product_api_siga',
  BetaTester = 'beta_tester',
}
