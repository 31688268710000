import { Box, Flex, Text } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import useKeyPress from 'utils/useKeyPress'
import styled from 'styled-components'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import './styles.css'
import { MonthYearSelect } from 'components/Dashboards/IDATrialsMapDashboard/DatePicker'

export type FilterType = any

const HiddenInput = styled.div`
  button {
    outline: none;
  }

  .SingleDatePickerInput {
    .DateInput {
      display: none;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
  .DateRangePickerInput {
    .DateInput {
      display: none;
    }
    .DateRangePickerInput_calendarIcon {
      padding: 0;
    }
    background: none;
    outline: none;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
`

const DatePickerTrigger = ({ date }: { date: string | null | undefined }) => {
  const dateString = moment(date).format('MMM Do YYYY')
  return (
    <Flex
      ml='-10px'
      w='100%'
      minW='120px'
      border={'1px'}
      borderColor={'gray.200'}
      h={8}
      position={'relative'}
      px={2}
      alignItems={'center'}
      justifyContent={'center'}
      py={1}
      borderRadius={'sm'}
    >
      {date ? (
        <Text fontSize={'xs'} color={'gray.800'} fontWeight={'semibold'}>
          {dateString}
        </Text>
      ) : (
        <Text fontSize={'xs'} color={'gray.800'} fontWeight={'semibold'}>
          Time Range
        </Text>
      )}
    </Flex>
  )
}

export const SingleDateFilter = ({
  date,
  onDateChange,
  minDate,
}: {
  date: string
  onDateChange: (date: string) => void
  minDate?: string
}) => {
  const isPresetSelected = useRef(false)
  const [startDate, setStartDate] = useState<Date>()
  const [focused, setFocused] = useState<boolean>(false)

  const handleDateChange = (startDate: Date) => {
    const start = moment(startDate).format('YYYY-MM-DD')
    onDateChange(start)
    isPresetSelected.current = false
  }

  useKeyPress('Escape', () => {
    if (focused) setFocused(false)
  })

  return (
    <Flex>
      <Box style={{ zIndex: 99 }}>
        <HiddenInput>
          <SingleDatePicker
            id='mpox-modelling-start-date-picker' // PropTypes.string.isRequired,
            hideKeyboardShortcutsPanel={true}
            withPortal
            isOutsideRange={(x) => {
              return moment(x).isBefore(minDate || '1900-01-01')
            }}
            renderMonthElement={(props) => <MonthYearSelect {...props} />}
            inputIconPosition='before'
            anchorDirection='right'
            onClose={({ date: _date }) => {
              if (!isPresetSelected.current) {
                handleDateChange(_date?.toDate() ?? new Date())
              }
            }}
            onDateChange={(_date) => {
              setStartDate(_date?.toDate() ?? new Date())
            }}
            focused={focused}
            onFocusChange={({ focused }) => {
              setFocused(focused)
            }}
            date={startDate ? moment(startDate) : moment(date)}
            customInputIcon={<DatePickerTrigger date={date} />}
          />
        </HiddenInput>
      </Box>
    </Flex>
  )
}

export default SingleDateFilter
