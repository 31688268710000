import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { TimeLapse } from '@carbon/pictograms-react'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useSharedReport } from 'api/cms/reports/useReports'
import { FullPageError, LoadingAnimation } from 'components'

import './styles.css'
import { useParams } from 'react-router-dom'
import { ShareReportViewer } from '../SecureReportById/PdfComponent'
import { Link } from 'react-router-dom'
import { Logo } from 'modules/Navigation/Logo'

const LinkExpiredPage = () => {
  return (
    <Flex
      p={16}
      textAlign='center'
      data-cy='error'
      flexDir={'column'}
      justify={'center'}
      alignItems={'center'}
      gap='0.5rem'
    >
      <TimeLapse />
      <Heading mb={2} fontSize='2xl' mt='2rem'>
        Sorry, the link has expired.
      </Heading>
      <Text maxW='lg' mx='auto'>
        To get more reports like this get in touch with us at
        support@airfinity.com.
      </Text>
    </Flex>
  )
}

const SharedReport = () => {
  const params = useParams()
  const reportSlug = params.slug || ''

  const {
    data: reportData,
    isError,
    error,
    isLoading,
  } = useSharedReport(reportSlug)

  const isLinkExpired = error?.status === 410

  if (isLinkExpired) return <LinkExpiredPage />
  if (!reportData?.url && isError) return <FullPageError />
  if (!reportData?.url && isLoading) return <LoadingAnimation />

  return (
    <>
      <Flex
        justifyContent={'center'}
        bg='gray.100'
        py='0.5rem'
        overflowY='scroll'
        mt='3rem'
      >
        <ShareReportViewer
          pdfId={reportData.id ? reportData.id.toString() : ''}
          pdfTitle={reportData.title ?? ''}
          pdfUrl={reportData.url}
          sharedPages={reportData.pageNumbers}
        />
      </Flex>
      <Flex
        bg='secondary'
        justifyContent={'center'}
        pos={'fixed'}
        zIndex={9999}
        w='full'
      >
        <Flex
          pos='relative'
          w-='full'
          justifyContent={'center'}
          flexDir={'column'}
          alignItems={'center'}
        >
          <Flex
            pos={['relative', 'relative', 'relative', 'absolute']}
            left={['0', '0', '0', '1rem']}
            top='0'
            bottom={'0'}
            alignItems={'center'}
            mt={['1rem', '1rem', '1rem', '0']}
          >
            <Link to='/'>
              <Box h={'33px'} w='100px'>
                <Logo color={'black'} />
              </Box>
            </Link>
          </Flex>
          <Text
            textAlign={'center'}
            width={'55%'}
            minW='350px'
            color={'black'}
            py='0.5rem'
            fontSize={['sm', 'sm', 'sm', 'md']}
          >
            This Airfinity report has been shared with you by{' '}
            <b>{reportData?.sharedFrom}</b>, if you are interested in continuing
            to receive these reports please email us at{' '}
            <b>contact@airfinity.com</b>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default SharedReport
