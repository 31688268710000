import { Flex } from '@chakra-ui/react'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import LatestMedia from 'modules/BioriskMpox/common/LatestMedia'
import LatestAlerts from 'modules/BioriskMpox/LatestAnalysisNews/LatestAlerts'

const LeftSection = ({ filters }: { filters: FiltersT }) => {
  return (
    <Flex flexDir={'column'} gap='0.5rem' w='350px' minW='350px' maxW='350px'>
      <LatestAlerts
        countryCode={filters.country}
        pagination={false}
        h='90%'
        dateRange={filters.dateRange}
      />
      <LatestMedia selectedArea={filters.country} />
    </Flex>
  )
}

export default LeftSection
