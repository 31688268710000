import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import Dashboard from 'routes/dashboard'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { getMediaDefaultWidget } from 'config/common/baseModel/Media'
import { getPresentationDefaultWidget } from 'config/common/baseModel/Presentations'
import { getPressReleasesDefaultWidget } from 'config/common/baseModel/PressReleases'

import { TreatmentsSlugs } from './TreatmentsMenu'
import { TreatmentsPages } from './TreatmentsPages'
import NewsletterPage from 'routes/common/FullReportPage/components/NewsletterPage'
import NewNotePopover from 'components/PageHeader/NewNotePopover'
import SingleReportByID from 'routes/common/SingleReportByID'
import InfluenzaPageBanner from 'components/PageBanner/Influenza'

const LatestNewsPage: ISingleCustomPage<TreatmentsSlugs> = {
  path: '',
  component: () => {
    return (
      <>
        <Dashboard
          widgetTabs={[
            getMediaDefaultWidget({ view: TreatmentsPages['Media'].views[0] }),
            getPresentationDefaultWidget({
              view: TreatmentsPages['CandidatePapers'].views[0],
              model: TreatmentsPages.CandidatePapers.model,
            }),
            getPressReleasesDefaultWidget({
              view: TreatmentsPages['PressReleases'].views[0],
            }),
          ]}
        />
      </>
    )
  },
}

const DeepDive: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['reports'],
  component: () => <FullReportPage showCategory={false} title='Reports' />,
}
const ResearchBriefs: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const VariantTreatmentImpact: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['results', 'variant-impact'],
  component: () => (
    <TableauEmbed path='/Variantvaccineheatmap_new/Dashboard1' />
  ),
}

const RecentTrialsMap: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['trials', 'recent-trials-map'],
  component: () => (
    <TableauEmbed
      path='/Clinical_trials_COVID_Flu_RSV/Clinicaltrials_treatments'
      height='170vh'
    />
  ),
}

const RegulatoryDashboard: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['regulatory', 'dashboard'],
  component: () => (
    <TableauEmbed path='/TxS360/RegulatoryDashboard' height='120vh' />
  ),
}

const ProgressTrackerDashboard: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['candidates', 'progress-tracker'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overall Progress',
          body: (
            <TableauEmbed
              path='/frontendcovidtxprogresstracker/OverallProgressbyPhase2'
              height='120vh'
            />
          ),
        },
        {
          heading: 'Metric Breakdown',
          body: (
            <TableauEmbed
              path='/frontendcovidtxprogresstracker/ProgressbyMetric'
              height='120vh'
            />
          ),
        },
      ]}
    />
  ),
}

const ConferencesDebriefs: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['conferences', 'debriefs'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
          are now available on the platform. If you would like a briefing on our conference insights, 
          please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <InfluenzaPageBanner mb={'1rem'} />
      <NewsletterPage
        endpoint='/lzdb/covid-treatments/newsletters/conference/'
        summaryRowIndex={6}
        title='Conferences Newsletters'
      />
    </>
  ),
}
const ConferencesDeepDives: ISingleCustomPage<TreatmentsSlugs> = {
  path: ['conferences', 'deep-dives'],
  component: () => (
    <>
      <NewNotePopover
        message={`Our daily debriefs, covering all of the important scientific and commercial content during conferences, 
      are now available on the platform. If you would like a briefing on our conference insights, 
      please speak to your account manager or email infectiousdiseases@airfinity.com`}
        newUntil={new Date(2024, 11, 1)}
      />
      <InfluenzaPageBanner mb={'1rem'} />
      <SingleReportByID title='Conferences Deep Dives' id='1471' />
    </>
  ),
}

export const TreatmentsCustomPages: Record<
  string,
  ISingleCustomPage<TreatmentsSlugs>
> = {
  LatestNewsPage,
  DeepDive,
  VariantTreatmentImpact,
  RecentTrialsMap,
  RegulatoryDashboard,
  ProgressTrackerDashboard,
  ResearchBriefs,
  ConferencesDebriefs,
  ConferencesDeepDives,
}
