import { Information } from '@carbon/icons-react'
import { Flex, Text } from '@chakra-ui/react'

import RSVDetailView from 'routes/apps/rsv/Candidates'

import { Panel } from 'components'

import { SortOrders } from 'enums/SortOrders'

import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { RespiratoryCandidatesModel } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'
import { RespiratoryToolMetaVaccineEfficacyModel } from 'config/common/baseModel/respiratory360/RespiratoryToolMetaVaccineEfficacy'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

const PhaseSortingObject = {
  sortOrderObject: [
    'null',
    'Validation',
    'Not Applicable',
    'Unknown',
    'Inactive',
    'Discontinued',
    'Paused',
    'Product Launch',
    'Approved',
    'Phase IV',
    'Preclinical',
    'Phase 0',
    'Phase I',
    'Phase I/II',
    'Phase II',
    'Phase II/III',
    'Phase III',
    'Phase III/IV',
  ],
}

export const RSVCandidatesVaccineModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'candidateName',
    'manualLatestPhase',
    'linkCompanies',
    'alternateNames',
    'linkInfectiousDisease',
    'vaccineType',
    'afTargetPopulation',
    'adjuvanted',
    'linkResearchInstitutions',
    'administrationRoute',
    'latestPhaseManualSource',
  ]),
  updateColumnAttributes('manualLatestPhase', PhaseSortingObject)
)({
  ...RespiratoryCandidatesModel,
  name: 'Vaccine Candidates',
  endpoint: 'vaccine-candidate',
  renderDetailView: RSVDetailView,
  defaultSortOrder: SortOrders.DESC,
  defaultSortKey: 'manualLatestPhase',
})

export const RSVCandidatesNonVaccinesModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'vaccineGroupTypeSplit',
    'afTargetPopulation',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
  ]),
  updateColumnAttributes('manualLatestPhase', PhaseSortingObject)
)({
  ...RespiratoryCandidatesModel,
  name: 'Combination vaccines',
  renderDetailView: RSVDetailView,
  endpoint: 'vaccine-candidate',
  defaultSortOrder: SortOrders.DESC,
  defaultSortKey: 'manualLatestPhase',
})

export const RSVCandidatesTreatmentModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'afTargetPopulation',
    'treatmentTiming',
    'treatmentType',
    'mechanismOfActionDescription',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
    'latestPhaseManualSource',
  ]),
  updateColumnAttributes('manualLatestPhase', PhaseSortingObject)
)({
  ...RespiratoryCandidatesModel,
  name: 'Non-Vaccine Candidates',
  endpoint: 'treatment-candidate',
  renderDetailView: RSVDetailView,
  defaultSortObject: [
    {
      id: 'manualLatestPhase',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'treatmentTiming',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const RSVCandidatesAllCombinedModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'candidateName',
    'linkInfectiousDisease',
    'alternateNames',
    'manualLatestPhase',
    'vaccineType',
    'afTargetPopulation',
    'adjuvanted',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
    'treatmentTiming',
    'mechanismOfActionDescription',
    'latestPhaseManualSource',
  ])
)({
  ...RespiratoryCandidatesModel,
  name: 'All',
  endpoint: 'vaccine-candidate',
  renderDetailView: RSVDetailView,
  defaultSortOrder: SortOrders.DESC,
  defaultSortKey: 'manualLatestPhase',
})

export const RSVCandidatesCombinationVaccinesModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'vaccineGroupTypeSplit',
    'afTargetPopulation',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
    'latestPhaseManualSource',
  ]),
  updateColumnAttributes('manualLatestPhase', PhaseSortingObject)
)({
  ...RespiratoryCandidatesModel,
  name: 'Combination vaccines',
  renderDetailView: RSVDetailView,
  endpoint: 'vaccine-candidate',
  defaultSortOrder: SortOrders.DESC,
  defaultSortKey: 'manualLatestPhase',
})

export const RSVToolMetaVaccineEfficacyModel = compose<
  typeof RespiratoryToolMetaVaccineEfficacyModel
>(
  whiteListColumn([
    'candidateNameFromVaccineCandidate',
    'companies',
    'phasesCleanFromClinicalTrialId',
    'trialType',
    'achievedPrimaryEndpoint',
    'criteriaForPrimaryEndpoint',
    'studyPopulationType',
    'clinicalTrialId',
    'genPop',
    'efficacyEffectiveness',
    'confidenceIntervalOfEfficacy',
    'adverseEventsMildModerate',
    'adverseEventsSevere',
    'trialSize',
    'numberDoses',
    'dosingInterval',
    'dose',
    'numberOfConfirmedCases',
    'numberOfCasesInVaccinatedGroup',
    'numberOfCasesOnPlaceboGroup',
    'trialLocations',
    'articleLink',
    'dataSource',
  ])
)({
  ...RespiratoryToolMetaVaccineEfficacyModel,
  name: 'Vaccine Efficacy',
  description: () => (
    <Panel
      px={2}
      py={1}
      my={2}
      maxW='600px'
      border='1px solid'
      borderColor='gray3'
      borderRadius='8px'
      mb={2}
      shadow={'sm'}
    >
      <Flex gap={1}>
        <Information style={{ marginTop: '2px' }} />
        <Text fontSize={'13px'}>
          These vaccines have not been compared in head to heads and thus,
          differences should be interpreted with caution.
        </Text>
      </Flex>
    </Panel>
  ),
  excludeGeneratedCharts: true,
})

export const RSVToolMetaNonVaccineEfficacyModel = compose<
  typeof RespiratoryToolMetaVaccineEfficacyModel
>(
  whiteListColumn([
    'candidateNameFromTreatmentCandidate',
    'manualLatestPhaseFromTreatmentCandidate',
    'linkCompaniesFromTreatmentCandidate',
    'trialType',
    'trialPhase',
    'achievedPrimaryEndpoint',
    'treatmentTimingFromTreatmentCandidate',
    'treatmentTypeFromTreatmentCandidate',
    'directVsHostTargetedFromTreatmentCandidate',
    'studyPopulationType',
    'clinicalTrialId',
    'genPop',
    'efficacyResults',
    'efficacyRelativeReductionInHospitalisation',
    'efficacyAdditionalSAndSScoreReduction',
    'efficacyAdditionalReductionViralLoad',
    'pooledEfficacyRelativeReductionInHospitalisation',
    'efficacyRelativeReductionInInfection',
    'efficacyAucMeanViralLoad',
    'efficacyAucMeanSymptomScore',
    'adverseReactions',
    'safetySevereAdverseReactions',
    'safetyGeGrade3',
    'safetyUpperRespiratoryInfection',
    'safetyPharyngitis',
    'safetyRhinitis',
    'safetyOtitisMedia',
    'safetyPain',
    'safetyHeadache',
    'safetyRash',
    'safetyHernia',
    'safetyGastrointestinalDisorders',
    'safetyDiarrhoea',
    'safetyDeath',
    'dataSource',
  ]),
  renameColumn('candidateNameFromVaccineCandidate', 'Non-Vaccine'),
  renameColumn('safetySevereAdverseReactions', 'Serious Adverse Reactions'),
  updateColumnAttributes('studyPopulationType', { width: 180 })
)({
  ...RespiratoryToolMetaVaccineEfficacyModel,
  displayKey: 'candidateNameFromTreatmentCandidate',
  searchField: 'candidateNameFromTreatmentCandidate',
  excludeGeneratedCharts: true,
})

export const RsvApprovalsVaccinesModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidateNameFromVaccineCandidateList',
    'linkCompaniesFromVaccineCandidateList',
    'afTargetPopulationFromVaccineCandidateList',
    'cleanedAgeGroup',
    'approvalType',
    'countryOrganisationGrantingDecision',
    'regulatoryBody',
    'url',
    'singleDate',
  ])
)({
  ...ApprovalsModel,
  name: 'Regulatory',
  defaultSortKey: 'singleDate',
})
export const RsvApprovalsNonVaccinesModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidateNameFromTreatmentCandidateList',
    'luTreatmentType',
    'linkCompaniesFromTreatmentCandidateList',
    'afTargetPopulationFromTreatmentCandidateList',
    'indication',
    'patientSetting',
    'cleanedAgeGroup',
    'approvalType',
    'countryOrganisationGrantingDecision',
    'regulatoryBody',
    'url',
    'singleDate',
  ])
)({
  ...ApprovalsModel,
  name: 'Regulatory',
})
