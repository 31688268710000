import { Box, Flex, Tooltip } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'

import { FullPageError, LoadingAnimation } from 'components'

import useAxios from 'api/useAxios'

import { LayerT } from './Map/layers'
import { CladeDataT, DataT, FeaturesT } from './Map/utils/types'
import { subYears } from 'date-fns'
import _ from 'lodash'
import { MapRef } from 'react-map-gl'
import { recursiveCamelCase } from 'api/helper'

import MainFilterSection from './Filters'
import moment from 'moment'
import { getCollections } from './Map/utils'
import {
  fetchCountryPolygons,
  getQueryString,
  removeNullCases,
} from './helpers'
import MapSection from './Map'
import { Information } from '@carbon/icons-react'
import { batchAndThrottleRequests } from './requestHelper'
import RightSection from './RightSection'
import LeftSection from './LeftSection'
import AboutDataPopover from './AboutDataPopover'
import UpsellBanner from '../common/UpsellBanner'

export const DefaultStartDate = subYears(new Date(), 1)

export interface TopCountriesT {
  area: string
  country: string
  countryCode: string
  value: number
  allCases: DataT[]
}
export interface EpiTrendDataT {
  area: string
  country: string
  value: number
  allCases: DataT[]
}

export type DemoDataT = _.Dictionary<DataT[]>

export const Card = ({
  minW,
  w,
  h,
  children,
  info,
  overflow,
  allowExpand = false,
}: {
  minW?: any
  h?: string
  w?: string
  children: any
  info?: string
  allowExpand?: boolean
  overflow?: string
}) => {
  return (
    <Box
      border='1px solid'
      borderColor='gray3'
      h={h || '100%'}
      w={w || '100%'}
      minW={minW || '350px'}
      p='1rem'
      bg='white'
      rounded='md'
      overflow={overflow || 'hidden'}
      pos='relative'
    >
      {info ? (
        <Flex pos='absolute' top={4} right={4}>
          <Tooltip label={info}>
            <Information color='gray5' />
          </Tooltip>
        </Flex>
      ) : null}
      {children}
    </Box>
  )
}

export const CARD_WIDTH = '500px'
export const CARD_HEIGHT = '17.4vh'

export function getCountryName(countryCode: string) {
  const code = countryCode?.toUpperCase()
  // @ts-ignore
  return countryCodeToNameMapping?.[code] || countryCode
}

export interface FiltersT {
  dataType: string
  dateRange: {
    start: string
    end: string
  }
  country: string
  nCountries: number
}

const DefaultFilters = {
  dataType: 'infections',
  dateRange: {
    start: moment().subtract(2, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  country: 'global',
  nCountries: 5,
}

const EpidemiologyDashboard = () => {
  const mapRef = useRef<MapRef>(null)

  const [filters, setFilters] = useState<FiltersT>(DefaultFilters)
  const [selectedLayer, setLayer] = useState<LayerT>('')
  const [features, setFeatures] = useState<FeaturesT>()

  const [countriesList, setCountryList] = useState<string[]>()

  const axios = useAxios()

  const { data: countryData } = useQuery(
    [filters.dataType, filters.dateRange],
    async () => {
      const { start, end } = filters.dateRange
      const formattedStartDate = moment(start).format('YYYY-MM-DD')
      const formattedEndDate = moment(end).format('YYYY-MM-DD')

      const queryString = getQueryString(
        filters.dataType,
        formattedStartDate,
        formattedEndDate,
        // TODO: subnational removal
        'global'
        // filters.country
      )

      const resStandard = await axios.get(queryString.standard)
      const standard = removeNullCases(recursiveCamelCase(resStandard.data))

      return standard as DataT[]
    }
  )

  const {
    data,
    error: dataError,
    isLoading,
  } = useQuery(
    [filters.dataType, filters.dateRange, filters.country],
    async () => {
      const { start, end } = filters.dateRange
      const formattedStartDate = moment(start).format('YYYY-MM-DD')
      const formattedEndDate = moment(end).format('YYYY-MM-DD')

      const queryString = getQueryString(
        filters.dataType,
        formattedStartDate,
        formattedEndDate,
        // TODO: subnational removal
        'global'
        // filters.country
      )

      const [resStandard, resClade, resCountryPolys] = await Promise.all([
        axios.get(queryString.standard),
        axios.get(queryString.clade),
        fetchCountryPolygons(),
      ])

      const standard = removeNullCases(recursiveCamelCase(resStandard.data))
      const clade = recursiveCamelCase(resClade.data) as CladeDataT

      const isGlobal = filters.country === 'global'

      const correctClade = clade.filter(
        (c: any) => c.clade === 'Clade Ia' || c.clade === 'Clade Ib'
      )

      const countryClades = isGlobal
        ? correctClade
        : correctClade.filter((c: any) => c.areaAlpha3 === filters.country)

      const dateClades = countryClades.filter((clade) => {
        const isValid = moment(
          clade.date || clade.dateSubmitted,
          'YYYY-MM-DD'
        ).isBetween(moment('2024-01-01'), moment(end), undefined, '[]')
        return isValid
      })

      const finalCladeList = dateClades

      const uniqueAreas = [
        ...new Set(
          finalCladeList
            .filter((x: any) => !!x.areaLongitude)
            .map((item: any) => item.areaId)
        ),
      ] as string[]

      const areaPolygons =
        !isGlobal &&
        ((await batchAndThrottleRequests(axios, uniqueAreas)) as any)

      function findAreaLevelById(areaId: string) {
        return (
          // eslint-disable-next-line eqeqeq
          finalCladeList.find((x: any) => x.areaId == areaId)?.areaAdminLevel ??
          0
        )
      }

      if (!isGlobal) {
        areaPolygons.features = [
          ...areaPolygons.features,
          ...resCountryPolys.features,
        ]
          .map((poly: any) => ({
            ...poly,
            adminLevel: findAreaLevelById(poly.properties.areaId),
          }))
          .sort((a: any, b: any) => a.adminLevel - b.adminLevel)
      }

      return {
        standard: standard as DataT[],
        clade: finalCladeList,
        countryPolygons: isGlobal ? resCountryPolys : areaPolygons,
      }
    }
  )

  function handleLegendClick(newLayer: LayerT) {
    setLayer(newLayer === selectedLayer ? '' : newLayer)
  }

  useEffect(() => {
    if (data && data?.countryPolygons) {
      const countryFilter =
        filters.country !== 'global' ? filters.country : undefined

      const collections = getCollections(
        data.standard,
        data.clade,
        data.countryPolygons,
        filters.dateRange.end,
        countryFilter
      )
      setFeatures(collections)
    }
  }, [data, filters.dateRange, filters.country, filters.dataType])

  useEffect(() => {
    if (countryData) {
      const countries = _.groupBy(countryData, (item) => item.areaAlpha3)
      setCountryList(Object.keys(countries))
    }
  }, [countryData])

  function selectCountry(countryId: string) {
    let _country = 'global'
    let fly
    if (!!countryId && countryId !== filters.country) {
      fly = countryId
      _country = countryId
    }
    flyTo(fly)
    setFilters((fil) => ({ ...fil, country: _country }))
  }

  function flyTo(countryId?: string) {
    let coords = [0, 10] as [number, number]
    if (countryId && countryId !== 'global') {
      const selectedCountry = data?.countryPolygons?.features.find(
        (feature: any) => feature.id === countryId
      )
      coords = selectedCountry.properties.coords
    }

    mapRef.current?.flyTo({
      center: coords,
      essential: true,
      zoom: countryId && countryId !== 'global' ? 2 : 0,
      speed: 0.5,
      curve: 1,
    })
  }

  if (dataError) {
    console.error({ dataError })
    return <FullPageError />
  }

  return (
    <Flex flexDir={'column'} gap='1rem'>
      <Flex gap='1rem' alignItems={'center'}>
        <AboutDataPopover />
        <MainFilterSection
          countries={countriesList || []}
          handleFilters={setFilters}
          handleCountry={selectCountry}
          filters={filters}
        />
        {/* )} */}
      </Flex>
      <Flex gap={2} h='75vh'>
        <LeftSection filters={filters} />
        {!features || isLoading ? (
          <Card w='100%'>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              h='100%'
              w='100%'
            >
              <LoadingAnimation />
            </Flex>
          </Card>
        ) : (
          <MapSection
            mapRef={mapRef}
            features={features}
            selectedLayer={selectedLayer}
            handleLegendClick={handleLegendClick}
            selectCountry={selectCountry}
            width='100%'
          />
        )}
        <RightSection
          filters={filters}
          dataType={filters.dataType}
          selectCountry={selectCountry}
        />
      </Flex>
      <UpsellBanner />
    </Flex>
  )
}

export default EpidemiologyDashboard
