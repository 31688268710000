import { ArrowUp } from '@carbon/icons-react'
import { Button, Flex, Icon, Input } from '@chakra-ui/react'
import { type KeyboardEvent, useEffect, useRef, useState } from 'react'
import useTracking from 'tracking/useTracking'

const InputSection = ({
  setQuery,
  search,
  query,
  placeholder = '',
  isEmptyScreen,
  isReport,
}: {
  setQuery: React.Dispatch<React.SetStateAction<string>>
  search: () => void
  query: string
  placeholder: string
  isEmptyScreen?: boolean
  isReport?: boolean
}) => {
  const [tracking] = useTracking()
  const [height] = useState('50px')
  const inputRef = useRef(null) as any

  // Function to adjust textarea height
  const adjustHeight = () => {
    if (inputRef.current) {
      // Reset height to auto to accurately measure the content
      inputRef.current.style.height = '0px'

      // Get the scroll height (the height of all content)
      const scrollHeight = inputRef.current.scrollHeight

      const newHeight = Math.min(scrollHeight, isReport ? 200 : 100)
      inputRef.current.style.height = `${newHeight}px`
    }
  }

  // Update height whenever the query changes
  useEffect(() => {
    adjustHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      search()
    }
  }

  return (
    <Flex
      flexDir={'column'}
      backgroundColor='gray5'
      border='1px solid'
      borderColor='gray3'
      roundedBottom={isEmptyScreen ? 'xl' : 'none'}
    >
      <Input
        borderRadius={0}
        ref={inputRef}
        height={height}
        minHeight='50px'
        maxHeight={isReport ? '200px' : '100px'}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        size='md'
        border='none'
        placeholder={placeholder}
        color='black'
        onFocus={() => tracking.clickedOnAIPromptInput({})}
        as='textarea'
        focusBorderColor='blue.400'
        fontWeight={400}
        fontSize='14px'
        p='8px'
        pt={isReport ? '32px' : '8px'}
        pr={'32px'}
        _focus={{ borderColor: 'gray3', boxShadow: 'none' }}
        _placeholder={{ color: 'gray2' }}
        resize='none'
      />
      <Button
        ml='auto'
        bg={'white'}
        border='1px solid'
        height={'26px'}
        w='fit-content'
        px='4px'
        borderColor={'gray.300'}
        _hover={{ bg: 'gray.200', borderColor: 'gray.500' }}
        rounded='lg'
        mr='6px'
        mb='4px'
        color='black'
        onClick={search}
        fontSize='12px'
      >
        <Icon as={ArrowUp} width='18px' height='18px' />
      </Button>
    </Flex>
  )
}

export default InputSection
