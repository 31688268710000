import SpikeMap from 'routes/apps/biorisk/SpikeMap'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { BioriskSlugs } from './BioriskMenu'
import BioriskMpoxTabs from 'modules/BioriskMpox'

const BioriskSpikeMap: ISingleCustomPage<BioriskSlugs> = {
  path: ['global-disease-resurgence'],
  component: () => <SpikeMap />,
}

const Mpox: ISingleCustomPage<BioriskSlugs> = {
  path: [''],
  component: BioriskMpoxTabs,
}

export const BioriskCustomPages: Record<
  string,
  ISingleCustomPage<BioriskSlugs>
> = {
  BioriskSpikeMap,
  Mpox,
}
