import _ from 'lodash'
import moment from 'moment'
import { GraphColors } from '../common/GraphColors'

export function formatData(
  { title, dates, lines }: any,
  data: any,
  baselineData: any
) {
  const chartData = dates.map((date: string, index: number) => {
    return {
      date: moment(date).format('YYYY-MM-DD'),
      ...lines.reduce((acc: any, line: any) => {
        const _data = line.type === 'forecast' ? data : baselineData
        const value = _.get(_data, `${line.path}[${index}]`)
        if (!value) return acc
        return {
          ...acc,
          [line.key]: _.get(_data, `${line.path}[${index}]`),
        }
      }, {}),
    }
  })

  const { vacStartDate, vacEndDate } = data.paramDict

  const refLines = [
    {
      xValue: vacStartDate,
      title: 'Vaccine Start',
      color: 'green',
    },
    {
      xValue: vacEndDate,
      title: 'Vaccine End',
      color: 'black',
    },
    {
      xValue: '2025-01-05',
      title: 'Forecast Start',
      color: 'blue',
    },
  ]

  return { title, data: chartData, refLines }
}

export function getGraphsData(data: any) {
  const { modelData } = data.chosen

  const getData = (
    dataType: 'cases' | 'deaths',
    forecastType: 'cumInfs' | 'cumDeas'
  ) => {
    return [
      {
        path: `modelData.${forecastType}.total`,
        key: 'total',
        title: 'Total Baseline',
        type: 'data',
        color: GraphColors[0],
      },
      {
        path: `modelData.${forecastType}.highRisk`,
        key: 'highRisk',
        title: 'High-Risk Baseline',
        type: 'data',
        color: GraphColors[1],
      },
      {
        path: `modelData.${forecastType}.young`,
        key: 'young',
        title: 'Young Baseline',
        type: 'data',
        color: GraphColors[2],
      },
      {
        path: `modelData.${forecastType}.total`,
        key: 'totalForecast',
        title: 'Total Forecast',
        type: 'forecast',
        color: GraphColors[0],
      },
      {
        path: `modelData.${forecastType}.highRisk`,
        key: 'highRiskForecast',
        title: 'High-Risk Forecast',
        type: 'forecast',
        color: GraphColors[1],
      },
      {
        path: `modelData.${forecastType}.young`,
        key: 'youngForecast',
        title: 'Young Forecast',
        type: 'forecast',
        color: GraphColors[2],
      },
    ]
  }

  const keys = getData('cases', 'cumInfs').reduce(
    (acc, item) => ({
      ...acc,
      [item.key]: { title: item.title, type: item.type, color: item.color },
    }),
    {}
  )

  const graphData = [
    {
      title: 'Cumulative Cases',
      dates: modelData.dates,
      lines: getData('cases', 'cumInfs'),
    },
    {
      title: 'Cumulative Deaths',
      dates: modelData.dates,
      lines: getData('deaths', 'cumDeas'),
    },
  ]
  return { graphData, keys }
}
