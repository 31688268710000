import { Box, TabPanel, TabPanelProps, TabPanels } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListMedia,
  DetailDrawerListPaper,
  DetailDrawerListPressRelease,
  DetailDrawerListTrial,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import TableView from 'routes/apps/TableView'
import { useAppRoute } from 'routes/utils'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { apps } from 'config/apps'
import {
  InfluenzaTrialsModel,
  InfluenzaCandidatePapersModel,
  InfluenzaConferencePresentionsModel,
} from 'config/apps/Influenza/Science360/VaccinesTherapeutics/InfluenzaModels'
import { MediaModel, PressReleasesModel } from 'config/common/baseModel'
import { RespiratoryCandidatesData } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<RespiratoryCandidatesData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<RespiratoryCandidatesData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props

  const app = useAppRoute()

  const exportName = (modelTarget: IModel<any>) =>
    `${apps[app].name}_${model.name}_${candidate && candidate[title]}_${
      modelTarget.name
    }`

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={[
          'Summary',
          'Media',
          'Press Releases',
          'Papers',
          'Trials',
          // 'Results',
          'Publications',
        ]}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={candidate} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                type='Media'
                model={MediaModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={candidate.linkMedia}
                itemComponent={DetailDrawerListMedia}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                type='PressReleases'
                sortOrder={(x) => moment(x.datePublished)}
                model={PressReleasesModel}
                ids={candidate.linkPressReleases}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={3}
                viewData={viewData}
                type='Papers'
                model={InfluenzaCandidatePapersModel}
                sortOrder={(x) => moment(x.publicationDate)}
                ids={candidate.linkPublications}
                itemComponent={DetailDrawerListPaper}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={4}
                viewData={viewData}
                sortOrder={(x) => moment(x.fStudyCompletion)}
                model={InfluenzaTrialsModel}
                ids={candidate.linkClinicalTrials}
                itemComponent={DetailDrawerListTrial}
              />
            </StyledTabPanel>
            {/* <StyledTabPanel>
              <TableView
                id={5}
                viewData={viewData}
                model={RespiratoryToolMetaVaccineEfficacyModel}
                ids={candidate.linkTrialResults}
                exportName={exportName(RespiratoryToolMetaVaccineEfficacyModel)}
              />
            </StyledTabPanel> */}
            <StyledTabPanel>
              <TableView
                id={5}
                viewData={viewData}
                model={InfluenzaConferencePresentionsModel}
                ids={candidate.conferencePresentations}
                exportName={exportName(InfluenzaConferencePresentionsModel)}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
