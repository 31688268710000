import { Button, Flex, Text } from '@chakra-ui/react'
import { Select } from 'components'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import moment from 'moment'
import { useContext, useState } from 'react'
import { DataContext } from '../..'
import HorizontalBarChart from '../Charts/HorizontalBarChart'
import { formatData } from '../helpers/formatData'
import CardContainer from 'components/Dashboards/IDATrialsMapDashboard/Cards/CardContainer'
import { Download } from '@carbon/icons-react'
import { exportCardsData } from '../helpers/exportData'
import { handleCountrySelectOnCard } from '../helpers'

export function getQueryData(filters: FiltersT, endpoint: string) {
  const { start, end } = filters.dateRange
  const dateStart = moment(start).format('YYYY-MM-DD')
  const dateEnd = moment(end).format('YYYY-MM-DD')
  const params = `date_start=${dateStart}&date_end=${dateEnd}`
  // TODO: subnational removal
  // (filters.country !== 'global' ? `&country_alpha_3=${filters.country}` : '')
  const path = `${endpoint}${params}`
  return path
}

const stackByOptions = {
  procurementMechanism: {
    key: 'procurementMechanism',
    title: 'Procurement Mechanism',
  },
  designationA: {
    key: 'designationA',
    title: 'Vaccine vs Treatment',
  },
  candidates: { key: 'candidates', title: 'Candidates' },
  suppliers: { key: 'suppliers', title: 'Developers' },
}

const VaccinationProcurement = ({ filters }: { filters: FiltersT }) => {
  const [expanded, setExpanded] = useState(false)
  const [stackBy, setStackBy] = useState(stackByOptions.candidates)
  const { procurement, handleCountrySelection } = useContext(DataContext)

  function handleClick(e: any) {
    handleCountrySelectOnCard(handleCountrySelection, e)
  }

  const { chartData, uniqueKeys } = formatData(
    procurement.data,
    'recipients',
    stackBy.key,
    filters.nCountries,
    filters.country
  )

  function handleSelect({ label, value }: { label: string; value: string }) {
    setStackBy({ title: label, key: value })
  }

  return (
    <CardContainer
      minWidth='350px'
      expanded={expanded}
      setExpanded={setExpanded}
      width='100%'
    >
      <Flex w='90%' gap={2} justifyContent={'space-between'}>
        <Text fontWeight={'semibold'} fontSize='sm'>
          Procurement
        </Text>
        <Flex mt={-1} alignItems={'center'} gap={2}>
          <Select
            value={{
              value: stackBy.key,
              label: stackBy.title,
            }}
            options={Object.values(stackByOptions).map(({ key, title }) => ({
              label: title,
              value: key,
            }))}
            onChange={handleSelect}
            isMulti={false}
            isSearchable={false}
            backgroundColor='#fff'
            color='#000'
            fontSize={'10px'}
            height='26px'
          />
          <Button
            onClick={() => exportCardsData(procurement.data, 'procurement')}
            variant={'ghost'}
            w={'24px'}
            h={'24px'}
            p={0}
          >
            <Download size={16} />
          </Button>
        </Flex>
      </Flex>
      <HorizontalBarChart
        isStacked={true}
        expanded={expanded}
        chartData={chartData}
        handleClick={handleClick}
        error={procurement?.error}
        isLoading={procurement?.isLoading}
        barKeys={uniqueKeys}
      />
    </CardContainer>
  )
}

export default VaccinationProcurement
