import { Box, Flex, Heading, Spinner } from '@chakra-ui/react'
import { NAV_HEIGHT } from 'constants/misc'
import { Link, useNavigate } from 'react-router-dom'

import Attribute from 'modules/Attributes/Attribute'

import { Card, InlineError } from 'components'

import { ResponseData } from 'api/types'
import { useCollectionDataSingleRaw } from 'api/useCollectionDataSingle'

import { EntityComponentPropsTypes } from 'interfaces/entity.interface'

import { relationGetDisplayValue } from 'utils/relational'
import resolvePath from 'utils/resolvePath'
import unCamelize from 'utils/unCamelize'

export default ({
  app,
  page,
  model,
  entityId,
  viewData,
}: EntityComponentPropsTypes) => {
  const navigate = useNavigate()

  const { isLoading, data, error } = useCollectionDataSingleRaw(
    app,
    model.endpoint,
    entityId,
    { airtableBase: viewData?.airtableBase }
  )
  return (
    <Box
      overflow='auto'
      bg='gray.100'
      flex={1}
      py={20}
      height={`calc(100vh - ${NAV_HEIGHT})`}
    >
      <Box maxW='3xl' width='100%' mx='auto'>
        <Flex mb={4} justifyContent='space-between'>
          <Box
            display='flex'
            fontSize='sm'
            fontWeight={500}
            color='gray.700'
            _hover={{
              textDecoration: 'underline',
            }}
            cursor='pointer'
            onClick={() => navigate(-1)}
          >
            ← Back
          </Box>

          <Link to={resolvePath([app, page.path])}>
            <Box
              display='flex'
              fontSize='sm'
              fontWeight={500}
              color='gray.700'
              _hover={{
                textDecoration: 'underline',
              }}
            >
              View All {model.name}
            </Box>
          </Link>
        </Flex>
        <Heading fontSize='2xl' fontWeight={600} lineHeight='1.6' mb={6}>
          {data &&
            relationGetDisplayValue(
              data[model.displayKey ?? ''] as ResponseData
            )}
        </Heading>
      </Box>

      <Box maxW='3xl' width='100%' mx='auto'>
        <Card>
          <Box px={6} py={2}>
            {isLoading && <Spinner />}
            {error && <InlineError />}

            {data &&
              (page?.entity?.renderDetail
                ? page?.entity?.renderDetail(data)
                : model.schema.columns.map((fieldModel) => {
                    const { key } = fieldModel

                    return (
                      <Attribute
                        key={unCamelize(key)}
                        rowId={data.id}
                        collection={model.endpoint}
                        fieldModel={fieldModel}
                        value={data[key]}
                      />
                    )
                  }))}
          </Box>
        </Card>
      </Box>
    </Box>
  )
}
