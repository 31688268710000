import { Box, Flex, Text } from '@chakra-ui/react'
import InputSection from './InputSection'

const BottomSection = ({
  setQuery,
  search,
  query,
  handleOpenWarningMessage,
  reportAI,
  isEmptyScreen,
}: {
  setQuery: React.Dispatch<React.SetStateAction<string>>
  search: (forceQuery?: string, _id?: string) => Promise<void>
  handleOpenWarningMessage: () => void
  query: string
  containerWidth?: string
  inputStyle?: React.CSSProperties
  reportAI?: boolean
  isEmptyScreen?: boolean
}) => {
  return (
    <Box
      w={isEmptyScreen ? '90%' : 'full'}
      margin={isEmptyScreen ? 'auto auto 70% auto' : 'auto 0 0 0'}
    >
      <Flex
        bg='#e9e7ef'
        onClick={handleOpenWarningMessage}
        cursor='pointer'
        justify={'space-between'}
        roundedTop={isEmptyScreen || !reportAI ? 'xl' : 'none'}
      >
        <Text fontSize={'xs'} px='1rem' py='4px' color={'#434032'}>
          Only Questions, answers, and feedback are logged.
        </Text>
        <Text
          fontSize={'xs'}
          px='1rem'
          py='4px'
          color={'#434032'}
          fontWeight='semibold'
          textDecoration={'underline'}
          whiteSpace='nowrap'
        >
          Read more.
        </Text>
      </Flex>
      <InputSection
        setQuery={setQuery}
        search={search}
        query={query}
        placeholder={
          reportAI ? 'Ask a question to the report' : `Search on Airfinity Data`
        }
        isEmptyScreen={isEmptyScreen}
        isReport={!!reportAI}
      />
      {(!reportAI || !isEmptyScreen) && (
        <Text
          fontSize={'11px'}
          textAlign='center'
          p='4px'
          bg='#f1f1f3'
          roundedBottom={!reportAI ? 'xl' : 'none'}
        >
          Airfinity’s AI can make mistakes. Consider checking the reports
          directly or verifying with our analysts.
        </Text>
      )}
    </Box>
  )
}

export default BottomSection
