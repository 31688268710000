import { Button, Flex, Input, Text, Tooltip } from '@chakra-ui/react'
import { Select } from 'components'
import { useEffect, useState } from 'react'
import { defaultFilters, FiltersT } from '.'
import SingleDateFilter from './DatePicker'
import { Information } from '@carbon/icons-react'

export const CountryOptions = [
  { value: 'UGA', label: 'Uganda' },
  { value: 'BDI', label: 'Burundi' },
  { value: 'COD', label: 'DRC (Congo)' },
]

const StrategyOptions = [
  { value: 'single', label: 'Single' },
  { value: 'double', label: 'Double' },
]
const TargetGroupOptions = [
  { value: 'general', label: 'General Population' },
  { value: 'high_risk', label: 'High Risk' },
  { value: 'young', label: 'Under 18’s ' },
]

const FilterSection = ({
  filters,
  setFilters,
}: {
  filters: FiltersT
  setFilters: React.Dispatch<React.SetStateAction<FiltersT>>
}) => {
  const [tempFilters, setTempFilters] = useState(filters)

  function handleSelectCountry(newCountry: { value: 'COD' | 'BDI' | 'UGA' }) {
    const newFilters = defaultFilters[newCountry.value]
    setTempFilters(newFilters)
  }

  function handleStrategy(strategy: { value: string }) {
    setTempFilters((x) => ({ ...x, doseStrategy: strategy.value }))
  }

  function handleTargetGroup(targetGroup: { value: string }[]) {
    setTempFilters((x) => ({
      ...x,
      targetGroup: targetGroup.map((item) => item.value),
    }))
  }

  function handleEfficacy(newValue: number) {
    setTempFilters((x) => ({ ...x, efficacy: newValue }))
  }

  function handleNVaccinations(newValue: number) {
    setTempFilters((x) => ({ ...x, nDoses: newValue }))
  }

  function handleVaccineDuration(newValue: number) {
    setTempFilters((x) => ({ ...x, vaccineDuration: newValue }))
  }
  function handleDateChange(date: string) {
    setTempFilters((x) => ({ ...x, vaccineStart: date }))
  }

  function handleSimulate() {
    setFilters(tempFilters)
  }

  useEffect(() => {
    setTempFilters(filters)
  }, [filters])

  return (
    <Flex
      mb='1rem'
      border='1px solid'
      borderColor='gray3'
      py='0.5rem'
      bg='white'
      rounded='lg'
      flexDir={'column'}
      alignItems={'center'}
      gap='1rem'
    >
      <Flex alignItems={'center'} gap={4} flexWrap={'wrap'} px='8px'>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            Country:
          </Text>
          <Select
            value={CountryOptions.find((x) => x.value === tempFilters.country)}
            isMulti={false}
            onChange={(x: any) => handleSelectCountry(x)}
            options={CountryOptions.sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            backgroundColor='white'
          />
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Text fontSize={'sm'} fontWeight={'medium'} whiteSpace={'nowrap'}>
            Nº of Doses Deployed:
          </Text>
          <Input
            px={3}
            fontSize={'sm'}
            // w='75px'
            value={tempFilters.nDoses}
            backgroundColor='white'
            height='32px'
            rounded='4px'
            _placeholder={{
              color: 'black',
            }}
            borderColor='gray3'
            onChange={(e) => handleNVaccinations(+e.target.value)}
          />
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Flex alignItems={'center'}>
            <Text fontSize={'sm'} fontWeight={'medium'} whiteSpace={'nowrap'}>
              Roll-out Duration (weeks)
            </Text>
            <Tooltip
              label={`The time it takes to roll out the vaccine response, in weeks.`}
            >
              <Information />
            </Tooltip>
          </Flex>
          <Input
            px={3}
            fontSize={'sm'}
            // w='100px'
            value={tempFilters.vaccineDuration}
            backgroundColor='white'
            height='32px'
            rounded='4px'
            _placeholder={{
              color: 'black',
            }}
            borderColor='gray3'
            onChange={(e) => handleVaccineDuration(+e.target.value)}
          />
        </Flex>
        <Flex ml={0} flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Text whiteSpace={'nowrap'} fontSize={'sm'} fontWeight={'medium'}>
            Vaccine Start Date:
          </Text>
          <SingleDateFilter
            date={tempFilters.vaccineStart}
            onDateChange={handleDateChange}
            minDate={'2023-01-01'}
          />
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Text whiteSpace={'nowrap'} fontSize={'sm'} fontWeight={'medium'}>
            Vaccine effectiveness (%)
          </Text>
          <Input
            type='number'
            px={3}
            // w='125px'
            fontSize={'sm'}
            value={tempFilters.efficacy * 100}
            backgroundColor='white'
            height='32px'
            rounded='4px'
            _placeholder={{
              color: 'black',
            }}
            borderColor='gray3'
            onChange={(e) => {
              if (+e.target.value > 100) return
              handleEfficacy(+e.target.value / 100)
            }}
          />
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Text whiteSpace={'nowrap'} fontSize={'sm'} fontWeight={'medium'}>
            Dose Strategy:
          </Text>
          <Select
            value={StrategyOptions.find(
              (x) => x.value === tempFilters.doseStrategy
            )}
            isMulti={false}
            isSearchable={false}
            onChange={handleStrategy}
            options={StrategyOptions}
            backgroundColor='white'
          />
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} fontSize='md'>
          <Flex alignItems={'center'}>
            <Text whiteSpace={'nowrap'} fontSize={'sm'} fontWeight={'medium'}>
              Target Group:
            </Text>
            <Tooltip
              label={`High-risk population includes MSM, healthcare workers, sex workers and immunocompromised`}
            >
              <Information />
            </Tooltip>
          </Flex>
          <Select
            value={TargetGroupOptions.filter((x) =>
              tempFilters.targetGroup.includes(x.value)
            )}
            isMulti={true}
            isSearchable={false}
            onChange={handleTargetGroup}
            options={TargetGroupOptions}
            backgroundColor='white'
          />
        </Flex>
      </Flex>
      <Button
        variant={'yellow'}
        size='lg'
        fontSize={'lg'}
        w='250px'
        onClick={handleSimulate}
      >
        Simulate
      </Button>
    </Flex>
  )
}

export default FilterSection
