import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import useAxios from 'api/useAxios'

export default function useFeedback(
  extraProps?: UseMutationOptions<
    AxiosResponse<any>,
    AxiosError<any>,
    { context: string; feedback: string; feedback_type: string }
  >
) {
  const axios = useAxios()

  return useMutation<
    AxiosResponse<any>,
    AxiosError<any>,
    { context: string; feedback: string; feedback_type: string }
    // eslint-disable-next-line camelcase
  >(({ context, feedback, feedback_type }) => {
    return sendFeedback(axios, context, feedback, feedback_type)
  }, extraProps)
}

export const sendFeedback = async (
  axios: AxiosInstance,
  context: string,
  feedback: string,
  // eslint-disable-next-line camelcase
  feedback_type: string
) => {
  return await axios
    // eslint-disable-next-line camelcase
    .post(`/user-llm-feedback/`, { feedback, context, feedback_type })
    .then((x) => x.data)
}
