import { IResponseBase } from 'api/types'

// Specs: https://github.com/CartoDB/basemap-styles?tab=readme-ov-file
const MAP_STYLE_BASE = 'https://basemaps.cartocdn.com/gl/'
const MAY_STYLE_ENDING = '-gl-style/style.json'

function getMapURL(id: string) {
  return MAP_STYLE_BASE + id + MAY_STYLE_ENDING
}

export const MAP_STYLES = {
  dark: getMapURL('dark-matter-nolabels'),
  'dark-labels': getMapURL('dark-matter'),
  'light-labels': getMapURL('voyager'),
  light: getMapURL('voyager-nolabels'),
  gray: getMapURL('positron-nolabels'),
  'gray-labels': getMapURL('positron'),
}

export const GEO_POLYGONS = {
  world:
    'https://raw.githubusercontent.com/johan/world.geo.json/master/countries.geo.json',
} as { [key: string]: string }

interface Feature {
  type: 'Feature'
  properties: {
    name: string
    [key: string]: any
  }
  geometry: {
    type: string
    coordinates: number[][]
  }
}

export interface FeatureCollection {
  type: 'FeatureCollection'
  features: Feature[]
}

export const countryNameMapping: { [key: string]: string } = {
  // LEFT = MAPLIBRE NAMES, RIGHT = AIRFINITY NAMES
  'Democratic Republic of the Congo': 'Congo, The Democratic Republic of the',
  Russia: 'Russian Federation',
  Bolivia: 'Bolivia, Plurinational State of',
  'United Republic of Tanzania': 'Tanzania, United Republic of',
  'Dominican Republic': 'Republica dominicana',
  'Guinea Bissau': 'Guinea-Bissau',
  'South Korea': 'Korea, Republic of',
  Iran: 'Iran, Islamic Republic of',
  Syria: 'Syrian Arab Republic',
  Moldova: 'Moldova, Republic of',
  Venezuela: 'Venezuela, Bolivarian Republic of',
  'Ivory Coast': "Côte d'Ivoire",
  'Czech Republic': 'Czechia',
  Vietnam: 'Viet Nam',
  'Republic of Serbia': 'Serbia',
  'The Bahamas': 'Bahamas',
}

export function updatePercentiles(
  featureCollection: any,
  data: IResponseBase[],
  countFunction: (item: any) => number,
  itemToCount: string
) {
  if (!featureCollection || !featureCollection.features) {
    throw new Error('Invalid feature collection')
  }

  const areaCounts = data.reduce((counts, item) => {
    const value = item[itemToCount] as string | string[]
    if (value && Array.isArray(value)) {
      value?.forEach((area: string) => {
        counts.set(
          area as string,
          (counts.get(area as string) || 0) + +countFunction(item)
        )
      })
    } else if (typeof value === 'string') {
      counts.set(value, (counts.get(value) || 0) + 1)
    }
    return counts
  }, new Map<string, number>())

  const updatedFeatures = featureCollection.features.map((f: Feature) => {
    const mapName = f.properties.name
    const dataName = countryNameMapping[mapName] || mapName

    const properties = {
      ...f,
      name: mapName,
      count: areaCounts.get(dataName) || 0,
    }
    return { ...f, properties }
  })

  const updated: FeatureCollection = {
    type: 'FeatureCollection',
    features: updatedFeatures,
  }
  return updated
}

export const getHighestNum = (data: FeatureCollection) => {
  if (data?.features?.length > 0) {
    const counts = data.features
      .map((feature) => feature.properties.count)
      .filter((count): count is number => typeof count === 'number')

    return counts.length > 0 ? Math.max(...counts) : 0
  }
  return 0
}
