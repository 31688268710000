import { Box, CloseButton, Heading, Spinner, Text } from '@chakra-ui/react'
import * as cheerio from 'cheerio'
import { useEffect, useRef, useState } from 'react'

import { Report } from 'api/types'
import { Checkmark, Download } from '@carbon/icons-react'
import { formatUtc } from 'utils/formatDate'
import { Button, Flex, Tooltip } from '@chakra-ui/react'
import useAxios from 'api/useAxios'

interface ModalPropTypes {
  report: Report
  handleClose: () => void
  type?: string
}

const Modal = ({ handleClose, report }: ModalPropTypes) => {
  const [downloadStatus, setDownloadStatus] = useState<string>('')
  const [htmlContent, setHtmlContent] = useState<string>('')
  const renderDivRef = useRef<HTMLDivElement>(null)
  const hrefToRemove = '/%%email_id%%/h/%%internal.tracker_url_hash%%'
  const axios = useAxios()
  const title = report.title
  const uri = report.report
  const date = formatUtc(new Date(report.date), 'dd MMMM yyyy')

  const handlePdfDownload = async () => {
    try {
      setDownloadStatus('loading')
      const pdfUrl = `${uri}/download_pdf/`

      // Fetch PDF as blob
      const response = await axios.get(pdfUrl, {
        responseType: 'blob',
      })

      const contentDisposition = response.headers['content-disposition']
      let fileName = `${title}.pdf`
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?(.+?)"?$/)
        if (match && match[1]) {
          fileName = match[1]
        }
      }

      // Create a blob from response data
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })

      // Programmatically download
      const url = window.URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      link.remove()

      window.URL.revokeObjectURL(url)
      setDownloadStatus('success')
      setTimeout(() => setDownloadStatus(''), 3000)
    } catch (error) {
      console.error('Error fetching PDF:', error)
      setDownloadStatus('error')
      setTimeout(() => setDownloadStatus(''), 3000)
    }
  }

  useEffect(() => {
    // scroll to top whenever new report is opened
    if (renderDivRef.current) {
      renderDivRef.current.scrollTop = 0
    }

    try {
      // load html content
      const $ = cheerio.load(report.content)

      // remove last 7 table containers
      $('table.contentbuilderBaseColumnRow').slice(-5).remove()

      // loop through each <a> tag and perform a simple string replace
      $('a').each((_, element) => {
        const href = $(element).attr('href')
        if (href && href.includes(hrefToRemove)) {
          const newHref = href.replace(hrefToRemove, '')
          $(element).attr('href', newHref)
        }
      })

      // set new modifed html in state
      const modifiedHtml = $.html()
      setHtmlContent(modifiedHtml)
    } catch (e) {
      // if error occurred with parsing fallback to original report content
      console.error('An error occured when parsing HTML content: ' + e)
      setHtmlContent(report.content)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.date, report.title])

  return (
    <>
      <Box w='full'>
        <Box bg='gray4' px={8} py={5}>
          <CloseButton
            onClick={handleClose}
            position='absolute'
            right={5}
            top={3}
            _hover={{ bg: 'none' }}
          />
          <Box maxW='2xl' mx='auto'>
            <Text variant='body3-bold' mb={1.5}>
              Daily Briefing
            </Text>
            <Flex justifyContent='space-between' alignItems='center' w='full'>
              <Heading variant='h3' maxW={'70%'}>
                {title}
              </Heading>
              {uri && (
                <Box>
                  <Tooltip label='Download as PDF'>
                    <Button
                      size={'xs'}
                      border={'1px'}
                      borderColor={'gray.200'}
                      variant={'solid'}
                      borderRadius={'md'}
                      px={2}
                      py={1}
                      onClick={handlePdfDownload}
                    >
                      {downloadStatus === 'loading' ? (
                        <Spinner size={'sm'} />
                      ) : downloadStatus === 'error' ? (
                        <Text color={'error'}>Something went wrong</Text>
                      ) : downloadStatus === 'success' ? (
                        <Checkmark size={16} color='#53B869' />
                      ) : (
                        <Download size={16} />
                      )}
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </Flex>
            <Text variant='body' mt={2}>
              {date}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box ref={renderDivRef} marginTop='1rem' overflow='scroll' px={8} py={6}>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Box>
    </>
  )
}

export default Modal
