import { Download, Locked } from '@carbon/icons-react'
import {
  Box,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
} from '@chakra-ui/react'
import { memo, useContext } from 'react'
import { ColumnInstance, TableState } from 'react-table'

import UpSellSection from 'routes/common/FullReportPage/components/UpSell'
import { useAppRoute, useAppParams } from 'routes/utils'

import { AuthContext } from 'contexts'
import { useExportModal } from 'contexts/ExportModal'

import useTracking from 'tracking/useTracking'

import { apps } from 'config/apps'

import useIsMobile from 'utils/useIsMobile'

import Export from './export'

type PropTypes = {
  state: TableState
  columns: ColumnInstance<any>[]
  exportName: string
  label?: string
  buttonProps?: ButtonProps
}

const TableExport = memo(
  ({
    state,
    columns,
    exportName,
    label = 'Export',
    buttonProps,
  }: PropTypes) => {
    const [isMobile] = useIsMobile()
    const { userIsBasicWithinApp } = useContext(AuthContext)
    const [tracking] = useTracking()
    const selectedApp = useAppRoute()

    const { closeModal, isModalOpen, openModal } = useExportModal()

    if (userIsBasicWithinApp(apps[selectedApp])) {
      return <FeatureLocked />
    }

    return (
      <>
        <Button
          variant='clean'
          data-cy='table-export-button'
          onClick={openModal}
          {...buttonProps}
        >
          <Box mr={1}>
            <Download size={16} />
          </Box>
          <Text fontSize='12px'>{label}</Text>
        </Button>
        <Modal
          size={isMobile ? 'sm' : 'lg'}
          isCentered
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <ModalOverlay />
          <ModalContent color='black' data-cy='table-export-modal-content'>
            <ModalHeader>Export Table</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize='14px' lineHeight='22px' mb='24px'>
                Select a file format for your download.
              </Text>
              <Export
                onClose={closeModal}
                state={state}
                columns={columns}
                exportName={exportName}
                exportDataCallback={() => {
                  tracking.downloadCsv({ file: exportName })
                  closeModal()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }
)

const FeatureLocked = () => {
  const { app, pageSlug, subPageSlug } = useAppParams()

  return (
    <Popover placement='top' closeOnBlur>
      <PopoverTrigger>
        <Button color='gray2' variant='clean'>
          <Box mr={1}>
            <Locked size={16} />
          </Box>
          Export
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <Box bg='white' fontSize='sm' fontWeight={400} zIndex='999'>
            <Box bg='gray.100' px={4} py={2} fontWeight={500}>
              This feature is locked
            </Box>
            <Box px={4} py={3} fontSize='sm'>
              Data exports are only available to higher subscription tiers.
            </Box>
            <Box px={4} pb={3}>
              <UpSellSection
                details={`User wants to export data from ${window.location.href}`}
                title={`${app}-${pageSlug}-${subPageSlug}`}
                table
              />
            </Box>
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default TableExport
