import { CloseOutline } from '@carbon/icons-react'
import { Box, Text } from '@chakra-ui/react'
import { capitalize } from 'lodash'

import { useFilterModal } from 'contexts/FilterModal'

import { getAllowedFilterType } from 'components/Table/AdvancedSearch/filters'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { IModel } from 'interfaces/model.interface'

interface ChartFilterPillProps {
  model: IModel<any, any>
  advancedFilter: SingleAdvancedFilter<any, any>
  removeFilters: (
    filter: SingleAdvancedFilter<any, any>,
    source?: string
  ) => void
}

const ChartFilterPill = ({
  advancedFilter,
  model,
  removeFilters,
}: ChartFilterPillProps) => {
  const {
    disclosure: { onOpen },
  } = useFilterModal()

  const columnOptions = model.schema.columns.map((column) => ({
    label: column.label || capitalize(column.key),
    value: column.key,
  }))

  const currentColumnLabel = columnOptions.find(
    (x) => x.value === advancedFilter.column
  )?.label
  const allowedFiltersBasedOnColumn = getAllowedFilterType(
    model.schema.columns.find((column) => column.key === advancedFilter.column)
      ?.type
  )

  const filtersOptions = allowedFiltersBasedOnColumn.map((allowedFilter) => ({
    label: allowedFilter.label || capitalize(allowedFilter.key),
    value: allowedFilter.key,
  }))

  const currentFilterLabel = filtersOptions.find(
    (x) => x.value === advancedFilter.type
  )?.label

  const filterValues = Array.isArray(advancedFilter.filterValue)
    ? advancedFilter.filterValue
        .slice(0, 2)
        .map((filter: { label: string; value: string }) =>
          filter.value.length > 20
            ? `${filter.value.slice(0, 20)}...`
            : filter.value
        )
        .join(', ') + (advancedFilter.filterValue.length > 2 ? '...' : '')
    : advancedFilter.filterValue instanceof Date
      ? new Date(advancedFilter.filterValue).toISOString().split('T')[0]
      : String(advancedFilter.filterValue)

  return (
    <Box
      ml={4}
      border='1px solid'
      borderColor='gray3'
      background='white '
      borderRadius='8px'
      boxShadow='sm'
      py={1}
      px={3}
      fontSize='xs'
      display={'flex'}
      cursor={'pointer'}
    >
      <Box onClick={onOpen}>
        <Text isTruncated display={'flex'} fontWeight='semibold'>
          <Text>{currentColumnLabel}</Text>
          <Text
            width='max-content'
            style={{
              marginLeft: '4px',
              marginRight: '4px',
              fontWeight: 'normal',
            }}
          >
            {currentFilterLabel}
          </Text>
          <Text>{filterValues}</Text>
        </Text>
        {/* <Text>{filterValues}</Text> */}
      </Box>
      <Box
        mt={'2.5px'}
        ml={2}
        cursor={'pointer'}
        onClick={() => {
          removeFilters(advancedFilter)
        }}
      >
        <CloseOutline />
      </Box>
    </Box>
  )
}

export default ChartFilterPill
