import { Box, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

export const NOT_REPORTED = ['Not Reported', 'not specified', '-']

export const Row = ({
  title,
  value,
  longFormat,
}: {
  title: string
  value: string | any
  longFormat?: boolean
}) => {
  const isEmpty = value === 'null' || NOT_REPORTED.includes(value) || !value
  return (
    <Box
      display={'flex'}
      flexDir={longFormat ? 'column' : 'row'}
      my={longFormat ? 2 : 0}
      gap={!longFormat ? '2' : 0}
    >
      <Text color='gray.800'>{title}:</Text>
      <Box
        fontSize={'sm'}
        maxH={'100px'}
        flexWrap={'wrap'}
        fontWeight={isEmpty ? '' : 'bold'}
        textTransform={'capitalize'}
      >
        {isEmpty ? 'Not specified' : value}
      </Box>
    </Box>
  )
}

export function getDates(dates: (dayjs.Dayjs | null)[] | (dayjs.Dayjs | null)) {
  if (!Array.isArray(dates)) {
    return dayjs(dates)?.format('YYYY-MM-DD')
  }
  const _dates = dates.filter(notEmpty)
  const elements = _dates.length
  if (elements === 0) return 'Not Reported'
  const oldestDate = dayjs.min(_dates)?.format('YYYY-MM-DD')
  const newestDate = dayjs.max(_dates)?.format('YYYY-MM-DD')

  if (oldestDate === newestDate) return newestDate

  return `${oldestDate} to ${newestDate}`
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function getHasSpecie(specie: string) {
  return specie !== 'null' && !!specie
}

export const ListItem = ({
  value,
  specie,
}: {
  value: string
  specie: string
}) => {
  const hasSpecie = getHasSpecie(specie)
  return (
    <Box fontWeight={hasSpecie ? 'semibold' : ''} mr={2} key={specie}>
      {value}
    </Box>
  )
}
