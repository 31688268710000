import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Map, {
  Source,
  Layer,
  MapRef,
  MapLayerMouseEvent,
} from 'react-map-gl/maplibre'
import './styles.css'

import { IResponseBase } from 'api/types'
import { dataLayer } from './map-styles'
import {
  FeatureCollection,
  GEO_POLYGONS,
  MAP_STYLES,
  countryNameMapping,
  getHighestNum,
  updatePercentiles,
} from './utils'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import Popover from './Popover'
import HeatMapLegend from './HeatMapLegend'
import { Box, Flex, Text } from '@chakra-ui/react'
import { compactNumberWithPrefix } from 'utils/formatNumber'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

export function MapHeatmap({
  data,
  summaryTitle,
  popoverTitle,
  countFunction,
  itemToCount,
  filterKey,
  onClickCB,
  colorScale,
}: {
  data: IResponseBase[]
  summaryTitle: string
  popoverTitle: string
  countFunction: (item: any) => number
  itemToCount: string
  filterKey?: string
  onClickCB?: (e: SingleAdvancedFilter<any, any>[], value: string) => void
  colorScale?: string[]
}) {
  const [countriesPolygons, setCountriesData] =
    useState<FeatureCollection | null>(null)
  const [hoverInfo, setHoverInfo] = useState<any>(null)

  const mapRef = useRef<MapRef>(null)

  const { tableFilters, addToFilters } = useTableChartFilters()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          GEO_POLYGONS['world'] || GEO_POLYGONS.world
        )
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const json = await response.json()
        setCountriesData(json)
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          console.error('Could not load data', err)
        }
      }
    }

    fetchData()
  }, [])

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const mapLibreName = event.features?.[0]?.properties?.name
      if (!mapLibreName) return

      const airfinityName = countryNameMapping[mapLibreName] ?? mapLibreName
      const newFilters = tableFilters.filter(
        (f) => f.column !== (filterKey || 'areas')
      )
      if (newFilters.length === tableFilters.length) {
        newFilters.push({
          column: filterKey || 'areas',
          filterValue: [{ label: airfinityName, value: airfinityName }],
          type: 'includesAll',
        })
      }
      onClickCB
        ? onClickCB(newFilters, airfinityName)
        : addToFilters(newFilters)
    },
    [addToFilters, tableFilters, filterKey, onClickCB]
  )

  const onHover = useCallback((event: MapLayerMouseEvent) => {
    const hoveredFeature = event.features?.[0]

    if (!hoveredFeature) {
      setHoverInfo(null)
      return
    }

    let properties = null
    if (hoveredFeature.properties?.properties) {
      try {
        properties = JSON.parse(hoveredFeature.properties.properties)
      } catch (error) {
        console.error('Failed to parse properties JSON', error)
      }
    }

    setHoverInfo({
      feature: hoveredFeature,
      properties,
      x: event.point.x,
      y: event.point.y,
    })
  }, [])

  const countryData = useMemo(() => {
    if (!countriesPolygons) return []
    const res =
      countriesPolygons &&
      updatePercentiles(countriesPolygons, data, countFunction, itemToCount)
    return res
  }, [
    countriesPolygons,
    data,
    countFunction,
    itemToCount,
  ]) as unknown as FeatureCollection

  const highestNum = useMemo(() => getHighestNum(countryData), [countryData])

  const totalCount = useMemo(
    () =>
      data?.reduce((acc: number, item: any) => (acc += countFunction(item)), 0),
    [data, countFunction]
  )

  const map = useMemo(() => {
    return (
      <Map
        initialViewState={{
          longitude: 0,
          latitude: 0,
          zoom: 0,
        }}
        renderWorldCopies={false}
        preserveDrawingBuffer={true}
        ref={mapRef}
        id='map-heatmap'
        interactiveLayerIds={['data']}
        onMouseMove={onHover}
        onClick={onClick}
        mapStyle={MAP_STYLES['gray-labels']}
      >
        <Source type='geojson' data={countryData}>
          <Layer {...(dataLayer(highestNum, colorScale) as any)} />
        </Source>
      </Map>
    )
  }, [countryData, highestNum, onClick, onHover, colorScale])

  return (
    <Box
      position={'relative'}
      height={'full'}
      width={'full'}
      borderRadius={'8px'}
      overflow={'hidden'}
      border='1px'
      borderColor={'gray.200'}
    >
      {map}
      <Flex
        border={'1px'}
        borderColor={'gray.200'}
        h={7}
        w='max-content'
        px={2}
        position={'absolute'}
        top={2}
        right={1}
        alignItems={'center'}
        bg='gray.100'
        gap={2}
        borderRadius={'sm'}
      >
        <Text
          fontWeight={'semibold'}
          color={'gray.800'}
          lineHeight={'14px'}
          fontSize={'14px'}
        >
          {summaryTitle}
        </Text>
        <Text lineHeight={'14px'} fontSize={'16px'} fontWeight={'semibold'}>
          {compactNumberWithPrefix(totalCount, 3)}
        </Text>
      </Flex>
      <HeatMapLegend highestNum={highestNum} colorScale={colorScale} />
      {hoverInfo && <Popover hoverInfo={hoverInfo} title={popoverTitle} />}
    </Box>
  )
}

export default MapHeatmap
