import { apps } from 'config/apps'
import { BioriskPages } from 'config/apps/Biorisk/Custom/Biorisk/BioriskPages'
import { createElement, useCallback, useMemo } from 'react'
import LatestMediaPipeline from './LatestMediaPipeline'
import { Box, Flex, Text } from '@chakra-ui/react'
import { SIDEBAR_WIDTH } from 'constants/misc'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

import { getAllowedFilterType } from 'components/Table/AdvancedSearch/filters'
import { IModel } from 'interfaces/model.interface'
import { resolveModel } from 'utils/model'
import useTableData from 'api/useTableData'
import { flattenPaginatedData } from 'api/helper'
import FilterButton from 'components/Table/Toolbar/FilterButton'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'
import { Panel } from 'components'
import ChartFilterPill from 'modules/Tables/Dashboard/ChartFilterPill/ChartFilterPill'
import { useFilterModal } from 'contexts/FilterModal'
import { Information } from '@carbon/icons-react'
import WarningPopover from 'components/PageHeader/WarningPopover'
import QuickFilter from 'components/PageHeader/QuickFilter'
import UpsellBanner from '../common/UpsellBanner'
import { TablePageDataContext } from 'modules/Tables/TablePageProvider'

export type FilterType = SingleAdvancedFilter<any, any>

const dataLimitationsMessage = `Data are gathered from publicly available sources only, including media articles, company pipelines, publications, and clinical trials. Clinical trial databases examined are clinicaltrials.gov and the ICTRP Registry Network. 
\n
Data are primarily identified via automated keyword based scrapes which are then manually curated for relevancy. Once new candidates are identified, further searches on them are conducted if relevant information is not available in the primary source or if the primary source is not considered sufficiently reliable (e.g. a pop-sci article might call antibodies a vaccine). 
\n
Candidates are screened on a daily rolling basis for inactivity and are considered inactive if they have no publication activity within the last 2 years and they no longer appear on a company pipeline page. Although many inactive candidates are likely terminated, candidates are only labelled as discontinued if there has been a public announcement that a development program has been terminated. `

const FilterSection = ({ page }: { page: ISingleNavigationPage<any, any> }) => {
  const defaultView = page.views[0]

  const model: IModel<any> = useMemo(
    () => resolveModel(page.model, defaultView),
    [defaultView, page.model]
  )

  const { collectionDataQuery } = useTableData({
    page: page,
    view: defaultView,
  })

  const { tableFilters, removeFromFilters } = useTableChartFilters()

  const {
    disclosure: { onOpen },
  } = useFilterModal()

  const flattenedPaginatedData = flattenPaginatedData(
    collectionDataQuery.data?.pages
  )
  const data = flattenedPaginatedData?.results

  const tableData = model.customTableData
    ? model.customTableData(collectionDataQuery.data)
    : data

  const isLoaded = tableData.length > 0

  return (
    <>
      <Panel
        minH='44px'
        w='full'
        border='1px solid'
        borderColor='gray3'
        background='gray.100'
        borderRadius='4px'
        flex={1}
        display={'flex'}
        py={1}
        pl={5}
        alignItems={'center'}
      >
        <Box borderRight={'1px solid'} borderColor='gray3' mr='1rem'>
          {isLoaded && (
            <QuickFilter
              tablePageData={{
                app: apps['biorisk'],
                model: model,
                viewData: defaultView,
              }}
            />
          )}
        </Box>
        {tableData.length > 0 && (
          <TablePageDataContext.Provider
            value={{
              app: apps['biorisk'],
              model,
              page,
              viewData: defaultView,
            }}
          >
            <FilterButton model={model} data={tableData} />
          </TablePageDataContext.Provider>
        )}
        <Flex ml={3} maxW={'75%'} overflowY='auto'>
          {tableFilters.length > 0 &&
            tableFilters
              .slice(0, 2)
              .map((filter, index) => (
                <ChartFilterPill
                  key={index}
                  model={model}
                  advancedFilter={filter}
                  removeFilters={removeFromFilters}
                />
              ))}
          {tableFilters.length > 2 && (
            <Box
              onClick={onOpen}
              ml={2}
              background='yellow.400'
              borderRadius='8px'
              boxShadow='sm'
              py={1}
              px={3}
              fontSize='xs'
              display={'flex'}
              cursor={'pointer'}
            >
              +{tableFilters.length - 2}
            </Box>
          )}
        </Flex>
      </Panel>
    </>
  )
}

function alreadySelected(rowValue: string, tableFilters: FilterType[]) {
  return (
    tableFilters.filter((filter) =>
      filter.filterValue.some((value: any) => value.value === rowValue)
    ).length > 0
  )
}
function removeSelectedFilter(rowValue: string, tableFilters: FilterType[]) {
  return tableFilters.filter((filter) =>
    filter.filterValue.some((value: any) => value.value !== rowValue)
  )
}

const MpoxPipeline = () => {
  const pipelinePage = BioriskPages['BioriskMpoxPipeline']
  const approvalsPage = BioriskPages['BioriskApprovals']
  const pipelineTablePage = BioriskPages['BioriskMpoxPipelineTable']

  const { addToFilters, tableFilters } = useTableChartFilters()

  const handleRowClick = useCallback(
    (rowData: any) => {
      const tempFilters: FilterType[] = [...tableFilters]
      const rowValue = rowData.candidateName
      const isSelected = alreadySelected(rowValue, tableFilters)
      if (isSelected) {
        addToFilters(removeSelectedFilter(rowValue, tempFilters))
        return
      }
      const newFilterObj = {
        label: rowValue,
        value: rowValue,
      }
      const pipelineModel = pipelineTablePage.model
      const filterType = getAllowedFilterType(
        pipelineModel.schema.columns.find(
          (column: any) => column.key === 'candidateName'
        )?.type
      )
      tempFilters.push({
        column: 'candidateName',
        filterValue: [newFilterObj],
        type: filterType[0].key,
      })
      addToFilters(tempFilters)
    },
    [addToFilters, pipelineTablePage.model, tableFilters]
  )

  return (
    <Flex flexDir={'column'} gap='1rem'>
      <Flex alignItems={'center'} gap='1rem'>
        <Flex alignItems={'center'} minWidth={'150px'} maxW='150px'>
          <Box mr={-3}>
            <Information />
          </Box>
          <WarningPopover message={dataLimitationsMessage} />
        </Flex>
        <FilterSection page={pipelinePage} />
      </Flex>
      <Flex gap={2} h='calc(70vh + 90px)'>
        <LatestMediaPipeline />
        <Box
          position={'relative'}
          minW={`calc(100vw - 370px - ${SIDEBAR_WIDTH} - 55px)`}
          maxW={`calc(100vw - 370px - ${SIDEBAR_WIDTH} - 55px)`}
        >
          {createElement(pipelinePage.component, {
            app: apps['biorisk'],
            views: pipelinePage.views,
            model: pipelinePage.model,
            page: pipelinePage,
          })}
        </Box>
      </Flex>
      <Flex
        maxW={`calc(100vw - ${SIDEBAR_WIDTH} - 55px)`}
        justifyContent={'space-between'}
      >
        <Flex mb={4} flexDir={'column'} w='49%'>
          <Text fontWeight={'semibold'} fontSize={'xl'}>
            Mpox Candidates
          </Text>
          <Box>
            {createElement(pipelineTablePage.component, {
              app: apps['biorisk'],
              views: pipelineTablePage.views,
              model: {
                ...pipelineTablePage.model,
                tableHeight: '700px',
                onlyAllowExport: true,
              },
              page: pipelineTablePage,
              extraActions: { onRowClick: handleRowClick },
            })}
          </Box>
        </Flex>
        <Flex mb={4} flexDir={'column'} w='49%'>
          <Text fontWeight={'semibold'} fontSize={'xl'}>
            Mpox Latest Approvals
          </Text>
          <Box>
            {createElement(approvalsPage.component, {
              app: apps['biorisk'],
              views: approvalsPage.views,
              model: {
                ...approvalsPage.model,
                tableHeight: '700px',
                onlyAllowExport: true,
              },
              page: approvalsPage,
            })}
          </Box>
        </Flex>
      </Flex>
      <UpsellBanner />
    </Flex>
  )
}

export default MpoxPipeline
