import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import { useEffect } from 'react'

const NEW_REPORTS_PRESENTATION_KEY = 'new_reports_presentation'

const PresentationModalSecureReports = ({
  forceOpen,
  setForceOpenInfo,
}: {
  forceOpen: boolean
  setForceOpenInfo: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    const value = localStorage.getItem(NEW_REPORTS_PRESENTATION_KEY)
    if (value !== 'true') {
      onOpen()
    }
  }, [onOpen])

  useEffect(() => {
    forceOpen && onOpen()
  }, [forceOpen, onOpen])

  function handleClose() {
    localStorage.setItem(NEW_REPORTS_PRESENTATION_KEY, 'true')
    onClose()
    setForceOpenInfo(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color='black'>
          Welcome to Airfinity’s new report interface.
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody color='black' py='0'>
          <Flex
            pos='absolute'
            top={'0.5rem'}
            right={'3rem'}
            bg='secondary'
            w='fit-content'
            px='8px'
            borderRadius={'4px'}
            my='8px'
          >
            <Text>Beta Features (WIP)</Text>
          </Flex>
          <Text fontSize={'lg'} pb='1rem' fontWeight={'semibold'}>
            We’ve introduced new features to enhance your report experience
          </Text>
          <Flex flexDir={'column'} pb='2rem' pt='1rem' color='gray.900'>
            <UnorderedList fontSize={'md'}>
              <ListItem mb='12px'>
                <b>AI-enabled reports:</b> Ask questions and get instant
                summaries or answers from the report content.
              </ListItem>
              <ListItem mb='12px'>
                <b>Easy sharing:</b> Share full reports or individual slides
                with colleagues effortlessly.
              </ListItem>
              <ListItem>
                <b>Metadata highlights:</b> Highlight any candidate name or
                company to view key metadata instantly.
              </ListItem>
            </UnorderedList>
            <Text as='span' fontSize={'sm'} mt='1rem'>
              These updates are designed to make your work faster, smarter, and
              more collaborative. If you have any feedback or questions please
              reach out to us at <b>contact@airfinity.com</b>.
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PresentationModalSecureReports
