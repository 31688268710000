import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import { apps, appsListUnion } from 'config/apps'

import { recursiveCamelCase } from '../../helper'
import useAxios from '../../useAxios'
import { Report } from './types'

type FetchReportsQueries = {
  categoryName?: string
  categoryType?: string
  searchText?: string
}

interface PdfT {
  title: string
  report?: string
  pdf?: string
}

export default function useReports(
  app: appsListUnion,
  paths: string[],
  queries?: FetchReportsQueries,
  extraProps?: UseQueryOptions<any, any>,
  endpoint?: string
) {
  const axios = useAxios()
  const res = useQueries({
    queries: paths.map<UseQueryOptions<Report[], AxiosError>>((newSlug) => {
      return {
        queryKey: ['reports', app, queries, newSlug],
        queryFn: () => fetchReports(axios, app, queries, newSlug, endpoint),
        ...extraProps,
        enabled: !!app,
      }
    }),
  })
  return res
}
export function useReportById(app: appsListUnion, id: string) {
  const axios = useAxios()

  return useQuery<Report, AxiosError>(
    ['collectionDataSingleRaw', app, id],
    () => fetchReportById(axios, app, id)
  )
}
export function useSingleReportById(
  app: appsListUnion,
  id: string,
  type: string
) {
  const axios = useAxios()

  return useQuery<PdfT, AxiosError>(['collectionDataSingleRaw', id, type], () =>
    fetchSecureReportById(axios, app, id, type)
  )
}

type SharedReportT = {
  url: string
  title: string
  id: number | string
  sharedFrom: string
  pageNumbers: number[]
}

export function useSharedReport(slug: string) {
  const axios = useAxios()

  return useQuery<SharedReportT, AxiosError>(['shared-report', slug], () =>
    fetchSharedReport(axios, slug)
  )
}

export const fetchSharedReport = async (
  axios: AxiosInstance,
  slug: string
): Promise<SharedReportT> => {
  const reports = axios
    .get(`/report-sharing/?share_slug=${slug}`, {
      withCredentials: true,
    })
    .then((res) => recursiveCamelCase(res.data))

  return reports
}

export const fetchReportById = async (
  axios: AxiosInstance,
  app: appsListUnion,
  id: string
): Promise<Report> => {
  const appEndpoint = apps[app].endpoint

  const reports = axios
    .get(`/${appEndpoint}/analyst-updates-hidden/${id}`, {})
    .then((res) => recursiveCamelCase(res.data))

  return reports
}

function getReportPath(type: string) {
  if (type === 'ResearchBrief') return 'research-briefs'
  if (!!type) return type
  else return 'analyst-updates'
}

export const fetchSecureReportById = async (
  axios: AxiosInstance,
  app: appsListUnion,
  id: string,
  type: string
): Promise<Report> => {
  const appEndpoint = apps[app].endpoint

  const path = getReportPath(type)

  const reports = axios
    .get(`/${appEndpoint}/${path}/${id}`, {})
    .then((res) => recursiveCamelCase(res.data))

  return reports
}
export const fetchReports = async (
  axios: AxiosInstance,
  app: appsListUnion,
  queries?: FetchReportsQueries,
  newSlug?: string,
  endpoint?: string
): Promise<Report[]> => {
  const appEndpoint = endpoint || apps[app].endpoint

  const reports = axios
    .get(`/${appEndpoint}/analyst-${newSlug}/`, {
      params: {
        ...(queries?.categoryName !== ''
          ? { category_name: queries?.categoryName }
          : {}),
        ...(queries?.categoryType !== ''
          ? { category_type: queries?.categoryType }
          : {}),
        ...(queries?.searchText !== ''
          ? { search_text: queries?.searchText }
          : {}),
      },
    })
    .then((res) => recursiveCamelCase(res.data))

  return reports
}
