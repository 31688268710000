import { Box, Flex, Text } from '@chakra-ui/react'
import { compactNumberWithPrefix } from 'utils/formatNumber'

import _ from 'lodash'

function getSummaryData(data: any) {
  const infectionsPaths = [
    { title: '', path: 'totalInfectionsChosen' },
    { title: 'Infections Averted', path: 'infectionsAverted' },
    { title: 'Infections Averted %', path: 'infectionsAvertedPercentage' },
  ]

  const deathsPaths = [
    { title: '', path: 'totalDeathsChosen' },
    { title: 'Deaths Averted', path: 'deathsAverted' },
    { title: 'Deaths Averted %', path: 'deathsAvertedPercentage' },
  ]
  const secondPaths = [
    { title: 'Total', path: 'total' },
    { title: 'High-Risk', path: 'highRisk' },
    { title: 'Young', path: 'young' },
  ]

  function getValues(paths: { title: string; path: string }[]) {
    return paths.map((item) => ({
      title: item.title,
      values: secondPaths.map((_item) => ({
        title: _item.title,
        value: _.get(data.summaryData, `${item.path}.${_item.path}`),
      })),
    }))
  }

  const infections = getValues(infectionsPaths)
  const deaths = getValues(deathsPaths)

  return { infections, deaths }
}

type DataT = {
  title: string
  values: {
    title: string
    value: any
  }[]
}[]

const ItemSummary = ({ data, title }: { data: DataT; title: string }) => {
  return (
    <Flex w='full' flexDir={'column'} justifyContent='center'>
      {title && (
        <Text textAlign={'center'} fontSize='xl' fontWeight={'semibold'}>
          {title}
        </Text>
      )}
      <Flex flexDir={'column'}>
        {data.map((item, index) => (
          <Flex
            key={index}
            flexDir={'column'}
            alignItems='center'
            justifyContent={'center'}
          >
            <Box whiteSpace={'nowrap'} mt='1rem' mb='8px'>
              {item.title}
            </Box>
            <Flex gap='8px'>
              {item.values.map((item) => (
                <Flex
                  key={item.title}
                  alignItems='center'
                  justifyContent={'center'}
                  border='1px solid'
                  borderColor='gray3'
                  p='8px'
                  bg='white'
                  rounded='lg'
                  w='200px'
                >
                  <Text whiteSpace={'nowrap'} mr='8px' color='gray.700'>
                    {item.title}:
                  </Text>
                  <Text fontWeight={'semibold'}>
                    {compactNumberWithPrefix(item.value, 3)}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

const SummaryDataWrapper = ({ data }: { data: any }) => {
  const { infections, deaths } = getSummaryData(data)
  return (
    <Flex my='2rem'>
      <ItemSummary data={infections} title='Infections' />
      <ItemSummary data={deaths} title='Deaths' />
    </Flex>
  )
}

export default SummaryDataWrapper
