import GenericTable from 'modules/Tables'
import BarChart from 'modules/Tables/Dashboard/AllDashboards/BarChart'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
  defaultDashboardConfig,
} from 'interfaces/navigationPage.interface'

import { RespiratoryCandidatesModel } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'

import { addOrOverrideColumn, compose } from 'utils/overrideModel'

import { InfluenzaSlugs } from './InfluenzaMenu'
import {
  InfluenzaApprovalsModel,
  InfluenzaCandidatePapersModel,
  InfluenzaCandidatesAllCombinedModel,
  InfluenzaCandidatesCombinationVaccinesModel,
  InfluenzaCandidatesNonVaccinesModels,
  InfluenzaCandidatesVaccineModel,
  InfluenzaMediaModel,
  InfluenzaPressReleasesModel,
  InfluenzaTrialsModel,
  NonVaccinesResultsModel,
  VaccinesResultsModel,
} from './InfluenzaModels'
import barChartMessages from 'modules/Tables/Dashboard/AllDashboards/BarChart/message'
import { fetchCollection } from 'api/useCollectionData'

type InfluenzaNavigationPage<TModel extends IModel<any>> =
  ISingleNavigationPage<InfluenzaSlugs, TModel>

const InfluenzaCandidatesVaccine: InfluenzaNavigationPage<
  typeof InfluenzaCandidatesVaccineModel
> = {
  key: 'InfluenzaCandidatesVaccine',
  path: ['candidates', 'dashboard'],
  model: {
    ...InfluenzaCandidatesVaccineModel,
    newNote: {
      text: barChartMessages,
      newUntil: new Date(2024, 8, 25),
    },
  },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'influenza_vax_treatments_combined',
      airtableBase: 'respiratory360',
      overrideModel: () => ({
        ...InfluenzaCandidatesAllCombinedModel,
        name: 'All',
        customAPIFunction: (axios) => {
          const resolveData = Promise.all([
            fetchCollection(axios, 'influenza', 'treatment-candidate', {
              airtableBase: 'respiratory360',
              view: 'front_end_export_influenza_treatments',
            }),
            fetchCollection(axios, 'influenza', 'vaccine-candidate', {
              airtableBase: 'respiratory360',
              view: 'front_end_export_influenza_vax',
            }),
          ]).then((d) => {
            return d[0].results
              .map((obj: any) => {
                const { treatmentType, vaccineType, ...rest } = obj
                return {
                  ...rest,
                  vaccineType: treatmentType ?? vaccineType,
                }
              })
              .concat(d[1].results)
          })

          return () => resolveData
        },
      }),
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vax',
      airtableBase: 'respiratory360',
      overrideModel: (model) =>
        compose(
          addOrOverrideColumn(
            RespiratoryCandidatesModel.schema.columns.find(
              (x) => x.key === 'influenzaValency'
            )!,
            4
          )
        )(model),
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesNonVaccinesModels,
        customAPIFunction: (axios) => {
          const resolveData = Promise.all([
            fetchCollection(axios, 'influenza', 'treatment-candidate', {
              airtableBase: 'respiratory360',
              view: 'front_end_export_influenza_treatments',
            }),
          ]).then((d) => {
            return d[0].results.map((obj: any) => {
              const { treatmentType, ...rest } = obj
              return {
                ...rest,
                vaccineType: treatmentType,
              }
            })
          })

          return () => resolveData
        },
      }),
    },
    {
      name: 'Combination vaccines',
      airtableName: 'api_combination_vaccines_influenza',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesCombinationVaccinesModel,
      }),
    },
    {
      name: 'Seasonal Vaccines',
      airtableName: 'front_end_export_influenza_vax_seasonal',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesVaccineModel,
      }),
    },
    {
      name: 'Pandemic Vaccines',
      airtableName: 'front_end_export_influenza_vax_pandemic',
      airtableBase: 'respiratory360',
      overrideModel: (model) => ({
        ...model,
        ...InfluenzaCandidatesVaccineModel,
      }),
    },
  ],
  autoGenerateDashboard: {
    ...defaultDashboardConfig,
    render: BarChart,
    verticalAxisLabel: 'Number of Candidates',
    filterBlacklistOptions: [
      'linkResearchInstitutions',
      'alternateNames',
      'mechanismOfActionDescription',
    ],
    defaultHorizontalFilterValue: {
      label: 'Phase',
      value: 'manualLatestPhase',
    },
    defaultStackByFilterValue: {
      label: 'Technology Type',
      value: 'vaccineType',
    },
    sortingOrders: {
      manualLatestPhase: [
        'Preclinical',
        'Phase 0',
        'Phase I',
        'Phase I/II',
        'Phase II',
        'Phase II/III',
        'Phase III',
        'Approved',
        'Phase IV',
        'Discontinued',
        'Paused',
        'Inactive',
        'Not Applicable',
        'Unknown',
      ],
    },
  },
}

const InfluenzaCandidatePapers: InfluenzaNavigationPage<
  typeof InfluenzaCandidatePapersModel
> = {
  key: 'InfluenzaPublications',
  path: null,
  model: InfluenzaCandidatePapersModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaTrials: InfluenzaNavigationPage<typeof InfluenzaTrialsModel> = {
  key: 'InfluenzaTrials',
  path: ['trials', 'all-data'],
  model: InfluenzaTrialsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const InfluenzaNewsPressReleases: InfluenzaNavigationPage<
  typeof InfluenzaPressReleasesModel
> = {
  key: 'InfluenzaNewsPressReleases',
  path: null,
  model: InfluenzaPressReleasesModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'front_end_export_influenza_default_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Vaccines',
      airtableName: 'front_end_export_influenza_vaccines_view',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'front_end_export_influenza_treatments_view',
      airtableBase: 'respiratory360',
    },
  ],
}
const InfluenzaNewsMedia: InfluenzaNavigationPage<typeof InfluenzaMediaModel> =
  {
    key: 'InfluenzaNewsMedia',
    path: null,
    model: InfluenzaMediaModel,
    component: GenericTable,
    views: [
      {
        name: 'Default',
        airtableName: 'front_end_export_influenza_default_view',
        airtableBase: 'respiratory360',
      },
      {
        name: 'Vaccines',
        airtableName: 'front_end_export_influenza_vaccines_view',
        airtableBase: 'respiratory360',
      },
      {
        name: 'Non-Vaccines',
        airtableName: 'front_end_export_influenza_treatments_view',
        airtableBase: 'respiratory360',
      },
    ],
  }

const InfluenzaVaccineResults: InfluenzaNavigationPage<
  typeof VaccinesResultsModel
> = {
  key: 'InfluenzaVaccineResults',
  path: ['results', 'vaccines'],
  model: VaccinesResultsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'Vaccines',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaNonVaccineResults: InfluenzaNavigationPage<
  typeof NonVaccinesResultsModel
> = {
  key: 'InfluenzaNonVaccineResults',
  path: ['results', 'non-vaccines'],
  model: NonVaccinesResultsModel,
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'Non-vaccines',
      airtableBase: 'respiratory360',
    },
  ],
}

const InfluenzaApprovals: InfluenzaNavigationPage<
  typeof InfluenzaApprovalsModel
> = {
  key: 'InfluenzaApprovals',
  path: ['regulatory', 'table'],
  model: InfluenzaApprovalsModel,
  component: GenericTable,
  views: [
    {
      name: 'Vaccines',
      airtableName: 'Front_end_influenza_vaccine_approvals',
      airtableBase: 'respiratory360',
    },
    {
      name: 'Non-Vaccines',
      airtableName: 'Front_end_influenza_treatment_approvals',
      airtableBase: 'respiratory360',
    },
  ],
}

export const InfluenzaPages: INavigationPages<InfluenzaSlugs> = {
  InfluenzaCandidatesVaccine,
  InfluenzaCandidatePapers,
  InfluenzaTrials,
  InfluenzaNewsPressReleases,
  InfluenzaNewsMedia,
  InfluenzaVaccineResults,
  InfluenzaNonVaccineResults,
  InfluenzaApprovals,
}
