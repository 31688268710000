import { downloadCSV } from 'modules/BioriskMpox/Epidemiology/helpers'

export function exportPriceData(data: any, type: string) {
  const headers = [
    'Recipients',
    'Recipient Region',
    'Procurement Mechanism',
    'Market',
    'Year',
    'Candidate',
    'Suppliers Organisation',
    'Price Per Unit (USD)',
    'Quantity',
    'Description',
    'RSS Articles',
    'Source',
    'Source Link',
  ].join(',')

  const csvRows = data.map((row: any, index: number) => {
    return [
      `${row.recipients.map((item: any) => item.replace(/,/g, ' ')).join('-') || ''}`,
      row.recipientRegion || '',
      row.procurementMechanism || '',
      row.market || '',
      row.yearDelivered || '',
      row.candidates || '',
      row.suppliers || '',
      row.pricePerUnitUsd || '',
      row.quantity || '',
      `"${(row.description || '')?.replace(/"/g, '""')}"`, // Escape quotes in description
      `${row.rssArticles?.map((item: any) => encodeURI(item)).join(' ') || ''}`,
      `${row.sources?.map((item: any) => item.title?.replace(/,/g, ' ')).join(' ') || ''}`,
      `${row.sources?.map((item: any) => encodeURI(item?.link)).join(' ') || ''}`,
    ].join(',')
  })

  const fileName = `${type}.csv`
  downloadCSV(headers, csvRows, fileName)
}

export function exportMapData(data: any, type: string) {
  exportPriceData(data, type)
}

export function exportCardsData(data: any, type: string) {
  const headers = [
    'Recipients',
    'Recipient Region',
    'Donor Country',
    'Donor Organisation',
    'Procurement Mechanism',
    'Market',
    'Year',
    'Candidate',
    'Supplier',
    'Quantity',
    'Price Per Unit (USD)',
    'Description',
    'Source',
    'Source Link',
  ].join(',')

  const csvRows = data.map((row: any) => {
    return [
      `${row.recipients.map((item: any) => item.replace(/,/g, ' ')).join('-') || ''}`,
      row.recipientRegion || '',
      row.donorAreas.join(' ') || '',
      row.donors.join(' ') || '',
      row.procurementMechanism || '',
      row.market || '',
      row.yearDelivered || '',
      row.candidates.join(' ') || '',
      row.suppliers.join(' ') || '',
      row.quantity || '',
      row.pricePerUnitUsd || '',
      `"${(row.description || '')?.replace(/"/g, '""')}"`, // Escape quotes in description
      `${row.rssArticles?.map((item: any) => encodeURI(item)).join(' ') || ''}`,
      `${row.sources?.map((item: any) => item.title?.replace(/,/g, ' ')).join(' ') || ''}`,
      `${row.sources?.map((item: any) => encodeURI(item?.link)).join(' ') || ''}`,
    ].join(',')
  })

  const fileName = `${type}.csv`
  downloadCSV(headers, csvRows, fileName)
}
