import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import DealType from '../../tagColours/DealType'
import EconomyIncomeBand from '../../tagColours/EconomyIncomeBand'
import { USDTransformFormat } from '../../transformValue/money'

export type IDTreatments360DealsData =
  | 'afDealId'
  | 'linkCandidateList'
  | 'afDealType'
  | 'linkOrganisations'
  | 'licensingAgreement'
  | 'typeOfProductionDealTreatments'
  | 'linkCountries'
  | 'afExpectedProduction2021'
  | 'tx2021ProductionCertainty'
  | 'afExpectedProduction2022'
  | 'tx2022ProductionCertainty'
  | 'afExpectedStartDateProduction'
  | 'afPublicExpectedProductionNotes'
  | 'mediaUrl'
  | 'pressUrl'
  | 'afDateDealMade'
  | 'afPurchaseType'
  | 'linkManufacturer'
  | 'linkRegions'
  | 'countryIncomeBandReceivingSupply'
  | 'afSupplyNumber'
  | 'afPotentialExpansion'
  | 'afSupplyCertainty'
  | 'afDeliveryToDate'
  | 'afDeliveryCertainty'
  | 'afDateDeliveryToDateWasLastUpdated'
  | 'afEstimatedPricePerDoseUsd'
  | 'supplyDealCostUsd'
  | 'notes'
  | 'volume'
  | 'pricePerDoseSource'
  | 'source'
  | 'linkSupplyDeal'
  | 'media'
  | 'pressReleases'
  | 'afFundingDealType'
  | 'afFundingType'
  | 'afFundingFigureUsDollars'
  | 'parentChild'
  | 'lookupVaccineName'
  | 'countryOrganisationGrantingDecision'
  | 'organisationsRegulators'
  | 'approvalType'
  | 'indication'
  | 'patientSetting'
  | 'supportingDocumentsUrlApprovalsFda'
  | 'clinicalTrials'
  | 'afDecisionDate'
  | 'luProject'
  | 'ttApprovalAgeWb'
  | 'linkInfectiousDisease'
  | 'approvalLabel'
  | 'countryOrOrganisationGrantingDecision'
  | 'organisationsOrRegulators'
  | 'mediaUrl'
  | 'pressUrl'
  | 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList'
  | 'lookupAfTrTypeFromBnfFromLinkCandidateList'

const allowedAirtables = [
  'API-PAD-Antivirals - Approvals - Treatments',
  'API-PAD-Antivirals - Approvals - Country',
] as const

export const IDTreatments360RegulatoryModel: IModel<
  Record<IDTreatments360DealsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'lookupVaccineName',
  name: 'supplyAndPrice',
  entityName: 'Supply and Price',
  searchField: 'lookupVaccineName',
  endpoint: 'deal',
  defaultSortKey: 'lookupVaccineName',
  schema: {
    columns: [
      {
        key: 'lookupVaccineName',
        label: 'Vaccine',
        type: ValueType.SINGLE,
        width: 450,
      },
      {
        key: 'afDealType',
        label: 'Deal type',
        type: ValueType.SINGLE,
        width: 230,
        cellFormat: {
          colours: DealType,
        },
      },
      {
        key: 'linkCandidateList',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 250,
      },
      { key: 'afDealId', label: 'Deal', type: ValueType.TEXT },
      {
        key: 'afSupplyNumber',
        label: 'Total supply of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
      },
      {
        key: 'afPotentialExpansion',
        label: 'Potential Expansion to order of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
      },
      {
        key: 'afDeliveryToDate',
        label: 'Total deliveries to date of COVID-19 vaccine doses',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'afDateDeliveryToDateWasLastUpdated',
        label: 'Delivery date was last updated',
        type: ValueType.DATE,
        width: 250,
      },
      {
        key: 'afExpectedProduction2022',
        label: 'Expected production of COVID-19 vaccine doses in 2022',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'afPurchaseType',
        label: 'Purchase Type',
        type: ValueType.SINGLE,
        width: 150,
      },
      {
        key: 'linkCandidateList',
        label: 'Candidate List',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'afDateDealMade',
        label: 'Date Deal Made',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'afSupplyCertainty',
        label: 'Supply Certainty',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'afDeliveryCertainty',
        label: 'Delivery Certainty',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'afPublicExpectedProductionNotes',
        label: 'Expected Production Notes',
        type: ValueType.TEXT,
        width: 240,
      },
      {
        key: 'organisationsOrRegulators',
        label: 'Organisation granting COVID-19 vaccine approval',
        type: ValueType.MULTI,
        width: 200,
      },
      { key: 'notes', label: 'Notes', width: 300, type: ValueType.TEXT },
      {
        key: 'afFundingDealType',
        label: 'Funding Deal Type',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afFundingType',
        label: 'Funding Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afEstimatedPricePerDoseUsd',
        label: 'Estimated Price Per Dose USD',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'afFundingFigureUsDollars',
        label: 'Funding Figure US Dollars',
        width: 200,
        type: ValueType.NUMBER,
      },
      {
        key: 'typeOfProductionDealTreatments',
        label: 'Production type',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afExpectedStartDateProduction',
        label: 'Expected Production Start Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'indication',
        label: 'Indication in COVID-19',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'countryIncomeBandReceivingSupply',
        label: 'Country income receiving supply',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: EconomyIncomeBand,
        },
      },
      {
        key: 'supplyDealCostUsd',
        label: 'Supply Deal Cost',
        width: 150,
        type: ValueType.TEXT,
        transformValue: USDTransformFormat,
      },
      {
        key: 'pricePerDoseSource',
        label: 'Price/Cost Source Type',
        width: 200,
        type: ValueType.MULTI,
      },
      { key: 'volume', label: 'Volume', width: 150, type: ValueType.MULTI },
      { key: 'source', label: 'Source', width: 120, type: ValueType.URL },
      {
        key: 'linkSupplyDeal',
        label: 'Price From Supply Deal',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'countryOrOrganisationGrantingDecision',
        label: 'Country/Organisation',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'approvalType',
        label: 'Approval Type',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'clinicalTrials',
        label: 'Trials Cited In Approval',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'parentChild',
        label: 'Parent Or Child',
        type: ValueType.TEXT,
        width: 200,
        showDetail: false,
        hiddenByDefault: true,
      },
      {
        key: 'linkManufacturer',
        label: 'Source Manufacturer',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'licensingAgreement',
        label: 'Under licensing agreement',
        type: ValueType.MULTI,
        width: 190,
      },
      {
        key: 'linkRegions',
        label: 'Regions that have secured these doses',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'afExpectedProduction2021',
        label: '2021 Production',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'tx2021ProductionCertainty',
        label: '2021 Production Certainty',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'tx2022ProductionCertainty',
        label: '2022 Production Certainty',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'linkOrganisations',
        label: 'Organisations',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCountries',
        label: 'Countries',
        width: 250,
        type: ValueType.MULTI,
      },
      { key: 'media', label: 'Media', width: 300, type: ValueType.MULTI },
      {
        key: 'pressReleases',
        label: 'Press Releases',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'countryOrganisationGrantingDecision',
        label: 'Country Organisation Granting Decision',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'organisationsRegulators',
        label: 'Organisations Regulators',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'supportingDocumentsUrlApprovalsFda',
        label: 'Supporting Documents URL Approvals FDA',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'afDecisionDate',
        label: 'Decision Date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'luProject',
        label: 'Project',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'ttApprovalAgeWb',
        label: 'Approval Age',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Infectious Disease',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'approvalLabel',
        label: 'Approval Label',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnfFromLinkCandidateList',
        label: 'Treatment Name',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupAfTrTypeFromBnfFromLinkCandidateList',
        label: 'Type',
        width: 100,
        type: ValueType.SINGLE,
      },
    ],
  },
}
