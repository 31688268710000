import {
  CleanResponseData,
  FileObject,
  LinkObject,
  RelationObject,
  ResponseData,
} from 'api/types'

export type TransformValueFunction = (value: string) => ResponseData | null

/** Returns True if the field is either an Relational Value or has the first element of the array is relational
 * Since the API only returns homogenous arrays of values, we can use this to determine if the field is relational
 */
export const isRelational = (rawValue: ResponseData) => {
  if (rawValue === null || rawValue === undefined) {
    return false
  }

  if (Array.isArray(rawValue)) {
    if (
      rawValue.length !== 0 &&
      typeof rawValue[0] === 'object' &&
      !!(rawValue[0] as RelationObject).model &&
      !!(rawValue[0] as RelationObject).endpoint
    ) {
      return true
    }
  } else if (
    typeof rawValue === 'object' &&
    !!(rawValue as RelationObject).model &&
    !!(rawValue as RelationObject).endpoint
  ) {
    return true
  }

  return false
}

const isLink = (rawValue: ResponseData) => {
  if (rawValue === null || rawValue === undefined) {
    return false
  }

  if (Array.isArray(rawValue)) {
    if (
      rawValue.length !== 0 &&
      typeof rawValue[0] === 'object' &&
      !!('link' in rawValue[0]) &&
      !!('title' in rawValue[0])
    ) {
      return true
    }
  } else if (
    typeof rawValue === 'object' &&
    !!('link' in rawValue) &&
    !!('title' in rawValue)
  ) {
    return true
  }

  return false
}

const isFile = (rawValue: ResponseData) => {
  if (rawValue === null || rawValue === undefined) {
    return false
  }
  let testSubject = rawValue
  if (Array.isArray(rawValue) && rawValue.length > 0) {
    testSubject = rawValue[0]
  }
  return (testSubject as FileObject).filename !== undefined
}

export const relationGetDisplayValue = (
  rawValue: ResponseData
): CleanResponseData => {
  if (rawValue === null || rawValue === undefined) {
    return rawValue
  }
  if (isRelational(rawValue)) {
    if (Array.isArray(rawValue)) {
      return (rawValue as RelationObject[]).map((x) => x.displayText)
    }
    return (rawValue as RelationObject).displayText
  }
  if (isLink(rawValue)) {
    if (Array.isArray(rawValue)) {
      return (rawValue as LinkObject[]).map((x) => x.link)
    }
    return (rawValue as LinkObject).title
  }
  if (isFile(rawValue)) {
    if (Array.isArray(rawValue)) {
      return (rawValue as FileObject[]).map((x) => x.filename)
    }
    return (rawValue as FileObject).filename as CleanResponseData
  }
  return rawValue as CleanResponseData
}

export const relationGetIds = (rawValue: ResponseData): string[] | null => {
  if (rawValue === null || rawValue === undefined) {
    return rawValue
  }
  if (isRelational(rawValue)) {
    if (Array.isArray(rawValue)) {
      return (rawValue as RelationObject[]).map((x) => x.id)
    }
    return [(rawValue as RelationObject).id]
  }
  return null
}

/** Apply the transformValueFunction passed from the parameter to the given raw value. Will handle it properly if given a relation object */
export const relationTransformValue = (
  rawValue: ResponseData,
  transformValueFunction: TransformValueFunction
): ResponseData => {
  if (rawValue === null || rawValue === undefined) {
    return rawValue
  }

  if (isRelational(rawValue)) {
    // If relational and is an array
    if (Array.isArray(rawValue)) {
      return (rawValue as RelationObject[]).map(
        (x) =>
          ({
            ...x,
            displayText: transformValueFunction(x.displayText),
          }) as RelationObject
      )
    }

    // If relational but just a single object
    return {
      ...(rawValue as RelationObject),
      displayText: transformValueFunction(
        (rawValue as RelationObject).displayText
      ),
    } as RelationObject
  }

  // Not relational, handle array
  if (Array.isArray(rawValue)) {
    return (rawValue as string[]).map(transformValueFunction) as string[]
  }

  // Not relational, handle standard case
  return transformValueFunction(rawValue as string)
}
