import { Box, TabPanel, TabPanelProps, TabPanels } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListMedia,
  DetailDrawerListPressRelease,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { PadClinicalTrialModel } from 'config/apps/PAD/PadModels'
import { MediaModel, PressReleasesModel } from 'config/common/baseModel'
import { IDTreatments360CandidateRankingData } from 'config/common/baseModel/idtreatments360/IDTreatments360Candidates'

import { PadDetailDrawerListTrial } from './PadDetailDrawerListTrial'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<IDTreatments360CandidateRankingData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<IDTreatments360CandidateRankingData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={['Summary', 'Media', 'Press Releases', 'Papers', 'Trials']}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={candidate} model={model} />
            </StyledTabPanel>{' '}
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                type='Media'
                model={MediaModel}
                sortOrder={(x) => moment(x.datePublished)}
                ids={candidate.media}
                itemComponent={DetailDrawerListMedia}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={2}
                viewData={viewData}
                type='PressReleases'
                sortOrder={(x) => moment(x.datePublished)}
                model={PressReleasesModel}
                ids={candidate.pressReleases}
                itemComponent={DetailDrawerListPressRelease}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={4}
                viewData={viewData}
                sortOrder={(x) => moment(x.completedAt)}
                model={PadClinicalTrialModel}
                ids={candidate.linkClinicalTrials}
                itemComponent={PadDetailDrawerListTrial}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
