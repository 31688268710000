import { Box } from '@chakra-ui/react'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { Covid19CommercialVaccinesSlugs } from './Covid19CommercialVaccinesMenu'
import NewNotePopover from 'components/PageHeader/NewNotePopover'

const DemandVaccineUptake: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['demand', 'vaccine-uptake'],
  component: () => (
    <TableauEmbed path='/COVIDVxuptake-V2/US-COVIDVxuptake' height={'88vh'} />
  ),
}

const Reports: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['reports'],
  component: () => (
    <FullReportPage
      showCategory={false}
      slugOverwrites={['updates']}
      researchBrief
    />
  ),
}

const ResearchBriefs: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const RevenueForecastReports: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['revenue', 'dashboard'],
    component: () => (
      <Box>
        <NewNotePopover
          newUntil={new Date(2024, 8, 24)}
          message='This Revenue Dashboard allows you to visualise the data contained within the Revenue table. This is a dynamic visual, use the axis options and filter options to configure the dashboard to suit your needs. Get in touch with any questions or if you would like a demo from Airfinity analysts.'
          navigate='/covid-19-commercial-vaccines/revenue/table'
        />
        <TableauEmbed path='/COVIDRevnueVx/InfluenzaRevenue' height={'90vh'} />
      </Box>
    ),
  }

const DemandReport: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['demand', 'demand-report'],
  component: () => (
    <Box display={'flex'} pl={1} flexDirection={'column'} overflowY={'auto'}>
      <NewNotePopover
        newUntil={new Date(2024, 8, 24)}
        message='Airfinity demand analysis has been compiled into three reports which will be updated as and when new developments occur. Read the Airfinity analysis on past, recent and future demand including estimated Autumn 2024 demand, forecasts of eligible population size changes and estimated market size of COVID-19 containing combination vaccines.'
      />
      <FullReportPage
        title='Demand Reports'
        showCategory={false}
        slugOverwrites={['full-report']}
      />
    </Box>
  ),
}

const PricingDashboard: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['supply', 'pricing-dashboard'],
  component: () => (
    <Box>
      <NewNotePopover
        newUntil={new Date(2024, 8, 24)}
        message='This Pricing Dashboard allows you to visualise the data contained within the Supply Deals table. This is a dynamic visual, use the axis options and filter options to configure the dashboard to suit your needs. Get in touch with any questions or if you would like a demo from Airfinity analysts.'
        navigate='/covid-19-commercial-vaccines/supply/table'
      />
      <TableauEmbed path='/COVIDPriceVx/InfluenzaPricingDashboard' />
    </Box>
  ),
}

export const Covid19CommercialVaccinesCustomPages: Record<
  string,
  ISingleCustomPage<Covid19CommercialVaccinesSlugs>
> = {
  DemandReport,
  Reports,
  RevenueForecastReports,
  ResearchBriefs,
  DemandVaccineUptake,
  PricingDashboard,
}
