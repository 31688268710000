import { AxiosInstance } from 'axios'
import pLimit from 'p-limit'

export const batchAndThrottleRequests = async (
  axios: AxiosInstance,
  urls: string[],
  batchSize = 20,
  concurrency = 5
) => {
  const limit = pLimit(concurrency)
  const results = []

  for (let i = 0; i < urls.length; i += batchSize) {
    const batch = urls.slice(i, i + batchSize)
    const promises = batch.map((areaId) =>
      limit(() => axios.get(`/pipeline/area/${areaId}/`))
    )

    try {
      const responses = await Promise.all(promises)
      results.push(
        ...responses.map((res) => {
          const geometry = JSON.parse(res.data.geom)
          if (!geometry) return null
          return {
            id: res.data.country_alpha_3,
            name: res.data.name,
            type: 'Feature',
            geometry: geometry,
            properties: {
              name: res.data.name,
              areaId: res.data.id,
              id: res.data.country_alpha_3,
              coords: [res.data.longitude, res.data.latitude],
            },
          }
        })
      )
    } catch (error) {
      console.error(`Error in batch ${i / batchSize + 1}:`, error)
    }
  }
  return {
    type: 'FeatureCollection',
    features: results.filter((x) => !!x),
  }
}
