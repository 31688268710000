import { Link, Text, Card } from '@chakra-ui/react'

export default function UpsellBanner() {
  return (
    <Card
      display='flex'
      border='1px solid'
      borderColor='gray.200'
      shadow='none'
      px={3}
      py={2}
      flexDir='column'
      alignItems='center'
    >
      <Text
        fontSize={'sm'}
        color='gray.800'
        textAlign='center'
        style={{ textWrap: 'balance' }}
        maxW='75%'
      >
        This dashboard can be expanded to cover additional parameters or
        infectious diseases. Get in touch with your account manager or{' '}
        <Link
          fontWeight={'medium'}
          color='black'
          href='mailto:infectiousdiseases@airfinity.com'
        >
          infectiousdiseases@airfinity.com
        </Link>{' '}
        to discuss further.
      </Text>
    </Card>
  )
}
