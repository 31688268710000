import { useMatch } from 'react-router-dom'

import { appsListUnion } from 'config/apps'
import { useContext } from 'react'
import { AuthContext } from 'contexts'

export type AppParams = {
  app: appsListUnion
  pageSlug?: string
  subPageSlug?: string
}

// Gets the app from params
export const useAppRoute = () => {
  const match = useMatch('/:app/*')
  const app = match?.params?.app

  return app as appsListUnion
}

export const useAppParams = () => {
  const app = useAppRoute()

  const pageSlug = useMatch('/:app/:pageSlug/*')?.params?.pageSlug
  const subPageSlug = useMatch('/:app/:pageSlug/:subPageSlug/*')?.params
    ?.subPageSlug

  return {
    app: app as appsListUnion,
    pageSlug: pageSlug ?? '',
    subPageSlug: subPageSlug ?? '',
  }
}

export const useCanUseBetaFeatures = () => {
  const app = useAppRoute()
  const { userCanAccessBetaFeatures } = useContext(AuthContext)

  if (app === 'ida-360' && userCanAccessBetaFeatures) return true
  return false
}
