import { Box, Text } from '@chakra-ui/react'
import { LayerT, colors, layersMappingToName } from './layers'

export default function Legend({
  handleClick,
  selectedLayer,
}: {
  handleClick: (e: LayerT) => void
  selectedLayer: LayerT
}) {
  return (
    <Box
      position='absolute'
      bottom={3}
      left={3}
      backgroundColor='white'
      zIndex={10}
      borderRadius={'md'}
      shadow={'md'}
      px={1}
      py={1}
    >
      {Object.keys(colors).map((item, index) => {
        const boxColor = (colors as any)[item]
        return (
          <Box
            key={index}
            display={'flex'}
            py={1}
            px={3}
            gap={4}
            alignItems={'center'}
            cursor={'pointer'}
            backgroundColor={item === selectedLayer ? 'gray.200' : ''}
            rounded='md'
            _hover={{
              backgroundColor: 'gray.100',
            }}
            onClick={() => handleClick(item as LayerT)}
          >
            <Box
              border='3px solid'
              bgColor={`${boxColor}b8`}
              borderColor={boxColor}
              width={'16px'}
              height={'16px'}
              rounded={'full'}
            />

            <Text textTransform={'capitalize'} fontSize={'sm'}>
              {layersMappingToName[item]}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}
