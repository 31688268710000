import { Box, Skeleton, Tooltip } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Cell, Row, TableInstance } from 'react-table'
import _ from 'underscore'

import { Arrow } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'

import { randomIntFromInterval } from 'utils/random'
import { relationGetDisplayValue } from 'utils/relational'

import ChildTable from './ChildTable'
import { useTableParentProps } from './provider/TableParentProps'

type PropTypes = {
  page: TableInstance<any>['page']
  getTableBodyProps: TableInstance<any>['getTableBodyProps']
  visibleColumns: TableInstance<any>['visibleColumns']
  prepareRow: TableInstance<any>['prepareRow']
  isLoading: boolean
  onRowClick?: (row: any) => void
  isChildTable?: boolean
}

export default ({
  getTableBodyProps,
  isLoading,
  prepareRow,
  page,
  visibleColumns,
  onRowClick,
  isChildTable = false,
}: PropTypes) => {
  const { parentProps } = useTableParentProps()

  const { model } = parentProps

  const tableIsGrouped = !!model.grouping

  return (
    <Box {...getTableBodyProps()} backgroundColor='white'>
      {!isLoading &&
        (tableIsGrouped && !isChildTable
          ? page.filter((x) => !!x.isGrouped)
          : page
        ).map((row) => {
          prepareRow(row)
          return (
            <CustomRow
              isChildTable={isChildTable}
              model={model}
              row={row}
              onRowClick={onRowClick}
              key={`custom_row_${row.id}`}
            />
          )
        })}
      {isLoading &&
        _.times(15, (index) => {
          return (
            <RowContainer key={index}>
              <Box
                key={index}
                px={3}
                borderBottom='1px solid'
                borderRight='1px solid'
                borderColor='gray.100'
                width='100%'
                flex={`1 0 auto`}
                display='flex'
                className='cell'
                color='legacy-primary.500'
              >
                <Skeleton
                  height='16px'
                  my='auto'
                  width={randomIntFromInterval(85, 95) + '%'}
                />
              </Box>
            </RowContainer>
          )
        })}
    </Box>
  )
}

const RowContainer = ({
  row,
  children,
  onRowClick,
}: {
  row?: any
  children: React.ReactNode
  onRowClick?: (data: any) => void
}) => {
  return (
    <Box
      {...row?.getRowProps()}
      onClick={onRowClick ? () => onRowClick(row.original) : null}
      minHeight={'44px'}
      display='flex'
      className={onRowClick ? 'clickable' : ''}
    >
      {children}
    </Box>
  )
}

const CustomRow = ({
  row,
  onRowClick,
  model,
  isChildTable,
}: {
  row: Row<any>
  onRowClick: any
  model: IModel<any, any>
  isChildTable: boolean
}) => {
  // Handle click if it's grouped
  const rowClick = useMemo(
    () =>
      !row.groupByID
        ? { onRowClick }
        : { onRowClick: row.subRows.length > 0 ? row.toggleRowExpanded : null },
    [row, onRowClick]
  )

  const cells =
    !!model.grouping && !!model.grouping.parentColumns
      ? isChildTable
        ? row.cells.filter((cell) =>
            !!model?.grouping?.childColumn
              ? model?.grouping?.childColumn?.find((x) => x === cell.column.id)
              : !model?.grouping?.parentColumns?.find(
                  (x) => x === cell.column.id
                )
          )
        : row.cells.filter((cell) =>
            model?.grouping?.parentColumns?.find((x) => x === cell.column.id)
          )
      : row.cells

  return (
    <>
      <RowContainer row={row} {...rowClick}>
        {cells.map((cell: Cell<any, any>, i) => {
          return (
            <CustomCell
              cell={cell}
              key={`${row.id}_${cell.column.id}_${row.isExpanded}`}
              // We get the cell props here so that we can memoize this
              cellProps={cell.getCellProps()}
              isExpanded={row.isExpanded}
              haveSubRows={!!row.subRows && row.subRows.length > 0}
              isFirstCell={i === 0}
            />
          )
        })}
      </RowContainer>
      {row.isExpanded ? (
        <ChildTable
          key={row.id}
          data={row.subRows.map((x) => x.original as IResponseBase<any>)}
        />
      ) : null}
    </>
  )
}

const CellWrapper = ({ children, showTooltip, label }: any) => {
  if (showTooltip) {
    return (
      <Tooltip label={Array.isArray(label) ? label.join(', ') : label}>
        <Box overflow={'hidden'}>{children}</Box>
      </Tooltip>
    )
  }
  return children
}

const CustomCell = React.memo(
  ({
    cell,
    cellProps,
    isExpanded,
    haveSubRows,
    isFirstCell,
  }: {
    cell: Cell<any, any>
    cellProps: any
    isExpanded: boolean
    haveSubRows: boolean
    isFirstCell: boolean
  }) => {
    const tooltipText = relationGetDisplayValue(cell.value)
    const showTooltip =
      (Array.isArray(tooltipText) && tooltipText.join(' ')?.length > 50) ||
      (typeof tooltipText === 'string' && tooltipText?.length > 50)
    return (
      <Box
        title={!showTooltip && tooltipText ? '' : ''}
        // @ts-ignore
        className={`${cell.column?.class ?? ''} cell`}
        fontSize='12px'
        lineHeight='none'
        borderBottom='1px solid'
        borderColor='gray.200'
        display='flex'
        alignItems='center'
        px={3}
        pr={0}
        overflow='auto'
        // @ts-ignore
        {...cell.column.style}
        {...cellProps}
        color='legacy-primary.500'
        sx={{
          '&::-webkit-scrollbar': {
            width: '3px',
            height: '3px',
          },
          '&::-webkit-scrollbar-track': {
            width: '3px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray3',
            borderRadius: '50px',
          },
        }}
      >
        {isFirstCell && haveSubRows && (cell.isGrouped || cell.isAggregated) ? (
          <>
            {isExpanded ? (
              <Arrow direction='down' />
            ) : (
              <Arrow direction='right' />
            )}
            <Box ml={2} />
          </>
        ) : null}

        {cell.isGrouped && haveSubRows ? (
          cell.render('Cell')
        ) : cell.isAggregated ? (
          cell.render('Aggregated')
        ) : cell.isPlaceholder ? (
          // Add some padding to match the parent row
          <>
            <Box ml={4} />
            {cell.render('Cell')}
          </>
        ) : (
          <CellWrapper showTooltip={showTooltip} label={tooltipText}>
            {cell.render('Cell')}
          </CellWrapper>
        )}
      </Box>
    )
  },
  (prev, next) =>
    prev.cell.value === next.cell.value &&
    _.isEqual(prev.cellProps, next.cellProps)
)
