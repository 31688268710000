import { Flex, Text } from '@chakra-ui/react'
import { Card, FiltersT } from '../..'
import { DemoDataT } from '../..'
import { useQuery } from '@tanstack/react-query'
import useAxios from 'api/useAxios'
import { LoadingAnimation } from 'components'
import DemographicChart from 'modules/BioriskMpox/common/Demographics'
import { getDemoData, removeNullCases } from '../../helpers'
import { recursiveCamelCase } from 'api/helper'
import { getQueryData } from 'modules/BioriskMpox/Response/LeftSection/Cards/Procurement'

// TODO: subnational removal
export const DemoDataPlaceholder = () => {
  return (
    <Card>
      <Text fontWeight='semibold'>Mpox trends by</Text>
      <Flex justifyContent={'center'} alignItems='center' h='100%'>
        <Text color='black'>Demographic data coming soon</Text>
      </Flex>
    </Card>
  )
}

const EpiDemographicsChart = ({
  filters,
  dataType,
  selectCountry,
}: {
  filters: FiltersT
  dataType: string
  selectCountry?: (country: string) => void
}) => {
  const axios = useAxios()
  const {
    data: demoData,
    isLoading,
    error,
  } = useQuery<DemoDataT>(
    ['demographics', filters.dateRange, filters.country, filters.dataType],
    async () => {
      const endpoint = `/lzdb/science-macro/time-series-${dataType}/?`
      const standard = await axios.get(getQueryData(filters, endpoint))
      const standardData = removeNullCases(recursiveCamelCase(standard.data))
      const countryFilter =
        filters.country !== 'global' ? filters.country : undefined
      return getDemoData(standardData, countryFilter)
    }
  )

  if (error) {
    return (
      <Card>
        <Text fontWeight='semibold'>Mpox trends by</Text>
        <Flex justifyContent={'center'} alignItems='center' h='100%'>
          <Text color='error'>Something went wrong loading the data</Text>
        </Flex>
      </Card>
    )
  }

  if (!demoData || isLoading) {
    return (
      <Card>
        <Text fontWeight='semibold'>Mpox trends by</Text>
        <Flex justifyContent={'center'} alignItems='center' h='100%'>
          <LoadingAnimation />
        </Flex>
      </Card>
    )
  }
  return (
    <DemographicChart
      data={demoData}
      filters={filters}
      selectCountry={selectCountry}
    />
  )
}

export default EpiDemographicsChart
