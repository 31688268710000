import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { FullPageError } from 'components'
import WarningPopover from '../common/WarningPopover'

import { StreamingWrapper, ThinkingWrapper } from '../common/ThinkingWrapper'
import Header from '../common/Header'
import ChatSection from '../common/ChatSection'
import BottomSection from '../common/BottomSection'
import EmptyScreen from '../common/EmptyScreen'
import useAI from 'api/ai/useAI'
import { assistantsId } from '../common/constants'
import { useEffect, useRef, useState } from 'react'
import { SimpleChatWidget } from 'modules/ChatWidget'
import useIsMobile from 'utils/useIsMobile'

const AiReport = ({
  reportId,
  reportTitle,
}: {
  reportId: number
  reportTitle: string
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile] = useIsMobile()

  const [pageWidth, setPageWidth] = useState(500)

  const pdfContainerRef = useRef(null)

  const updateWidth = () => {
    if (pdfContainerRef.current) {
      const containerWidth =
        (pdfContainerRef.current as any).parentNode.offsetWidth - 100
      setPageWidth(containerWidth / 3)
    }
  }

  // Update width on window resize
  useEffect(() => {
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const {
    chatHistory,
    error,
    divRef,
    streamingAnswer,
    search,
    answer,
    handleAnotherQuestion,
    setQuery,
    query,
    assistantId,
  } = useAI(reportId, assistantsId.single_report, reportTitle)

  if (error) return <FullPageError />

  const isEmptyScreen = chatHistory.length === 0 && !streamingAnswer

  return (
    <Flex ref={pdfContainerRef} h='100%'>
      <WarningPopover onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
      <Flex w={isMobile ? '100%' : pageWidth} zIndex={1} h='100%'>
        <Box
          bg='white'
          overflow='auto'
          pos='relative'
          border='1px solid'
          borderColor={'gray3'}
          borderRadius='6px'
          w='100%'
        >
          <Box flex={1} display='flex' flexDirection='column' h='100%'>
            <Flex
              flexDir={'column'}
              border={'none'}
              borderColor={'gray3'}
              borderRadius={'6px'}
              mb={0}
              mt={'4px'}
            >
              <Header title='Report AI' />
            </Flex>
            {isEmptyScreen && (
              <EmptyScreen
                description='The questions will be answered using this report.'
                handleSuggestion={(query) => search(query)}
                isReportSearch
              />
            )}
            <Flex overflow={'scroll'} flexDir={'column'} p='1rem'>
              {chatHistory.map((message, index) => {
                return (
                  <ChatSection
                    message={message}
                    key={index}
                    chatHistory={chatHistory}
                    isReport
                  />
                )
              })}
              <StreamingWrapper answer={streamingAnswer} isReport />
              <ThinkingWrapper answer={answer} assistantId={assistantId} />
              {chatHistory.length >= 2 && (
                <Flex
                  justifyContent={'space-around'}
                  mb='2rem'
                  mt='2rem'
                  flexDir={'column'}
                  gap='1rem'
                >
                  <Button variant={'yellow'} onClick={handleAnotherQuestion}>
                    Ask a different question
                  </Button>
                  <SimpleChatWidget />
                </Flex>
              )}
            </Flex>

            <BottomSection
              setQuery={setQuery}
              search={search}
              query={query}
              handleOpenWarningMessage={onOpen}
              isEmptyScreen={isEmptyScreen}
              reportAI={!!reportId}
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default AiReport
