import { ZoomIn, ZoomOut } from '@carbon/icons-react'
import { Box, Button } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Page } from 'react-pdf'

export type PdfViewerProps = {
  reportUrl: string
  nPagesCB?: React.Dispatch<React.SetStateAction<number>>
  reportId?: string
  reportTitle?: string
  reportType?: string
  simpleView?: boolean
  hideAI?: boolean
}

export type TrackingEventProps = {
  app: string
  page: string
  reportId: string
  report: string
  type: string
  pageNumber?: number
}

export type SharedReportTracking = {
  reportId: string
  report: string
  fullReportShared: boolean
  pageNumbers?: number[]
}

// Custom hook for PDF width calculation
export const usePdfWidth = (
  containerRef: React.RefObject<HTMLDivElement>,
  hideAI?: boolean,
  isMobile?: boolean
) => {
  const _hideAI = isMobile || hideAI
  const [pageWidth, setPageWidth] = useState(500)

  const updateWidth = () => {
    if (!containerRef.current) return

    const containerWidth =
      (containerRef.current.parentNode as HTMLElement)?.offsetWidth -
        (isMobile ? 30 : 100) || 500
    setPageWidth((containerWidth * 2) / (_hideAI ? 2 : 3))
  }

  useEffect(() => {
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateWidth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hideAI])

  return pageWidth
}

// Custom hook for PDF zooming
export const useZoom = (initialScale = 1.0) => {
  const [scale, setScale] = useState<number>(initialScale)

  const handleZoom = (zoomIn: boolean) => {
    setScale((prevScale) => {
      return zoomIn
        ? Math.min(prevScale + 0.2, 3)
        : Math.max(prevScale - 0.2, 0.5)
    })
  }

  return { scale, handleZoom }
}

// Custom hook for tracking page visibility
export const usePageVisibilityTracking = (
  numPages: number | undefined,
  pageRefs: React.MutableRefObject<any[]>,
  trackingEvent: (props: any) => void,
  trackingProps: Omit<TrackingEventProps, 'pageNumber'>,
  currentPageCB: (page: number) => void
) => {
  useEffect(() => {
    if (!numPages) return

    let lastViewedPage = 0
    const observers = pageRefs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && index > 0) {
              currentPageCB(index + 1)
            }
            if (entry.isIntersecting && index + 1 > lastViewedPage) {
              lastViewedPage = index + 1
              trackingEvent({
                ...trackingProps,
                pageNumber: index + 1,
              })
            }
          })
        },
        { threshold: 0.5 }
      )

      if (ref) observer.observe(ref)
      return observer
    })

    return () => {
      observers.forEach((observer) => observer.disconnect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPages, trackingEvent, trackingProps, pageRefs])
}

// Component for zoom controls
export const ZoomControls = ({
  onZoomIn,
  onZoomOut,
  scale,
}: {
  onZoomIn: () => void
  onZoomOut: () => void
  scale: number
}) => (
  <Box
    mb='-35px'
    display='flex'
    gap={2}
    pos='sticky'
    zIndex={5}
    left='0px'
    top='10px'
    marginLeft='15px'
  >
    <Button onClick={onZoomOut} isDisabled={scale <= 0.5} variant='yellow'>
      <ZoomOut />
    </Button>
    <Button onClick={onZoomIn} isDisabled={scale >= 3} variant='yellow'>
      <ZoomIn />
    </Button>
  </Box>
)

// Component for PDF loading placeholder
export const PdfLoadingPlaceholder = ({
  width,
  height,
}: {
  width: number
  height: number
}) => <Box height={height} bg='gray.300' width={width} />

// Component for individual PDF page
interface PdfPageComponentProps {
  pageNumber: number
  width: number
  scale: number
  pageRef?: any
  pageRenderCB?: (page: number) => void
}

export const PdfPageComponent: React.FC<PdfPageComponentProps> = ({
  pageNumber,
  width,
  scale,
  pageRef,
  pageRenderCB,
}) => {
  return (
    <Box
      my='4px'
      ref={pageRef}
      position='relative'
      overflow={scale === 1 ? 'hidden' : 'auto'}
      width={width}
      transformOrigin={`top left`}
    >
      <Page
        pageNumber={pageNumber}
        width={width * scale}
        onRenderSuccess={(props) =>
          pageRenderCB && pageRenderCB(props._pageIndex)
        }
        loading={() => (
          <PdfLoadingPlaceholder width={width} height={width / 1.77} />
        )}
      >
        <Box
          bg='white'
          position='absolute'
          bottom='1%'
          right='3%'
          zIndex={1}
          padding='6px'
          fontSize={(width * scale) / 100}
          border='1px solid'
          borderColor='gray.300'
          borderRadius='sm'
        >
          {pageNumber}
        </Box>
      </Page>
    </Box>
  )
}

// Function to handle text selection
export const useTextSelection = (
  containerRef: React.RefObject<HTMLDivElement>,
  onTextSelected: (text: string) => void,
  trackingEvent: (props: TrackingEventProps) => void,
  trackingProps: TrackingEventProps,
  savePageLocation: () => void
) => {
  const [popoverStyle, setPopoverStyle] = useState({})

  const handleMouseUp = () => {
    const selection = window.getSelection()
    if (!selection) return

    const text = selection.toString().trim()
    if (!text) {
      setPopoverStyle({ display: 'none' })
      return
    }

    savePageLocation()

    onTextSelected(text)
    trackingEvent(trackingProps)

    const range = selection.getRangeAt(0)
    const rect = range.getBoundingClientRect()

    if (!containerRef.current) return

    const containerRect = containerRef.current.getBoundingClientRect()
    const scrollTop = containerRef.current.scrollTop

    let left: number | null =
      rect.left - 300 < containerRect.x
        ? rect.left < 300
          ? 250
          : rect.left
        : rect.left - 200
    let right: number | null = null

    if (left > containerRect.width / 2) {
      left = null
      right = 0
    }

    const top = rect.top - containerRect.top + scrollTop + 20
    setPopoverStyle({ display: 'block', top, left, right })
  }

  return { popoverStyle, handleMouseUp }
}
