import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import _ from 'lodash'
import moment from 'moment'
import {
  CladeCountry,
  CladeDataT,
  CladeItem,
  DataT,
  FeaturePropertiesT,
  FeaturesT,
  FeatureT,
  FormattedDataT,
  OldestCladeItem,
  OldestCladesByCountry,
} from './types'

dayjs.extend(minMax)

const getNewestClade = (items: CladeItem[]): OldestCladeItem[] => {
  return Object.values(
    items.reduce((acc: Record<string, OldestCladeItem>, item) => {
      const clade = item.clade
      const date = item.date || item.dateSubmitted
      if (!clade || !date) return acc
      if (!acc[clade] || new Date(date) < new Date(acc[clade].date)) {
        acc[clade] = {
          ...item,
          clade,
          date,
          newDate: date,
          strain: item.strain,
        }
      }
      if (!acc[clade] || new Date(date) > new Date(acc[clade].newDate)) {
        acc[clade] = {
          ...item,
          date: acc[clade].date,
          clade: item.clade,
          strain: item.strain,
          newDate: date,
        }
      }

      return acc
    }, {})
  )
}

const oldestCladeItemsByCountry = (
  cladeCountries: CladeCountry
): OldestCladesByCountry => {
  const clades = Object.fromEntries(
    Object.entries(cladeCountries)
      .filter(([country]) => country && country !== null) // Exclude invalid country keys
      .map(([country, items]) => {
        const filteredItems = items
          .map((item) => ({
            ...item,
            clade: item.clade,
            date: item.date,
          }))
          .filter(({ clade, date }) => !!clade && !!date) // Type guard for valid items
        return [country, getNewestClade(filteredItems)]
      })
      .filter(([country, oldestItems]) => !!country && oldestItems.length > 0) // Exclude countries with no valid clades
  )
  return clades
}

function assignCladeToCountryMap(
  countryPoly: { features: Feature[] },
  oldestClades: OldestCladesByCountry,
  countryFilter?: string
) {
  const countryWithClade = countryPoly.features.map((item) => {
    const id = countryFilter ? item.name : item.id
    const clade = oldestClades[id]?.[0]?.clade
    return {
      ...item,
      properties: {
        ...item.properties,
        ...oldestClades[id]?.[0],
        clade: clade,
        color: !clade ? 'transparent' : 'purple',
      },
    }
  })
  return countryWithClade
}

function getCountryOldestClade(
  clade: CladeDataT,
  countryPoly: { features: Feature[] },
  dateFilter: string,
  countryFilter?: string
) {
  const cladeByAreas = _.groupBy(clade, (item) =>
    countryFilter ? item.area : item.areaAlpha3
  )

  const oldestClades = oldestCladeItemsByCountry(cladeByAreas)

  const filteredClades = Object.keys(oldestClades).reduce(
    (acc, country: string) => {
      const date = oldestClades[country][0].date
      if (moment(date).isSameOrBefore(moment(dateFilter))) {
        return {
          ...acc,
          [country]: oldestClades[country],
        }
      }
      return acc
    },
    {}
  )

  const countryWithClade = assignCladeToCountryMap(
    countryPoly,
    filteredClades,
    countryFilter
  )

  return countryWithClade
}

interface Feature {
  type: 'Feature'
  id: string
  name: string
  properties: {
    name: string
    coords?: any
    [key: string]: any
  }
  geometry: {
    type: string
    coordinates: number[][]
  }
}

export function getCollections(
  data: DataT[],
  clade: CladeDataT,
  countryPoly: { features: Feature[] },
  dateFilter: string,
  countryFilter?: string
): FeaturesT {
  const filteredData = countryFilter
    ? data.filter((item) => item.areaAlpha3 === countryFilter)
    : data

  const countryClades = getCountryOldestClade(
    clade,
    countryPoly,
    dateFilter,
    countryFilter
  )

  const byLocation = _.groupBy(filteredData, (item) =>
    countryFilter
      ? `${item.areaLatitude}-${item.areaLongitude}`
      : `${item.areaAlpha3}`
  )

  function getCasesByLocation(location: string) {
    const cases = byLocation[location].reduce(
      (acc, item) => {
        const newAcc = { ...acc }
        const { isSuspected } = item
        if (typeof isSuspected === 'boolean' && !isSuspected) {
          newAcc.confirmed = [...newAcc.confirmed, item]
        } else if (isSuspected) {
          newAcc.suspected = [...newAcc.suspected, item]
        } else {
          newAcc.notSpecified = [...newAcc.notSpecified, item]
        }
        return newAcc
      },
      {
        confirmed: [] as any[],
        suspected: [] as any[],
        notSpecified: [] as any[],
      }
    )
    return cases
  }

  function calculateTotal(cases: any[], key = 'value') {
    return cases.reduce((total, item) => total + Number(item[key] || 0), 0)
  }

  function buildFeature(cases: DataT[], total: number, useCoords = false) {
    const defaultCase = cases[0]
    const { area, areaAlpha3: country, areaLatitude, areaLongitude } = cases[0]
    const countryInfo = countryPoly.features.find(
      (feature) => feature.id === defaultCase.areaAlpha3
    )?.properties
    const { coords, name } = countryInfo || {}

    const isAreaCountry = useCoords && name === area

    const nAreas = Object.keys(byLocation).length

    if (isAreaCountry && nAreas > 1) {
      return null
    }

    const lat = (useCoords ? areaLatitude : coords?.[1]) || areaLatitude
    const long = (useCoords ? areaLongitude : coords?.[0]) || areaLongitude

    return getFeatureStruct({
      area,
      country,
      lat,
      long,
      value: total,
      allCases: cases,
    })
  }

  const formattedData = Object.keys(byLocation).reduce(
    (acc, location) => {
      const cases = getCasesByLocation(location)

      const totalConfirmed = calculateTotal(cases.confirmed)
      const totalSuspected = calculateTotal(cases.suspected)
      const totalNotSpecified = calculateTotal(cases.notSpecified)

      const notSpecified = [...acc.notSpecified]
      if (totalNotSpecified > 0 && cases.notSpecified) {
        const newFeature = buildFeature(
          cases.notSpecified,
          totalNotSpecified,
          !!countryFilter
        )
        newFeature && notSpecified.push(newFeature)
      }

      const suspected = [...acc.suspected]
      if (totalSuspected > 0 && cases.suspected) {
        const newFeature = buildFeature(
          cases.suspected,
          totalSuspected,
          !!countryFilter
        )
        newFeature && suspected.push(newFeature)
      }

      const confirmed = [...acc.confirmed]
      if (totalConfirmed > 0 && cases.confirmed) {
        const newFeature = buildFeature(
          cases.confirmed,
          totalConfirmed,
          !!countryFilter
        )
        newFeature && confirmed.push(newFeature)
      }

      return {
        confirmed: confirmed,
        suspected: suspected,
        notSpecified: notSpecified,
      }
    },
    { confirmed: [], suspected: [], notSpecified: [] } as FormattedDataT
  )

  const type = { type: 'FeatureCollection' }

  return {
    confirmed: { ...type, features: formattedData.confirmed },
    suspected: { ...type, features: formattedData.suspected },
    notSpecified: { ...type, features: formattedData.notSpecified },
    clade: { ...type, features: countryClades as any },
  }
}

function getFeatureStruct(feature: FeaturePropertiesT): FeatureT {
  const { long, lat } = feature
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [long, lat],
    },
    properties: feature,
  }
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function getDates(dates: (dayjs.Dayjs | null)[] | (dayjs.Dayjs | null)) {
  if (!Array.isArray(dates)) {
    return dayjs(dates)?.format('YYYY-MM-DD')
  }
  const _dates = dates.filter(notEmpty)
  const elements = _dates.length
  if (elements === 0) return 'Not Reported'
  const oldestDate = dayjs.min(_dates)?.format('YYYY-MM-DD')
  const newestDate = dayjs.max(_dates)?.format('YYYY-MM-DD')

  if (oldestDate === newestDate) return newestDate

  return `${oldestDate} to ${newestDate}`
}
