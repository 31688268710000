import { CandidateRankingModel } from 'config/common/baseModel'

import {
  compose,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'
import { IDACandidatesDetailView } from 'routes/apps/ida360/Candidates'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { SortOrders } from 'enums/SortOrders'

export const BioriskCandidateModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    'candidateName',
    'latestPhaseManual',
    'technologyType',
    'sponsors',
    'designationA',
  ])
)({
  ...CandidateRankingModel,
  hideViewSelector: true,
  excludeGeneratedCharts: true,
  detailViewType: 'Generic',
  renderDetailView: IDACandidatesDetailView,
})

export const BioriskMpoxCandidateModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    'candidateName',
    'latestPhaseManual',
    'sponsors',
    'alternativeNames',
    'diseaseName',
    'pathogenTypes',
    'designationB',
    'routeOfAdministration',
    'type',
    'subType',
    'vaccineAdjuvants',
    'vaccineTargetPopulation',
    'vaccineValency',
    'targetViralStrain',
    'deliverySystemName',
    'frontendNotes',
  ]),
  updateColumnAttributes('latestPhaseManual', {
    sortOrderObject: [
      'Approved',
      'Phase III',
      'Phase II/III',
      'Phase II',
      'Phase I/II',
      'Phase I',
      'Preclinical',
    ],
  })
)({
  ...CandidateRankingModel,
  defaultSortObject: [
    {
      id: 'latestPhaseManual',
      sortOrder: SortOrders.ASC,
    },
  ],
})

export const BioriskApprovalsModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'candidateName',
    'sponsors',
    'type',
    'area',
    'regulatoryOrganisation',
    'targetPopulations',
    'approvalDate',
    'associatedDocumentUrl',
    'designationA',
  ])
)({ ...ApprovalsModel, name: 'Mpox New Approvals' })
