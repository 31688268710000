import _ from 'lodash'
import moment from 'moment'
import { DataT, SummaryDataItemT, SummaryDataT } from '../Map/utils/types'
import { TopCountriesChartDataT } from '../../common/TopCountries'
import { GEO_POLYGONS } from 'components/Dashboards/MapHeatmap/utils'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'

function getDatesBetween(startDate: string, endDate: string) {
  let dates = []
  let currentDate = moment(startDate)

  // Loop until the currentDate is the same or after the endDate
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'day') // Increment by one day
  }
  return dates
}

type ValueType = 'value_per_100k' | 'cumulative_value'

interface CumulativeDataT {
  [key: string]: {
    [innerKey: string]: { [valueKey in ValueType]: number }
  }
}

export function getEpiTrendData(
  data: CumulativeDataT,
  valueType: ValueType,
  dateRange: { start: string; end: string },
  countryFilter: string
) {
  const filteredData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const newKey = key.replace(/\s\([\w]+\)$/, '') // Remove ISO code in brackets
      return [newKey, value]
    })
  )

  // TODO: subnational removal
  const countryName =
    countryCodeToNameMapping[
      countryFilter as keyof typeof countryCodeToNameMapping
    ]

  const _countries = Object.keys(filteredData)
  // TODO: subnational removal (remove filter)
  const countries =
    countryFilter !== 'global'
      ? _countries.filter((x) => x === countryName)
      : _countries

  if (!countries.length) return { data: [{ date: '' }], countries: [] }

  // Create a summary of total values for each country
  const countryTotals: { [key: string]: number } = {}

  countries.forEach((country) => {
    countryTotals[country] = Object.values(filteredData[country]).reduce(
      (sum, value) => {
        return sum + value[valueType]
      },
      0
    )
  })

  // Get the top 5 countries by total value
  const topCountries = Object.entries(countryTotals)
    .sort(([, a], [, b]) => b - a) // Sort by descending value
    .map(([key]) => key) // Get country names

  // Extract dates from the first country (assuming all countries have the same dates)
  const dates = getDatesBetween(dateRange.start, dateRange.end)

  // Format data for the top countries and their values on each date
  const countriesForDate: { [key: string]: number } = {}
  const formattedData = dates.map((date) => {
    topCountries.forEach((country) => {
      if (filteredData[country][date] !== undefined) {
        countriesForDate[country] = filteredData[country][date][valueType]
      }
    })
    return { date, ...countriesForDate }
  })

  return { countries: topCountries, data: formattedData }
}

function getValueKey(type: string) {
  const valueKey =
    type === 'cases' || type === 'suspected_cases'
      ? {
          curr: 'finalValueCurrentPeriod',
          prev: 'finalValuePreviousPeriod',
          suspected: 'finalValueCurrentPeriodSuspected',
        }
      : {
          curr: 'finalValuePer100KCurrentPeriod',
          prev: 'finalValuePer100KPreviousPeriod',
          suspected: 'finalValuePer100KCurrentPeriodSuspected',
        }
  return valueKey as {
    curr: keyof SummaryDataItemT
    prev: keyof SummaryDataItemT
    suspected: keyof SummaryDataItemT
  }
}

export function getTopCountries(
  data: SummaryDataT,
  dataType: string,
  country: string
) {
  const filteredData = data

  const valueKey = getValueKey(dataType)

  const sortedData = Object.keys(filteredData).sort((a, b) => {
    return data[b][valueKey.curr] - data[a][valueKey.curr]
  })

  const formattedData = sortedData
    .map((item) => {
      const dataItem = data[item]
      const areaName = item.split(' (')[0]
      const areaCode = item.split('(')[1].split(')')[0]
      if (!dataItem?.[valueKey?.curr]) return null
      return {
        label: item,
        country: areaCode,
        countryName: areaName,
        curr: dataItem[valueKey.curr],
        prev: dataItem[valueKey.prev],
        suspected: dataItem[valueKey.suspected],
      }
    })
    .filter((x) => !!x) as TopCountriesChartDataT

  return formattedData
}

const DATA_URL = '/lzdb/science-macro/time-series'

const COUNTRY_COORDS_URL =
  'https://raw.githubusercontent.com/eesur/country-codes-lat-long/refs/heads/master/country-codes-lat-long-alpha3.json'

export const getQueryString = (
  dataType: string,
  startDate: string,
  endDate: string,
  country: string
) => {
  const queryParams = `date_start=${startDate}&date_end=${endDate}`
  return {
    standard:
      `${DATA_URL}-${dataType}/?${queryParams}` +
      (country !== 'global' ? `&country_alpha_3=${country}` : ''),
    clade: `/lzdb/science-macro/clade-sequence-metadata/?view=outbreaks_mpox`,
  }
}

export function getDemoData(data: DataT[], country?: string) {
  const filteredData = data
  const groupedData = _.groupBy(filteredData, (item) =>
    country ? item.area : item.areaAlpha3
  )
  return groupedData
}

export function removeNullCases(data: DataT[]) {
  return data.filter((item) => +item.value !== 0)
}

export const fetchCountryPolygons = async () => {
  try {
    const [resPolygons, resCoord] = await Promise.all([
      fetch(GEO_POLYGONS.world),
      fetch(COUNTRY_COORDS_URL),
    ])
    if (!resPolygons.ok) {
      throw new Error(`HTTP error! status: ${resPolygons.status}`)
    }
    const json = await resPolygons.json()
    const coordsJson = await resCoord.json()
    const coords = coordsJson.ref_country_codes.reduce(
      (acc: any, curr: any) => {
        return {
          ...acc,
          [curr.alpha3]: curr,
        }
      },
      {}
    )

    const countries = {
      ...json,
      features: json.features.map((feature: any) => {
        const countryCoords = coords[feature.id]
        return {
          ...feature,
          properties: {
            ...feature.properties,
            id: feature.id,
            coords: countryCoords
              ? [countryCoords?.longitude, countryCoords?.latitude]
              : [],
          },
        }
      }),
    }

    return countries
  } catch (err) {
    if (err instanceof Error && err.name !== 'AbortError') {
      console.error('Could not load data', err)
    }
  }
}

const colorCache: { [key: string]: string } = {}
let colorIndex = 0

export const generateColorForKey = (mechanism: string, alpha = 0.7) => {
  if (colorCache[mechanism]) {
    return colorCache[mechanism]
  }

  const randomSeed = 0.618033988749895
  const hue = (colorIndex * randomSeed * 360) % 360

  const color = `hsla(${hue}, 65%, 60%, ${alpha})`
  colorCache[mechanism] = color
  colorIndex++

  return color
}

export const generateColorFromString = (code: string, alpha = 1) => {
  let hash = 0
  for (let i = 0; i < code.length; i++) {
    hash = code.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = 180 + (Math.abs(hash) % 360)
  const s = 45 + (hash % 15)
  const l = 75 + (hash % 10)

  return `hsla(${h}, ${s}%, ${l}%, ${alpha})`
}

export const formatNumber = (number: number) => {
  if (number === 0) return '0'

  const isNegative = number < 0
  const absNumber = Math.abs(number)
  const sign = isNegative ? '-' : ''

  if (absNumber >= 1000000) {
    return `${sign}${(absNumber / 1000000).toFixed(1)}M`
  }
  if (absNumber >= 1000) {
    return `${sign}${(absNumber / 1000).toFixed(1)}K`
  }
  return `${sign}${absNumber}`
}

export function downloadCSV(
  headers: string,
  sortedRows: string[],
  fileName: string
) {
  const blob = new Blob([`${headers}\n${sortedRows.join('\n')}`], {
    type: 'text/csv;charset=utf-8;',
  })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  URL.revokeObjectURL(link.href)
}
