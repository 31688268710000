import { LayerT } from './layers'
import { FeaturesT } from './utils/types'
import { Box, Button } from '@chakra-ui/react'
import html2canvas from 'html2canvas'
import _ from 'lodash'
import Legend from './Legend'
import MapContainer from './MapContainer'

const MapSection = ({
  features,
  selectedLayer,
  handleLegendClick,
  selectCountry,
  mapRef,
  width,
}: {
  features: FeaturesT
  selectedLayer: LayerT
  handleLegendClick: (newLayer: LayerT) => void
  selectCountry: (country: string) => void
  mapRef: any
  width?: string
}) => {
  const exportToImage = async () => {
    const style = document.createElement('style')
    document.head.appendChild(style)
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }'
    )
    style.sheet?.insertRule('#exportButtons { display: none !important; }')

    const pdfContent = document.getElementById('divToPrint') as HTMLElement
    const svgElements = document.body.querySelectorAll('svg')
    const originalDimensions = Array.from(svgElements).map((svg) => ({
      element: svg,
      width: svg.style.width,
      height: svg.style.height,
    }))

    svgElements.forEach(function (item) {
      item.setAttribute('width', String(item.getBoundingClientRect().width))
      item.setAttribute('height', String(item.getBoundingClientRect().height))
      item.style.width = ''
      item.style.height = ''
    })

    try {
      const canvas = await html2canvas(pdfContent, {
        scale: 2,
        logging: true,
      })
      const imgData = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = imgData
      link.download = `IDA Trials Map Export - ${new Date().toLocaleDateString()}.png`
      link.click()
    } finally {
      document.head.removeChild(style)
      originalDimensions.forEach(({ element, width, height }) => {
        element.style.width = width
        element.style.height = height
      })
    }
  }

  const exportData = () => {
    const headers = [
      'Country',
      'Country Code',
      'Date',
      'Cases',
      'Type',
      'Gender',
      'Race',
      'Age',
      'Is Suspected',
      'Source ID',
    ].join(',')
    const rows: string[] = []

    ;['confirmed', 'suspected', 'notSpecified'].forEach((type) => {
      features[type as keyof typeof features].features.forEach((f) => {
        f.properties.allCases.forEach((c) => {
          rows.push(
            [
              `"${c.area}"`,
              c.areaAlpha3,
              c.dateStart,
              c.value,
              _.upperFirst(type),
              c.gender,
              c.race,
              c.airfinityAgeText,
              c.isSuspected,
              c.source,
            ].join(',')
          )
        })
      })
    })

    const sortedRows = rows.sort((a, b) => {
      const areaA = a.split(',')[0].toLowerCase()
      const areaB = b.split(',')[0].toLowerCase()
      return areaA.localeCompare(areaB)
    })

    const blob = new Blob([`${headers}\n${sortedRows.join('\n')}`], {
      type: 'text/csv;charset=utf-8;',
    })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `Mpox_Cases_${new Date().toLocaleDateString()}.csv`
    link.click()
    URL.revokeObjectURL(link.href)
  }

  return (
    <Box
      id='divToPrint'
      border='1px solid'
      borderColor='gray3'
      w={width || '460px'}
      h='100%'
      borderRadius={'10px'}
      position={'relative'}
      overflow='hidden'
    >
      <Box
        pos='absolute'
        gap={2}
        display={'flex'}
        top={2}
        right={2}
        zIndex={10}
        id='exportButtons'
      >
        <Button
          size={'xs'}
          border={'1px'}
          borderColor={'gray.200'}
          h={8}
          variant={'solid'}
          borderRadius={'sm'}
          onClick={exportToImage}
        >
          Export image
        </Button>
        <Button
          size={'xs'}
          border={'1px'}
          borderColor={'gray.200'}
          h={8}
          variant={'solid'}
          borderRadius={'sm'}
          onClick={exportData}
        >
          Export data
        </Button>
      </Box>
      <Legend handleClick={handleLegendClick} selectedLayer={selectedLayer} />
      <MapContainer
        mapRef={mapRef}
        selectedLayer={selectedLayer}
        features={features}
        selectCountry={selectCountry}
      />
    </Box>
  )
}

export default MapSection
