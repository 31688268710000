export const handleCountrySelectOnCard = (
  handleCountrySelection: (e: any, value: string) => void,
  e: { activeLabel: string }
) => {
  const countryId = e?.activeLabel
  if (countryId) {
    const newFilters = [
      {
        column: 'area',
        filterValue: [{ label: countryId, value: countryId }],
        type: 'includesAll',
      },
    ]
    handleCountrySelection(newFilters, countryId)
  }
}
