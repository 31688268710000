import { Box, Text } from '@chakra-ui/react'
import moment from 'moment'

import { ItemComponentPropTypes } from 'modules/DetailDrawer/DetailDrawerList'

import { ApprovalsData } from 'config/common/baseModel/Approvals'

import { formatDate } from 'utils/formatDate'

export const DetailDrawerListApprovals = ({
  item,
  handleSelect,
}: ItemComponentPropTypes<Record<ApprovalsData, any>>) => {
  const {
    approvalDate,
    organisationsRegulators,
    approvalType,
    expeditedStatusDesignation,
    countryOrganisationGrantingDecision,
  } = item

  const approvedDate = moment(approvalDate)

  return (
    <Box
      px={8}
      py={4}
      cursor='pointer'
      borderBottom='1px solid'
      borderColor='gray.100'
      onClick={() => handleSelect(item)}
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        fontSize='xs'
        fontWeight={600}
        textTransform='uppercase'
        color='gray.700'
      >
        <Box mr={2}>
          {approvedDate?.isValid()
            ? `Approved on ${formatDate(approvedDate)}`
            : `Approval date unknown`}
        </Box>
      </Box>
      {organisationsRegulators && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Regulatory Body: <b>{organisationsRegulators}</b>
        </Text>
      )}
      {approvalType && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Type: <b>{approvalType}</b>
        </Text>
      )}
      {Array.isArray(expeditedStatusDesignation) &&
        expeditedStatusDesignation.length > 0 && (
          <Text fontSize='xs' fontWeight={400} color='gray.800'>
            Expedited Status Designation: <b>{expeditedStatusDesignation}</b>
          </Text>
        )}
      {countryOrganisationGrantingDecision && (
        <Text fontSize='xs' fontWeight={400} color='gray.800'>
          Country: <b>{countryOrganisationGrantingDecision}</b>
        </Text>
      )}
    </Box>
  )
}
