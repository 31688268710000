import { Flex } from '@chakra-ui/react'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import VaccineDonations from './Cards/Donations'
import VaccinationProcurement from './Cards/Procurement'
import VaccinesSupply from './Cards/Supply'

const LeftSection = ({ filters }: { filters: FiltersT }) => {
  return (
    <Flex
      h='full'
      pointerEvents={'auto'}
      justifyContent={'space-between'}
      flexDir={'column'}
      gap={'0.5rem'}
      w='350px'
      minW='350px'
      maxW='350px'
    >
      <Flex flexDir={'column'} h='100%' gap='0.5rem'>
        <Flex h='100%'>
          <VaccinationProcurement filters={filters} />
        </Flex>
        <Flex h='100%'>
          <VaccinesSupply filters={filters} />
        </Flex>
        <Flex h='100%'>
          <VaccineDonations filters={filters} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default LeftSection
