import { Information } from '@carbon/icons-react'
import { Box, Text } from '@chakra-ui/layout'
import { Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'

const InfoPopover = () => {
  const [isOpen, setIsPopoverOpen] = useState(false)
  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor='lightgrey'
          arrowSize={10}
          arrowStyle={{
            opacity: 0.7,
          }}
        >
          <Box
            bgColor='white'
            maxW={'550px'}
            marginTop='24px'
            borderRadius={15}
            p='24px'
            fontSize={'sm'}
            boxShadow='lg'
            border={'1px solid'}
            borderColor='gray.200'
          >
            Human outbreaks are categorised according to Airfinity’s Outbreak
            Risk index, which is calculated based on pathogen-specific factors,
            the availability of medical countermeasures, as well as a country’s
            level of preparedness.
          </Box>
        </ArrowContainer>
      )}
    >
      <Box
        onClick={() => setIsPopoverOpen((x) => !x)}
        width='fit-content'
        cursor='pointer'
        ml='1rem'
        display={'flex'}
      >
        <Icon m='0 5px 2px 0' boxSize='20px' as={Information} />
        <Text variant='body' textDecor={'underline'} mb='-6px' color='gray1'>
          Information
        </Text>
      </Box>
    </Popover>
  )
}

export default InfoPopover
