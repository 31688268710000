import { Box } from '@chakra-ui/react'
import styled from 'styled-components'

import { ResponseData } from 'api/types'

import { IModelField } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import formatValues from 'utils/formatValues'
import { relationGetDisplayValue } from 'utils/relational'
import unCamelize from 'utils/unCamelize'

import AttributeLabel from './AttributeLabel'

const Row = styled.div`
  border-bottom: 1px solid;
  padding: 1rem 0px;
  border-color: #eceef0;
  margin-inline: 1rem;

  &:last-child {
    border-bottom: none;
  }
`

type PropTypes = {
  fieldModel: IModelField<any>
  rowId: string | number
  collection: string
  value: ResponseData
}

const AttributeRow = ({ fieldModel, value }: PropTypes) => {
  const label = fieldModel.label || unCamelize(fieldModel.key)

  return (
    <Row>
      <Box display='flex' justifyContent='space-between'>
        <AttributeLabel label={label} mb={2} />
      </Box>
      <Box
        fontSize='sm'
        fontWeight={500}
        title={relationGetDisplayValue(value) as string}
        width='100%'
      >
        {formatValues({
          ...fieldModel.cellFormat,
          type: fieldModel.type ?? ValueType.TEXT,
          value,
          boxStyles: { flexWrap: 'wrap', whiteSpace: 'normal' },
          transformValue: fieldModel.transformValue,
          wrap: true,
        })}
      </Box>
    </Row>
  )
}

export default AttributeRow
