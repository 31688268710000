import { CheckmarkOutline, Warning } from '@carbon/icons-react'
import { Box, Icon, Spinner, Text } from '@chakra-ui/react'

import useUpSellReport from 'api/useUpSellReports'

import useTracking from 'tracking/useTracking'

const UpSellSection = ({
  details,
  title,
  table,
}: {
  details: string
  title: string
  table?: boolean
}) => {
  const [tracking] = useTracking()
  const {
    mutate: triggerUpSell,
    isLoading,
    isError,
    isSuccess,
  } = useUpSellReport({
    onSuccess: () => console.log('done'),
    onError: (e) => console.error('Error', e),
  })

  function handleUpSell() {
    if (isError || isLoading) return
    tracking.upSellReport({ report: title })
    triggerUpSell(details)
  }

  return (
    <Box>
      <Text
        color={isError ? 'red.500' : 'interactive'}
        variant={'body-bold'}
        onClick={handleUpSell}
        _hover={{ textDecor: 'underline', cursor: 'pointer' }}
      >
        {isLoading && (
          <Spinner size='sm' color='interactive' mr='1rem' mb='-1px' />
        )}
        {isSuccess && (
          <Icon
            mb='-4px'
            mr='1rem'
            boxSize='20px'
            as={CheckmarkOutline}
            color='interactive'
          />
        )}
        {isError && (
          <Icon
            mb='-4px'
            mr='1rem'
            boxSize='20px'
            as={Warning}
            color='red.500'
          />
        )}
        {isLoading
          ? 'Requesting Access...'
          : isSuccess
            ? 'Access Requested Successfully'
            : isError
              ? 'Something went wrong, try again later'
              : `Click here to request access to this ${
                  table ? 'table' : 'report'
                }`}
      </Text>{' '}
    </Box>
  )
}

export default UpSellSection
