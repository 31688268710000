import { ArrowLeft, Search } from '@carbon/icons-react'
import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import { NAV_HEIGHT, SIDEBAR_WIDTH } from 'constants/misc'
import { useLocation, Link } from 'react-router-dom'

import AppsSelectorModal from 'modules/AppsSelector/AppsSelectorModal'

import { useAppParams } from 'routes/utils'

import { useClientType } from 'contexts/ClientType'
import { useGlobalSearch } from 'contexts/GlobalSearch'

import { Logo } from './Logo'

import AppletSelector from './AppletSelector'
import RightMenu from './RightMenu'
import useTracking from 'tracking/useTracking'

type PropTypes = {
  showSearchBar?: boolean
  onBackButton?: () => void
}

const Navigation = ({ showSearchBar = true, onBackButton }: PropTypes) => {
  const appParams = useAppParams()
  const location = useLocation()
  const { isGov } = useClientType()

  const [tracking] = useTracking()

  const isHome = location.pathname === '/'

  const isDemo = appParams?.app?.includes('demo')

  const { overlayShown, reset, setOverlayShown } = useGlobalSearch()

  return (
    <Box
      height={NAV_HEIGHT}
      minHeight={NAV_HEIGHT}
      bg={isGov ? 'iqtBlack' : 'black'}
      borderBottom='4px solid'
      borderColor={isGov ? 'iqtBlue' : 'yellow.500'}
      color='white'
      display='flex'
      alignItems='center'
      position={'relative'}
    >
      <Box display='flex' alignItems='center'>
        <Box
          w={SIDEBAR_WIDTH}
          bg={isHome ? 'black' : 'white'}
          h={isHome ? `44px` : '45px'}
          borderRight='1px solid'
          borderColor={isHome ? 'transparent ' : 'gray.200'}
        >
          <Flex ml='40px' mt='8px' alignItems={'center'}>
            <Link
              onClick={() =>
                !isHome && !isGov && tracking.navigatedToHomePage({})
              }
              to='/'
            >
              <Box h={'33px'} w='100px'>
                <Logo color={isHome ? 'white' : 'black'} />
              </Box>
            </Link>
            {isGov && (
              <Text
                ml='8px'
                fontWeight={'800'}
                color={isHome ? 'yellow' : 'iqtBlue'}
              >
                Gov.
              </Text>
            )}
          </Flex>
        </Box>
        {onBackButton ? (
          <Box
            display='flex'
            alignItems='center'
            onClick={onBackButton}
            cursor='pointer'
            role='group'
          >
            <Box
              rounded='full'
              width='36px'
              height='36px'
              display='flex'
              mr={1}
              alignItems='center'
              transition='all .25s'
              justifyContent='center'
              _groupHover={{
                bg: 'legacy-primary.600',
              }}
            >
              <ArrowLeft size={20} />
            </Box>
            <Box textTransform='uppercase' fontWeight={600} fontSize='sm'>
              Back
            </Box>
          </Box>
        ) : !isHome ? (
          <Box minWidth={SIDEBAR_WIDTH}>
            <AppsSelectorModal />
          </Box>
        ) : null}
        <Box position={'absolute'} left='50%' transform={'translate(-50%,0)'}>
          {!isHome && !onBackButton && <AppletSelector appParams={appParams} />}
        </Box>
      </Box>

      <Box
        ml='auto'
        fontSize='sm'
        color='white'
        display='flex'
        alignItems='center'
        height='100%'
      >
        {!isHome && showSearchBar && !isDemo && (
          <IconButton
            icon={<Search size={20} />}
            aria-label='Search'
            data-cy='global-search-button'
            variant='ghost'
            color='white'
            _hover={{
              bg: 'none',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            _active={{
              bg: 'none',
            }}
            onClick={() => (!overlayShown ? setOverlayShown(true) : reset())}
          />
        )}
        <RightMenu />
      </Box>
    </Box>
  )
}

export default Navigation
