import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react'

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  TooltipProps,
  ReferenceLine,
} from 'recharts'

import { useState } from 'react'
import { scaleLog } from 'd3-scale'
import _ from 'lodash'

interface ChartDataPoint {
  date: string
  highRisk?: number
  total?: number
  totalForecast?: number
}

interface ZoomState {
  left: string | number
  right: string | number
  top: string | number
  bottom: string | number
}

interface SelectionState {
  refAreaLeft: string
  refAreaRight: string
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean
  payload?: Array<{
    value: number
    name: string
    color: string
  }>
  label?: string
}

const formatTooltipValue = (value: number | undefined): string => {
  return value
    ? value.toLocaleString('en', {
        maximumFractionDigits: 0,
      })
    : 'N/A'
}

const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
  })
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bg='white'
        rounded={'xl'}
        border='1px solid'
        borderColor={'gray2'}
        p='8px'
      >
        <p className='font-bold'>{label && formatDate(label)}</p>
        <Grid gridTemplateColumns={'1fr 1fr'}>
          {payload
            .sort((a, b) => b.value - a.value)
            .map((entry, index) => (
              <>
                <Text style={{ color: entry.color }} key={index}>
                  {_.startCase(entry.name)}:
                </Text>
                <Text
                  key={index + 1}
                  style={{ color: entry.color }}
                  fontWeight={'semibold'}
                  ml='4px'
                  textAlign={'end'}
                >
                  {formatTooltipValue(entry.value)}
                </Text>
              </>
            ))}
        </Grid>
      </Box>
    )
  }
  return null
}

function getIsDataEmpty(data: ChartDataPoint[]) {
  return !data.some((date) => {
    return Object.keys(date).length > 1
  })
}

const ZoomableLineChart = ({
  chartData,
  keys,
}: {
  chartData: {
    title: string
    data: ChartDataPoint[]
    refLines: { xValue: string; title: string; color: string }[]
  }
  keys: {
    [key: string]: {
      title: string
      type: 'data' | 'forecast'
      color: string
    }
  }
}) => {
  const [isLog, setIsLog] = useState(false)
  const [data] = useState<ChartDataPoint[]>(chartData.data)
  const [zoomState, setZoomState] = useState<ZoomState>({
    left: 'dataMin',
    right: 'dataMax',
    top: 'dataMax+1',
    bottom: 'dataMin-1',
  })
  const [selection, setSelection] = useState<SelectionState>({
    refAreaLeft: '',
    refAreaRight: '',
  })

  const scale = scaleLog().base(Math.E)

  const isDataEmpty = getIsDataEmpty(data)

  const handleZoom = (): void => {
    if (isLog) return
    const { refAreaLeft, refAreaRight } = selection

    if (!refAreaLeft || !refAreaRight || refAreaLeft === refAreaRight) {
      setSelection({ refAreaLeft: '', refAreaRight: '' })
      return
    }

    const [start, end] = [refAreaLeft, refAreaRight].sort()
    const dataPoints = data.filter((d) => d.date >= start && d.date <= end)
    const values = dataPoints.map((d) => {
      return Math.max(d.total || 0, d.totalForecast || 0)
    })
    const bottom = Math.min(...values)
    const top = Math.max(...values)

    setZoomState({
      left: start,
      right: end,
      top: top * 1.1,
      bottom: bottom * 0.9,
    })

    setSelection({ refAreaLeft: '', refAreaRight: '' })
  }

  const handleReset = (): void => {
    setZoomState({
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+1',
      bottom: 'dataMin-1',
    })
  }

  return (
    <Box
      w='full'
      userSelect={'none'}
      border='1px solid'
      borderColor='gray3'
      p='1rem'
      bg='white'
      rounded='lg'
    >
      <Text textAlign={'center'} fontSize='xl' fontWeight={'semibold'}>
        {chartData.title}
      </Text>
      {isDataEmpty ? (
        <Flex justifyContent={'center'} alignItems='center' h='100%'>
          No {chartData.title} data for this parameters
        </Flex>
      ) : (
        <>
          <Flex
            w='full'
            alignItems={'center'}
            justifyContent='flex-start'
            gap='1rem'
          >
            <Button variant={'outline'} onClick={() => setIsLog((x) => !x)}>
              {isLog ? 'Linear Scale' : 'Log Scale'}
            </Button>
            {!isLog && (
              <Button variant={'outline'} onClick={handleReset}>
                Reset Zoom
              </Button>
            )}
          </Flex>
          <ResponsiveContainer width='100%' height={400}>
            <LineChart
              data={data}
              onMouseDown={(e) =>
                e?.activeLabel &&
                setSelection((prev: any) => ({
                  ...prev,
                  refAreaLeft: e.activeLabel,
                }))
              }
              onMouseMove={(e) =>
                selection.refAreaLeft &&
                e?.activeLabel &&
                setSelection((prev: any) => ({
                  ...prev,
                  refAreaRight: e.activeLabel,
                }))
              }
              onMouseUp={handleZoom}
            >
              {chartData.refLines.map((refLine, index) => (
                <ReferenceLine
                  key={refLine.title + index}
                  x={refLine.xValue}
                  stroke={refLine.color}
                  strokeWidth={2}
                  strokeDasharray='3 3'
                />
              ))}
              <XAxis
                fontSize={'10px'}
                allowDataOverflow
                dataKey='date'
                domain={[zoomState.left, zoomState.right]}
                tickFormatter={formatDate}
                type='category'
              />
              <YAxis
                interval={0}
                fontSize={'10px'}
                allowDataOverflow
                tickFormatter={(value: number) => value.toFixed(0)}
                domain={
                  isLog ? ['auto', 'auto'] : [zoomState.bottom, zoomState.top]
                }
                scale={isLog ? scale : 'linear'}
              />

              <Tooltip content={<CustomTooltip />} />

              {Object.keys(keys).map((dataKey, index) => {
                const { type, color } = keys[dataKey]
                return (
                  <Line
                    key={dataKey + index}
                    type='monotone'
                    strokeDasharray={type === 'forecast' ? '4 2' : ''}
                    dataKey={dataKey}
                    stroke={color}
                    strokeWidth={2}
                    dot={false}
                    name={dataKey}
                    connectNulls
                  />
                )
              })}

              {selection.refAreaLeft && selection.refAreaRight && (
                <ReferenceArea
                  x1={selection.refAreaLeft}
                  x2={selection.refAreaRight}
                  fill='#2563eb'
                  fillOpacity={0.2}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
      <Grid fontSize={'xs'} templateColumns={'1fr 1fr'} color='gray.800'>
        <Flex alignItems={'center'} gap='1rem'>
          <Box
            w='40px'
            h='2px'
            style={{
              backgroundImage:
                'linear-gradient(to right, #6e6e6e 50%, rgba(255, 255, 255, 0) 0%)',
              backgroundPosition: 'top',
              backgroundSize: '10px 2px',
              backgroundRepeat: 'repeat-x',
            }}
          />
          Predicted Values
        </Flex>
        <Flex alignItems={'center'} gap='1rem'>
          <Box w='35px' h='1px' border='1px solid' ml='5px' />
          Baseline Values
        </Flex>
        {chartData.refLines.map((refLine, index) => (
          <Flex alignItems={'center'} gap='1rem' key={index}>
            <Box
              w='40px'
              h='2px'
              style={{
                backgroundImage: `linear-gradient(to right, ${refLine.color} 50%, rgba(255, 255, 255, 0) 0%)`,
                backgroundPosition: 'top',
                backgroundSize: '10px 2px',
                backgroundRepeat: 'repeat-x',
              }}
            />
            {refLine.title}
          </Flex>
        ))}
      </Grid>
    </Box>
  )
}

export default ZoomableLineChart
