import {
  generateColorForKey,
  generateColorFromString,
} from 'modules/BioriskMpox/Epidemiology/helpers'
import { LoadingAnimation } from 'components'
import {
  ResponsiveContainer,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  Cell,
} from 'recharts'

import { Box, Flex, Text } from '@chakra-ui/react'
import countryCodeToNameMapping from 'api/data/countryCodeToNameMapping'
import _ from 'lodash'

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload
    const country = data.country
    const countryCode = _.findKey(
      countryCodeToNameMapping,
      _.partial(_.isEqual, country)
    ) as keyof typeof countryCodeToNameMapping

    const countryName = countryCodeToNameMapping[countryCode] || country

    return (
      <Box
        p={2}
        borderWidth='1px'
        borderRadius='md'
        bg='white'
        shadow='md'
        zIndex='999999'
      >
        <Flex direction='column' gap={1}>
          <Flex gap='4px' fontSize={'sm'}>
            <Text fontWeight={'semibold'}>{countryName}</Text>
          </Flex>
          <Flex direction='column'>
            {Object.entries(data)
              .filter(([key]) => key !== 'country')
              .sort((a: any, b: any) => b[1] - a[1])
              .map(([mechanism, value]) => (
                <Flex gap='8px' key={mechanism} fontSize='sm'>
                  <Box
                    mt={1.5}
                    backgroundColor={generateColorForKey(mechanism)}
                    borderRadius='sm'
                    w='10px'
                    h='10px'
                  />
                  <Text color='gray.700' maxW={'180px'} isTruncated>
                    {mechanism}:{' '}
                  </Text>
                  <Text fontWeight={'semibold'}>
                    {Number(value).toFixed(3)}M
                  </Text>
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Box>
    )
  }

  return null
}

const HorizontalBarChart = ({
  chartData,
  handleClick,
  error,
  isLoading,
  barKeys,
  expanded,
  isStacked = false,
}: {
  chartData: any
  handleClick: (e: any) => void
  error: any
  isLoading: boolean
  barKeys: string[]
  expanded?: boolean
  isStacked?: boolean
}) => {
  if (error?.message) {
    return (
      <Flex justifyContent={'center'} alignItems='center' h='100%'>
        <Text color='error'>Something went wrong loading the data</Text>
      </Flex>
    )
  }

  if (!chartData || (chartData?.length === 0 && isLoading)) {
    return (
      <Flex
        h={expanded ? '100%' : '148px'}
        justifyContent={'center'}
        alignItems='center'
      >
        <LoadingAnimation />
      </Flex>
    )
  }

  if (chartData?.length === 0) {
    return (
      <Flex
        h={expanded ? '100%' : '148px'}
        justifyContent={'center'}
        alignItems='center'
      >
        <Text color='gray.500'>No data available</Text>
      </Flex>
    )
  }

  const truncateText = (text: string) => {
    if (text.length > 20) return `${text.substring(0, 13)}`
    return text
  }

  return (
    <Box w={'100%'} h={expanded ? '100%' : '100%'} pb='1rem'>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          data={chartData}
          layout='vertical'
          margin={{
            top: 10,
            right: 10,
            left: expanded ? 10 : 0,
            bottom: -30,
          }}
          onClick={handleClick}
        >
          <Tooltip content={<CustomTooltip />} />

          <YAxis
            dataKey='country'
            type='category'
            fontSize={expanded ? '12px' : '8px'}
            interval={0}
            tickSize={2}
            width={40}
            tickFormatter={truncateText} // Apply custom formatter
          />
          <XAxis type='number' unit='M' fontSize={expanded ? '12px' : '10px'} />
          {!isStacked
            ? barKeys.map((barKey) => (
                <Bar dataKey={barKey} stackId='a' key={barKey}>
                  {chartData.map((entry: any, index: number) => {
                    const countryCode = _.findKey(
                      countryCodeToNameMapping,
                      _.partial(_.isEqual, entry.country)
                    )
                    const color = generateColorFromString(countryCode || '')
                    return <Cell key={`cell-${index}`} fill={color} />
                  })}
                </Bar>
              ))
            : barKeys.map((barKey, barIndex) => (
                <Bar
                  key={barKey}
                  dataKey={barKey}
                  stackId='a'
                  fill={generateColorForKey(barKey)}
                />
              ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default HorizontalBarChart
