import PandemicDashboard from 'modules/Pandemic'
import AlertsSection from 'modules/Pandemic/Alerts'
import OutbreakOverviewDashboards from 'modules/Pandemic/OutbreakOverview'

import SpikeMap from 'routes/apps/biorisk/SpikeMap'
import FullReportPage from 'routes/common/FullReportPage'
import NewsletterPage from 'routes/common/FullReportPage/components/NewsletterPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'
import AiSearch from 'routes/common/ai/ai-search'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { BioriskSlugs } from './BioriskMenu'
import SingleReportByID from 'routes/common/SingleReportByID'
import BioriskMpoxTabs from 'modules/BioriskMpox'

const Reports: ISingleCustomPage<BioriskSlugs> = {
  path: ['reports'],
  component: () => <FullReportPage showCategory={false} />,
}

const ResearchBriefs: ISingleCustomPage<BioriskSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const MainDashboard: ISingleCustomPage<BioriskSlugs> = {
  path: [''],
  component: () => <PandemicDashboard />,
}

const OutbreakTracker: ISingleCustomPage<BioriskSlugs> = {
  path: ['outbreak-overview'],
  component: OutbreakOverviewDashboards,
}
const Alerts: ISingleCustomPage<BioriskSlugs> = {
  path: ['alerts'],
  component: () => <AlertsSection fullPage />,
}
const DailyBriefing: ISingleCustomPage<BioriskSlugs> = {
  path: ['daily-briefing'],
  component: () => (
    <NewsletterPage
      endpoint='/lzdb/science-macro/newsletters/daily/'
      summaryRowIndex={5}
      title='Biorisk Daily Briefing'
    />
  ),
}

const BioriskSpikeMap: ISingleCustomPage<BioriskSlugs> = {
  path: ['global-disease-resurgence'],
  component: () => <SpikeMap />,
}

const AvianInfluenza: ISingleCustomPage<BioriskSlugs> = {
  path: ['current-outbreaks', 'avian-influenza'],
  component: () => (
    <SingleReportByID title='Avian Influenza Report' id='1471' />
  ),
}

const Mpox: ISingleCustomPage<BioriskSlugs> = {
  path: ['current-outbreaks', 'mpox'],
  component: BioriskMpoxTabs,
}

const AiDashboard: ISingleCustomPage<BioriskSlugs> = {
  path: 'ai',
  component: () => <AiSearch />,
}

export const BioriskCustomPages: Record<
  string,
  ISingleCustomPage<BioriskSlugs>
> = {
  Alerts,
  Reports,
  MainDashboard,
  OutbreakTracker,
  ResearchBriefs,
  AiDashboard,
  BioriskSpikeMap,
  DailyBriefing,
  AvianInfluenza,
  Mpox,
}
