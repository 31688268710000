import { Box, Flex } from '@chakra-ui/react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import useAxios from 'api/useAxios'
import { createContext, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { recursiveCamelCase } from 'api/helper'
import MainFilterSection from '../Epidemiology/Filters'
import LeftSection from './LeftSection'
import MpoxApprovalsTable from './MpoxApprovalsTable'
import { getQueryData } from './LeftSection/Cards/Procurement'
import VaccinesPricing from './LeftSection/Cards/Pricing'
import RightSection from './RightSection'
import MapSection from './MapSection'
import { AxiosInstance } from 'axios'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import WarningPopover from 'components/PageHeader/WarningPopover'
import { Information } from '@carbon/icons-react'
import UpsellBanner from '../common/UpsellBanner'
import { SingleAdvancedFilter } from 'components/Table/AdvancedSearch/useAdvancedFilters'

interface FiltersT {
  dataType: string
  dateRange: {
    start: string
    end: string
  }
  country: string
  nCountries: number
}

const dataLimitationsMessage = `Methodology and Limitations: The data is based on 
publicly available information regarding mpox vaccine and treatment deals and pricing from 2022. 
For more detailed information about the deals, including those where the quantity of doses is not 
disclosed, please refer to the complete dataset available for download. Deal data is categorised 
into three sections: 1. Procurement: Represents who purchases vaccines and treatments, 
either a country (self-procurement) or an organisation (pool procurement, e.g., EU, PAHO). 2. 
Current Supply: Represents where vaccines and treatments are being delivered. 
This includes vaccines or treatments that recipient countries are expecting from self-procurement, 
vaccines received through pool procurement, and vaccines received as donations. 
3. Donation Pledges: Represents the total amount of vaccines or treatments that countries have 
announced they will donate. `

const DefaultFilters = {
  dataType: 'vaccinations',
  dateRange: {
    start: moment().subtract(10, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  },
  country: 'global',
  nCountries: 5,
}

export const DataTypes = {
  procurement: {
    valueKey: 'recipients',
    endpoint: '/lzdb/science-macro/deal/?view=outbreak_procurement_mpox&',
    title: 'Total Candidates Procured',
    popoverTitle: 'Candidates Procured',
  },
  supply: {
    valueKey: 'recipients',
    endpoint: 'lzdb/science-macro/deal/?view=outbreak_supply_mpox&',
    title: 'Total Candidates Supply',
    popoverTitle: 'Candidates Supply',
  },
  donations: {
    valueKey: 'donorAreas',
    endpoint: '/lzdb/science-macro/deal/?view=outbreak_pledges_mpox&',
    title: 'Total Candidates Donated',
    popoverTitle: 'Candidates Donated',
  },
}
export type DataTypesT = keyof typeof DataTypes
export type DataT = {
  [key in DataTypesT]: UseQueryResult<any, unknown>
} & { handleCountrySelection: (e: any, value: string) => void }

function getFilterValue(filter: SingleAdvancedFilter<any, any> | undefined) {
  if (!filter) return null
  return filter?.filterValue?.[0].value
}
function findFilter(
  filters: SingleAdvancedFilter<any, any>[],
  filterColumn: string
) {
  const filter = filters.find((filter) => filter.column === filterColumn)
  return filter
}

type SimpleDataT = {
  [key in DataTypesT]: UseQueryResult<any, unknown>
}

function filterSingleDataPoints(
  data: any[],
  item: string,
  filterValue: string
) {
  const _filteredData = data?.filter((x: any) =>
    filterValue ? x?.[item]?.includes(filterValue) : true
  )
  return _filteredData
}

function filterData(
  data: SimpleDataT,
  tableFilters: SingleAdvancedFilter<any, any>[]
) {
  const filteredData = Object.keys(data).reduce((acc, item) => {
    const _data = data[item as DataTypesT].data
    const candidateFilter = getFilterValue(
      findFilter(tableFilters, 'candidateName')
    )
    const typeFilter = getFilterValue(findFilter(tableFilters, 'designationA'))
    const candidatesFiltered = filterSingleDataPoints(
      _data,
      'candidates',
      candidateFilter
    )
    const typesFiltered = filterSingleDataPoints(
      candidatesFiltered,
      'designationA',
      typeFilter
    )

    return {
      ...acc,
      [item]: { data: typesFiltered },
    }
  }, {}) as SimpleDataT
  return filteredData
}

export const DataContext = createContext<DataT>({
  procurement: {} as any,
  supply: {} as any,
  donations: {} as any,
  handleCountrySelection: () => {},
})

async function getDataLogic(
  axios: AxiosInstance,
  filters: FiltersT,
  endpoint: string
) {
  const response = await axios.get(getQueryData(filters, endpoint))
  return recursiveCamelCase(response.data)
}

const useData = (filters: FiltersT) => {
  const axios = useAxios()
  const procurement = useQuery(
    ['vaccines', 'procurement', filters.dateRange],
    async () => getDataLogic(axios, filters, DataTypes.procurement.endpoint)
  )
  const supply = useQuery(['vaccines', 'supply', filters.dateRange], async () =>
    getDataLogic(axios, filters, DataTypes.supply.endpoint)
  )
  const donations = useQuery(
    ['vaccines', 'donations', filters.dateRange],
    async () => getDataLogic(axios, filters, DataTypes.donations.endpoint)
  )
  return { procurement, supply, donations }
}

const Response = () => {
  const [dataType, setDataType] = useState<DataTypesT>('supply')
  const [filters, setFilters] = useState<FiltersT>(DefaultFilters)
  const countryList = useRef<string[]>([])

  const { addToFilters, tableFilters } = useTableChartFilters()

  const _data = useData(filters)
  const data = filterData(_data, tableFilters)

  function selectCountry(countryId: string) {
    if (filters.country !== 'global') {
      setFilters((fil) => ({ ...fil, country: 'global' }))
    } else {
      if (countryId === filters.country) {
        setFilters((fil) => ({ ...fil, country: 'global' }))
      } else if (countryList.current?.includes(countryId)) {
        setFilters((fil) => ({ ...fil, country: countryId }))
      } else {
        setFilters((fil) => ({ ...fil, country: 'global' }))
      }
    }
  }

  function handleSelect({ value }: { value: DataTypesT }) {
    setDataType(value)
  }

  const handleCountrySelection = (_filters: any, countryId: string) => {
    selectCountry(countryId)
    if (countryId === filters.country) {
      const newFilters = tableFilters.filter((f) => f.column !== 'area')
      addToFilters(newFilters)
    } else {
      addToFilters(_filters)
    }
  }

  useEffect(() => {
    if (data) {
      const countries = Object.keys(data)
        .flatMap((item) => {
          return data[item as DataTypesT]?.data?.flatMap((item: any) => [
            ...item?.recipients,
            ...item?.donorAreas,
          ])
        })
        .filter((x) => !!x)
      if (countries.length > 0) {
        const uniqueCountries = [...new Set(countries)]
        countryList.current = uniqueCountries
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.procurement.data, data.donations.data, data.supply.data])

  return (
    <DataContext.Provider value={{ ...data, handleCountrySelection }}>
      <Flex flexDir={'column'} gap='1rem'>
        <Flex alignItems={'center'} gap='1rem'>
          <Flex alignItems={'center'} minWidth={'150px'} maxW='150px'>
            <Box mr={-3}>
              <Information />
            </Box>
            <WarningPopover message={dataLimitationsMessage} />
          </Flex>
          <MainFilterSection
            isSingleType={'Procurement, Supply and Donations'}
            countries={countryList.current}
            handleFilters={setFilters}
            handleCountry={selectCountry}
            filters={filters}
          />
        </Flex>
        <Flex height='100%' background='white' flex={1} position={'relative'}>
          <Flex w='100%' h='70vh' minH='100%' gap={2}>
            <LeftSection filters={filters} />
            <MapSection
              dataType={dataType}
              handleSelect={handleSelect}
              filters={filters}
            />
            <RightSection dataType={dataType} filters={filters} />
          </Flex>
        </Flex>
        <Flex gap='1rem' w='100%' maxH='550px' position={'relative'}>
          <Box maxW='66%'>
            <MpoxApprovalsTable />
          </Box>
          <Box w='100%'>
            <VaccinesPricing filters={filters} />
          </Box>
        </Flex>
      </Flex>
      <UpsellBanner />
    </DataContext.Provider>
  )
}

export default Response
