import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { FilterType } from '../utils'
import { useEffect, useState } from 'react'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import useKeyPress from 'utils/useKeyPress'
import styled from 'styled-components'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import './styles.css'
import { Select } from 'components/Select'

const HiddenInput = styled.div`
  width: 150px;
  button {
    outline: none;
  }
  .SingleDatePickerInput {
    .DateInput {
      display: none;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
  .DateRangePickerInput {
    .DateInput {
      display: none;
    }
    .DateRangePickerInput_calendarIcon {
      padding: 0;
    }
    background: none;
    outline: none;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
`

const DatePickerTrigger = () => {
  return (
    <Flex
      className='dateRange'
      border={'1px'}
      borderColor={'gray.200'}
      h={8}
      minW='120px'
      position={'relative'}
      px={2}
      alignItems={'center'}
      bg='gray.100'
      py={1}
      borderRadius={'sm'}
    >
      <Text fontSize={'xs'} color={'gray.800'} fontWeight={'semibold'}>
        Filter by date range
      </Text>
    </Flex>
  )
}

const DateHeader = () => {
  const { addToFilters, tableFilters } = useTableChartFilters()

  const [startDate, setStartDate] = useState<Date | null>(
    tableFilters.find(
      (filter) =>
        filter.column === 'enroledAt' &&
        filter.type === 'isLessThanOrEqualToDate'
    )?.filterValue ?? null
  )
  const [endDate, setEndDate] = useState<Date | null>(
    tableFilters.find(
      (filter) =>
        filter.column === 'enroledAt' &&
        filter.type === 'isGreaterThanOrEqualToDate'
    )?.filterValue ?? null
  )
  const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null)

  const handleDateChange = (startDate: Date, endDate: Date) => {
    const tempFilters: FilterType[] = [...tableFilters]

    const newDateObj = [
      {
        column: 'enroledAt',
        filterValue: startDate,
        type: 'isGreaterThanOrEqualToDate',
      },
      {
        column: 'enroledAt',
        filterValue: endDate,
        type: 'isLessThanOrEqualToDate',
      },
    ]

    const columnExists = tempFilters.some((f) => f.column === 'enroledAt')
    if (columnExists) {
      addToFilters([
        ...tempFilters.filter((f) => f.column !== 'enroledAt'),
        ...newDateObj,
      ])
    } else {
      tempFilters.push(...newDateObj)
      addToFilters(tempFilters)
    }
  }

  useKeyPress('Escape', () => {
    if (focused) setFocused(null)
  })

  useEffect(() => {
    setStartDate(
      tableFilters.find(
        (filter) =>
          filter.column === 'enroledAt' &&
          filter.type === 'isGreaterThanOrEqualToDate'
      )?.filterValue
    )
    setEndDate(
      tableFilters.find(
        (filter) =>
          filter.column === 'enroledAt' &&
          filter.type === 'isLessThanOrEqualToDate'
      )?.filterValue
    )
  }, [tableFilters])

  return (
    <Flex>
      <Box style={{ zIndex: 99 }}>
        <HiddenInput>
          <DateRangePicker
            hideKeyboardShortcutsPanel={true}
            startDateId='startDatePicker'
            endDateId='endDatePicker'
            withPortal
            renderMonthElement={(props) => <MonthYearSelect {...props} />}
            inputIconPosition='before'
            anchorDirection='right'
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate?.toDate() ?? new Date())
              if (endDate) {
                setEndDate(endDate?.toDate() ?? new Date())
                handleDateChange(
                  startDate?.toDate() ?? new Date(),
                  endDate.toDate()
                )
              }
            }}
            renderCalendarInfo={() => (
              <RenderPresets
                onPresetClick={({ startDate, endDate }) => {
                  setStartDate(startDate?.toDate() ?? new Date())
                  if (endDate) {
                    setEndDate(endDate?.toDate() ?? new Date())
                    handleDateChange(
                      startDate?.toDate() ?? new Date(),
                      endDate.toDate()
                    )
                  }
                }}
              />
            )}
            onFocusChange={(focused) => {
              setFocused(focused)
            }}
            startDate={startDate ? moment(startDate) : null}
            endDate={endDate ? moment(endDate) : null}
            customInputIcon={<DatePickerTrigger />}
            focusedInput={focused}
          />
        </HiddenInput>
      </Box>
    </Flex>
  )
}

export const MonthYearSelect = ({
  month,
  onMonthSelect,
  onYearSelect,
}: {
  month: moment.Moment
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
}) => {
  return (
    <Box
      px={4}
      style={{
        display: 'flex',
        justifyContent: 'center',
        zIndex: 850,
      }}
      gap={2}
    >
      <Select
        menuHeight='200px'
        backgroundColor='white'
        value={{
          label: month.format('MMMM'),
          value: month.month(),
        }}
        onChange={(e: any) => {
          onMonthSelect(month, e.value)
        }}
        options={moment.months().map((label, value) => {
          return {
            label,
            value,
          }
        })}
      />
      <Select
        menuHeight='200px'
        backgroundColor='white'
        value={{
          label: month.format('YYYY'),
          value: month.year(),
        }}
        onChange={(e: any) => {
          onYearSelect(month, e.value)
        }}
        options={Array.from(
          { length: moment().year() - 1992 + 1 },
          (v, i) => 1992 + i
        )
          .sort((a, b) => b - a)
          .map((label, value) => {
            return {
              label,
              value: label,
            }
          })}
      />
    </Box>
  )
}

export const RenderPresets = ({
  onPresetClick,
}: {
  onPresetClick: ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => void
}) => {
  const presets = [
    {
      text: 'Last 3 weeks',
      start: moment().subtract(3, 'weeks').startOf('day'), // 3 weeks ago
      end: moment().endOf('day'),
    },
    {
      text: 'Last 3 months',
      start: moment().subtract(3, 'months').startOf('day'), // 3 months ago
      end: moment().endOf('day'),
    },
    {
      text: 'Last 3 years',
      start: moment().subtract(3, 'years').startOf('day'), // 3 years ago
      end: moment().endOf('day'),
    },
    {
      text: 'Year to date',
      start: moment().startOf('year'), // beginning of this year
      end: moment().endOf('day'),
    },
  ]

  return (
    <Flex gap={2} mb={4} ml={4}>
      {presets.map(({ text, start, end }) => {
        return (
          <Button
            size={'xs'}
            h={8}
            variant={'solid'}
            key={text}
            onClick={() => onPresetClick({ startDate: start, endDate: end })}
          >
            {text}
          </Button>
        )
      })}
    </Flex>
  )
}

export default DateHeader
