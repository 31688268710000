import { Box, Flex } from '@chakra-ui/react'
import { createRef, useEffect, useRef, useState } from 'react'
import { Document } from 'react-pdf'
import { useAppParams } from 'routes/utils'
import useTracking from 'tracking/useTracking'
import TextTooltip from '../TextTooltip'
import {
  PdfLoadingPlaceholder,
  PdfPageComponent,
  PdfViewerProps,
  SharedReportTracking,
  TrackingEventProps,
  usePageVisibilityTracking,
  usePdfWidth,
  useTextSelection,
  useZoom,
  ZoomControls,
} from './helpers'
import useIsMobile from 'utils/useIsMobile'

export const ShareReportViewer = ({
  pdfUrl,
  pdfId,
  pdfTitle,
  sharedPages,
}: {
  pdfId: string
  pdfUrl: string
  pdfTitle: string
  sharedPages: number[]
}) => {
  const [numPages, setNumPages] = useState<number>()
  const pageRefs = useRef<any[]>([])

  //TODO: add tracking

  const hasSharedPages = sharedPages.length > 0

  // Base tracking props used in multiple places
  const baseTrackingProps: SharedReportTracking = {
    reportId: pdfId,
    report: pdfTitle,
    fullReportShared: !hasSharedPages,
    pageNumbers: sharedPages,
  }

  const pdfContainerRef = useRef<HTMLDivElement>(null)
  const pageWidth = usePdfWidth(pdfContainerRef, true)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
    pageRefs.current = Array(numPages)
      .fill(null)
      .map(() => createRef())
  }

  const handleClick = ({
    pageNumber,
  }: {
    pageNumber: number
    noTracking?: boolean
  }) => {
    const targetRef = pageRefs.current[pageNumber - 1]
    if (targetRef && !hasSharedPages) {
      targetRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const onDocumentLoadError = (error: Error): void => {
    console.error('Error loading PDF:', error)
  }

  const displayPages = hasSharedPages
    ? sharedPages
    : new Array(numPages).fill(1).map((_, i) => i)

  return (
    <Box ref={pdfContainerRef}>
      <Document
        className='pdf-document'
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        onItemClick={handleClick}
        externalLinkTarget='_blank'
        externalLinkRel='noopener noreferrer'
        loading={() => (
          <PdfLoadingPlaceholder width={pageWidth} height={pageWidth / 1.77} />
        )}
      >
        <Flex flexDir={'column'}>
          {displayPages.map((pageNumber) => (
            <PdfPageComponent
              key={pageNumber}
              pageNumber={+pageNumber + 1}
              width={pageWidth}
              scale={1}
              pageRef={(el: any) => (pageRefs.current[pageNumber] = el)}
            />
          ))}
        </Flex>
      </Document>
    </Box>
  )
}

const PdfComponent = ({
  reportUrl,
  nPagesCB,
  reportId = '',
  reportTitle = '',
  reportType = '',
  simpleView,
  hideAI,
}: PdfViewerProps) => {
  const [isMobile] = useIsMobile()
  const [numPages, setNumPages] = useState<number>()
  const [selectedText, setSelectedText] = useState('')
  const currentPage = useRef<number>(1)

  const pageRefs = useRef<any[]>([])
  const pdfContainerRef = useRef<HTMLDivElement>(null)

  const [tracking] = useTracking()
  const { app, pageSlug } = useAppParams()

  // Use custom hooks
  const { scale, handleZoom } = useZoom()

  function scrollToPage() {
    const targetRef = pageRefs.current[currentPage.current - 1]
    if (targetRef) {
      targetRef.scrollIntoView()
    }
  }

  useEffect(() => {
    setTimeout(() => scrollToPage(), 0)
  }, [hideAI])

  const pageWidth = usePdfWidth(pdfContainerRef, hideAI, isMobile)

  // Base tracking props used in multiple places
  const baseTrackingProps: TrackingEventProps = {
    app,
    page: pageSlug,
    reportId,
    report: reportTitle,
    type: reportType,
  }

  function currentPageCB(pageNumber: number) {
    currentPage.current = pageNumber
  }

  function savePageLocation() {
    sessionStorage.setItem(
      'report_scroll_position_' + reportId,
      currentPage.current.toString()
    )
  }

  // Text selection handler
  const { popoverStyle, handleMouseUp } = useTextSelection(
    pdfContainerRef,
    setSelectedText,
    tracking.selectedTextInPdf,
    baseTrackingProps,
    savePageLocation
  )

  // Setup page visibility tracking
  usePageVisibilityTracking(
    numPages,
    pageRefs,
    tracking.scrolledToPdfPage,
    baseTrackingProps,
    currentPageCB
  )

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
    nPagesCB && nPagesCB(numPages)
    pageRefs.current = Array(numPages)
      .fill(null)
      .map(() => createRef())
  }

  const onDocumentLoadError = (error: Error): void => {
    console.error('Error loading PDF:', error)
  }

  function pageRenderCB(pageNumber: number) {
    const savedPage = +(
      sessionStorage.getItem('report_scroll_position_' + reportId) || 0
    )
    if (pageNumber === savedPage) {
      handleClick({ pageNumber: savedPage, noTracking: true })
    }
  }

  const handleClick = ({
    pageNumber,
    noTracking,
  }: {
    pageNumber: number
    noTracking?: boolean
  }) => {
    !noTracking && tracking.clickedOnPdfIndex(baseTrackingProps)
    const targetRef = pageRefs.current[pageNumber - 1]
    if (targetRef) {
      targetRef.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <Flex ref={pdfContainerRef} position='relative' h='100%' zIndex={100}>
      <Flex overflow='auto' width='100%' flexDir={'column'} h='100%'>
        <ZoomControls
          onZoomIn={() => handleZoom(true)}
          onZoomOut={() => handleZoom(false)}
          scale={scale}
        />
        <Box>
          <Document
            onMouseUp={handleMouseUp}
            className='pdf-document'
            file={reportUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            onItemClick={handleClick}
            externalLinkTarget='_blank'
            externalLinkRel='noopener noreferrer'
            loading={() => (
              <PdfLoadingPlaceholder
                width={pageWidth}
                height={pageWidth / 1.77}
              />
            )}
          >
            <Flex flexDir={'column'}>
              {Array(numPages)
                .fill(1)
                .map((_, index) => (
                  <PdfPageComponent
                    key={index}
                    pageNumber={index + 1}
                    width={pageWidth}
                    scale={scale}
                    pageRef={(el: any) => (pageRefs.current[index] = el)}
                    pageRenderCB={pageRenderCB}
                  />
                ))}
            </Flex>
          </Document>
        </Box>
      </Flex>
      {!simpleView && (
        <TextTooltip
          app={app}
          selectedText={selectedText}
          popoverStyle={popoverStyle}
        />
      )}
    </Flex>
  )
}

export default PdfComponent
