import { Box, Flex, Text } from '@chakra-ui/react'
import { ExtractModelDataUnion } from 'interfaces/model.interface'
import { ClinicalTrialsModel } from 'config/common/baseModel'
import { DashboardConfigProps } from 'interfaces/navigationPage.interface'
import { Panel } from 'components/Panel'
import KeyPieCharts from './Cards/KeyPieCharts'
import TrialsByYearPieChart from './Cards/TrialsByYearPieChart'
import TrialsLineChart from './Cards/TrialsLineChart'
import TrialsProgressChart from './Cards/TrialsProgressChart'
import { useState } from 'react'
import useIsMobile from 'utils/useIsMobile'
import Header from './Header'
import DashboardTour from './DashboardTour'
import { createPortal } from 'react-dom'
import MapHeatmap from '../MapHeatmap'

export interface InputObject {
  [key: string]: any
}

export type ClinicalTrialsColumnKeys = ExtractModelDataUnion<
  typeof ClinicalTrialsModel
>

export default function IDATrialsMapDashboard({
  chartData,
  loaded,
}: DashboardConfigProps) {
  const [isMobile] = useIsMobile()

  const [stepIndex, setStepIndex] = useState<number>(0)
  const [stepsEnabled, setStepsEnabled] = useState(
    localStorage.getItem('trialsDashboardTourCompleted') !== 'true' &&
      !isMobile &&
      window.location.hostname !== 'localhost'
  )

  const hasData = chartData && chartData.length > 0

  return (
    <>
      <DashboardTour
        stepIndex={stepIndex}
        stepsEnabled={stepsEnabled}
        setStepIndex={setStepIndex}
        setStepsEnabled={setStepsEnabled}
      />
      <Box height='100%' id='divToPrint' display={'flex'}>
        <Panel
          height='100%'
          width='65%'
          border='1px solid'
          borderColor='gray3'
          background='white'
          borderRadius='8px'
          flex={1}
          p={2}
          position={'relative'}
        >
          {document.getElementById('mapTrialsPortal') &&
            createPortal(
              <Header
                dataLength={chartData.length}
                setStepIndex={setStepIndex}
                setStepsEnabled={setStepsEnabled}
              />,
              document.getElementById('mapTrialsPortal') as Element
            )}
          {hasData ? (
            <Flex
              pointerEvents={'none'}
              zIndex={50}
              justifyContent={'space-between'}
              w='full'
              h='100%'
              gap={2}
            >
              <Flex
                pointerEvents={'auto'}
                justifyContent={'space-between'}
                flexDir={'column'}
                gap={2}
                maxW='350px'
              >
                <Flex gap={2} className='keyPieCharts' flexDir={'column'}>
                  <KeyPieCharts
                    title='Type'
                    property='designationA'
                    data={chartData}
                    loading={!loaded}
                  />
                  <KeyPieCharts
                    multipleSelection={true}
                    title='Status'
                    property='status'
                    data={chartData}
                    loading={!loaded}
                  />
                </Flex>
                <TrialsProgressChart loading={!loaded} data={chartData} />
              </Flex>

              <Box
                pointerEvents={'auto'}
                className='mapContainer'
                w='full'
                bg='white'
                height={'100%'}
              >
                <MapHeatmap
                  data={chartData}
                  summaryTitle='Total trials'
                  popoverTitle='Clinical Trials'
                  countFunction={(item: any) => 1}
                  itemToCount='areas'
                />
              </Box>

              <Flex
                h='full'
                pointerEvents={'auto'}
                justifyContent={'space-between'}
                flexDir={'column'}
                gap={2}
              >
                <TrialsByYearPieChart data={chartData} loading={!loaded} />
                <TrialsLineChart data={chartData} loading={!loaded} />
              </Flex>
            </Flex>
          ) : (
            <Box
              p={40}
              textAlign='center'
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
              data-cy='loading'
            >
              <Text ml={2}> No data found, try adjusting the filters.</Text>
            </Box>
          )}
        </Panel>
      </Box>
    </>
  )
}
