import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { RSVCommercialSlugs } from './RSVCommercialMenu'

const Reports: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['reports'],
  component: () => <FullReportPage />,
}

const ResearchBriefs: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const Revenue: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['revenue', 'revenue-dashboard'],
  component: () => <TableauEmbed path='/RSVRevenue/Revenue' />,
}

const Price: ISingleCustomPage<RSVCommercialSlugs> = {
  path: ['supply-price', 'price-dashboard'],
  component: () => <TableauEmbed path='/RSVPricing/RSVPricingDashboard' />,
}

export const RSVCommercialCustomPages: Record<
  string,
  ISingleCustomPage<RSVCommercialSlugs>
> = {
  Reports,
  Price,
  Revenue,
  ResearchBriefs,
}
