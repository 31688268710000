import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'

import useAxios, { getBaseUrl } from './useAxios'
import { getIsGov } from 'contexts/ClientType'

export default function useTableauAuth(extraProps?: UseQueryOptions<any, any>) {
  const axios = useAxios()

  return useQuery<any, AxiosError>(
    ['tableauAuthentication'],
    () => {
      return permissionCheck(axios)
    },
    extraProps
  )
}

export const permissionCheck = async (axios: AxiosInstance): Promise<any> => {
  const baseURL = getBaseUrl(getIsGov())
  return axios.post(`${baseURL}/tableau/token/`).then((x) => x.data?.token)
}
