import { Box, Button, Flex, Text } from '@chakra-ui/react'

import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useSingleReportById } from 'api/cms/reports/useReports'
import { useAppParams } from 'routes/utils'
import { LoadingAnimation } from 'components'

import './styles.css'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useAxios from 'api/useAxios'
import ShareEmailPopover from './ShareEmailPopover'
import useIsMobile from 'utils/useIsMobile'
import { NAV_HEIGHT } from 'constants/misc'
import useTracking from 'tracking/useTracking'
import resolvePath from 'utils/resolvePath'
import _ from 'lodash'
import AiReport from '../ai/ai-report'
import { useState } from 'react'
import PresentationModalSecureReports from './PresentationModal'
import PdfComponent from './PdfComponent'
import { Information } from '@carbon/icons-react'

const SecureReport = () => {
  const [queryParams] = useSearchParams()
  const params = useParams()

  const pdfType = queryParams.get('type') || ''
  const reportId = params.id || ''
  const isReport = [
    'analyst-updates',
    'analyst-updates-hidden',
    'analyst-deep-dive',
    'analyst-combination-vaccines-report',
  ].includes(pdfType)

  const axios = useAxios()
  const navigate = useNavigate()
  const { pageSlug, app, subPageSlug } = useAppParams()
  const [tracking] = useTracking()
  const [hideAI, setHideAI] = useState(isReport ? false : true)
  const [nPages, setNPages] = useState(0)
  const [forceOpenInfo, setForceOpenInfo] = useState(false)

  const { data: reportData } = useSingleReportById(app, reportId, pdfType)

  const pdfUrl = reportData?.report || reportData?.pdf

  const [isMobile] = useIsMobile()

  function handleGoBack() {
    if (!subPageSlug) navigate(resolvePath([app]))
    else {
      const isNumber = _.isNumber(+subPageSlug)
      navigate(resolvePath([app, pageSlug, isNumber ? '' : subPageSlug]))
    }
  }

  async function handleShareReport(email: string, pages?: number[]) {
    tracking.shareSecureReport({
      app,
      page: pageSlug,
      reportId,
      report: reportData?.title ?? '',
      type: pdfType,
      to: email,
    })
    const params = new URLSearchParams()
    pages &&
      pages.forEach((page) => params.append('page_numbers', page.toString()))
    try {
      await axios.post(
        `/report-sharing/?email_address=${encodeURI(email)}&report_id=${reportId}&${params.toString()}`
      )
      return true
    } catch (err) {
      console.error('Sharing report error', err)
      return false
    }
  }

  return (
    <Flex
      justifyContent={'flex-start'}
      alignItems='center'
      h={isMobile ? '100vh' : `calc(100vh - ${NAV_HEIGHT})`}
      overflow={'auto'}
      flexDir={'column'}
      bg='gray.100'
      py='0.5rem'
      pos={'relative'}
    >
      <Flex
        flexDir={isMobile ? 'column' : 'row'}
        textAlign='center'
        alignItems={'center'}
        justifyContent='center'
        w='100%'
        p='1rem 3rem'
        pos='relative'
      >
        <Box
          left='2rem'
          top='1rem'
          display='flex'
          fontSize='sm'
          fontWeight={500}
          color='gray.700'
          _hover={{
            textDecoration: 'underline',
          }}
          cursor='pointer'
          onClick={handleGoBack}
          pos='absolute'
        >
          ← Back
        </Box>

        <Text
          mb='8px'
          fontSize={isMobile ? 'lg' : '2xl'}
          fontWeight='bold'
          maxW={isMobile ? '80%' : '60%'}
        >
          {reportData?.title}
        </Text>
        {isReport && (
          <Flex
            pos={isMobile ? 'relative' : 'absolute'}
            right={isMobile ? 0 : '1rem'}
            top='0.5rem'
            flexDirection={'row'}
            gap='1rem'
          >
            <Button
              variant={'outline'}
              onClick={() => setForceOpenInfo(true)}
              gap='8px'
            >
              <Information />
              New interface info
            </Button>
            <ShareEmailPopover
              handleClick={handleShareReport}
              nPages={nPages}
            />
          </Flex>
        )}
        {isReport && (
          <Flex
            gap='1rem'
            pos={isMobile ? 'relative' : 'absolute'}
            right={isMobile ? 0 : '200px'}
            top={isMobile ? '1rem' : '3rem'}
            flexDirection={'column'}
          >
            <Button onClick={() => setHideAI((x) => !x)} variant={'yellow'}>
              {hideAI ? 'Show AI' : 'Hide AI'}
            </Button>
          </Flex>
        )}
      </Flex>

      <Box w='100%' justifyContent={isReport ? '' : 'center'} height='84vh'>
        <PresentationModalSecureReports
          forceOpen={forceOpenInfo}
          setForceOpenInfo={setForceOpenInfo}
        />
        {!pdfUrl ? (
          <LoadingAnimation backgroundColor='#f6f6f6' />
        ) : (
          <Flex pos='relative' gap='1rem' px='1rem' w='100%' h='100%'>
            {((isMobile && hideAI) || !isMobile) && (
              <PdfComponent
                reportUrl={pdfUrl}
                reportId={reportId}
                reportTitle={reportData?.title ?? ''}
                reportType={pdfType}
                hideAI={hideAI}
                nPagesCB={setNPages}
              />
            )}
            {isReport && !hideAI && (
              <AiReport reportId={+reportId} reportTitle={reportData?.title} />
            )}
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

export default SecureReport
