import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'

const InfluenzaPageBanner = ({ mb }: { mb?: string }) => {
  return (
    <Flex
      p='12px'
      flexDir={'column'}
      bg='gray.50'
      border='1px solid'
      borderColor='gray3'
      borderRadius='8px'
      fontSize='14px'
      gap='8px'
      mb={mb}
    >
      Airfinity’s 2025 conference attendance will include:
      <UnorderedList styleType='lower-roman' ml='2rem'>
        <ListItem mb='4px'>
          The European Society of Clinical Microbiology and Infectious Diseases
          (ESCMID, 11th April 2025)
        </ListItem>
        <ListItem mb='4px'>​ID Week (19 October 2025)</ListItem>
        <ListItem>
          The European Scientific Working group on Influenza and other
          Respiratory Viruses (ESWI, 20th October 2025)
        </ListItem>
      </UnorderedList>
      <Text>
        Get in touch with your account manager or at{' '}
        <b>infectiousdiseases@airfinity.com</b> if you are interested in
        expanding your conference coverage
      </Text>
    </Flex>
  )
}

export default InfluenzaPageBanner
