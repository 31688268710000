import { TabPanelProps, TabPanel, Box, TabPanels } from '@chakra-ui/react'
import moment from 'moment'

import {
  DetailDrawerHeader,
  DetailDrawerList,
  DetailDrawerListTrial,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { RNATherapeuticsClinicalTrialsModel } from 'config/apps/RNATherapeutics/Custom/RNATherapeutics/RNATherapeuticsModels'
import { CandidateRankingData } from 'config/common/baseModel'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' p={0} {...props} />

type PropTypes<D extends Record<CandidateRankingData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<CandidateRankingData, any>>(
  props: PropTypes<D>
) {
  const { candidate, handleClose, model, title, viewData } = props

  return (
    <SlideOver show={true}>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs tabs={['Summary', 'Trials']}>
        <Box flexGrow={1} flexBasis={0} overflow={'hidden'}>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow={'auto'}>
              <DetailDrawerSummary rowData={candidate} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <DetailDrawerList
                id={1}
                viewData={viewData}
                model={RNATherapeuticsClinicalTrialsModel}
                sortOrder={(x) => moment(x.completedAt)}
                ids={candidate.linkClinicalTrials}
                itemComponent={DetailDrawerListTrial}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
