import TabbedDisplay from 'modules/TabbedDisplay'

import { apps, appsListUnion } from 'config/apps'

import callFunctionOrReturn from 'utils/callFunctionOrReturn'

import { TableauEmbed } from './TableauEmbed'
import { TableauPaths } from './paths'

/**
 * This file helps map a Tableau embed into the path it's shown in.
 * We do this by actually traversing the react node to see if we can find the React component we used to render the Tableau
 * We also handle when the embed is within a tab with the TabbedDisplay component
 *
 * CAVEAT
 * ------
 *
 * Because we don't actually render the component, we cannot detect usage of the component if it's not within the Page's scope
 * Example:
 * () => <TableauEmbed />
 * We can easily detect this since we will resolve the function and the return type will be a "schema" of how react will render it which contains the TableauEmbed
 *
 * The following example also works fine because of the same reason:
 * () => <Box><TableauEmbed /></Box>
 *
 * However, this won't work:
 * const AnotherComponent = () => <TableauEmbed />
 * () => <AnotherComponent />
 *
 * Because when we resolve the function, we find that we will be rendering AnotherComponent.
 * There is no way of finding out whether we will render TableauEmbed without actually rendering it.
 *
 * To rationalize how this works further, it's helpful to think how those JSX code resolve into JS:
 * Example 2: () => React.createElement(Box, null, React.createElement(TableauEmbed, null));
 * Example 3: () => React.createElement(AnotherComponent, null);
 *
 * Those function returns an object detailing what will be rendered from the specification. Think of it like an AST.
 * It will then be rendered by ReactDOM. We traverse through that AST to find what we need
 *
 * ------------------------------------------------------------------
 *
 * It's not always possible to make the component fulfil the above requirement. Especially if we need to use react hooks.
 * Because of that we also have a separate method of registering a tableau embed.
 * We simply need to call the registerTableauPage function with the mapping data.
 *
 */

type TableauPathMapping = {
  tableauPath: TableauPaths
  app: appsListUnion
  // Path of the page
  path: string | string[] | null
  // Used to indicate which tab the tableau component is on
  pathExtras?: string
}

// The mapping data is stored here
export let tableauMapping: TableauPathMapping[] = []

/**
 * Go through the passed React node and find all the TableauEmbed components
 */
const traverseAndGetTableau = (
  components: React.ReactElement<any, any>[]
): React.ReactElement<any, any>[] => {
  const store = []
  for (const component of components) {
    // If the component we need, push it and continue searching
    if (component.type === TableauEmbed) {
      store.push(component)
    }
    if (
      // We need to do all this checks because it could be anything (string, no children, etc)
      typeof component === 'object' &&
      'props' in component &&
      'children' in component.props
    ) {
      // Recurse through all the children till we get everything
      store.push(...traverseAndGetTableau([component.props.children].flat()))
    }
  }
  return store
}

/**
 * Go through the passed React node and find the first TabbedDisplay component
 */
const traverseAndGetTabbedDisplay = (
  components: React.ReactElement<any, any>[]
): React.ReactElement<any, any> | null => {
  for (const component of components) {
    if (component.type === TabbedDisplay) {
      return component
    }
    if (
      typeof component === 'object' &&
      'props' in component &&
      'children' in component.props
    ) {
      return traverseAndGetTabbedDisplay([component.props.children].flat())
    }
  }
  return null
}

/**
 * We use this if we need to register a standalone tableau page
 */
export const registerTableauPage = (tableauPath: TableauPathMapping) => {
  tableauMapping.push(tableauPath)
}

/**
 * Go through all the app & pages to detect all the tableau mapping we can find
 */
export const registerInitialTableauMapping = () => {
  tableauMapping.push(
    ...(Object.values(apps)
      .map((app) => {
        const appSlug = app.slug

        return Object.values(app.customPages ?? {}).map((customPage) => {
          const mapping: TableauPathMapping[] = []

          const initialComponent = [customPage.component({})!] as any
          const tabbedDisplay = traverseAndGetTabbedDisplay(initialComponent)

          // First we handle if it's a TabbedDisplay
          if (!!tabbedDisplay) {
            for (let i = 0; i < tabbedDisplay.props.tabs.length; i++) {
              const tab = tabbedDisplay.props.tabs[i]
              if ('body' in tab) {
                const foundTableau = traverseAndGetTableau([
                  callFunctionOrReturn(tab.body, undefined),
                ])

                mapping.push(
                  ...foundTableau.map((tableau) => ({
                    tableauPath: tableau.props.path,
                    app: appSlug,
                    path: customPage.path,
                    pathExtras: i === 0 ? undefined : `#${i}`,
                  }))
                )
              }
            }
          } else {
            // Otherwise we just check for the tableau component
            const traversedTableau = traverseAndGetTableau(initialComponent)
            if (traversedTableau.length > 0) {
              mapping.push(
                ...traversedTableau.map((tableau) => ({
                  tableauPath: tableau.props.path,
                  app: appSlug,
                  path: customPage.path,
                }))
              )
            }
          }

          return mapping
        })
      })
      .flat(Infinity) as TableauPathMapping[])
  )
}
