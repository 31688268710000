import { Flex } from '@chakra-ui/react'
import { LoadingAnimation } from 'components'
import KeyPieCharts from 'components/Dashboards/IDATrialsMapDashboard/Cards/KeyPieCharts'
import { FiltersT } from 'modules/BioriskMpox/Epidemiology'
import { useContext } from 'react'
import { DataContext, DataTypesT } from '..'
import SummaryCards from '../SummaryCards'

const RightSection = ({
  dataType,
  filters,
}: {
  dataType: DataTypesT
  filters: FiltersT
}) => {
  const data = useContext(DataContext)
  if (!data?.[dataType]?.data) return <LoadingAnimation />

  const formattedData = data[dataType].data
    .map((item: any) => ({
      ...item,
      candidateName: item.candidates,
    }))
    .filter((item: any) => {
      return (
        filters.country === 'global' ||
        item.recipients.includes(filters.country)
      )
    })

  return (
    <Flex
      h='full'
      pointerEvents={'auto'}
      justifyContent={'space-between'}
      flexDir={'column'}
      gap={'0.5rem'}
    >
      <SummaryCards data={data} filters={filters} />
      <Flex flexDir={'column'} h='100%' gap='0.5rem'>
        <Flex h='100%'>
          <KeyPieCharts
            key={dataType + 'designationA' + filters.country}
            title='Type'
            property='designationA'
            data={formattedData}
            loading={data?.[dataType].isLoading}
            filterType='includesAny'
          />
        </Flex>
        <Flex h='100%'>
          <KeyPieCharts
            key={dataType + 'candidates' + filters.country}
            title='Candidates'
            property='candidateName'
            data={formattedData}
            loading={data?.[dataType].isLoading}
            filterType='includesAny'
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RightSection
