import { Box, Button, Text } from '@chakra-ui/react'
import { useState } from 'react'
import SingleReportByID from 'routes/common/SingleReportByID'

const LatestAnalysis = () => {
  const [readMore, setReadMore] = useState(false)
  return (
    <Box
      border={'1px solid'}
      borderColor='gray3'
      borderRadius='8px'
      p={4}
      position={'relative'}
      overflowY={'hidden'}
      fontSize='sm'
      w='100%'
      h={readMore ? '100%' : '260px'}
    >
      <Text
        position={'absolute'}
        top={3}
        fontWeight={'semibold'}
        fontSize={'lg'}
      >
        Read the Latest Mpox report
      </Text>
      <SingleReportByID id='1535' noBorder />
      <Box pos='absolute' left='50%' bottom='8px' bg='white'>
        <Button variant='outline' onClick={() => setReadMore((x) => !x)}>
          {readMore ? 'Read Less' : 'Read More'}
        </Button>
      </Box>
    </Box>
  )
}

export default LatestAnalysis
