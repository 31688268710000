import { useCallback, useMemo, useState } from 'react'
import Map, { Layer, MapLayerMouseEvent, Source } from 'react-map-gl/maplibre'

import layers, { EmptyLayer, LayerT } from './layers'
import { FeaturesT } from './utils/types'
import { MapPopover } from './Popover'
import { MAP_STYLES } from 'components/Dashboards/MapHeatmap/utils'
import { Box } from '@chakra-ui/react'

const MapContainer = ({
  selectedLayer,
  mapRef,
  features,
  selectCountry,
}: {
  selectedLayer: LayerT
  mapRef: any
  features: FeaturesT
  selectCountry: (country: string) => void
}) => {
  const [hoverInfo, setHoverInfo] = useState<any>(null)

  const onHover = useCallback((event: any) => {
    const {
      features,
      point: { x, y },
      lngLat: { lng, lat },
    } = event
    const hoveredFeature = features && features[0]

    if (hoveredFeature?.layer?.id === 'data-countries') {
      setHoverInfo(
        hoveredFeature && {
          feature: hoveredFeature,
          properties: hoveredFeature?.properties,
          x,
          y,
          lng,
          lat,
          isClade: true,
        }
      )
      return
    }

    const properties = JSON.parse(
      hoveredFeature?.properties?.properties || '{}'
    )

    // prettier-ignore
    setHoverInfo(hoveredFeature && {feature: hoveredFeature, properties, x, y, lng, lat});
  }, [])

  function getLayer(layer: LayerT) {
    if (layer && layer === selectedLayer) return features[layer]
    if (layer === '' || (selectedLayer !== layer && !!selectedLayer)) {
      return EmptyLayer
    } else return features[layer]
  }

  function handleClick(event: MapLayerMouseEvent) {
    const countryId = event.features?.[0]?.properties?.id
    selectCountry(countryId)
  }
  function handleDotClick(countryId: string) {
    selectCountry(countryId)
  }

  const highestCaseNumber = [
    ...getLayer('suspected').features,
    ...getLayer('confirmed').features,
    ...getLayer('notSpecified').features,
  ]
    .map((item) => item.properties.value)
    .sort((a, b) => b - a)[0]

  const map = useMemo(() => {
    return (
      <Map
        initialViewState={{
          longitude: 0,
          latitude: 10,
          zoom: 0,
        }}
        renderWorldCopies={false}
        preserveDrawingBuffer={true}
        ref={mapRef}
        id='myMap'
        interactiveLayerIds={[
          'data-countries',
          'data-suspected',
          'data-notSpecified',
          'data-confirmed',
        ]}
        onMouseMove={onHover}
        mapStyle={MAP_STYLES['gray-labels']}
        onClick={handleClick}
      >
        <Source id='data-countries' data={features.clade} type={'geojson'}>
          <Layer {...layers.CountryBorders} />
        </Source>
        <Source
          id='data-suspected'
          type={'geojson'}
          data={getLayer('suspected')}
        >
          <Layer {...layers.CircleSuspected(highestCaseNumber)} />
        </Source>
        <Source
          id='data-notSpecified'
          type={'geojson'}
          data={getLayer('notSpecified')}
        >
          <Layer {...layers.CircleNotSpecified(highestCaseNumber)} />
        </Source>
        <Source
          id='data-confirmed'
          data={getLayer('confirmed')}
          type={'geojson'}
        >
          <Layer {...layers.CircleConfirmed(highestCaseNumber)} />
        </Source>
        <Source id='data-countries' data={getLayer('clade')} type={'geojson'}>
          <Layer {...layers.CountryBorders} />
        </Source>
      </Map>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features, mapRef, highestCaseNumber, selectedLayer])

  return (
    <Box
      position={'relative'}
      height={'full'}
      width={'full'}
      borderRadius={'sm'}
    >
      {map}
      {hoverInfo && (
        <MapPopover hoverInfo={hoverInfo} onClick={handleDotClick} />
      )}
    </Box>
  )
}

export default MapContainer
