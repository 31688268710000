import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'

import useSearchEntities from 'api/useSearchEntities'
import { apps, appsListUnion } from 'config/apps'
import { ISingleNavigationPage } from 'interfaces/navigationPage.interface'
import { IModel } from 'interfaces/model.interface'
import EntitySection from 'modules/GlobalSearchOverlay/EntitySection'
import { LoadingAnimation } from 'components'
import { EntitySearchType } from 'api/types'

const TabNamesMappings: { [key: string]: string } = {
  Approvals: 'Regulatory',
}

const ResultsSection = ({
  selectedText,
  app,
  data,
  pageSectionToRender,
}: {
  data: EntitySearchType[] | undefined
  app: appsListUnion
  selectedText: string
  pageSectionToRender: ISingleNavigationPage<any, IModel<any, any>>[]
}) => {
  const [tabSelected, setTabSelected] = useState<string>('')

  const groupedData: { [key: string]: EntitySearchType[] | undefined } =
    pageSectionToRender.reduce((acc, _, index) => {
      const model = pageSectionToRender?.[index]?.model
      const filtered = data?.filter((x) => x?.endpoint === model?.endpoint)
      return { ...acc, [pageSectionToRender?.[index].key]: filtered }
    }, {})

  useEffect(() => {
    const initialEntity = pageSectionToRender?.[0]?.key
    if (initialEntity) setTabSelected(initialEntity)
  }, [pageSectionToRender])

  const selectedData = groupedData?.[tabSelected]

  const page = pageSectionToRender.find((x) => x.key === tabSelected)

  if (!selectedData) return null

  return (
    <Flex
      px='8px'
      flexDir={'column'}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Flex gap='12px' my='8px'>
        {pageSectionToRender.map((page, i) => {
          const isSelected = page.key === tabSelected
          const name = page?.model.entityName
          return (
            <Flex gap='4px' key={i} whiteSpace={'nowrap'}>
              <Text
                fontSize={'md'}
                cursor='pointer'
                color={isSelected ? 'black' : 'gray2'}
                textDecor={isSelected ? 'underline' : 'none'}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setTabSelected(page.key)
                }}
              >
                {name in TabNamesMappings ? TabNamesMappings[name] : name}
              </Text>
              <Text fontSize={'md'} color={isSelected ? 'black' : 'gray2'}>
                ({groupedData?.[page?.key]?.length})
              </Text>
            </Flex>
          )
        })}
      </Flex>
      {selectedData?.length > 0 && (
        <Box pos='relative' maxW='400px'>
          <EntitySection
            app={app}
            page={page!}
            data={selectedData}
            searchString={selectedText}
            reportView
          />
        </Box>
      )}
    </Flex>
  )
}

const PageSortOrder = [
  'Candidates',
  'Approvals',
  'Clinical Trials',
  'Publications',
]

const TextTooltip = ({
  app,
  selectedText,
  popoverStyle,
}: {
  app: appsListUnion
  selectedText: string
  popoverStyle: {
    top?: number
    left?: number
    display?: string
    right?: string
  }
}) => {
  const selectedAppObject = useMemo(() => apps[app], [app])

  const { data, isLoading, error } = useSearchEntities(app, selectedText)

  const appPages = selectedAppObject.pages

  const pageSectionToRender = useMemo(() => {
    if (!data) {
      return []
    }

    const pageList = Array.from(new Set(data.map((x) => x.endpoint)))
      .map((endpoint) =>
        Object.values(appPages).find((page) => page.model.endpoint === endpoint)
      )
      .filter((page) => page !== undefined)
      .filter((page) => page?.path !== null) as ISingleNavigationPage<
      any,
      IModel<any, any>
    >[]

    const orderMap = new Map(PageSortOrder.map((key, index) => [key, index]))

    function getOrder(name: string) {
      return Number(orderMap.has(name) ? orderMap.get(name) : Infinity)
    }

    pageList.sort(
      (a, b) => getOrder(a.model.entityName) - getOrder(b.model.entityName)
    )

    return pageList
  }, [data, appPages])

  if (!selectedText) return null

  return (
    <Box
      minW='400px'
      pos='absolute'
      bg='white'
      border='1px solid'
      borderColor={'gray3'}
      p='8px'
      borderRadius='4px'
      zIndex={10}
      right={popoverStyle?.right}
      top={popoverStyle?.top}
      left={popoverStyle?.left ? popoverStyle.left - 200 : undefined}
      display={popoverStyle.display}
      boxShadow={'xl'}
    >
      {isLoading && (
        <Flex
          h='50px'
          justifyContent={'center'}
          alignItems={'center'}
          py='3rem'
          flexDir={'column'}
          overflow={'hidden'}
        >
          <Flex w='50%' ml='-1rem' maxW='200px'>
            <LoadingAnimation height={'40px'} />
          </Flex>
          <Text mt='8px'>Searching on the DB.</Text>
        </Flex>
      )}

      <ResultsSection
        data={data}
        pageSectionToRender={pageSectionToRender}
        selectedText={selectedText}
        app={app}
      />
      {!isLoading && !error && (pageSectionToRender?.length || 0) === 0 && (
        <Box>
          No matches for <b>{selectedText}</b>
        </Box>
      )}
    </Box>
  )
}

export default TextTooltip
