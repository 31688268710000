import { Information } from '@carbon/icons-react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'

const DetailsPage = () => {
  return (
    <Box className='gap-3 w-[70%] max-w-[700px] flex flex-col m-auto border border-gray-200 p-6 bg-gray-50 rounded leading-relaxed'>
      <Text as='h3' fontSize='2xl' my={3} fontWeight='bold'>
        Airfinity Mpox Epidemiological Data Overview
      </Text>

      <Text fontWeight='bold'>Summary</Text>
      <Text>
        This guide presents an overview of a comprehensive, multi-source dataset
        on mpox, curated to support the Airfinity mpox platform.
      </Text>

      <Text mt={4}>
        At Airfinity, we employ a comprehensive approach to data collection, and
        this mpox dataset is generated using both manual and automated processes
        to scrape a variety of sources including publicly accessible datasets
        and data requests of access-restricted sources. Our sources encompass
        public health dashboards, repositories, reports, media articles,
        peer-reviewed literature and more. All data undergoes thorough quality
        assurance and regular scoping is conducted to identify new sources.
      </Text>

      <Text mt={4}>
        The data feeding our mpox platform spans 120+ countries from 50+
        sources, with historical and ongoing collections on cases and deaths. If
        available, individual cases/deaths data are tagged with clade metadata,
        but we also display country-level geographical spread of mpox clades
        based on sequencing data from Nextstrain. There may be a discrepancy in
        the reporting of Clade I presence and that shown on the map due to a
        lack of sequencing data submitted to Nextstrain.
      </Text>

      <Text mt={4}>
        Mpox hospitalisation and vaccination uptake data are not currently
        presented on the platform but are available in our internal database for
        future use.
      </Text>

      <Text mt={4}>
        For cases and deaths, our primary source is Our World in Data (OWID),
        which aggregates data from the World Health Organization for confirmed
        cases/deaths and the Africa CDC for suspected cases. To avoid overlaps,
        data from alternative sources are only shown if they detail areas and/or
        time periods not covered by OWID, or if they provide additional
        information such as demographics. In line with this, we supplement the
        monthly OWID data lag with timely updates from various media outlets and
        national sources. Once OWID updates, its records take precedence where
        overlaps occur.
      </Text>

      <Text mt={4}>
        Where possible, we also collect subnational data and data on case
        demographics, prioritising regions most affected by mpox in Africa and
        the United States. However, we have scoped additional sources specifying
        demographic data from other countries, which can be integrated if deemed
        relevant.
      </Text>

      <Box mt={6}>
        <Text as='h4' fontSize='xl' mb={4} fontWeight='bold'>
          Ongoing collections
        </Text>

        <Text fontWeight='bold' mb={2}>
          Table 1: Overview of mpox sources being actively ingested into the
          Airfinity database
        </Text>
        <Table variant='simple' size='sm' mb={4}>
          <Thead>
            <Tr>
              <Th>Area(s)</Th>
              <Th>Source(s)</Th>
              <Th>Ingestion frequency*</Th>
              <Th>Ingestion method</Th>
              <Th>Time</Th>
              <Th>Metric(s)*</Th>
              <Th>Subnational**</Th>
              <Th>Demographics**</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Global (126 countries)</Td>
              <Td>OWID</Td>
              <Td>Monthly</Td>
              <Td>Automated</Td>
              <Td>2022-present</Td>
              <Td>Cases, deaths</Td>
              <Td>National</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>USA</Td>
              <Td>NNDSS</Td>
              <Td>Weekly</Td>
              <Td>Automated</Td>
              <Td>2024-present</Td>
              <Td>Cases</Td>
              <Td>National and state</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>USA</Td>
              <Td>25x State Department of Health</Td>
              <Td>Monthly</Td>
              <Td>19x manual, 6x automated</Td>
              <Td>2022-present</Td>
              <Td>Cases, deaths</Td>
              <Td>Subnational (state and county)</Td>
              <Td>Sex, age, gender, race, ethnicity</Td>
            </Tr>
            <Tr>
              <Td>Africa</Td>
              <Td>Africa CDC</Td>
              <Td>Weekly</Td>
              <Td>Manual</Td>
              <Td>2022-present</Td>
              <Td>Cases, deaths</Td>
              <Td>National and subnational</Td>
              <Td>Sex, age</Td>
            </Tr>
            <Tr>
              <Td>Canada</Td>
              <Td>Gov of Canada</Td>
              <Td>Monthly</Td>
              <Td>Manual</Td>
              <Td>2022-present</Td>
              <Td>Cases</Td>
              <Td>National</Td>
              <Td>Age, gender</Td>
            </Tr>
            <Tr>
              <Td>Global</Td>
              <Td>Various media sources</Td>
              <Td>Daily (if appropriate)</Td>
              <Td>Manual</Td>
              <Td>real-time</Td>
              <Td>Cases, deaths</Td>
              <Td>National and subnational</Td>
              <Td>Depending on article</Td>
            </Tr>
            <Tr>
              <Td>Global</Td>
              <Td>Nextstrain</Td>
              <Td>Weekly</Td>
              <Td>Automated</Td>
              <Td>2017-present</Td>
              <Td>Clade distribution</Td>
              <Td>National</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>

        <Text fontSize='sm' fontStyle='italic' mb={6}>
          *Ingestion frequency is not the same as reporting frequency.
          <br />
          **Where available.
        </Text>

        <Text as='h4' fontSize='xl' mb={4} fontWeight='bold'>
          Historical sources
        </Text>

        <Text fontWeight='bold' mb={2}>
          Table 2: Overview of historical mpox sources in the Airfinity database
        </Text>
        <Table variant='simple' size='sm' mb={6}>
          <Thead>
            <Tr>
              <Th>Area(s)</Th>
              <Th>Source(s)</Th>
              <Th>Time covered</Th>
              <Th>Metric(s)*</Th>
              <Th>Subnational*</Th>
              <Th>Demographics*</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>USA</Td>
              <Td>US CDC</Td>
              <Td>2022-2024</Td>
              <Td>Cases</Td>
              <Td>State</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>USA states</Td>
              <Td>21x State Department of Health sources</Td>
              <Td>2022-2024</Td>
              <Td>Cases</Td>
              <Td>State and county</Td>
              <Td>Sex, age, gender, race, ethnicity</Td>
            </Tr>
            <Tr>
              <Td>Africa</Td>
              <Td>WHO Africa</Td>
              <Td>2016-2022</Td>
              <Td>Cases, deaths</Td>
              <Td>National</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>USA</Td>
              <Td>CDC Past U.S. Cases and Outbreaks of Mpox</Td>
              <Td>2003-2021</Td>
              <Td>Cases</Td>
              <Td>State</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Africa</Td>
              <Td>Various publications</Td>
              <Td>1970-2019</Td>
              <Td>Cases</Td>
              <Td>National</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>

        <Text fontSize='sm' fontStyle='italic' mb={6}>
          *Where available.
        </Text>

        <Text as='h4' fontSize='xl' mb={4} fontWeight='bold'>
          Additional sources not currently feeding the platform
        </Text>

        <Text mb={4}>
          As described above, Airfinity ingest a wide range of data sources from
          different regions covering additional metrics (vaccinations and
          hospitalisations), not all of which are currently being displayed on
          the platform. If of interest, the following sources are either already
          ingested or have been identified for future ingestion.
        </Text>

        <Text fontWeight='bold' mb={2}>
          Table 3: Overview of additional sources to expand the mpox platform
        </Text>
        <Table variant='simple' size='sm' mb={6}>
          <Thead>
            <Tr>
              <Th>Source(s)</Th>
              <Th>Data</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Africa CDC
                <br />
                Santé Publique France
                <br />
                US State Departments of Health
              </Td>
              <Td>Vaccination uptake</Td>
              <Td>Ongoing collection</Td>
            </Tr>
            <Tr>
              <Td>
                ECDC
                <br />
                RKI Germany
                <br />
                PHA Sweden
                <br />
                Taiwan
                <br />
                NHS England
                <br />
                CDC USA
                <br />
                US State Departments of Health
                <br />
                HSPC Ireland
              </Td>
              <Td>Vaccinations uptake</Td>
              <Td>Historical collections</Td>
            </Tr>
            <Tr>
              <Td>
                Spain ISCIII
                <br />
                Santé Publique France
                <br />
                Government of Canada
                <br />
                Sciensano Belgium
                <br />
                US State Departments of Health
                <br />
                Various media sources
              </Td>
              <Td>Hospitalisations</Td>
              <Td>Ongoing collections</Td>
            </Tr>
            <Tr>
              <Td>
                Various publications
                <br />
                US State Departments of Health
                <br />
                WHO Africa
                <br />
                CDC USA
              </Td>
              <Td>Hospitalisations</Td>
              <Td>Historical collections</Td>
            </Tr>
            <Tr>
              <Td>
                Brazil Ministry of Health
                <br />
                Australia NNDSS
                <br />
                Government of Canada
                <br />
                RKI Germany
                <br />
                Directorate-General of Health of Portugal
                <br />
                Sciensano Belgium
              </Td>
              <Td>Demographics</Td>
              <Td>Scoped</Td>
            </Tr>
          </Tbody>
        </Table>

        <Box mt={6}>
          <Text as='h4' fontSize='xl' mb={4} fontWeight='bold'>
            Data Limitations
          </Text>
          <Text mb={4}>
            Airfinity aggregates data from various external sources and are not
            a data generator. While we continuously review data to ensure
            accuracy and reliability, the quality of the data (such as its
            accuracy, completeness and granularity) is dependent on the original
            sources. Reporting frequency can be further subject to delays due to
            the time required for case detection, confirmatory testing,
            sequencing and sharing. Many sources report at the national level
            only, making it difficult to attribute cases to specific locations;
            Airfinity does not claim to currently provide subnational or
            demographic data on a global scale, but rather on key areas in the
            US and Africa. It should be noted that surveillance systems and
            methodologies differ between regions, which can skew data towards
            regions with higher testing capabilities and lead to estimations
            and/or data gaps in regions with limited reporting. All data are
            subject to change.
          </Text>
          <Flex mb={4} flexDir={'column'}>
            <Text>
              Negative values for cases or deaths may appear in the dashboard
              and are true to the original source data. These values can occur
              due to:
            </Text>
            <UnorderedList>
              <ListItem>
                Corrections of misclassified cases or deaths – Cases initially
                attributed to one disease, jurisdiction, or category may later
                be reassigned.
              </ListItem>
              <ListItem>
                Reclassification of suspected cases – Suspected cases may later
                be confirmed, leading to a drop in the suspected case count
                while confirmed cases increase.
              </ListItem>
              <ListItem>
                Data deduplication – Previously reported duplicates may be
                removed, resulting in a net decrease.
              </ListItem>
              <ListItem>
                Periodic reporting adjustments – Health authorities may revise
                totals based on updated reviews or audits.
              </ListItem>
            </UnorderedList>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

const AboutDataPopover = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        minWidth={'150px'}
        maxW='150px'
        variant='clean'
        mr='auto'
        onClick={onOpen}
        fontSize='12px'
        alignItems={'center'}
      >
        <Box mr={1}>
          <Information />
        </Box>
        <Text
          variant='body'
          textDecor={'underline'}
          color='gray1'
          width='fit-content'
          whiteSpace={'break-spaces'}
          textAlign={'left'}
        >
          Data Sources and Limitations
        </Text>
      </Button>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxH='75vh' overflow='auto' width='100%' minW='1000px'>
          <ModalCloseButton />
          <ModalBody>
            <DetailsPage />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
export default AboutDataPopover
