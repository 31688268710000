import { Copy } from '@carbon/icons-react'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useClipboard,
  VStack,
} from '@chakra-ui/react'
import { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

import { AuthContext } from 'contexts'

import { InlineError, InlineLoading } from 'components'

import useAPIReadme from 'api/forecast/useAPIReadme'
import useAPIToken from 'api/forecast/useAPIToken'

import { AccessGroups } from 'enums/AccessGroups'

const APIPage = () => {
  const {
    data: readme,
    isLoading: readmeIsLoading,
    isError: readmeIsError,
  } = useAPIReadme()
  const {
    data: token,
    isLoading: tokenIsLoading,
    isError: tokenIsError,
  } = useAPIToken()

  const { userInfo } = useContext(AuthContext)

  const groups = userInfo?.groups ?? []

  const { hasCopied, onCopy } = useClipboard(token ?? '')

  if ([readmeIsLoading, tokenIsLoading].includes(true)) {
    return <InlineLoading />
  }
  if ([readmeIsError, tokenIsError].includes(true)) {
    return <InlineError />
  }

  return (
    <Box px={10}>
      <ReactMarkdown className='markdown'>{readme ?? ''}</ReactMarkdown>

      <Alert bg='gray4' status='info' borderRadius={4} mb={4} maxWidth='xl'>
        <Box flex={1}>
          <AlertTitle mb={2} color='black'>
            Your Authentication Token
          </AlertTitle>
          <AlertDescription>
            <InputGroup size='md'>
              <Input
                readOnly
                onFocus={(event) => event.target.select()}
                value={token}
                backgroundColor='white'
                textShadow='none'
              />
              <InputRightElement width='5.5rem'>
                <Button variant='clean' onClick={() => onCopy()}>
                  {hasCopied ? (
                    'Copied!'
                  ) : (
                    <Tooltip label='Copy'>
                      <Copy size={16} />
                    </Tooltip>
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </AlertDescription>
        </Box>
      </Alert>
      <VStack align={'left'}>
        {!!groups.includes(AccessGroups.APIForecast) && (
          <Link to='/api-spec-forecast?ref=/settings/api'>
            <Button variant='yellow'>View Forecast API Specification</Button>
          </Link>
        )}
        {!!groups.includes(AccessGroups.APIEpidId) && (
          <Link to='/api-spec-epid-id?ref=/settings/api'>
            <Button variant='yellow'>
              View Infectious Epidemiology API Specification
            </Button>
          </Link>
        )}
        {!!groups.includes(AccessGroups.APISiga) && (
          <Link to='/api-spec-siga?ref=/settings/api'>
            <Button variant='yellow'>View Siga API Specification</Button>
          </Link>
        )}
        {!!groups.includes(AccessGroups.APIOutbreak) && (
          <Link to='/api-spec-outbreak?ref=/settings/api'>
            <Button variant='yellow'>View Outbreaks API Specification</Button>
          </Link>
        )}
        {!!groups.includes(AccessGroups.APIReport) && (
          <Link to='/api-spec-report?ref=/settings/api'>
            <Button variant='yellow'>View Reports API Specification</Button>
          </Link>
        )}
      </VStack>
    </Box>
  )
}

export default APIPage
