import { Box, Portal } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

export default function ({
  isSelected,
  isDropdownChild = false,
  infoText,
}: {
  isSelected?: boolean
  isDropdownChild?: boolean
  infoText: {
    title: string
    content: string
  }
}) {
  const [isHover, setIsHover] = useState(false)
  const [position, setPosition] = useState({ top: null, left: 0 })
  const elementRef = useRef(null) as any

  useEffect(() => {
    if (elementRef.current) {
      const { top, left } = elementRef?.current?.getBoundingClientRect()
      if (top !== 0) {
        setPosition({ top, left })
      }
    }
  }, [isHover])

  return (
    <>
      <Box
        ref={elementRef}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        position='absolute'
        color='black'
        bg={isSelected ? 'gray.100' : 'yellow.500'}
        right={isDropdownChild ? 5 : 10}
        rounded={'sm'}
        shadow={'sm'}
        fontSize='x-small'
        px={1}
        py={0.5}
      >
        {infoText.title}
      </Box>
      {isHover && position.top && (
        <Portal>
          <Box
            left={position.left + 40}
            border='1px solid'
            borderColor={'gray.200'}
            borderRadius={'md'}
            shadow={'xl'}
            top={position.top}
            position='absolute'
            bg='white'
            p='1rem'
          >
            {infoText.content}
          </Box>
        </Portal>
      )}
    </>
  )
}
