import { Document, Security } from '@carbon/icons-react'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Image,
  Link,
  Spinner,
  useToken,
  Text,
  Icon,
  Badge,
} from '@chakra-ui/react'
import _ from 'lodash'
import { memo } from 'react'
import Truncate from 'react-truncate'

import { useAppRoute } from 'routes/utils'

import { InlineLoading } from 'components'

import { defaultWebMeta, WebMeta } from 'api/types'
import useCollectionMeta from 'api/useCollectionMeta'

import { IBaseView } from 'interfaces/navigationPage.interface'
import { WidgetComponentPropsTypes } from 'interfaces/widget.interface'

import useTracking from 'tracking/useTracking'

import { formatDate } from 'utils/formatDate'

import { useWidgetData } from '.'

type CustomProp<TData extends string> = {
  url: TData
  date: TData
  title: TData
  keyNews?: TData
}

export const Article = memo(
  ({
    id,
    url,
    date,
    title,
    isKeyNews,
    modelEndpoint,
    airtableBase,
    trackMediaClick,
  }: {
    id: string | number
    url: string
    date: string
    title: string
    isKeyNews: boolean
    modelEndpoint: string
    airtableBase: IBaseView<any>['airtableBase']
    trackMediaClick: (e: any) => void
  }) => {
    const app = useAppRoute()
    const { data } = useCollectionMeta(app, modelEndpoint, { airtableBase }, [
      id,
    ])

    const { image, logo, description, publisher, lang } = data
      ? data[0]
      : defaultWebMeta

    const red = useToken('colors', 'red.600')

    return (
      <Box
        onClick={() => trackMediaClick(data)}
        {...(isKeyNews ? { borderLeft: `10px solid ${red}` } : {})}
      >
        <Link
          display='flex'
          px={4}
          href={url}
          target='_blank'
          py={3}
          width='100%'
          fontWeight={500}
          fontSize='sm'
          lineHeight='1.4'
          borderBottom='1px solid'
          borderColor='gray.100'
          _hover={{
            bg: 'gray.300',
            textDecoration: 'none',
          }}
        >
          <Box minWidth='75px' mr={3}>
            <AspectRatio
              ratio={1}
              rounded='md'
              bg='white'
              overflow='hidden'
              border='1px solid'
              borderColor='gray.100'
            >
              {image && image.url ? (
                <Image src={image.url} objectFit='cover' />
              ) : (
                <Box bg='gray.100'>
                  <Icon as={Document} boxSize='30px' />
                </Box>
              )}
            </AspectRatio>
          </Box>
          <Box width='85%'>
            <Box
              textTransform='uppercase'
              fontSize='xs'
              color='gray.600'
              lineHeight='none'
              mb={1}
            >
              {formatDate(date)}
            </Box>
            <Box fontWeight={500} mb={1} lineHeight='none'>
              <Text lineHeight={'16px'}>{title}</Text>
            </Box>
            <Box fontSize='xs' opacity={0.5} mb={2}>
              <Truncate lines={1}>{description}</Truncate>
            </Box>
            <Box display='flex' alignItems='center'>
              {logo && logo.url && (
                <Box
                  width='16px'
                  border='1px solid'
                  rounded='sm'
                  borderColor='gray.200'
                  overflow='hidden'
                  bg='white'
                  mr={1}
                >
                  <AspectRatio ratio={1}>
                    <Image src={logo.url} objectFit='contain' />
                  </AspectRatio>
                </Box>
              )}
              <Box fontSize='xs' lineHeight='none' color='gray.600'>
                {publisher}
              </Box>
            </Box>
            <Badge
              fontSize='xx-small'
              float='right'
              colorScheme='blackAlpha'
              px={1}
            >
              {lang}
            </Badge>
          </Box>
          {isKeyNews && (
            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
            >
              <Security size={24} fontSize={30} color={red} />
              <Text textAlign='center' fontSize='sm' color={red}>
                Key Intel
              </Text>
            </Flex>
          )}
        </Link>
      </Box>
    )
  }
)

export default function WidgetComponentArticle<TData extends string>({
  maxItems,
  model,
  view,
  url,
  title,
  keyNews,
  date,
}: WidgetComponentPropsTypes & CustomProp<TData>) {
  const [tracking] = useTracking()
  const { isLoading, data, canFetchMore, fetchMore, isFetchingNextPage } =
    useWidgetData({
      maxItems,
      model,
      view,
    })

  const modelEndpoint = model.endpoint
  if (isLoading || !data) {
    return <InlineLoading />
  }
  function trackMediaClick(data: WebMeta[]) {
    if (!data) return
    const { date, publisher, title: articleTitle, url } = data?.[0]
    tracking.mediaSelection({
      section: view.airtableName,
      date: date || '',
      source: publisher,
      title: articleTitle,
      link: url,
      topic: '',
    })
  }
  return (
    <>
      {data.map((row, i) => {
        const parsedUrl = _.isObject(row[url])
          ? (row[url] as any).link
          : row[url]
        const parsedTitle = _.isObject(row[title])
          ? (row[title] as any).title
          : row[title]

        return (
          <Article
            id={row.id}
            modelEndpoint={modelEndpoint}
            airtableBase={view.airtableBase}
            key={row.id}
            url={(parsedUrl || row.link) as string}
            date={row[date] as string}
            title={parsedTitle as string}
            isKeyNews={!!row[keyNews]}
            trackMediaClick={trackMediaClick}
          />
        )
      })}
      {canFetchMore && (
        <Flex p='1rem' w='100%' justifyContent='center'>
          <Button
            variant='outline'
            onClick={() => {
              tracking.loadMoreItems({ section: view.airtableName })
              fetchMore()
            }}
          >
            {isFetchingNextPage ? <Spinner /> : 'Load More'}
          </Button>
        </Flex>
      )}
    </>
  )
}
