import { Box, Flex, Text } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import useKeyPress from 'utils/useKeyPress'
import styled from 'styled-components'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import { Select } from 'components/Select'
import './styles.css'
import {
  MonthYearSelect,
  RenderPresets,
} from 'components/Dashboards/IDATrialsMapDashboard/DatePicker'
import { FiltersT, getCountryName } from '..'

export type FilterType = any

const HiddenInput = styled.div`
  button {
    outline: none;
  }

  .SingleDatePickerInput {
    .DateInput {
      display: none;
    }
    .SingleDatePickerInput_calendarIcon {
      padding: 0;
    }
  }
  .DateRangePickerInput {
    .DateInput {
      display: none;
    }
    .DateRangePickerInput_calendarIcon {
      padding: 0;
    }
    background: none;
    outline: none;
  }
  .DateRangePickerInput_arrow {
    display: none;
  }
`

const ShowTopCountryOptions = [1, 2, 5, 10, 15, 20, 'All']

const DatePickerTrigger = ({
  startDate,
  endDate,
}: {
  startDate: string | null | undefined
  endDate: string | null | undefined
}) => {
  const start = moment(startDate).format('MMM Do YYYY')
  const end = moment(endDate).format('MMM Do YYYY')
  return (
    <Flex
      bg='white'
      className='dateRange'
      border={'1px'}
      borderColor={'gray.200'}
      h={8}
      position={'relative'}
      px={2}
      alignItems={'center'}
      py={1}
      borderRadius={'sm'}
    >
      {startDate || endDate ? (
        <Text fontSize={'xs'} color={'gray.800'} fontWeight={'semibold'}>
          {start} - {end}
        </Text>
      ) : (
        <Text fontSize={'xs'} color={'gray.800'} fontWeight={'semibold'}>
          Time Range
        </Text>
      )}
    </Flex>
  )
}

export const DateFilter = ({
  dates,
  onDateChange,
}: {
  dates: { start: string; end: string }
  onDateChange: (start: string, end: string) => void
}) => {
  const isPresetSelected = useRef(false)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [focused, setFocused] = useState<'startDate' | 'endDate' | null>(null)

  const handleDateChange = (startDate: Date, endDate: Date) => {
    const start = moment(startDate).format('YYYY-MM-DD')
    const end = moment(endDate).format('YYYY-MM-DD')
    onDateChange(start, end)
    isPresetSelected.current = false
  }

  useKeyPress('Escape', () => {
    if (focused) setFocused(null)
  })

  return (
    <Flex>
      <Box style={{ zIndex: 99 }}>
        <HiddenInput>
          <DateRangePicker
            hideKeyboardShortcutsPanel={true}
            startDateId='startDatePicker'
            endDateId='endDatePicker'
            withPortal
            renderMonthElement={(props) => <MonthYearSelect {...props} />}
            inputIconPosition='before'
            anchorDirection='right'
            isOutsideRange={() => false}
            onClose={({ startDate, endDate }) => {
              if (!isPresetSelected.current) {
                handleDateChange(
                  startDate?.toDate() ?? new Date(),
                  endDate?.toDate() ?? new Date()
                )
              }
            }}
            onDatesChange={({ startDate, endDate, ...res }) => {
              setStartDate(startDate?.toDate() ?? new Date())
              if (endDate) {
                setEndDate(endDate?.toDate() ?? new Date())
              }
            }}
            renderCalendarInfo={() => (
              <RenderPresets
                onPresetClick={({ startDate, endDate }) => {
                  setStartDate(startDate?.toDate() ?? new Date())
                  if (endDate) {
                    setEndDate(endDate?.toDate() ?? new Date())
                    handleDateChange(
                      startDate?.toDate() ?? new Date(),
                      endDate?.toDate()
                    )
                  }
                  isPresetSelected.current = true
                }}
              />
            )}
            onFocusChange={(focused) => {
              setFocused(focused)
            }}
            startDate={startDate ? moment(startDate) : moment(dates.start)}
            endDate={endDate ? moment(endDate) : moment(dates.end)}
            customInputIcon={
              <DatePickerTrigger startDate={dates.start} endDate={dates.end} />
            }
            focusedInput={focused}
          />
        </HiddenInput>
      </Box>
    </Flex>
  )
}

const MainFilterSection = ({
  countries,
  filters,
  handleFilters,
  handleCountry,
  isSingleType,
}: {
  countries: string[]
  handleFilters: (filters: FiltersT) => void
  handleCountry: (country: string) => void
  filters: FiltersT
  isSingleType?: string
}) => {
  function onDataTypeChange(e: any) {
    handleFilters({ ...filters, dataType: e.value })
  }
  function onNCountriesChange(e: any) {
    if (e.value === 'All') {
      handleFilters({ ...filters, nCountries: countries?.length })
    } else {
      handleFilters({ ...filters, nCountries: e.value })
    }
  }

  function onCountryChange(e: any) {
    if (e.value === 'global') {
      handleCountry(filters.country)
    }
    handleCountry(e.value)
  }
  function onDateChange(start: string, end: string) {
    handleFilters({ ...filters, dateRange: { start, end } })
  }

  const countryOptions = [
    { value: 'global', label: 'Global' },
    ...countries
      .sort((a, b) => {
        const countryA = getCountryName(a)
        const countryB = getCountryName(b)
        return countryA.localeCompare(countryB)
      })
      .map((item) => ({
        value: item ?? '',
        label: getCountryName(item ?? ''),
      })),
  ]

  return (
    <Flex
      h='44px'
      w='full'
      border={'1px'}
      borderColor={'gray.200'}
      bg='gray.100'
      px={3}
      py={1}
      borderRadius={'md'}
      justifyContent={'space-between'}
      fontSize={'sm'}
    >
      <Flex alignItems={'center'}>
        <Box>Mpox</Box>
        {!isSingleType ? (
          <Box textTransform='capitalize' mx='8px'>
            <Select
              value={{ value: filters?.dataType, label: filters?.dataType }}
              options={[
                { value: 'infections', label: 'infections' },
                { value: 'deaths', label: 'deaths' },
              ]}
              onChange={onDataTypeChange}
              isMulti={false}
              isSearchable={false}
              backgroundColor='#fff'
              color='#000'
              fontSize={'14px'}
              height='26px'
            />
          </Box>
        ) : (
          <Text mx={2}>{isSingleType}</Text>
        )}
        <Text>over</Text>
        <Box>
          <DateFilter dates={filters.dateRange} onDateChange={onDateChange} />
        </Box>
        <Text>in</Text>
        <Box textTransform='capitalize' mx='8px'>
          <Select
            value={{
              value: filters?.country,
              label: getCountryName(filters?.country) || filters?.country,
            }}
            options={countryOptions}
            onChange={onCountryChange}
            isMulti={false}
            isSearchable={true}
            backgroundColor='#fff'
            color='#000'
            fontSize={'14px'}
            height='26px'
          />
        </Box>
      </Flex>
      <Flex alignItems={'center'} fontSize={'14px'}>
        <Text>Show Top </Text>
        <Box mt={-1} mx={2} textTransform='capitalize'>
          <Select
            value={{ value: filters.nCountries, label: filters.nCountries }}
            isMulti={false}
            options={ShowTopCountryOptions.map((option) => {
              return { value: option, label: option }
            })}
            isSearchable={false}
            backgroundColor='#fff'
            color='#000'
            onChange={onNCountriesChange}
            fontSize={'14px'}
            height='26px'
          />
        </Box>
        <Text>{filters.country === 'global' ? 'Countries' : 'Regions'}</Text>
      </Flex>
    </Flex>
  )
}

export default MainFilterSection
