import { Box, TabPanels, TabPanel, TabPanelProps } from '@chakra-ui/react'

import {
  DetailDrawerHeader,
  DetailDrawerSummary,
  DetailDrawerTabs,
} from 'modules/DetailDrawer'

import TableView from 'routes/apps/TableView'
import { useAppRoute } from 'routes/utils'

import { SlideOver } from 'components'

import { IResponseBase } from 'api/types'

import { IModel } from 'interfaces/model.interface'
import { ViewData } from 'interfaces/navigationPage.interface'

import { apps } from 'config/apps'
import { CardioCandidateData } from 'config/common/baseModel/cardio360/Cardio360Candidate'
import {
  ObesityMediaModel,
  ObesityPressReleasesModel,
  ObesityPublicationsModel,
  ObesitySocialMediaModel,
  ObesityTrialsModel,
} from 'config/apps/Obesity/Obesity360/ObesityModels'

const StyledTabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => <TabPanel height='100%' display='flex' flexDir='column' p={0} {...props} />

type PropTypes<D extends Record<CardioCandidateData, any>> = {
  handleClose: () => void
  candidate: IResponseBase<Extract<keyof D, string>>
  model: IModel<D>
  title: Extract<keyof D, string>
  viewData: ViewData
}

function Detail<D extends Record<CardioCandidateData, any>>(
  props: PropTypes<D>
) {
  const { handleClose, candidate, model, title, viewData } = props

  const app = useAppRoute()

  const exportName = (modelTarget: IModel<any>) =>
    `${apps[app].name}_${model.name}_${candidate && candidate[title]}_${
      modelTarget.name
    }`

  return (
    <SlideOver show={true} width='80vw'>
      <DetailDrawerHeader
        isLoading={false}
        title={candidate && candidate[title]}
        handleClose={handleClose}
      />
      <DetailDrawerTabs
        tabs={[
          'Summary',
          'Publication',
          'Trials',
          'Press Releases',
          'Media',
          'Tweets',
        ]}
      >
        <Box flexGrow={1} flexBasis={0} overflow='hidden'>
          <TabPanels height='100%'>
            <StyledTabPanel p={6} overflow='auto'>
              <DetailDrawerSummary rowData={candidate} model={model} />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={1}
                viewData={viewData}
                model={ObesityPublicationsModel}
                ids={candidate.lookupCandidatePublications}
                exportName={exportName(ObesityPublicationsModel)}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={2}
                viewData={viewData}
                model={ObesityTrialsModel}
                ids={candidate.lookupCandidateClinicalTrials}
                exportName={exportName(ObesityTrialsModel)}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={3}
                viewData={viewData}
                model={ObesityPressReleasesModel}
                ids={candidate.lookupPressReleases}
                exportName={exportName(ObesityPressReleasesModel)}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={4}
                viewData={viewData}
                model={ObesityMediaModel}
                ids={candidate.lookupCandidateMediaArticles}
                exportName={exportName(ObesityMediaModel)}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <TableView
                id={5}
                viewData={viewData}
                model={ObesitySocialMediaModel}
                ids={candidate.lookupCandidateTweets}
                exportName={exportName(ObesitySocialMediaModel)}
              />
            </StyledTabPanel>
          </TabPanels>
        </Box>
      </DetailDrawerTabs>
    </SlideOver>
  )
}

export default Detail
