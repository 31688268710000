import { Flex, Grid } from '@chakra-ui/react'

import LatestAnalysis from './LatestAnalysis'
import LatestAlerts from './LatestAlerts'
import LatestMedia from './LatestMedia'
import UpsellBanner from '../common/UpsellBanner'

const LatestAnalysisNews = () => {
  return (
    <Flex gap={4} flexDir='column'>
      <Flex>
        <LatestAnalysis />
      </Flex>
      <Grid gap={4} templateColumns='1fr 1fr'>
        <LatestAlerts />
        <LatestMedia />
      </Grid>
      <UpsellBanner />
    </Flex>
  )
}

export default LatestAnalysisNews
