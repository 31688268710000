import { Information } from '@carbon/icons-react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { ResponsiveBarCanvas } from '@nivo/bar'
import { capitalize } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Events,
  STATUS,
  Status,
} from 'react-joyride'

import { useExportModal } from 'contexts/ExportModal'
import { useTableChartFilters } from 'contexts/TableChartFilters'

import { Panel } from 'components'
import { SelectOption } from 'components/Select'

import {
  DashboardConfigProps,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import useTracking from 'tracking/useTracking'

import { compactNumberWithPrefix } from 'utils/formatNumber'
import useIsMobile from 'utils/useIsMobile'

import { demoCommonProperties, exportChartToPng } from '../../dashboardsConfig'
import {
  BarReshapedDataType,
  BarTotalsLayer,
  DynamicKeyType,
  barChartCommonProperties,
  colorTheme,
  combineObject,
  compactLabel,
  formatKeyAndItem,
  invalidKeys,
  barChartSteps,
} from '../../dashboardsConfig'
import CustomLegends from './CustomLegends'
import CustomTooltip from './CustomTooltip'
import DataAdjustments from './DataAdjustments'
import KeyPieCharts from 'components/Dashboards/IDATrialsMapDashboard/Cards/KeyPieCharts'
import TrialsProgressChart from 'components/Dashboards/IDATrialsMapDashboard/Cards/TrialsProgressChart'
import { IResponseBase } from 'api/types'

const LeftSectionCards = ({
  page,
  chartData,
  loaded,
}: {
  page: ISingleNavigationPage<any, any>
  chartData: IResponseBase[]
  loaded: boolean | undefined
}) => {
  if (!page.autoGenerateDashboard?.isEmbed) return null
  return (
    <Flex
      pointerEvents={'auto'}
      justifyContent={'space-between'}
      flexDir={'column'}
      gap={2}
      maxW='350px'
      maxH='70vh'
    >
      <KeyPieCharts
        filterType='includesAny'
        title='Type'
        property='designationA'
        data={chartData}
        loading={!loaded}
      />
      <KeyPieCharts
        filterType='includesAny'
        multipleSelection={true}
        title='Phase'
        property='latestPhaseManual'
        data={chartData}
        loading={!loaded}
      />
      <TrialsProgressChart
        isEmbed={page.autoGenerateDashboard?.isEmbed}
        filterType='includesAll'
        title='Candidates by'
        options={[
          { label: 'Technology Type', value: 'type' },
          { label: 'Technology Subtype', value: 'subType' },
          { label: 'Sponsors', value: 'sponsors' },
          { label: 'Affiliates', value: 'affiliates' },
          {
            label: 'Target Population',
            value: 'vaccineTargetPopulation',
          },
        ]}
        loading={!loaded}
        data={chartData}
      />
    </Flex>
  )
}

const UPPER_COLUMN_LIMIT = 200
const infoMessage = (total: number) =>
  `We are only displaying a subset of the data. Total length of data is ${total}, but only ${UPPER_COLUMN_LIMIT} is shown to prevent performance issues. If you wish to see the entire data set, please utilise the filters above.`

const BarChart = ({
  chartData,
  model,
  page,
  loaded,
  customLegendsRef,
  customLegendsRef1,
}: DashboardConfigProps) => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const { openModal } = useExportModal()
  const {
    verticalAxisLabel,
    filterBlacklistOptions,
    sumByKey,
    allowValuesToBeJoinedWhitelist,
    showStackBy,
    showTransformBy,
    showOccurenceThreshold,
    showSortBy,
    sortingOrders,
    defaultHorizontalFilterValue,
    defaultStackByFilterValue,
  } = page.autoGenerateDashboard || {}

  const tableChartFilters = useTableChartFilters()
  const transformBy =
    tableChartFilters.transformBy.value !== ''
      ? tableChartFilters.transformBy
      : defaultHorizontalFilterValue!
  const stackBy =
    tableChartFilters.stackBy.value !== ''
      ? tableChartFilters.stackBy
      : defaultStackByFilterValue!

  const columns = filterBlacklistOptions
    ? model.schema.columns.filter(
        (column) => !(filterBlacklistOptions as string[]).includes(column.key)
      )
    : model.schema.columns

  // column options for select filters
  const columnOptions = columns
    .filter((c) => c.key !== 'name')
    .map((column) => ({
      label: column.label || capitalize(column.key),
      value: column.key,
    })) as Array<SelectOption<string>>

  // ref to trigger toggling by specific bar from the bar chart component
  // const customLegendsRef = useRef<{
  //   handleSpecificBarToggle: (id: string | number) => void
  // }>()
  const chartRef = useRef<HTMLCanvasElement>(null)

  // data related state
  const [reshapedDataArr, setReshapedDataArr] = useState<BarReshapedDataType>(
    []
  )
  const [stepIndex, setStepIndex] = useState<number>(0)
  const [filteredReshapedDataArr, setFilteredReshapedDataArr] =
    useState<BarReshapedDataType>([])

  const [stepsEnabled, setStepsEnabled] = useState(
    page.autoGenerateDashboard?.demoEnabled === true &&
      localStorage.getItem('dashboardTourCompleted') !== 'true' &&
      !isMobile &&
      window.location.hostname !== 'localhost'
  )

  // chart related state
  const [totalData, setTotalData] = useState<number>(0)
  const [colors, setColors] = useState<DynamicKeyType<string>>({})
  const [uniqueKeys, setUniqueKeys] = useState<string[]>([])
  const [adjustOpen, setAdjustOpen] = useState<boolean>(false)
  // filtering state
  const [occurenceThreshold, setOccurenceThreshold] = useState<number>(1)

  const [sortBy, setSortBy] = useState<SelectOption<string>>({
    label: 'Default',
    value: '',
  })

  const sortOrder = sortingOrders
    ? sortingOrders[transformBy.value as keyof typeof sortingOrders]
    : undefined

  const legendSortOrder = sortingOrders
    ? sortingOrders[stackBy.value as keyof typeof sortingOrders]
    : undefined

  const showThresholdSlider =
    !Boolean(sumByKey) && Boolean(showOccurenceThreshold)

  const totalCandidates = useMemo(() => {
    return filteredReshapedDataArr.reduce((sum, item) => {
      return (
        sum +
        Object.keys(item).reduce((propertySum, property) => {
          if (property !== 'key' && typeof item[property] === 'number') {
            return propertySum + (item[property] as unknown as number)
          }
          return propertySum
        }, 0)
      )
    }, 0)
  }, [filteredReshapedDataArr])

  const aggregateDataByAxisAndStackValues = (
    data: typeof chartData,
    transformByPropertyKey: string,
    stackByKey: string
  ): { [key: string]: DynamicKeyType<number> } => {
    const groupedData: { [key: string]: DynamicKeyType<number> } = {}

    data.forEach((item: any) => {
      const transformKey = Array.isArray(item[transformByPropertyKey])
        ? item[transformByPropertyKey]
        : [item[transformByPropertyKey]]

      const stackKey = Array.isArray(item[stackByKey])
        ? item[stackByKey]
        : [item[stackByKey]]

      const isSumming = typeof sumByKey !== 'undefined'
      const sumByKeyValue = sumByKey ? item[sumByKey] : null

      transformKey.forEach((key: any) => {
        // this if checks if the stackBy item is meant to be separated
        // if so, we enter the else and loop over each entry.
        if (
          (allowValuesToBeJoinedWhitelist as string[])?.includes(stackByKey) &&
          !invalidKeys.includes(key) &&
          !invalidKeys.includes(stackKey as any)
        ) {
          const { formattedKey, formattedStackByItem } = formatKeyAndItem(
            key,
            stackKey
          )
          combineObject(
            groupedData,
            formattedKey,
            formattedStackByItem,
            sumByKeyValue,
            isSumming
          )
        } else {
          stackKey.forEach((stack: any) => {
            if (!invalidKeys.includes(key) && !invalidKeys.includes(stack)) {
              const { formattedKey, formattedStackByItem } = formatKeyAndItem(
                key,
                stack
              )
              combineObject(
                groupedData,
                formattedKey,
                formattedStackByItem,
                sumByKeyValue,
                isSumming
              )
            }
          })
        }
      })
    })
    return groupedData
  }

  const transformAggregatedDataIntoCountArray = (intermediateObject: {
    [key: string]: DynamicKeyType<number>
  }) => {
    const keysArray: string[] = []
    let uniqueKeysSet: string[] = []

    const transformedArray = Object.keys(intermediateObject).map((key) => {
      const countObj = intermediateObject[key]
      const transformedCounts: DynamicKeyType<number> = Object.keys(
        countObj
      ).reduce((result: any, name) => {
        if (
          !invalidKeys.includes(name) &&
          countObj[name] >= occurenceThreshold
        ) {
          result[name] = countObj[name]
        }
        return result
      }, {})
      if (Object.keys(transformedCounts).length > 0) {
        keysArray.push(...Object.keys(transformedCounts))
        uniqueKeysSet = [...new Set(keysArray)]
      }
      return {
        key,
        ...transformedCounts,
      }
    })

    const filteredArray = transformedArray.filter(
      (obj) => Object.keys(obj).length > 1
    )

    const finalArray = sortOrder
      ? filteredArray.filter((w) => sortOrder.includes(w.key))
      : filteredArray

    setTotalData(filteredArray.length)

    return {
      filteredArray:
        finalArray.length > UPPER_COLUMN_LIMIT
          ? finalArray.slice(0, UPPER_COLUMN_LIMIT)
          : finalArray,
      uniqueKeysSet:
        filteredArray.length > UPPER_COLUMN_LIMIT
          ? uniqueKeysSet.slice(0, UPPER_COLUMN_LIMIT)
          : uniqueKeysSet,
    }
  }

  const reshapeDataForChart = useCallback(
    (data: typeof chartData) => {
      const transformByPropertyKey = transformBy.value
      const stackByKey = stackBy.value
      const intermediateObject = aggregateDataByAxisAndStackValues(
        data,
        transformByPropertyKey,
        stackByKey
      )

      const { filteredArray, uniqueKeysSet } =
        transformAggregatedDataIntoCountArray(intermediateObject)

      if (sortBy.value === '') {
        if (typeof sortOrder !== 'undefined') {
          filteredArray.sort(
            (a, b) => sortOrder.indexOf(a.key) - sortOrder.indexOf(b.key)
          )
        } else {
          filteredArray.sort((a, b) =>
            a.key.toLowerCase().localeCompare(b.key.toLowerCase())
          )
        }
      } else if (sortBy.value === 'ALPHA') {
        filteredArray.sort((a, b) =>
          a.key.toLowerCase().localeCompare(b.key.toLowerCase())
        )
      } else if (sortBy.value === 'DESC' || sortBy.value === 'ASC') {
        filteredArray.sort((a, b) => {
          const sumA = Object.values(a)
            .filter((key) => key !== 'key')
            .reduce(
              (acc, curr) => acc + (typeof curr === 'number' ? curr : 0),
              0
            )
          const sumB = Object.values(b)
            .filter((key) => key !== 'key')
            .reduce(
              (acc, curr) => acc + (typeof curr === 'number' ? curr : 0),
              0
            )
          if (sortBy.value === 'ASC') return sumA - sumB
          return sumB - sumA
        })
      }
      generateColorsForStack(uniqueKeysSet, colorTheme)
      return { filteredArray, uniqueKeysSet }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chartData,
      occurenceThreshold,
      transformBy.value,
      stackBy.value,
      sortBy.value,
    ]
  )

  const getColor = useCallback(
    (bar: any) => colors[bar.id as keyof typeof colors],
    [colors]
  )

  const generateColorsForStack = useCallback(
    (uniqueKeys: string[], baseColors: string[]) => {
      uniqueKeys.forEach((key, index) => {
        const color = baseColors[index % baseColors.length]
        if (!colors[key]) {
          setColors((prevColorKeys) => {
            let newColorKeys = {
              ...prevColorKeys,
              [key]: color,
            }
            return newColorKeys
          })
        }
      })
    },
    [colors]
  )

  const chart = useMemo(() => {
    return (
      <ResponsiveBarCanvas
        {...barChartCommonProperties}
        data={filteredReshapedDataArr}
        keys={uniqueKeys}
        valueScale={
          sumByKey ? { type: 'symlog', constant: 1e7 } : { type: 'linear' }
        }
        colors={getColor}
        tooltip={(input) => (
          <CustomTooltip
            numElementsX={filteredReshapedDataArr.length}
            horizontalKey={transformBy.label}
            stackByKey={stackBy.label}
            sumByKey={Boolean(sumByKey)}
            {...input}
          />
        )}
        margin={{
          top: 70,
          right: 40,
          bottom: totalData < 150 ? 80 : 40,
          left: 60,
        }}
        layers={['axes', 'bars', BarTotalsLayer]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: reshapedDataArr.length > 6 ? 30 : 0,
          legend: `${transformBy.label}`,
          legendPosition: 'middle',
          legendOffset: totalData < 150 ? 70 : 25,
          format: (d) =>
            totalData < 150
              ? `${d.length > 12 ? `${d.substring(0, 12)}...` : d}`
              : '',
        }}
        axisLeft={{
          tickValues: sumByKey ? 5 : 10,
          tickSize: 5,
          tickPadding: 2,
          legend: verticalAxisLabel,
          legendPosition: 'middle',
          legendOffset: -40,
          format: (e) =>
            sumByKey
              ? compactNumberWithPrefix(Number(e))
              : Math.floor(e) === e
                ? e
                : '',
        }}
        ref={chartRef}
        label={(d) =>
          `${
            filteredReshapedDataArr.length > 5
              ? compactLabel(
                  d.id.toString(),
                  filteredReshapedDataArr.length,
                  10
                )
              : d.id.toString()
          }`
        }
        onClick={(data) => {
          customLegendsRef.current?.handleSpecificBarToggle(
            data.id,
            data.indexValue
          )
          customLegendsRef1.current?.handleSpecificBarToggle(
            data.id,
            data.indexValue
          )
        }}
      />
    )
  }, [
    customLegendsRef,
    customLegendsRef1,
    filteredReshapedDataArr,
    getColor,
    reshapedDataArr.length,
    stackBy.label,
    sumByKey,
    totalData,
    transformBy.label,
    uniqueKeys,
    verticalAxisLabel,
  ])

  useEffect(() => {
    const { filteredArray, uniqueKeysSet: uniqueKeys } =
      reshapeDataForChart(chartData)
    setReshapedDataArr(filteredArray)
    setFilteredReshapedDataArr(filteredArray)
    setUniqueKeys(uniqueKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartData,
    occurenceThreshold,
    transformBy.value,
    stackBy.value,
    sortBy.value,
  ])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as Events[]).includes(type)
    ) {
      if (action === ACTIONS.PREV) {
        if (stepIndex === 3) setAdjustOpen(false)
        if (stepIndex === 5) setAdjustOpen(true)
      } else {
        if (stepIndex === 2) setAdjustOpen(true)
        if (stepIndex === 4) setAdjustOpen(false)
      }
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as Status[]).includes(status)
    ) {
      localStorage.setItem('dashboardTourCompleted', 'true')
      setAdjustOpen(false)
      setStepsEnabled(false)
    }
  }

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        continuous
        disableScrolling
        run={stepsEnabled}
        steps={barChartSteps}
        {...demoCommonProperties}
      />
      <Flex gap={2} w='full' h='full'>
        {!page.autoGenerateDashboard?.cardsRight && (
          <LeftSectionCards page={page} chartData={chartData} loaded={loaded} />
        )}
        <Box w='full' height='100%' display={'flex'}>
          <Panel
            height='100%'
            width='65%'
            border='1px solid'
            borderColor='gray3'
            background='white'
            borderRadius='8px'
            boxShadow='sm'
            flex={1}
            py={2}
            pos='relative'
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              position={'absolute'}
              left='0'
              top='0'
              zIndex={30}
            >
              {loaded ? (
                <>
                  <DataAdjustments
                    adjustOpen={adjustOpen}
                    setAdjustOpen={setAdjustOpen}
                    showStackBy={Boolean(showStackBy)}
                    showTransformBy={Boolean(showTransformBy)}
                    showThresholdSlider={showThresholdSlider}
                    showSortBy={Boolean(showSortBy)}
                    columnOptions={columnOptions}
                    sortBy={[sortBy, setSortBy]}
                    occurenceThreshold={[
                      occurenceThreshold,
                      setOccurenceThreshold,
                    ]}
                    defaultHorizontalFilterValue={defaultHorizontalFilterValue}
                    defaultStackByFilterValue={defaultStackByFilterValue}
                  >
                    <Box display={'flex'} flexDir={isMobile ? 'column' : 'row'}>
                      <CustomLegends
                        adjustOpen={adjustOpen}
                        columnKey={stackBy.value}
                        model={model}
                        ref={customLegendsRef}
                        colors={colors}
                        chartData={chartData}
                        stackByValue={stackBy.value}
                        loaded={Boolean(loaded)}
                        sortOrder={legendSortOrder}
                        joinedValues={
                          allowValuesToBeJoinedWhitelist as string[]
                        }
                        defaultHorizontalFilterValue={
                          defaultHorizontalFilterValue
                        }
                        defaultStackByFilterValue={defaultStackByFilterValue}
                      />
                      <CustomLegends
                        adjustOpen={adjustOpen}
                        columnKey={transformBy.value}
                        model={model}
                        ref={customLegendsRef1}
                        title='X-axis Options'
                        chartData={chartData}
                        stackByValue={transformBy.value}
                        loaded={Boolean(loaded)}
                        sortOrder={sortOrder}
                        joinedValues={
                          allowValuesToBeJoinedWhitelist as string[]
                        }
                        defaultHorizontalFilterValue={
                          defaultHorizontalFilterValue
                        }
                        defaultStackByFilterValue={defaultStackByFilterValue}
                      />
                    </Box>
                  </DataAdjustments>
                  {!isMobile &&
                    page.autoGenerateDashboard?.demoEnabled === true && (
                      <Button
                        mt={'10px'}
                        className='adjustButton'
                        variant='solid'
                        size={'xs'}
                        fontSize='12px'
                        onClick={() => {
                          localStorage.removeItem('dashboardTourCompleted')
                          setStepsEnabled(true)
                          setStepIndex(0)
                        }}
                      >
                        <Box mr={1}>
                          <Information size={16} />
                        </Box>
                        View demo
                      </Button>
                    )}
                  <Flex
                    border={'1px'}
                    borderColor={'gray.200'}
                    h={8}
                    px={1}
                    alignItems={'center'}
                    bg='gray.100'
                    mt={'8px'}
                    py={1}
                    ml={1}
                    gap={'1px'}
                    borderRadius={'sm'}
                  >
                    <Text
                      fontWeight={'semibold'}
                      color={'gray.800'}
                      lineHeight={'14px'}
                      fontSize={'xs'}
                      mr='4px'
                    >
                      Total candidates
                    </Text>
                    <Text
                      lineHeight={'14px'}
                      fontSize={'sm'}
                      fontWeight={'semibold'}
                    >
                      {totalCandidates}
                    </Text>
                  </Flex>
                  <Flex
                    gap={1}
                    className='exportButton'
                    ml={page.autoGenerateDashboard?.isEmbed ? 1 : 'auto'}
                  >
                    <Button
                      mt={2}
                      size={'xs'}
                      variant={'solid'}
                      onClick={() => openModal()}
                    >
                      Export Data
                    </Button>
                    <Button
                      mt={2}
                      mr={3}
                      size={'xs'}
                      variant={'solid'}
                      onClick={() => {
                        tracking.exportDashboardImage({})
                        exportChartToPng(
                          chartRef,
                          verticalAxisLabel!,
                          transformBy.label,
                          stackBy.label
                        )
                      }}
                    >
                      Export as Image
                    </Button>
                  </Flex>
                </>
              ) : null}
            </Box>
            {totalData > 100 && (
              <Box
                display={'flex'}
                alignItems='center'
                position={'relative'}
                justifyContent={'center'}
              >
                <Alert
                  position='absolute'
                  borderRadius={'lg'}
                  width='50%'
                  transition={'ease-in'}
                  fontWeight='semibold'
                  mt={'85px'}
                  fontSize='12px'
                  status='warning'
                  backgroundColor={'yellow.300'}
                >
                  <AlertIcon color={'yellow.800'} />
                  {infoMessage(totalData)}
                </Alert>
              </Box>
            )}
            {reshapedDataArr.length > 0 ? (
              <Box
                bg='white'
                className='barHighlight'
                position={'relative'}
                h='calc(100%)'
                zIndex={11}
              >
                {chart}
              </Box>
            ) : (
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                h={'100%'}
                w='100%'
                data-cy={loaded ? 'loading' : 'data-error'}
                flexDir={'column'}
                gap='1rem'
              >
                {loaded ? (
                  <Text ml={2}> No data found, try adjusting the filters.</Text>
                ) : (
                  <>
                    <Spinner thickness={'4px'} size='md' mt={4} />
                    <Text>Loading Data</Text>{' '}
                  </>
                )}
              </Flex>
            )}
          </Panel>
        </Box>
        {page.autoGenerateDashboard?.cardsRight && (
          <LeftSectionCards page={page} chartData={chartData} loaded={loaded} />
        )}
      </Flex>
    </>
  )
}

export default memo(BarChart)
