import { WidgetProps } from 'routes/dashboard/WidgetComponents'
import WidgetComponentArticle from 'routes/dashboard/WidgetComponents/WidgetArticle'

import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import ArticleTypes from '../tagColours/ArticleTypes'
import AuthorTypes from '../tagColours/AuthorTypes'
import Channels from '../tagColours/Channels'
import EfficacySentiment from '../tagColours/EfficacySentiment'
import LongCovidChannel from '../tagColours/LongCovidChannel'
import LongCovidPaperType from '../tagColours/LongCovidPaperType'
import Priority from '../tagColours/Priority'
import ReviewStatus from '../tagColours/ReviewStatus'
import Sentiments from '../tagColours/Sentiments'
import Tag from '../tagColours/Tag'
import Topic from '../tagColours/Topic'

export type PresentationsData =
  | 'title'
  | 'channel'
  | 'publicationDate'
  | 'language'
  | 'lookupJournalTitlesFromJournals'
  | 'sentiment'
  | 'candidate'
  | 'topics'
  | 'authors'
  | 'articleLink'
  | 'lookupKeywordsFromKeywords'
  | 'organisations'
  | 'candidates'
  | 'authorType'
  | 'abstract'
  | 'reviewStatus'
  | 'articleType'
  | 'articleViews'
  | 'pdfDownloads'
  | 'journalImpactScore'
  | 'tweetMentions'
  | 'socialScore'
  | 'otherKeywords'
  | 'journals'
  | 'stageOfIntervention'
  | 'afTrTrialDataType'
  | 'studyTypeClean'
  | 'afTrTrialDataSize'
  | 'countries'
  | 'type'
  | 'linkAfTrUniqueTreatmentNameToBnf'
  | 'afRiskFactor'
  | 'id'
  | 'drugClassGrouping'
  | 'afIndication'
  | 'afTopics'
  | 'afSubtopics'
  | 'afSentiment'
  | 'journalTitle'
  | 'candidateList'
  | 'linkAfLeadAuthor'
  | 'linkAfLastAuthor'
  | 'linkJournals'
  | 'afRelevant'
  | 'projectKeywordHit'
  | 'published'
  | 'candidateName'
  | 'lookupJournalImpactScore'
  | 'afArticleType'
  | 'doiUrl'
  | 'lookupCandidateList'
  | 'lookupCandidateName'
  | 'lookupCompanies'
  | 'lookupDrugType'
  | 'lookupDrugTarget'
  | 'linkClinicalTrials'
  | 'afDataInChildren'
  | 'keyNewsAlert'
  | 'automatedTopicsCategory'
  | 'automatedTopicsSubCategory'
  | 'typeFromCandidateList'
  | 'afComorbidity'
  | 'afComorbidityTreatmentsUtilised'
  | 'afComorbidityStudyType'
  | 'afComorbidityImpact'
  | 'afComorbidityStudyNumberOfDoses'
  | 'notes'
  | 'eidList'
  | 'articleTypesSpecific'
  | 'afEfficacySentiment'
  | 'candidateNameFromVaccinesCandidateList'
  | 'candidateNameFromTreatmentsCandidateList'
  | 'afTags'
  | 'concatOrganisations'
  | 'clinicalTrials'
  | 'studyTypeCleanFromClinicalTrials'
  | 'targetEnrollmentFromClinicalTrials'
  | 'priorityFromCandidates'
  | 'datePublishedInternally'
  | 'linkJournals'
  | 'fundingBody'
  | 'countryFromFundingBody'
  | 'researchInstitute'
  | 'countryFromResearchInstitute'
  | 'targetTreatments'
  | 'linkFundingBodyOrg'
  | 'linkResearchInstitute'
  | 'number'
  | 'targetTreatments'
  | 'afIndependent'
  | 'lookupLeadAuthorHIndex'
  | 'lookupLastAuthorHIndex'
  | 'luPathogenFamily'
  | 'indicationLinkInfectiousDisease'
  | 'linkAfTrUniqueTreatmentNameToBnfFromCandidateList'
  | 'countryFromResearchInstitute'
  | 'reviewType'
  | 'indications'
  | 'candidateClass'
  | 'detailedDeliverySystem'
  | 'safetyPaperType'
  | 'correlateInvestigated'
  | 'disease'
  | 'grouping2'
  | 'vaccineV'
  | 'longCovidPaperType'
  | 'afLeadAuthorHIndex'
  | 'afLastAuthorHIndex'

const allowedAirtables = [
  'api_app_treatments',
  'api_candidate_papers',
  'Long COVID_frontend',
  'api_igm',
  'api_multiple_myeloma_360',
  'api_lipid_360',
  'front_end_export_influenza_default_view',
  'front_end_export_influenza_vaccines_view',
  'front_end_export_influenza_treatments_view',
  'front_end_export_rsv_default_view',
  'front_end_export_rsv_vaccines_view',
  'front_end_export_rsv_treatments_view',
  'api_tt_rwd_papers',
  'api_booster_results',
  'api_anti_thrombotics_publications',
  'RNA_API_Publications',
  'API_Thrombotic_Adverse_Events',
  'API_Cardiac_Adverse_Events',
  'api_correlates',
  'API Front End Export',
  'API_Neurological_Adverse_Events',
  'Obesity Publications - Platform View',
  'Obesity Publications - Platform View - Epidemiology',
  'Obesity Publications - Platform bariatric surgery',
  'Obesity Publications - Platform non-bariatric surgery',
] as const

export const getPresentationDefaultWidget = ({
  view,
  model,
  title,
}: WidgetProps) => ({
  heading: title ?? 'Candidate Papers',
  body: (
    <WidgetComponentArticle<PresentationsData>
      view={view}
      model={model ?? PresentationsModel}
      url='articleLink'
      title='title'
      date='publicationDate'
    />
  ),
})

export const PresentationsModel: IModel<
  Record<PresentationsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'title',
  name: 'Candidate Papers',
  entityName: 'Publications',
  searchField: 'title',
  endpoint: 'presentation',
  defaultSortKey: 'publicationDate',
  defaultSortOrder: SortOrders.DESC,
  detailViewType: 'Article',
  detailView: {
    link: 'articleLink',
    abstract: 'abstract',
  },
  noPrint: true,
  schema: {
    columns: [
      {
        key: 'title',
        label: 'Title',
        width: 450,
      },
      {
        key: 'channel',
        label: 'Channel',
        type: ValueType.SINGLE,
        width: 100,
        cellFormat: {
          colours: Channels,
        },
      },
      {
        key: 'publicationDate',
        label: 'Publication date',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'language',
        label: 'Language',
        type: ValueType.SINGLE,
        width: 100,
      },
      {
        key: 'lookupJournalTitlesFromJournals',
        label: 'Journals',
        type: ValueType.MULTI,
        width: 300,
      },
      {
        key: 'sentiment',
        label: 'Sentiment',
        width: 120,
        type: ValueType.SINGLE,
        cellFormat: { colours: Sentiments },
      },
      {
        key: 'candidate',
        type: ValueType.MULTI,
        width: 230,
        label: 'Candidates',
      },
      {
        key: 'topics',
        width: 230,
        type: ValueType.MULTI,
        label: 'Topics',
      },
      {
        key: 'authors',
        width: 300,
        type: ValueType.MULTI,
        label: 'Authors',
      },
      {
        key: 'articleLink',
        label: 'Article Link',
        type: ValueType.URL,
      },
      {
        key: 'lookupKeywordsFromKeywords',
        width: 300,
        label: 'Keywords',
        type: ValueType.MULTI,
      },
      {
        key: 'organisations',
        type: ValueType.MULTI,
      },
      {
        key: 'candidates',
        type: ValueType.MULTI,
        cellFormat: {
          maxTags: 3,
        },
        showDetail: false,
        showTable: false,
      },
      {
        key: 'authorType',
        type: ValueType.SINGLE,
        label: 'Author type',
        showDetail: false,
        cellFormat: {
          colours: AuthorTypes,
        },
      },
      { key: 'abstract', type: ValueType.LONG, showTable: true },
      {
        key: 'reviewStatus',
        type: ValueType.SINGLE,
        width: 160,
        cellFormat: { colours: ReviewStatus },
        label: 'Review status',
      },
      {
        key: 'articleType',
        type: ValueType.MULTI,
        width: 300,
        cellFormat: { colours: ArticleTypes },
        label: 'Article Type',
      },
      {
        key: 'articleViews',
        width: 120,
        label: 'Views',
        type: ValueType.NUMBER,
      },
      {
        key: 'pdfDownloads',
        label: 'PDF downloads',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'journalImpactScore',
        width: 170,
        label: 'Journal impact score',
        type: ValueType.NUMBER,
      },
      {
        key: 'tweetMentions',
        width: 120,
        label: 'Tweets',
        type: ValueType.NUMBER,
      },
      {
        key: 'socialScore',
        label: 'Social score',
        showDetail: false,
        width: 120,
        type: ValueType.NUMBER,
      },
      {
        key: 'otherKeywords',
        label: 'Keywords',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'journals',
        label: 'Journal',
        type: ValueType.MULTI,
        width: 300,
        cellFormat: {
          colours: LongCovidChannel,
        },
      },
      {
        key: 'stageOfIntervention',
        label: 'Stage of Intervention',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'afTrTrialDataType',
        label: 'Trial Data Type',
        type: ValueType.SINGLE,
        width: 200,
      },
      {
        key: 'studyTypeClean',
        label: 'Study Type Clean',
        type: ValueType.SINGLE,
        width: 200,
      },
      {
        key: 'afTrTrialDataSize',
        label: 'Trial Data Size',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'countries',
        label: 'Country',
        type: ValueType.MULTI,
        width: 120,
      },
      {
        key: 'type',
        label: 'Type',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnf',
        label: 'Treatment Name',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'afRiskFactor',
        label: 'Risk Factor',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'drugClassGrouping',
        label: 'Drug Class Grouping',
        width: 220,
        type: ValueType.MULTI,
      },
      {
        key: 'afIndication',
        label: 'Indication',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afTopics',
        label: 'Topics',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: { colours: Topic },
      },
      {
        key: 'afSubtopics',
        label: 'Subtopics',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'automatedTopicsCategory',
        label: 'Topics',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'automatedTopicsSubCategory',
        label: 'Subtopics',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afSentiment',
        label: 'Sentiment',
        width: 130,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Sentiments,
        },
      },
      {
        key: 'journalTitle',
        label: 'Journal',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'linkAfLeadAuthor',
        label: 'Lead Author',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'linkAfLastAuthor',
        label: 'Last Author',
        width: 170,
        type: ValueType.MULTI,
      },
      {
        key: 'linkJournals',
        label: 'Journal',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'projectKeywordHit',
        label: 'Project Keyword Hit',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'published',
        label: 'Published',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'candidateName',
        label: 'Candidates',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupJournalImpactScore',
        label: 'Journal Impact Score',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afArticleType',
        label: 'Article Type',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: { colours: ArticleTypes },
      },
      {
        key: 'doiUrl',
        label: 'Article Link',
        width: 120,
        type: ValueType.URL,
      },
      {
        key: 'lookupCandidateList',
        label: 'Candidates',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkClinicalTrials',
        label: 'Clinical Trials',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'keyNewsAlert',
        label: 'Key News Alert',
        width: 120,
        type: ValueType.BOOLEAN,
      },
      {
        key: 'typeFromCandidateList',
        label: 'Treatment Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afComorbidity',
        label: 'Comorbidity',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afComorbidityTreatmentsUtilised',
        label: 'Treatments for comorbidity used',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afComorbidityStudyType',
        label: 'Study Type',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afComorbidityImpact',
        label: 'Comorbidity Impact',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'afComorbidityStudyNumberOfDoses',
        label: 'Number of Doses',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'notes',
        label: 'Notes',
        width: 180,
        type: ValueType.TEXT,
      },

      { key: 'eidList', label: 'Diseases', width: 200, type: ValueType.MULTI },
      {
        key: 'articleTypesSpecific',
        label: 'Article Type',
        width: 190,
        type: ValueType.MULTI,
        cellFormat: { colours: ArticleTypes },
      },
      {
        key: 'afEfficacySentiment',
        label: 'Sentiment',
        width: 170,
        type: ValueType.SINGLE,
        cellFormat: { colours: EfficacySentiment },
      },
      {
        key: 'candidateNameFromVaccinesCandidateList',
        label: 'Vaccine Candidate',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromTreatmentsCandidateList',
        label: 'Non-vaccine Candidate',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'afTags',
        label: 'Tags',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: { colours: Tag },
      },
      {
        key: 'concatOrganisations',
        label: 'Organisations',
        width: 240,
        type: ValueType.TEXT,
      },
      {
        key: 'clinicalTrials',
        label: 'Clinical Trials',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'studyTypeCleanFromClinicalTrials',
        label: 'Study Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'targetEnrollmentFromClinicalTrials',
        label: 'Target Enrollment',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'priorityFromCandidates',
        label: 'Priority',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Priority,
        },
      },
      {
        key: 'datePublishedInternally',
        label: 'Date Added',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'linkJournals',
        label: 'Journal Title',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'fundingBody',
        label: 'Funding Body',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromFundingBody',
        label: 'Funding Body Country',
        width: 310,
        type: ValueType.MULTI,
      },
      {
        key: 'researchInstitute',
        label: 'Research Institute',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'countryFromResearchInstitute',
        label: 'Research Institute Country',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkAfTrUniqueTreatmentNameToBnfFromCandidateList',
        label: 'Candidates',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'targetTreatments',
        label: 'Target',
      },
      {
        key: 'targetTreatments',
        label: 'Target (Treatments)',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkFundingBodyOrg',
        label: 'Funding Body/Organisation',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'number',
        label: 'Number',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'afIndependent',
        label: 'Af Independent',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkResearchInstitute',
        label: 'Research Institute',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupLeadAuthorHIndex',
        label: 'Lookup Lead Author h-Index',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'luPathogenFamily',
        label: 'lu Pathogen Family',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'indicationLinkInfectiousDisease',
        label: 'Indication Link Infectious Disease',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'reviewType',
        label: 'Review Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'indications',
        label: 'Disease',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'detailedDeliverySystem',
        label: 'Delivery System',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'safetyPaperType',
        label: 'Safety Paper Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'correlateInvestigated',
        label: 'Correlate of Protection',
        width: 180,
        type: ValueType.MULTI,
      },
      { key: 'disease', label: 'Disease', width: 150, type: ValueType.MULTI },
      {
        key: 'grouping2',
        label: 'Transmission Mode',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'vaccineV',
        label: 'Vaccine',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'longCovidPaperType',
        label: 'Paper Type',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: {
          colours: LongCovidPaperType,
        },
      },
      {
        key: 'afLeadAuthorHIndex',
        label: 'Lead Author H Index',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'afLastAuthorHIndex',
        label: 'Last Author H Index',
        width: 200,
        type: ValueType.MULTI,
      },
    ],
  },
}
