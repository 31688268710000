import { Box, Button, Flex, Text } from '@chakra-ui/react'
import CardContainer from 'components/Dashboards/IDATrialsMapDashboard/Cards/CardContainer'
import { useState } from 'react'
import { Line } from 'recharts'
import { LineChart, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { FiltersT } from '../../Epidemiology'
import {
  downloadCSV,
  formatNumber,
  generateColorFromString,
  getEpiTrendData,
} from '../../Epidemiology/helpers'
import { Download } from '@carbon/icons-react'
import { DataTypeSelector } from '../DataTypeSelector'

const SimpleDataSelectorOptions = [
  { value: 'confirmed_value', label: 'Cumulative' },
  { value: 'confirmed_per_100k', label: 'Per 100K' },
]
const DataSelectorOptions = [
  { value: 'confirmed_value', label: 'Confirmed' },
  { value: 'suspected_value', label: 'Suspected' },
  { value: 'confirmed_per_100k', label: 'Confirmed per 100K' },
  { value: 'suspected_per_100k', label: 'Suspected per 100K' },
]

function exportData(
  countries: string[],
  graphData: any,
  dataType: string,
  filters: FiltersT
) {
  const headers = ['Date', 'Cumulative Value', countries].join(',')
  const rows: string[] = []

  graphData.forEach((row: any) => {
    rows.push(
      [row.date, countries.map((country) => row[country] || 0)].join(',')
    )
  })

  const fileName = `Cases_over_time_${filters.country}_${dataType}_${filters.dateRange.start}_${filters.dateRange.end}.csv`
  downloadCSV(headers, rows, fileName)
}

export type SubDataTypeT =
  | 'confirmed_value'
  | 'suspected_value'
  | 'confirmed_per_100k'
  | 'suspected_per_100k'

const EpiTrend = ({
  data,
  filters,
  title,
  subDataType,
  setSubDataType,
}: {
  data: any | undefined
  subDataType: SubDataTypeT
  setSubDataType: (value: SubDataTypeT) => void
  filters: FiltersT
  title: string
}) => {
  const [expanded, setExpanded] = useState(false)

  if (!data) return null

  const valueType = subDataType.includes('per_100k')
    ? 'value_per_100k'
    : 'cumulative_value'

  const formattedData = getEpiTrendData(
    data,
    valueType,
    filters.dateRange,
    filters.country
  )

  const { data: graphData, countries } = formattedData

  const chartLines = countries.slice(0, filters.nCountries)

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          px={1}
          py={2}
          borderWidth='1px'
          borderRadius='md'
          bg='#ffffff'
          shadow='md'
          fontSize={'sm'}
        >
          <Text textAlign={'center'} fontWeight='bold'>
            {label}
          </Text>
          <Flex flexDir={'column'} gap='4px'>
            {payload.map((item: any) => {
              return (
                <Flex key={item.dataKey} alignItems='center' gap='6px'>
                  <Box w='12px' h='12px' bg={item.color} />
                  <Text>{item.dataKey}:</Text>
                  <Text fontWeight={'semibold'}>{item.value}</Text>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      )
    }
    return null
  }

  function handleDataTypeChange(e: { label: string; value: string }) {
    setSubDataType(e.value as SubDataTypeT)
  }

  const isInfections = filters.dataType === 'infections'

  const noData = !countries.some((item) => !!item)
  return (
    <CardContainer
      minWidth='350px'
      expanded={expanded}
      setExpanded={setExpanded}
      width='100%'
    >
      <Flex
        w='90%'
        gap={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontWeight={'semibold'} fontSize='sm'>
          {title}
        </Text>
        <Flex alignItems={'center'} gap={2}>
          <DataTypeSelector
            value={subDataType}
            onChange={handleDataTypeChange}
            options={
              isInfections ? DataSelectorOptions : SimpleDataSelectorOptions
            }
          />
          <Button
            onClick={() =>
              exportData(countries, graphData, subDataType, filters)
            }
            variant={'ghost'}
            w={'24px'}
            h={'24px'}
            p={0}
          >
            <Download size={16} />
          </Button>
        </Flex>
      </Flex>
      <Box w={'100%'} h={'100%'} pb='1rem'>
        {noData ? (
          <Flex justifyContent={'center'} alignItems='center' height='100%'>
            <Text>No data for this filter</Text>
          </Flex>
        ) : (
          <ResponsiveContainer width='100%' height='100%'>
            <LineChart
              data={graphData}
              margin={{
                top: 20,
                right: 10,
                left: expanded ? 10 : 20,
                bottom: -20,
              }}
            >
              <XAxis dataKey='date' fontSize={expanded ? '12px' : '8px'} />
              <YAxis
                tickFormatter={formatNumber}
                type='number'
                width={15}
                fontSize={expanded ? '12px' : '8px'}
              />
              <Tooltip content={<CustomTooltip />} />
              {chartLines.map((country) => {
                return (
                  <Line
                    strokeWidth={2}
                    key={country}
                    type='monotone'
                    dataKey={country}
                    stroke={generateColorFromString(country)}
                    dot={false}
                    connectNulls
                  />
                )
              })}
            </LineChart>
          </ResponsiveContainer>
        )}
      </Box>
    </CardContainer>
  )
}

export default EpiTrend
