import { Flex, Text, Box, Progress } from '@chakra-ui/react'
import { IResponseBase } from 'api/types'
import { useCallback, useEffect, useState } from 'react'
import {
  FilterType,
  OccurenceByKeyOutput,
  checkIfSelected,
  countOccurrences,
  getResultsValue,
  progressChartSelectOptions,
  useGetFilterType,
} from '../utils'
import { Select, SelectOption } from 'components/Select'
import CardContainer from './CardContainer'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { FilterTypeKeys } from 'components/Table/AdvancedSearch/filters'

const TrialsProgressChart = ({
  data,
  loading,
  options,
  title,
  filterType,
  isEmbed,
}: {
  data: IResponseBase[]
  loading: boolean
  options?: SelectOption<string>[]
  filterType?: FilterTypeKeys
  isEmbed?: boolean
  title?: string
}) => {
  const [_, setResults] = useState<OccurenceByKeyOutput[]>([])
  const [valueResults, setValueResults] = useState<OccurenceByKeyOutput[]>([])
  const [expanded, setExpanded] = useState<boolean>(false)
  const [selectedProperty, setSelectedProperty] = useState<
    SelectOption<string>
  >(
    options?.[0] ?? {
      label: 'Country',
      value: 'areas',
    }
  )

  const { tableFilters, addToFilters } = useTableChartFilters()
  const filterTypes = useGetFilterType(selectedProperty.value)

  const handleClick = useCallback(
    (property: string, value: string) => {
      const tempFilters: FilterType[] = [...tableFilters]
      const existingIndex = tempFilters.findIndex((f) => f.column === property)
      const newFilterObj = {
        label: value,
        value,
      }

      if (existingIndex !== -1) {
        const existingFilter = tempFilters[existingIndex]
        const valueIndex = existingFilter.filterValue.findIndex(
          (v: any) => v.value === value
        )

        if (valueIndex !== -1) {
          existingFilter.filterValue.splice(valueIndex, 1)
          if (existingFilter.filterValue.length === 0) {
            tempFilters.splice(existingIndex, 1)
          }
        } else {
          existingFilter.filterValue.push(newFilterObj)
        }
      } else {
        tempFilters.push({
          column: property,
          filterValue: [newFilterObj],
          type: filterType ?? filterTypes[1].key ?? 'includesAny',
        })
      }

      addToFilters(tempFilters)
    },
    [addToFilters, filterTypes, filterType, tableFilters]
  )

  useEffect(() => {
    setResults(countOccurrences(data, selectedProperty.value, false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, loading])

  useEffect(() => {
    setValueResults(countOccurrences(data, selectedProperty.value, false))
  }, [selectedProperty, data])

  return (
    <CardContainer
      className='trialsByCountry'
      expanded={expanded}
      setExpanded={setExpanded}
      loading={loading}
      height='100%'
    >
      <Flex mt={1} alignItems={'center'} h='max-content'>
        <Flex flexDir={'column'}>
          <Text fontWeight='bold' mt={-2} fontSize={'xs'} color={'black'}>
            {title ?? 'Clinical Trials by'}
          </Text>
        </Flex>
        <Box mt={-2} ml={-2} w={expanded ? '200px' : '170px'}>
          <Select
            value={selectedProperty}
            isMulti={false}
            onChange={(x: any) => setSelectedProperty(x)}
            options={
              options ??
              progressChartSelectOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )
            }
            backgroundColor='transparent'
            fontSize={'13px'}
            noBorder
          />
        </Box>
      </Flex>
      <Box
        overflow={'scroll'}
        maxH={expanded ? '450px' : isEmbed ? '350px' : '250px'}
        h={expanded ? '50vh' : false ? '100%' : '22vh'}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        overflowY='auto'
        mt={2}
      >
        {valueResults.map((item, index) => {
          const isSelected = checkIfSelected(
            tableFilters,
            selectedProperty.value,
            item.label
          )
          const { cases, percentage } =
            getResultsValue(valueResults, item.label as string) ?? {}
          return (
            <Flex
              px={2}
              py={1}
              rounded='md'
              cursor={'pointer'}
              backgroundColor={isSelected ? 'gray.100' : 'white'}
              onClick={() =>
                handleClick(selectedProperty.value, item.label as string)
              }
              _hover={{
                backgroundColor: 'gray.100',
                color: 'black',
              }}
              border={isSelected ? '1px' : 'none'}
              borderColor={'gray.200'}
              key={index}
              flexDir='column'
              mb={1}
            >
              <Flex justifyContent={'space-between'}>
                <Text maxW='160px' fontSize={'xs'}>
                  {item.label}
                </Text>
                <Text w='50px' ml='1rem' fontSize={'xs'} textAlign='end'>
                  {cases ?? 0}
                </Text>
              </Flex>
              <Progress value={percentage ?? 0} size='xs' color='interactive' />
            </Flex>
          )
        })}
      </Box>
    </CardContainer>
  )
}

export default TrialsProgressChart
