import { Text, Flex, Box, Tooltip } from '@chakra-ui/react'
import { IResponseBase, ResponseData } from 'api/types'
import { useCallback, useMemo, useState } from 'react'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'
import CardContainer from './CardContainer'
import {
  FilterType,
  checkIfSelected,
  progressChartSelectOptions,
  useGetFilterType,
} from '../utils'
import { useTableChartFilters } from 'contexts/TableChartFilters'
import { Select, SelectOption } from 'components/Select'

const COLORS = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FF5A5E',
  '#8AC926',
  '#FF8A80',
  '#6A4C93',
  '#FFD166',
]

const TrialsByYearPieChart = ({
  data,
  loading,
}: {
  data: IResponseBase[]
  loading: boolean
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [selectedProperty, setSelectedProperty] = useState<
    SelectOption<string>
  >({
    label: 'Country',
    value: 'areas',
  })

  const { tableFilters, addToFilters } = useTableChartFilters()
  const filterTypes = useGetFilterType('areas')

  const handleClick = useCallback(
    (property: string, value: string) => {
      const tempFilters: FilterType[] = [...tableFilters]
      const existingIndex = tempFilters.findIndex((f) => f.column === property)
      const newFilterObj = {
        label: value,
        value,
      }

      if (existingIndex !== -1) {
        const existingFilter = tempFilters[existingIndex]
        const valueIndex = existingFilter.filterValue.findIndex(
          (v: any) => v.value === value
        )

        if (valueIndex !== -1) {
          existingFilter.filterValue.splice(valueIndex, 1)
          if (existingFilter.filterValue.length === 0) {
            tempFilters.splice(existingIndex, 1)
          }
        } else {
          existingFilter.filterValue.push(newFilterObj)
        }
      } else {
        tempFilters.push({
          column: property,
          filterValue: [newFilterObj],
          type: filterTypes[1].key,
        })
      }

      addToFilters(tempFilters)
    },
    [addToFilters, filterTypes, tableFilters]
  )

  const countTopOccurrences = useCallback(
    (key: string, top: number = 4) => {
      const counts: { [key: string]: number } = {}
      let total = 0

      for (const obj of data) {
        const value = obj[key] as ResponseData
        if (Array.isArray(value)) {
          for (const item of value) {
            if (typeof item === 'string') {
              counts[item] = (counts[item] || 0) + 1
              total++
            }
          }
        } else if (typeof value === 'string') {
          counts[value] = (counts[value] || 0) + 1
          total++
        }
      }
      const sortedCounts = Object.entries(counts)
        .sort(([, a], [, b]) => b - a)
        .slice(0, top)

      const topCounts = sortedCounts.map(([name, value]) => ({ name, value }))
      const otherCount =
        total - topCounts.reduce((sum, { value }) => sum + value, 0)

      if (otherCount > 0) {
        topCounts.push({ name: 'Other', value: otherCount })
      }

      return {
        counts: topCounts,
        totalForYear: total,
      }
    },
    [data]
  )
  const { counts, totalForYear } = useMemo(
    () => countTopOccurrences(selectedProperty.value, expanded ? 10 : 4),
    [countTopOccurrences, expanded, selectedProperty.value]
  )

  return (
    <CardContainer
      className='clinicalTrialsPerCountry'
      expanded={expanded}
      setExpanded={setExpanded}
      loading={loading}
    >
      <Box>
        <Flex mt={1} alignItems={'center'} h='max-content'>
          <Flex flexDir={'column'}>
            <Text fontWeight='bold' mt={-2} fontSize={'xs'} color={'black'}>
              Clinical Trials by
            </Text>
          </Flex>
          <Box mt={-2} ml={-2} w={expanded ? '200px' : '170px'}>
            <Select
              value={selectedProperty}
              isMulti={false}
              onChange={(x: any) => setSelectedProperty(x)}
              options={progressChartSelectOptions.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              backgroundColor='transparent'
              fontSize={'13px'}
              noBorder
            />
          </Box>
        </Flex>
        {counts.length > 0 ? (
          <Flex
            mt={expanded ? -4 : 0}
            flexDir={expanded ? 'row' : 'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              w={expanded ? '500px' : '180px'}
              h={expanded ? '500px' : '140px'}
            >
              <ResponsiveContainer width='100%' height='100%'>
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={counts}
                    onClick={(entry) => {
                      entry.name !== 'Other' &&
                        handleClick(
                          selectedProperty.value,
                          entry.name as string
                        )
                    }}
                    startAngle={90}
                    endAngle={-450}
                    dataKey='value'
                    nameKey='name'
                    cx='50%'
                    cy='50%'
                    innerRadius={expanded ? 140 : 45}
                    outerRadius={expanded ? 180 : 65}
                    fill='#82ca9d'
                  >
                    {counts.map((entry, index) => (
                      <Cell
                        key={entry.name}
                        fill={
                          entry.name === 'Other'
                            ? '#5D6D7E'
                            : COLORS[index % COLORS.length]
                        }
                      />
                    ))}
                    <Label
                      value={totalForYear}
                      position='centerBottom'
                      fontSize={expanded ? '22px' : '18px'}
                    />
                    <Label
                      value='total'
                      position='centerTop'
                      fontSize={expanded ? '16px' : '12px'}
                    />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Flex>
            <Flex
              maxW={'500px'}
              flexWrap={'wrap'}
              p={1}
              gap={1}
              columnGap={expanded ? 4 : 1}
            >
              {counts.map((entry, index) => {
                const isSelected = checkIfSelected(
                  tableFilters,
                  'areas',
                  entry.name
                )
                return (
                  <Box
                    backgroundColor={isSelected ? 'gray.100' : 'white'}
                    border={isSelected ? '1px' : 'none'}
                    borderColor={'gray.200'}
                    color={isSelected ? 'black' : 'gray.500'}
                    cursor={'pointer'}
                    rounded={'lg'}
                    px={1}
                    _hover={{
                      backgroundColor: 'gray.100',
                      color: 'black',
                    }}
                    onClick={() =>
                      entry.name !== 'Other' &&
                      handleClick(selectedProperty.value, entry.name as string)
                    }
                    key={entry.name}
                  >
                    <Flex alignItems={'center'} gap={0.5}>
                      <Box
                        h={3}
                        w={3}
                        rounded='sm'
                        backgroundColor={
                          entry.name === 'Other'
                            ? '#5D6D7E'
                            : COLORS[index % COLORS.length]
                        }
                      />
                      <Tooltip label={entry.name}>
                        <Text
                          maxW={expanded ? 'initial' : '100px'}
                          isTruncated={expanded ? false : true}
                          fontSize={expanded ? 'sm' : 'xs'}
                        >
                          {entry.name}
                        </Text>
                      </Tooltip>
                      <Text
                        fontSize={expanded ? 'sm' : 'xs'}
                        fontWeight={'semibold'}
                        color={'black'}
                      >
                        {Number(entry.value)}
                      </Text>
                    </Flex>
                  </Box>
                )
              })}
            </Flex>
          </Flex>
        ) : (
          <Flex h={24} justifyContent={'center'} alignItems={'center'}>
            <Text
              fontSize={'sm'}
              textAlign={'center'}
              fontWeight={'semibold'}
              color='gray.600'
            >
              No data found for current selection
            </Text>
          </Flex>
        )}
      </Box>
    </CardContainer>
  )
}

export default TrialsByYearPieChart
