import { CaretDown, CaretUp } from '@carbon/icons-react'
import { Box, Flex, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { AlertsType, AlertType } from 'api/cms/alerts/useCountryAlerts'

export const alertColors: Record<AlertType, string> = {
  WA: 'red.400',
  IN: '#8BB9EA',
  HR: '#F47B7C',
  MR: '#FFBF84',
  LR: '#FFEEAD',
  UR: 'gray.300',
  NR: '#98C785',
  AI: '#FFB6C1',
  ZR: 'purple.400',
}
export const alertLevel: Record<AlertType, string> = {
  HR: 'High',
  MR: 'Moderate',
  LR: 'Low',
  UR: 'Unknown',
  NR: 'None',
  WA: 'Warning',
  IN: 'Information',
  AI: 'HPAI',
  ZR: 'Zoonotic',
}
export const alertRiskLevelOrder = Object.keys(alertLevel)

type PropTypes = {
  alert: AlertsType
  forceOpen?: boolean
  fullPage?: boolean
}

const AlertCard = ({ alert, fullPage, forceOpen }: PropTypes) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: forceOpen })
  const [isHover, setIsHover] = useState(false)
  const { title, sub_header: subHeader, description, type } = alert

  const color = alertColors[type]

  return (
    <Box
      border='1px solid'
      rounded={'4px'}
      borderColor='gray.200'
      display='flex'
      mr='1rem'
      mb='0.75rem'
      overflow={'hidden'}
      pos='relative'
      cursor='pointer'
      onClick={onToggle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      minH='55px'
    >
      <Tooltip label={`Risk Type: ${alertLevel[type]}`} aria-label='Risk Type'>
        <Box color={`${color}`} bg={`${color}`} minW='25px' maxW='25px'>
          {!fullPage && (
            <Text
              color='legacy-primary.500'
              fontSize={'11px'}
              style={{
                transform: 'rotate(-90deg)',
                whiteSpace: 'nowrap',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {alertLevel[type]} {isOpen ? ' Risk' : ''}
            </Text>
          )}
        </Box>
      </Tooltip>

      <Box mx='0.5rem' mr='1rem' py='1rem'>
        <Flex>
          <Text variant={'body-bold'} color='legacy-primary.500'>
            {title}
          </Text>
        </Flex>
        {isOpen && (
          <>
            <Text color='legacy-primary.500' fontSize='sm' mb='4px'>
              {subHeader}
            </Text>
            <Box color='legacy-primary.500' fontSize='12px' mt='0.5rem'>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {description}
              </ReactMarkdown>
            </Box>
          </>
        )}
      </Box>
      {isHover && (
        <Box pos='absolute' bottom='0px' right={'4px'} id='dropdown'>
          <Icon
            as={isOpen ? CaretUp : CaretDown}
            color={'legacy-primary.400'}
          />
        </Box>
      )}
    </Box>
  )
}

export default AlertCard
