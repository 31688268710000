import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type RSVEpidemiologySlugs =
  | ''
  | 'hemispheres'
  | 'us-states'
  | 'hospitalisations'
  | 'research-briefs'

const RSVEpidemiologyMenu: Array<INavigationMenu<RSVEpidemiologySlugs>[]> = [
  [
    {
      title: 'Cases',
      slug: '',
      createdAt: new Date(2022, 4, 10),
    },
    {
      title: 'Hospitalisations',
      slug: 'hospitalisations',
      createdAt: new Date(2023, 2, 13),
    },
    {
      title: 'Research Briefs',
      slug: 'research-briefs',
      createdAt: new Date(2024, 1, 25),
    },
  ],
]

export { RSVEpidemiologyMenu }
