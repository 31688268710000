import { useQuery } from '@tanstack/react-query'
import { Card, FiltersT } from '../..'
import useAxios from 'api/useAxios'
import { getQueryData } from 'modules/BioriskMpox/Response/LeftSection/Cards/Procurement'
import { Flex, Text } from '@chakra-ui/react'
import { LoadingAnimation } from 'components'
import EpiTrend, { SubDataTypeT } from 'modules/BioriskMpox/common/EpiTrend'
import _ from 'lodash'
import { useState } from 'react'

const EpiCases = ({
  filters,
  dataType,
}: {
  filters: FiltersT
  dataType: string
}) => {
  const [subDataType, setSubDataType] =
    useState<SubDataTypeT>('confirmed_value')

  const axios = useAxios()
  const { data, isLoading, error } = useQuery<any>(
    [
      'epi-cases',
      filters.dateRange,
      // TODO: subnational removal
      // filters.country,
      filters.dataType,
      subDataType,
    ],
    async () => {
      const extraDataEndpoint =
        subDataType.includes('suspected') && dataType === 'infections'
          ? 'suspected-'
          : ''
      const endpoint = `/lzdb/science-macro/time-series-${extraDataEndpoint}${dataType}-national-cumulative/?`
      const cumulative = await axios.get(getQueryData(filters, endpoint))
      return cumulative.data.data
    }
  )

  if (error || isLoading || !data) {
    return (
      <Card h='100%'>
        <Text fontWeight='semibold'>Cases</Text>
        <Flex justifyContent={'center'} alignItems='center' h='100%'>
          {error ? (
            <Text color='error'>Something went wrong loading the data</Text>
          ) : (
            <Flex h='50px' justifyContent={'center'} alignItems='center'>
              <LoadingAnimation />
            </Flex>
          )}
        </Flex>
      </Card>
    )
  }

  return (
    <EpiTrend
      title={`${filters.dataType === 'infections' ? 'Cases' : _.upperFirst(filters.dataType)} over time`}
      filters={filters}
      data={data}
      setSubDataType={setSubDataType}
      subDataType={subDataType}
    />
  )
}

export default EpiCases
