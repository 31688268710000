import { INavigationMenu } from 'interfaces/navigationMenu.interface'

export type ObesitySlugs =
  | ''
  | 'candidates'
  | 'table'
  | 'overview'
  | 'publications'
  | 'trials'
  | 'all-data'
  | 'timeline'
  | 'media'
  | 'reports'
  | 'press-releases'
  | 'progress-tracker'
  | 'approvals-forecast'
  | 'results'
  | 'efficacy-heat-map'
  | 'safety-heat-map'
  | 'social-media'
  | 'social-media-listening'
  | 'candidate-mentions'

const ObesityMenu: Array<INavigationMenu<ObesitySlugs>[]> = [
  [
    { title: 'Latest Information', slug: '', createdAt: new Date(2023, 12, 6) },
    { title: 'Reports', slug: 'reports', createdAt: new Date(2023, 12, 6) },
  ],
  [
    {
      title: 'Candidates',
      slug: 'candidates',
      description: '',
      createdAt: new Date(2023, 12, 6),
      children: [
        {
          title: 'Table',
          slug: 'table',
          createdAt: new Date(2024, 0, 1),
        },
        {
          title: 'Candidates Overview',
          slug: 'overview',
          createdAt: new Date(2023, 12, 6),
        },
        {
          title: 'Progress Tracker',
          slug: 'progress-tracker',
          createdAt: new Date(2023, 12, 6),
        },
      ],
    },
    {
      title: 'Publications',
      slug: 'publications',
      description: '',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Trials',
      slug: 'trials',
      description: '',
      createdAt: new Date(2023, 12, 6),
      children: [
        {
          slug: 'all-data',
          title: 'All Data',
          createdAt: new Date(2023, 12, 6),
        },
        {
          slug: 'timeline',
          title: 'Timeline',
          createdAt: new Date(2023, 12, 6),
        },
        {
          slug: 'approvals-forecast',
          title: 'Approvals Forecast',
          createdAt: new Date(2023, 12, 6),
        },
      ],
    },
    {
      title: 'Results',
      slug: 'results',
      description: '',
      createdAt: new Date(2023, 12, 6),
      children: [
        {
          slug: 'efficacy-heat-map',
          title: 'Efficacy Heat Map',
          createdAt: new Date(2023, 12, 6),
        },
        {
          slug: 'safety-heat-map',
          title: 'Safety Heat Map',
          createdAt: new Date(2023, 12, 6),
        },
      ],
    },
    {
      title: 'Media',
      slug: 'media',
      description: '',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Press Releases',
      slug: 'press-releases',
      description: '',
      createdAt: new Date(2023, 12, 6),
    },
    {
      title: 'Social Media',
      slug: 'social-media',
      createdAt: new Date(2024, 4, 29),
      children: [
        {
          title: 'Social Media Listening',
          slug: 'social-media-listening',
          createdAt: new Date(2024, 4, 29),
        },
        {
          title: 'Candidate Mentions',
          slug: 'candidate-mentions',
          createdAt: new Date(2024, 4, 29),
        },
      ],
    },
  ],
]

export { ObesityMenu }
